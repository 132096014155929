import axios, { AxiosHeaders } from 'axios';
import i18n from 'src/locales/i18n';

import {
  ApiKeysApi as ApiKeysAPI,
  CashFlowApi as CashFlowAPI,
  ChangelogsApi as ChangelogsAPI,
  ChartsApi as ChartsAPI,
  Configuration,
  ContactsApi as ContactsAPI,
  ContactsDebtsTransactionsApi as ContactsDebtsTransactionsAPI,
  CountriesApi as CountriesAPI,
  CouponsApi as CouponsAPI,
  DataImportsApi as DataImportsAPI,
  DebtTransactionsApi as DebtTransactionsAPI,
  DepositsApi as DepositsAPI,
  DeviceInfosApi as DeviceInfosAPI,
  DirectUploadApi as DirectUploadAPI,
  ExpenseTransactionsApi as ExpenseTransactionsAPI,
  FinancialDataApi as FinancialDataAPI,
  GuestUsersApi as GuestUsersAPI,
  IndustriesApi as IndustriesAPI,
  InitialBalanceAmountsApi as InitialBalanceAmountsAPI,
  ModifiersApi,
  OffersApi as OffersAPI,
  OrganizationApi as OrganizationAPI,
  OrganizationSettingApi as OrganizationSettingAPI,
  PaymentInfoApi as PaymentInfoAPI,
  PosTransactionsApi as PosTransactionsAPI,
  ProductCategoriesApi as ProductCategoriesAPI,
  ProductsApi as ProductsAPI,
  ReceiptsApi as ReceiptsAPI,
  ReportsApi as ReportsAPI,
  RolesApi as RolesAPI,
  SaleTransactionsApi as SaleTransactionsAPI,
  ServiceCategoriesApi as ServiceCategoriesAPI,
  ServicesApi as ServicesAPI,
  StatsApi as StatsAPI,
  StripeChargesApi as StripeChargesAPI,
  TagsApi as TagsAPI,
  TransactionCategoriesApi as TransactionCategoriesAPI,
  TransactionsApi as TransactionsAPI,
  UserApi as UserAPI,
  UserOrganizationsApi as UserOrganizationsAPI,
  UsersApi as UsersAPI,
  VoiceCommandsApi as VoiceCommandsAPI,
  WebTransactionsApi as WebTransactionsAPI,
} from './generated';

export * from './generated/api';

export const globalAxiosOptions = {
  headers: {},
};
interface CabalHeaders extends AxiosHeaders {
  Organization: string;
  TZ: string;
}

const customAxios = axios.create({
  headers: {
    Authorization: '',
    Organization: '',
    Accept: 'application/json',
  },
});

customAxios.interceptors.request.use((config) => {
  if (config.headers) {
    const headers = config.headers as CabalHeaders;

    headers.set('TZ', Intl.DateTimeFormat().resolvedOptions().timeZone);
    headers.set('Authorization', localStorage.getItem('accessToken') ?? '');

    const storedLang = localStorage.getItem('i18nextLng');
    const lang = storedLang || i18n.language || 'es';
    headers.set('Accept-Language', lang);

    if (!headers.Organization) {
      headers.set('Organization', localStorage.getItem('organization') ?? '');
    }
  }

  return config;
});

export const WebTransactionsApi = new WebTransactionsAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const ContactsApi = new ContactsAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const ProductCategoriesApi = new ProductCategoriesAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const TransactionCategoriesApi = new TransactionCategoriesAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const ProductsApi = new ProductsAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const DirectUploadApi = new DirectUploadAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const TransactionsApi = new TransactionsAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const CashFlowApi = new CashFlowAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const DebtTransactionsApi = new DebtTransactionsAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const ContactsDebtsTransactionsApi = new ContactsDebtsTransactionsAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const SaleTransactionsApi = new SaleTransactionsAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const ExpenseTransactionsApi = new ExpenseTransactionsAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const UserApi = new UserAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const DepositsApi = new DepositsAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const OrganizationSettingApi = new OrganizationSettingAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const UsersApi = new UsersAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const CountriesApi = new CountriesAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const ChartsApi = new ChartsAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const StatsApi = new StatsAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const IndustriesApi = new IndustriesAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const DeviceInfosApi = new DeviceInfosAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const PaymentInfoApi = new PaymentInfoAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const TagsApi = new TagsAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const PosTransactionsApi = new PosTransactionsAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const RolesApi = new RolesAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const UserOrganizationsApi = new UserOrganizationsAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const CouponsApi = new CouponsAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const OrganizationApi = new OrganizationAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const DataImportsApi = new DataImportsAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const OffersApi = new OffersAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const FinancialDataApi = new FinancialDataAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const ReportsApi = new ReportsAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const StripeChargesApi = new StripeChargesAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const ChangelogsApi = new ChangelogsAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const ReceiptsApi = new ReceiptsAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const ServicesApi = new ServicesAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const ServiceCategoriesApi = new ServiceCategoriesAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const ProductModifiersApi = new ModifiersApi(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const InitialBalanceAmountsApi = new InitialBalanceAmountsAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const ApiKeysApi = new ApiKeysAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const VoiceCommandsApi = new VoiceCommandsAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);

export const GuestUsersApi = new GuestUsersAPI(
  new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    baseOptions: {
      headers: {},
    },
  }),
  process.env.REACT_APP_API_URL as string,
  customAxios
);
