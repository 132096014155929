export const subscription = {
  subscription: '',
  subscriptionCancelSuccess: 'Tu suscripción ha sido cancelada',
  loadingSubscriptionInfo: 'Cargando información',
  loadingSubscriptionInfoError: 'Ocurrió un error cargando la data.',
  monthly: 'Mensual',
  yearly: 'Anual',
  lifetime: 'Lifetime',
  free: 'Gratis',
  status: 'Estatus',
  daysAvailable: 'días disponibles',
  dayAvailable: 'día disponible',
  freeTrial: 'Prueba gratuita',
  activeSubscription: 'Activa',
  canceledSubscription: 'Cancelada',
  startDate: 'Fecha de inicio',
  endDate: 'Fecha de finalización',
  nextPayment: 'Próximo pago',
  cancelationInfo:
    'Has cancelado tu suscripción, tendrás acceso a Cabal Plus hasta el',
  confirmCancelation: 'Confirmar Cancelación',
  confirmAction: 'Confirmar acción',
  confirmCancelationMessage:
    'Guardaremos tus datos, pero perderás acceso a todas las funcionalidades de Cabal Plus.',
  cancelSubscription: 'Cancelar suscripción',
  cancelExternalSubscriptionMesage:
    'Para cancelar tu suscripción visita la tienda (Google Play o App Store) de tu dispósitivo móvil.',
  wsCTA: 'Únete ahora',
  wsMessage:
    'Únete a nuestro grupo de WhatsApp para aprender maneras efectivas de manejar tu negocio.',
};
export type SubscriptionStrings = typeof subscription;
