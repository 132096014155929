import { TranslationKeys } from '../es';
import { general } from './general';
import { navigation } from './navigation';
import { product } from './products';
import { subscription } from './subscription';
import { suggestedPrice } from './suggestedPrice';
import { userAccount } from './userAccount';

const en: TranslationKeys = {
  ...navigation,
  ...general,
  ...product,
  ...userAccount,
  ...subscription,
  ...suggestedPrice,
};

export default en;
