import { KeyboardArrowLeft } from '@mui/icons-material';
import { Box, BoxProps, Button, Link, Stack } from '@mui/material';
import { ReactNode, forwardRef, useContext, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';

import { PageContext } from 'src/contexts/PageContext';
import { useStrings } from 'src/hooks/useStrings';
import { useSimpleLayout } from 'src/layouts/SimpleLayout';

interface Props extends BoxProps {
  children: ReactNode;
  meta?: ReactNode;
  title: string;
  backTo?: string;
  simpleLayoutProps?: {
    fullWidth: boolean;
    showLogo: boolean;
  };
}

const Page = forwardRef<HTMLDivElement, Props>(
  (
    { children, backTo, title = '', meta, simpleLayoutProps, ...other },
    ref
  ) => {
    const { strings } = useStrings();
    const { setShowLogo, setFullWidth } = useSimpleLayout();
    const { setPageTitle } = useContext(PageContext);

    useLayoutEffect(() => {
      if (simpleLayoutProps) {
        setShowLogo(simpleLayoutProps.showLogo);
        setFullWidth(simpleLayoutProps.fullWidth);
      }
      setPageTitle(title);
    }, [simpleLayoutProps]);

    return (
      <>
        <Helmet>
          <title>{`${title} | Cabal`}</title>
          {meta}
        </Helmet>

        {backTo && (
          <Stack>
            <Link component={RouterLink} to={backTo}>
              <Button startIcon={<KeyboardArrowLeft />}>
                {strings('return')}
              </Button>
            </Link>
          </Stack>
        )}

        <Box ref={ref} {...other}>
          {children}
        </Box>
      </>
    );
  }
);

export default Page;
