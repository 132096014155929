import { AppBar, Box, Chip, Stack, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext } from 'react';

import { hasOffersEnabled } from 'src/components/CabalPlusCTA/JoinCabalPlus/data/subscriptionTypes';
import { PromoBanner } from 'src/components/CabalPlusCTA/PromoBanner';
import { PageContext } from 'src/contexts/PageContext';
import { usePermissions } from 'src/hooks/usePermissions';
import { useStrings } from 'src/hooks/useStrings';
import { PaidAccountSwitcher } from 'src/sections/@dashboard/user/account/PaidAccountSwitcher';
import { WebOrderCounter } from 'src/sections/@dashboard/web-sales/WebOrderCounter';

import Iconify from '../../../components/Iconify';
import Logo from '../../../components/Logo';
import { IconButtonAnimate } from '../../../components/animate';
import { HEADER, NAVBAR } from '../../../config';
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
import cssStyles from '../../../utils/cssStyles';
import AccountPopover from './AccountPopover';
import { CountryFlag } from './CountryFlag';
import LanguagePopover from './LanguagePopover';
import Searchbar from './Searchbar';
import { TrialInformation } from './TrialInformation';
import WhatsNewPopover from './WhatsNewPopover';

type RootStyleProps = {
  isCollapse: boolean;
  isOffset: boolean;
  verticalLayout: boolean;
  extraHeight?: number;
  offSetExtraHeight?: number;
};

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})<RootStyleProps>(
  ({
    isCollapse,
    isOffset,
    verticalLayout,
    theme,
    extraHeight = 0,
    offSetExtraHeight = 0,
  }) => ({
    ...cssStyles(theme).bgBlur(),
    boxShadow: 'none',
    height: HEADER.MOBILE_HEIGHT,
    zIndex: theme.zIndex.appBar + 1,
    transition: theme.transitions.create(['width', 'height'], {
      duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up('lg')]: {
      height: HEADER.DASHBOARD_DESKTOP_HEIGHT + extraHeight,
      width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
      ...(isCollapse && {
        width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
      }),
      ...(isOffset && {
        height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT + offSetExtraHeight,
      }),
      ...(verticalLayout && {
        width: '100%',
        height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
        backgroundColor: theme.palette.background.default,
      }),
    },
  })
);

// ----------------------------------------------------------------------

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
};

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}: Props) {
  const { title } = useContext(PageContext);
  const { has } = usePermissions();
  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const { strings } = useStrings();

  const isDesktop = useResponsive('up', 'lg');
  const hasOffers = hasOffersEnabled();
  const shouldAddExtraHeight = hasOffers && !has('paid_access');

  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
      extraHeight={shouldAddExtraHeight ? 17 : 0}
      offSetExtraHeight={shouldAddExtraHeight ? 44 : 0}
    >
      <PromoBanner />
      <Toolbar
        sx={{
          minHeight: shouldAddExtraHeight ? '' : '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: 'text.primary' }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}
        <Typography color="text.primary" variant="h6">
          {title}
        </Typography>

        <Searchbar />
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {!has('paid_access') && (
            <Chip
              variant="filled"
              color="primary"
              size="small"
              label={strings('free')}
            />
          )}
          <PaidAccountSwitcher />
          <TrialInformation />

          <CountryFlag />
          <WebOrderCounter
            processStatus="in-approval"
            refetchInterval={1000 * 60}
          />
          <WhatsNewPopover />
          <LanguagePopover />
          {/* 
          <NotificationsPopover />
          <ContactsPopover />*/}
          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
