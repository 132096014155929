import { useCallback, useEffect, useState } from 'react';

// ----------------------------------------------------------------------

export default function useLocalStorage<ValueType>(
  key: string,
  defaultValue: ValueType
) {
  const [value, setValue] = useState<ValueType>(() => {
    const storedValue = localStorage.getItem(key);
    let response: any = defaultValue;

    if (storedValue === null) return response;

    try {
      response = JSON.parse(storedValue);
    } catch (error) {
      response = storedValue;
    }

    return response;
  });

  useEffect(() => {
    const listener = (e: StorageEvent) => {
      if (e.storageArea === localStorage && e.key === key) {
        setValue(e.newValue ? JSON.parse(e.newValue) : e.newValue);
      }
    };
    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, [key, defaultValue]);

  const setValueInLocalStorage = useCallback(
    (newValue: ValueType) => {
      setValue((currentValue: ValueType) => {
        const result =
          typeof newValue === 'function' ? newValue(currentValue) : newValue;

        localStorage.setItem(key, JSON.stringify(result));

        return result;
      });
    },
    [setValue]
  );

  return [value, setValueInLocalStorage] as const;
}
