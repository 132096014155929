export const suggestedPrice = {
  spTriggerText: 'Suggest price',
  spModalTitle: 'Price suggestion',
  spModaldescription:
    'The suggested retail price is calculated using the sales price with contribution margin formula.',
  spLabel: 'Suggested price',
  spActionsApply: 'Apply',
  spActionsClear: 'Clear',
  spCost: 'How much did the product cost you?',
  spMargin: 'How much do you want to earn? (in %)',
  spConfirmButton: 'Calculate price',
};
