export const suggestedPrice = {
  spTriggerText: 'Sugerir precio',
  spModalTitle: 'Sugerencia de precio',
  spModaldescription:
    'El precio sugerido se calcula mediante la fórmula de precio de venta con margen de contribución.',
  spLabel: 'Precio sugerido',
  spActionsApply: 'Aplicar',
  spActionsClear: 'Reiniciar',
  spCost: '¿Cuánto te costó el producto?',
  spMargin: '¿Cuánto deseas ganar? (en %)',
  spConfirmButton: 'Calcular precio',
};
