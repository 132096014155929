import { TranslationKeys } from 'src/locales/es';

import useLocales from './useLocales';

export const useStrings = () => {
  const { translate, currentLang } = useLocales();

  const strings = (key: keyof TranslationKeys, options?: any) =>
    translate(key, options);

  return {
    strings,
    currentLang,
  };
};
