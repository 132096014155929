import { useCallback } from 'react';

import { GuestUsersApi } from 'src/api-client';

import useLocalStorage from './useLocalStorage';

async function redeemAppSumoCode({
  code,
  state,
}: {
  code: string;
  state: string | null;
}) {
  const response = await GuestUsersApi.usersValidateAppsumoCodePost({
    usersValidateAppsumoCodePostRequest: {
      code,
      state: state ?? '',
    },
  });

  return response.data;
}

export function useAppSumoIntegration() {
  const [value, setValue] = useLocalStorage<{
    code: string;
    state: string | null;
  } | null>('appSumoIntegration:code', null);

  const clear = useCallback(() => setValue(null), [setValue]);
  const processAppSumoIntegration = useCallback(async () => {
    if (value === null) return;

    try {
      await redeemAppSumoCode(value);
      clear();
    } catch (error) {
      console.error('Error redeeming AppSumo code', error);
    }
  }, []);

  return {
    setCredentials: setValue,
    processAppSumoIntegration,
  };
}
