import { Alert, Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useMemo, useState } from 'react';
import { Navigate } from 'react-router';

import LoadingScreen from 'src/components/LoadingScreen';
import Logo from 'src/components/Logo';
import Page from 'src/components/Page';
import { useAppSumoIntegration } from 'src/hooks/useAppSumoIntegration';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import { useLocationQuery } from 'src/hooks/useLocationQuery';
import { usePermissions } from 'src/hooks/usePermissions';
import { useStrings } from 'src/hooks/useStrings';
import LanguagePopover from 'src/layouts/dashboard/header/LanguagePopover';
import { PATH_AUTH, PATH_DASHBOARD } from 'src/routes/paths';

const ContentStyle = styled('div')(({ theme }) => {
  const downTo = theme.breakpoints.down('sm');

  return {
    maxWidth: 720,
    margin: 'auto',
    minHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(downTo ? 8 : 12, 0),
    '#login-buttons button': {
      display: 'flex !important',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '8px !important',
      overflow: 'hidden !important',
    },
    '#login-buttons button > div': {
      marginRight: '0 !important',
    },
  };
});

export default function AppSumoCallback() {
  const { currentLang, onChangeLang: setLang } = useLocales();

  const { strings } = useStrings();
  const query = useLocationQuery();

  const code = query.get('code');
  const state = query.get('state');

  const { isAuthenticated } = useAuth();
  const { setCredentials } = useAppSumoIntegration();
  const { isAccountType } = usePermissions();

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (currentLang.value === 'es') {
      setLang('en');
    }
  }, []);

  const appSumoError = useMemo(() => {
    if (!code || !state) {
      return strings('appSumoErrorMissingCode');
    }

    return null;
  }, [code, state, strings]);

  useEffect(() => {
    if (!code) return;

    setCredentials({ code, state });
    setIsReady(true);
  }, [code, state, setCredentials]);

  if (isReady) {
    if (isAuthenticated) {
      if (isAccountType('business')) {
        return <Navigate to={PATH_DASHBOARD.POS.root} />;
      }

      return <Navigate to={PATH_DASHBOARD.general.app} />;
    } else {
      return <Navigate to={PATH_AUTH.root} />;
    }
  }

  if (appSumoError) {
    return (
      <Page
        title={strings('appSumoSignUp')}
        simpleLayoutProps={{
          showLogo: false,
          fullWidth: true,
        }}
      >
        <Container maxWidth="lg" sx={{ position: 'relative' }}>
          <div style={{ position: 'absolute', top: 20, right: 20 }}>
            <LanguagePopover altVersion />
          </div>

          <ContentStyle>
            <Box style={{ marginBottom: 20 }}>
              <Logo />
            </Box>

            <div style={{ marginTop: 20 }}>
              <Alert severity="error">{appSumoError}</Alert>
            </div>
          </ContentStyle>
        </Container>
      </Page>
    );
  }

  return <LoadingScreen />;
}
