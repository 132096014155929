/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface UsersAndroidPost200Response
 */
export interface UsersAndroidPost200Response {
    /**
     * 
     * @type {boolean}
     * @memberof UsersAndroidPost200Response
     */
    'step_two': boolean;
}
/**
 * 
 * @export
 * @interface UsersAndroidPostRequest
 */
export interface UsersAndroidPostRequest {
    /**
     * 
     * @type {UsersAndroidPostRequestAndroid}
     * @memberof UsersAndroidPostRequest
     */
    'android': UsersAndroidPostRequestAndroid;
}
/**
 * 
 * @export
 * @interface UsersAndroidPostRequestAndroid
 */
export interface UsersAndroidPostRequestAndroid {
    /**
     * 
     * @type {string}
     * @memberof UsersAndroidPostRequestAndroid
     */
    'accessToken': string;
}
/**
 * 
 * @export
 * @interface UsersConfirmationGet200Response
 */
export interface UsersConfirmationGet200Response {
    /**
     * 
     * @type {number}
     * @memberof UsersConfirmationGet200Response
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UsersConfirmationGet200Response
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UsersConfirmationGet200Response
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof UsersConfirmationGet200Response
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof UsersConfirmationGet200Response
     */
    'jti': string;
    /**
     * 
     * @type {string}
     * @memberof UsersConfirmationGet200Response
     */
    'first_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UsersConfirmationGet200Response
     */
    'last_name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UsersConfirmationGet200Response
     */
    'country_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UsersConfirmationGet200Response
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UsersConfirmationGet200Response
     */
    'fcm_token'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UsersConfirmationGet200Response
     */
    'fcm_type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UsersConfirmationGet200Response
     */
    'provider'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UsersConfirmationGet200Response
     */
    'alternate_email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UsersConfirmationGet200Response
     */
    'destroy_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UsersConfirmationGet200Response
     */
    'time_zone'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UsersConfirmationGet200Response
     */
    'is_guest'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UsersConfirmationGet200Response
     */
    'converted_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UsersConfirmationGet200Response
     */
    'language': string;
    /**
     * 
     * @type {string}
     * @memberof UsersConfirmationGet200Response
     */
    'uuid': string;
    /**
     * 
     * @type {number}
     * @memberof UsersConfirmationGet200Response
     */
    'subscription_expiracy'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UsersConfirmationGet200Response
     */
    'first_payment_at'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UsersConfirmationGet200Response
     */
    'invited_expiracy'?: number | null;
}
/**
 * 
 * @export
 * @interface UsersIosPostRequest
 */
export interface UsersIosPostRequest {
    /**
     * 
     * @type {UsersIosPostRequestIos}
     * @memberof UsersIosPostRequest
     */
    'ios': UsersIosPostRequestIos;
}
/**
 * 
 * @export
 * @interface UsersIosPostRequestIos
 */
export interface UsersIosPostRequestIos {
    /**
     * 
     * @type {string}
     * @memberof UsersIosPostRequestIos
     */
    'identityToken': string;
    /**
     * 
     * @type {string}
     * @memberof UsersIosPostRequestIos
     */
    'user': string;
    /**
     * 
     * @type {string}
     * @memberof UsersIosPostRequestIos
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UsersIosPostRequestIos
     */
    'authorizationCode': string;
    /**
     * 
     * @type {UsersIosPostRequestIosFullName}
     * @memberof UsersIosPostRequestIos
     */
    'fullName': UsersIosPostRequestIosFullName;
}
/**
 * 
 * @export
 * @interface UsersIosPostRequestIosFullName
 */
export interface UsersIosPostRequestIosFullName {
    /**
     * 
     * @type {string}
     * @memberof UsersIosPostRequestIosFullName
     */
    'givenName': string;
}
/**
 * 
 * @export
 * @interface UsersLoginPostRequest
 */
export interface UsersLoginPostRequest {
    /**
     * 
     * @type {UsersLoginPostRequestUser}
     * @memberof UsersLoginPostRequest
     */
    'user': UsersLoginPostRequestUser;
}
/**
 * 
 * @export
 * @interface UsersLoginPostRequestUser
 */
export interface UsersLoginPostRequestUser {
    /**
     * 
     * @type {string}
     * @memberof UsersLoginPostRequestUser
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UsersLoginPostRequestUser
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface UsersPasswordPostRequest
 */
export interface UsersPasswordPostRequest {
    /**
     * 
     * @type {UsersPasswordPostRequestUser}
     * @memberof UsersPasswordPostRequest
     */
    'user': UsersPasswordPostRequestUser;
}
/**
 * 
 * @export
 * @interface UsersPasswordPostRequest1
 */
export interface UsersPasswordPostRequest1 {
    /**
     * 
     * @type {UsersPasswordPostRequest1User}
     * @memberof UsersPasswordPostRequest1
     */
    'user': UsersPasswordPostRequest1User;
}
/**
 * 
 * @export
 * @interface UsersPasswordPostRequest1User
 */
export interface UsersPasswordPostRequest1User {
    /**
     * 
     * @type {string}
     * @memberof UsersPasswordPostRequest1User
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface UsersPasswordPostRequestUser
 */
export interface UsersPasswordPostRequestUser {
    /**
     * 
     * @type {string}
     * @memberof UsersPasswordPostRequestUser
     */
    'reset_password_token': string;
    /**
     * 
     * @type {string}
     * @memberof UsersPasswordPostRequestUser
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof UsersPasswordPostRequestUser
     */
    'password_confirmation': string;
}
/**
 * 
 * @export
 * @interface UsersPost201Response
 */
export interface UsersPost201Response {
    /**
     * 
     * @type {string}
     * @memberof UsersPost201Response
     */
    'confirmation_token': string | null;
}
/**
 * 
 * @export
 * @interface UsersPostRequest
 */
export interface UsersPostRequest {
    /**
     * 
     * @type {UsersPostRequestUser}
     * @memberof UsersPostRequest
     */
    'user': UsersPostRequestUser;
}
/**
 * 
 * @export
 * @interface UsersPostRequestUser
 */
export interface UsersPostRequestUser {
    /**
     * 
     * @type {string}
     * @memberof UsersPostRequestUser
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UsersPostRequestUser
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof UsersPostRequestUser
     */
    'confirm_password': string;
}
/**
 * 
 * @export
 * @interface UsersPreValidateSubscriptionPost200Response
 */
export interface UsersPreValidateSubscriptionPost200Response {
    /**
     * 
     * @type {string}
     * @memberof UsersPreValidateSubscriptionPost200Response
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface UsersPreValidateSubscriptionPost422Response
 */
export interface UsersPreValidateSubscriptionPost422Response {
    /**
     * 
     * @type {string}
     * @memberof UsersPreValidateSubscriptionPost422Response
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface UsersPreValidateSubscriptionPostRequest
 */
export interface UsersPreValidateSubscriptionPostRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersPreValidateSubscriptionPostRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UsersPreValidateSubscriptionPostRequest
     */
    'payment_id': string;
}
/**
 * 
 * @export
 * @interface UsersRedimAppsumoCodePatch404Response
 */
export interface UsersRedimAppsumoCodePatch404Response {
    /**
     * 
     * @type {Array<string>}
     * @memberof UsersRedimAppsumoCodePatch404Response
     */
    'errors': Array<string>;
}
/**
 * 
 * @export
 * @interface UsersRedimAppsumoCodePatchRequest
 */
export interface UsersRedimAppsumoCodePatchRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersRedimAppsumoCodePatchRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof UsersRedimAppsumoCodePatchRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface UsersValidateAppsumoCodePostRequest
 */
export interface UsersValidateAppsumoCodePostRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersValidateAppsumoCodePostRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof UsersValidateAppsumoCodePostRequest
     */
    'state': string;
}
/**
 * 
 * @export
 * @interface V1ApiKeysGet200Response
 */
export interface V1ApiKeysGet200Response {
    /**
     * 
     * @type {Array<V1ApiKeysGet200ResponseApiKeysInner>}
     * @memberof V1ApiKeysGet200Response
     */
    'api_keys': Array<V1ApiKeysGet200ResponseApiKeysInner>;
}
/**
 * 
 * @export
 * @interface V1ApiKeysGet200ResponseApiKeysInner
 */
export interface V1ApiKeysGet200ResponseApiKeysInner {
    /**
     * 
     * @type {string}
     * @memberof V1ApiKeysGet200ResponseApiKeysInner
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface V1CabalCardsActivatePost200Response
 */
export interface V1CabalCardsActivatePost200Response {
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsActivatePost200Response
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsActivatePost200Response
     */
    'errors'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsActivatePost200Response
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface V1CabalCardsActivatePostRequest
 */
export interface V1CabalCardsActivatePostRequest {
    /**
     * 
     * @type {V1CabalCardsActivatePostRequestCustomer}
     * @memberof V1CabalCardsActivatePostRequest
     */
    'customer'?: V1CabalCardsActivatePostRequestCustomer;
}
/**
 * 
 * @export
 * @interface V1CabalCardsActivatePostRequestCustomer
 */
export interface V1CabalCardsActivatePostRequestCustomer {
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsActivatePostRequestCustomer
     */
    'tarjeta': string;
}
/**
 * 
 * @export
 * @interface V1CabalCardsEnabledPhonesPost200Response
 */
export interface V1CabalCardsEnabledPhonesPost200Response {
    /**
     * 
     * @type {Array<V1CabalCardsEnabledPhonesPost200ResponseEnabledPhonesInner>}
     * @memberof V1CabalCardsEnabledPhonesPost200Response
     */
    'enabled_phones'?: Array<V1CabalCardsEnabledPhonesPost200ResponseEnabledPhonesInner>;
}
/**
 * 
 * @export
 * @interface V1CabalCardsEnabledPhonesPost200ResponseEnabledPhonesInner
 */
export interface V1CabalCardsEnabledPhonesPost200ResponseEnabledPhonesInner {
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsEnabledPhonesPost200ResponseEnabledPhonesInner
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsEnabledPhonesPost200ResponseEnabledPhonesInner
     */
    'card_number': string;
}
/**
 * 
 * @export
 * @interface V1CabalCardsEnabledPhonesPostRequest
 */
export interface V1CabalCardsEnabledPhonesPostRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof V1CabalCardsEnabledPhonesPostRequest
     */
    'phones'?: Array<string>;
}
/**
 * 
 * @export
 * @interface V1CabalCardsGet200Response
 */
export interface V1CabalCardsGet200Response {
    /**
     * 
     * @type {Array<V1CabalCardsGet200ResponseCardsInner>}
     * @memberof V1CabalCardsGet200Response
     */
    'cards': Array<V1CabalCardsGet200ResponseCardsInner>;
    /**
     * 
     * @type {V1CabalCardsGet200ResponsePagination}
     * @memberof V1CabalCardsGet200Response
     */
    'pagination': V1CabalCardsGet200ResponsePagination | null;
}
/**
 * 
 * @export
 * @interface V1CabalCardsGet200ResponseCardsInner
 */
export interface V1CabalCardsGet200ResponseCardsInner {
    /**
     * 
     * @type {number}
     * @memberof V1CabalCardsGet200ResponseCardsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsGet200ResponseCardsInner
     */
    'card_number': string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsGet200ResponseCardsInner
     */
    'masked_card_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsGet200ResponseCardsInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsGet200ResponseCardsInner
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V1CabalCardsGet200ResponsePagination
 */
export interface V1CabalCardsGet200ResponsePagination {
    /**
     * 
     * @type {number}
     * @memberof V1CabalCardsGet200ResponsePagination
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof V1CabalCardsGet200ResponsePagination
     */
    'prev': number | null;
    /**
     * 
     * @type {number}
     * @memberof V1CabalCardsGet200ResponsePagination
     */
    'next': number | null;
    /**
     * 
     * @type {number}
     * @memberof V1CabalCardsGet200ResponsePagination
     */
    'last': number;
    /**
     * 
     * @type {number}
     * @memberof V1CabalCardsGet200ResponsePagination
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface V1CabalCardsIdBlockPost200Response
 */
export interface V1CabalCardsIdBlockPost200Response {
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdBlockPost200Response
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdBlockPost200Response
     */
    'errors'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdBlockPost200Response
     */
    'code'?: string;
    /**
     * 
     * @type {number}
     * @memberof V1CabalCardsIdBlockPost200Response
     */
    'balance'?: number;
}
/**
 * 
 * @export
 * @interface V1CabalCardsIdBlockPostRequest
 */
export interface V1CabalCardsIdBlockPostRequest {
    /**
     * 
     * @type {V1CabalCardsIdBlockPostRequestBlock}
     * @memberof V1CabalCardsIdBlockPostRequest
     */
    'block'?: V1CabalCardsIdBlockPostRequestBlock;
}
/**
 * 
 * @export
 * @interface V1CabalCardsIdBlockPostRequestBlock
 */
export interface V1CabalCardsIdBlockPostRequestBlock {
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdBlockPostRequestBlock
     */
    'reason': string;
}
/**
 * 
 * @export
 * @interface V1CabalCardsIdGet200Response
 */
export interface V1CabalCardsIdGet200Response {
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdGet200Response
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdGet200Response
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdGet200Response
     */
    'expirationdate'?: string;
    /**
     * 
     * @type {number}
     * @memberof V1CabalCardsIdGet200Response
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdGet200Response
     */
    'descripcionstatus'?: string;
    /**
     * 
     * @type {Array<V1CabalCardsIdGet200ResponseCurrentbalanceInner>}
     * @memberof V1CabalCardsIdGet200Response
     */
    'currentbalance'?: Array<V1CabalCardsIdGet200ResponseCurrentbalanceInner>;
}
/**
 * 
 * @export
 * @interface V1CabalCardsIdGet200ResponseCurrentbalanceInner
 */
export interface V1CabalCardsIdGet200ResponseCurrentbalanceInner {
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdGet200ResponseCurrentbalanceInner
     */
    'accounttypedescription'?: V1CabalCardsIdGet200ResponseCurrentbalanceInnerAccounttypedescriptionEnum;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdGet200ResponseCurrentbalanceInner
     */
    'balance'?: string;
}

export const V1CabalCardsIdGet200ResponseCurrentbalanceInnerAccounttypedescriptionEnum = {
    UsdCuentaAutorizacin: 'USD-Cuenta Autorización',
    UsdCuentaCompensacin: 'USD-Cuenta Compensación',
    UsdCuentasPorPagar: 'USD-Cuentas por Pagar',
    UsdCuentaCredito: 'USD-Cuenta Credito',
    SalvoBuenCobro: 'Salvo Buen Cobro'
} as const;

export type V1CabalCardsIdGet200ResponseCurrentbalanceInnerAccounttypedescriptionEnum = typeof V1CabalCardsIdGet200ResponseCurrentbalanceInnerAccounttypedescriptionEnum[keyof typeof V1CabalCardsIdGet200ResponseCurrentbalanceInnerAccounttypedescriptionEnum];

/**
 * 
 * @export
 * @interface V1CabalCardsIdReassignPatchRequest
 */
export interface V1CabalCardsIdReassignPatchRequest {
    /**
     * 
     * @type {V1CabalCardsIdReassignPatchRequestReassign}
     * @memberof V1CabalCardsIdReassignPatchRequest
     */
    'reassign'?: V1CabalCardsIdReassignPatchRequestReassign;
}
/**
 * 
 * @export
 * @interface V1CabalCardsIdReassignPatchRequestReassign
 */
export interface V1CabalCardsIdReassignPatchRequestReassign {
    /**
     * The target card number
     * @type {string}
     * @memberof V1CabalCardsIdReassignPatchRequestReassign
     */
    'card': string;
}
/**
 * 
 * @export
 * @interface V1CabalCardsIdRechargeBalancePost200Response
 */
export interface V1CabalCardsIdRechargeBalancePost200Response {
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdRechargeBalancePost200Response
     */
    'IDSolicitud'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdRechargeBalancePost200Response
     */
    'Tarjeta'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdRechargeBalancePost200Response
     */
    'Importe'?: string;
    /**
     * 
     * @type {number}
     * @memberof V1CabalCardsIdRechargeBalancePost200Response
     */
    'SaldoFinal'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdRechargeBalancePost200Response
     */
    'CodRespuesta'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdRechargeBalancePost200Response
     */
    'DescRespuesta'?: string;
}
/**
 * 
 * @export
 * @interface V1CabalCardsIdRechargeBalancePostRequest
 */
export interface V1CabalCardsIdRechargeBalancePostRequest {
    /**
     * The amount to recharge
     * @type {string}
     * @memberof V1CabalCardsIdRechargeBalancePostRequest
     */
    'amount': string;
}
/**
 * 
 * @export
 * @interface V1CabalCardsIdTransactionsGet200Response
 */
export interface V1CabalCardsIdTransactionsGet200Response {
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransactionsGet200Response
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransactionsGet200Response
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof V1CabalCardsIdTransactionsGet200Response
     */
    'idrequest'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransactionsGet200Response
     */
    'card'?: string | null;
    /**
     * 
     * @type {Array<V1CabalCardsIdTransactionsGet200ResponseMovementsInner>}
     * @memberof V1CabalCardsIdTransactionsGet200Response
     */
    'movements'?: Array<V1CabalCardsIdTransactionsGet200ResponseMovementsInner>;
}
/**
 * 
 * @export
 * @interface V1CabalCardsIdTransactionsGet200ResponseMovementsInner
 */
export interface V1CabalCardsIdTransactionsGet200ResponseMovementsInner {
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransactionsGet200ResponseMovementsInner
     */
    'movementoriginamount'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransactionsGet200ResponseMovementsInner
     */
    'currencyoriginmovement'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransactionsGet200ResponseMovementsInner
     */
    'tradeaffiliationnumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransactionsGet200ResponseMovementsInner
     */
    'tradedescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransactionsGet200ResponseMovementsInner
     */
    'countrytrade'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransactionsGet200ResponseMovementsInner
     */
    'descriptioncountry'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransactionsGet200ResponseMovementsInner
     */
    'balanceaftermovement'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransactionsGet200ResponseMovementsInner
     */
    'keymovement'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransactionsGet200ResponseMovementsInner
     */
    'mcc'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransactionsGet200ResponseMovementsInner
     */
    'postoperationstatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransactionsGet200ResponseMovementsInner
     */
    'postoperationdescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransactionsGet200ResponseMovementsInner
     */
    'authorization'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransactionsGet200ResponseMovementsInner
     */
    'idmovement'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransactionsGet200ResponseMovementsInner
     */
    'datemovement'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransactionsGet200ResponseMovementsInner
     */
    'conceptmovement'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransactionsGet200ResponseMovementsInner
     */
    'typemovementchargecredit'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransactionsGet200ResponseMovementsInner
     */
    'amountmovement'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransactionsGet200ResponseMovementsInner
     */
    'currencymovement'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransactionsGet200ResponseMovementsInner
     */
    'reference'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransactionsGet200ResponseMovementsInner
     */
    'observations'?: string;
}
/**
 * 
 * @export
 * @interface V1CabalCardsIdTransferPatch200Response
 */
export interface V1CabalCardsIdTransferPatch200Response {
    /**
     * 
     * @type {V1CabalCardsIdTransferPatch200ResponseTransfer}
     * @memberof V1CabalCardsIdTransferPatch200Response
     */
    'transfer'?: V1CabalCardsIdTransferPatch200ResponseTransfer;
}
/**
 * 
 * @export
 * @interface V1CabalCardsIdTransferPatch200ResponseTransfer
 */
export interface V1CabalCardsIdTransferPatch200ResponseTransfer {
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransferPatch200ResponseTransfer
     */
    'IDSolicitud': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransferPatch200ResponseTransfer
     */
    'ClaveMovimiento': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransferPatch200ResponseTransfer
     */
    'TarjetaOrigen': string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransferPatch200ResponseTransfer
     */
    'TarjetaDestino': string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransferPatch200ResponseTransfer
     */
    'Importe': string;
    /**
     * 
     * @type {number}
     * @memberof V1CabalCardsIdTransferPatch200ResponseTransfer
     */
    'SaldoFinal': number;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransferPatch200ResponseTransfer
     */
    'CodRespuesta': string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdTransferPatch200ResponseTransfer
     */
    'DescRespuesta': string;
}
/**
 * 
 * @export
 * @interface V1CabalCardsIdTransferPatchRequest
 */
export interface V1CabalCardsIdTransferPatchRequest {
    /**
     * 
     * @type {V1CabalCardsIdTransferPatchRequestTransfer}
     * @memberof V1CabalCardsIdTransferPatchRequest
     */
    'transfer'?: V1CabalCardsIdTransferPatchRequestTransfer;
}
/**
 * 
 * @export
 * @interface V1CabalCardsIdTransferPatchRequestTransfer
 */
export interface V1CabalCardsIdTransferPatchRequestTransfer {
    /**
     * The target card number
     * @type {string}
     * @memberof V1CabalCardsIdTransferPatchRequestTransfer
     */
    'target_card'?: string;
    /**
     * The amount to transfer
     * @type {string}
     * @memberof V1CabalCardsIdTransferPatchRequestTransfer
     */
    'amount'?: string;
}
/**
 * 
 * @export
 * @interface V1CabalCardsIdUnlockPatch200Response
 */
export interface V1CabalCardsIdUnlockPatch200Response {
    /**
     * The message
     * @type {string}
     * @memberof V1CabalCardsIdUnlockPatch200Response
     */
    'message'?: string | null;
    /**
     * The errors
     * @type {string}
     * @memberof V1CabalCardsIdUnlockPatch200Response
     */
    'errors'?: string | null;
    /**
     * The code
     * @type {string}
     * @memberof V1CabalCardsIdUnlockPatch200Response
     */
    'code'?: string;
    /**
     * The balance
     * @type {string}
     * @memberof V1CabalCardsIdUnlockPatch200Response
     */
    'balance'?: string;
}
/**
 * 
 * @export
 * @interface V1CabalCardsIdUnmaskNumberGet200Response
 */
export interface V1CabalCardsIdUnmaskNumberGet200Response {
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdUnmaskNumberGet200Response
     */
    'card_number'?: string;
}
/**
 * 
 * @export
 * @interface V1CabalCardsIdentityDocumentPost200Response
 */
export interface V1CabalCardsIdentityDocumentPost200Response {
    /**
     * 
     * @type {V1CabalCardsIdentityDocumentPost200ResponseDocument}
     * @memberof V1CabalCardsIdentityDocumentPost200Response
     */
    'document'?: V1CabalCardsIdentityDocumentPost200ResponseDocument;
}
/**
 * 
 * @export
 * @interface V1CabalCardsIdentityDocumentPost200ResponseDocument
 */
export interface V1CabalCardsIdentityDocumentPost200ResponseDocument {
    /**
     * 
     * @type {boolean}
     * @memberof V1CabalCardsIdentityDocumentPost200ResponseDocument
     */
    'is_real': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1CabalCardsIdentityDocumentPost200ResponseDocument
     */
    'is_identity_document': boolean;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdentityDocumentPost200ResponseDocument
     */
    'document_country': string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdentityDocumentPost200ResponseDocument
     */
    'id_number': string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdentityDocumentPost200ResponseDocument
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdentityDocumentPost200ResponseDocument
     */
    'birth_date': string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdentityDocumentPost200ResponseDocument
     */
    'department': string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsIdentityDocumentPost200ResponseDocument
     */
    'municipality': string;
}
/**
 * 
 * @export
 * @interface V1CabalCardsOnboardingPostRequest
 */
export interface V1CabalCardsOnboardingPostRequest {
    /**
     * 
     * @type {V1CabalCardsOnboardingPostRequestCustomer}
     * @memberof V1CabalCardsOnboardingPostRequest
     */
    'customer'?: V1CabalCardsOnboardingPostRequestCustomer;
}
/**
 * 
 * @export
 * @interface V1CabalCardsOnboardingPostRequestCustomer
 */
export interface V1CabalCardsOnboardingPostRequestCustomer {
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsOnboardingPostRequestCustomer
     */
    'secondlastname': string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsOnboardingPostRequestCustomer
     */
    'birthdate': string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsOnboardingPostRequestCustomer
     */
    'gender': string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsOnboardingPostRequestCustomer
     */
    'latitude': string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsOnboardingPostRequestCustomer
     */
    'longitude': string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsOnboardingPostRequestCustomer
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsOnboardingPostRequestCustomer
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof V1CabalCardsOnboardingPostRequestCustomer
     */
    'id_number': string;
}
/**
 * 
 * @export
 * @interface V1CabalCardsSelfiePost200Response
 */
export interface V1CabalCardsSelfiePost200Response {
    /**
     * 
     * @type {V1CabalCardsSelfiePost200ResponseSelfie}
     * @memberof V1CabalCardsSelfiePost200Response
     */
    'selfie'?: V1CabalCardsSelfiePost200ResponseSelfie;
}
/**
 * 
 * @export
 * @interface V1CabalCardsSelfiePost200ResponseSelfie
 */
export interface V1CabalCardsSelfiePost200ResponseSelfie {
    /**
     * 
     * @type {boolean}
     * @memberof V1CabalCardsSelfiePost200ResponseSelfie
     */
    'is_real'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1CabalCardsSelfiePost200ResponseSelfie
     */
    'is_selfie'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1CabalCardsSelfiePost200ResponseSelfie
     */
    'face_match'?: boolean;
}
/**
 * 
 * @export
 * @interface V1CashFlowGet200Response
 */
export interface V1CashFlowGet200Response {
    /**
     * 
     * @type {Array<V1CashFlowGet200ResponseTransactionsInner>}
     * @memberof V1CashFlowGet200Response
     */
    'transactions': Array<V1CashFlowGet200ResponseTransactionsInner>;
    /**
     * 
     * @type {V1CashFlowGet200ResponsePagination}
     * @memberof V1CashFlowGet200Response
     */
    'pagination': V1CashFlowGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1CashFlowGet200ResponsePagination
 */
export interface V1CashFlowGet200ResponsePagination {
    /**
     * 
     * @type {number}
     * @memberof V1CashFlowGet200ResponsePagination
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof V1CashFlowGet200ResponsePagination
     */
    'prev': number | null;
    /**
     * 
     * @type {number}
     * @memberof V1CashFlowGet200ResponsePagination
     */
    'next': number | null;
    /**
     * 
     * @type {number}
     * @memberof V1CashFlowGet200ResponsePagination
     */
    'last': number;
}
/**
 * 
 * @export
 * @interface V1CashFlowGet200ResponseTransactionsInner
 */
export interface V1CashFlowGet200ResponseTransactionsInner {
    /**
     * 
     * @type {number}
     * @memberof V1CashFlowGet200ResponseTransactionsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1CashFlowGet200ResponseTransactionsInner
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof V1CashFlowGet200ResponseTransactionsInner
     */
    'transaction_code': string;
    /**
     * 
     * @type {string}
     * @memberof V1CashFlowGet200ResponseTransactionsInner
     */
    'amount_total': string;
    /**
     * 
     * @type {string}
     * @memberof V1CashFlowGet200ResponseTransactionsInner
     */
    'payment_method': string;
    /**
     * 
     * @type {string}
     * @memberof V1CashFlowGet200ResponseTransactionsInner
     */
    'transaction_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1CashFlowGet200ResponseTransactionsInner
     */
    'payment_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1CashFlowGet200ResponseTransactionsInner
     */
    'parent_transaction_type': string | null;
    /**
     * 
     * @type {number}
     * @memberof V1CashFlowGet200ResponseTransactionsInner
     */
    'parent_id': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof V1CashFlowGet200ResponseTransactionsInner
     */
    'skip_cost': boolean;
    /**
     * 
     * @type {string}
     * @memberof V1CashFlowGet200ResponseTransactionsInner
     */
    'created_at': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1CashFlowGet200ResponseTransactionsInner
     */
    'is_recurrent': boolean;
    /**
     * 
     * @type {string}
     * @memberof V1CashFlowGet200ResponseTransactionsInner
     */
    'contact': string | null;
    /**
     * 
     * @type {V1CashFlowGet200ResponseTransactionsInnerPosMetadata}
     * @memberof V1CashFlowGet200ResponseTransactionsInner
     */
    'pos_metadata': V1CashFlowGet200ResponseTransactionsInnerPosMetadata | null;
}
/**
 * 
 * @export
 * @interface V1CashFlowGet200ResponseTransactionsInnerPosMetadata
 */
export interface V1CashFlowGet200ResponseTransactionsInnerPosMetadata {
    /**
     * 
     * @type {string}
     * @memberof V1CashFlowGet200ResponseTransactionsInnerPosMetadata
     */
    'name': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1CashFlowGet200ResponseTransactionsInnerPosMetadata
     */
    'table_identifier': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1CashFlowGet200ResponseTransactionsInnerPosMetadata
     */
    'comments': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1CashFlowGet200ResponseTransactionsInnerPosMetadata
     */
    'custom_id': string | null;
}
/**
 * 
 * @export
 * @interface V1ChangelogsGet200Response
 */
export interface V1ChangelogsGet200Response {
    /**
     * 
     * @type {Array<V1ChangelogsGet200ResponseChangelogsInner>}
     * @memberof V1ChangelogsGet200Response
     */
    'changelogs': Array<V1ChangelogsGet200ResponseChangelogsInner>;
    /**
     * 
     * @type {V1CashFlowGet200ResponsePagination}
     * @memberof V1ChangelogsGet200Response
     */
    'pagination': V1CashFlowGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1ChangelogsGet200ResponseChangelogsInner
 */
export interface V1ChangelogsGet200ResponseChangelogsInner {
    /**
     * 
     * @type {number}
     * @memberof V1ChangelogsGet200ResponseChangelogsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ChangelogsGet200ResponseChangelogsInner
     */
    'title': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1ChangelogsGet200ResponseChangelogsInner
     */
    'excerpt': string;
    /**
     * 
     * @type {string}
     * @memberof V1ChangelogsGet200ResponseChangelogsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1ChangelogsGet200ResponseChangelogsInner
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface V1ChangelogsIdGet200Response
 */
export interface V1ChangelogsIdGet200Response {
    /**
     * 
     * @type {number}
     * @memberof V1ChangelogsIdGet200Response
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ChangelogsIdGet200Response
     */
    'title': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1ChangelogsIdGet200Response
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof V1ChangelogsIdGet200Response
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1ChangelogsIdGet200Response
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface V1ChartsGet200Response
 */
export interface V1ChartsGet200Response {
    /**
     * 
     * @type {Array<V1ChartsGet200ResponseExpensesInner>}
     * @memberof V1ChartsGet200Response
     */
    'expenses': Array<V1ChartsGet200ResponseExpensesInner>;
    /**
     * 
     * @type {Array<V1ChartsGet200ResponseExpensesInner>}
     * @memberof V1ChartsGet200Response
     */
    'sales': Array<V1ChartsGet200ResponseExpensesInner>;
    /**
     * 
     * @type {Array<V1ChartsGet200ResponseExpensesInner>}
     * @memberof V1ChartsGet200Response
     */
    'debts': Array<V1ChartsGet200ResponseExpensesInner>;
    /**
     * 
     * @type {Array<V1ChartsGet200ResponseExpensesInner>}
     * @memberof V1ChartsGet200Response
     */
    'costs': Array<V1ChartsGet200ResponseExpensesInner>;
}
/**
 * 
 * @export
 * @interface V1ChartsGet200ResponseExpensesInner
 */
export interface V1ChartsGet200ResponseExpensesInner {
    /**
     * 
     * @type {string}
     * @memberof V1ChartsGet200ResponseExpensesInner
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof V1ChartsGet200ResponseExpensesInner
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof V1ChartsGet200ResponseExpensesInner
     */
    'label': string;
}
/**
 * 
 * @export
 * @interface V1ContactsDebtsTransactionsGet200Response
 */
export interface V1ContactsDebtsTransactionsGet200Response {
    /**
     * 
     * @type {Array<V1ContactsDebtsTransactionsGet200ResponseContactsInner>}
     * @memberof V1ContactsDebtsTransactionsGet200Response
     */
    'contacts': Array<V1ContactsDebtsTransactionsGet200ResponseContactsInner>;
    /**
     * 
     * @type {V1ContactsDebtsTransactionsGet200ResponsePagination}
     * @memberof V1ContactsDebtsTransactionsGet200Response
     */
    'pagination': V1ContactsDebtsTransactionsGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1ContactsDebtsTransactionsGet200ResponseContactsInner
 */
export interface V1ContactsDebtsTransactionsGet200ResponseContactsInner {
    /**
     * 
     * @type {number}
     * @memberof V1ContactsDebtsTransactionsGet200ResponseContactsInner
     */
    'contact_id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsDebtsTransactionsGet200ResponseContactsInner
     */
    'contact_name': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsDebtsTransactionsGet200ResponseContactsInner
     */
    'contact_phone': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsDebtsTransactionsGet200ResponseContactsInner
     */
    'contact_email': string | null;
    /**
     * 
     * @type {number}
     * @memberof V1ContactsDebtsTransactionsGet200ResponseContactsInner
     */
    'transactions': number;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsDebtsTransactionsGet200ResponseContactsInner
     */
    'total_debt': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsDebtsTransactionsGet200ResponseContactsInner
     */
    'total_paid': string;
}
/**
 * 
 * @export
 * @interface V1ContactsDebtsTransactionsGet200ResponsePagination
 */
export interface V1ContactsDebtsTransactionsGet200ResponsePagination {
    /**
     * 
     * @type {number}
     * @memberof V1ContactsDebtsTransactionsGet200ResponsePagination
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof V1ContactsDebtsTransactionsGet200ResponsePagination
     */
    'prev': number | null;
    /**
     * 
     * @type {number}
     * @memberof V1ContactsDebtsTransactionsGet200ResponsePagination
     */
    'next': number | null;
    /**
     * 
     * @type {number}
     * @memberof V1ContactsDebtsTransactionsGet200ResponsePagination
     */
    'last': number;
    /**
     * 
     * @type {number}
     * @memberof V1ContactsDebtsTransactionsGet200ResponsePagination
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface V1ContactsDebtsTransactionsIdGet200Response
 */
export interface V1ContactsDebtsTransactionsIdGet200Response {
    /**
     * 
     * @type {number}
     * @memberof V1ContactsDebtsTransactionsIdGet200Response
     */
    'contact_id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsDebtsTransactionsIdGet200Response
     */
    'contact_name': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsDebtsTransactionsIdGet200Response
     */
    'contact_phone': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsDebtsTransactionsIdGet200Response
     */
    'contact_email': string | null;
    /**
     * 
     * @type {number}
     * @memberof V1ContactsDebtsTransactionsIdGet200Response
     */
    'total_debts_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsDebtsTransactionsIdGet200Response
     */
    'total_debts': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsDebtsTransactionsIdGet200Response
     */
    'total_paid'?: string;
    /**
     * 
     * @type {Array<V1ContactsDebtsTransactionsIdGet200ResponseTransactionsInner>}
     * @memberof V1ContactsDebtsTransactionsIdGet200Response
     */
    'transactions': Array<V1ContactsDebtsTransactionsIdGet200ResponseTransactionsInner>;
    /**
     * 
     * @type {V1ContactsDebtsTransactionsGet200ResponsePagination}
     * @memberof V1ContactsDebtsTransactionsIdGet200Response
     */
    'pagination': V1ContactsDebtsTransactionsGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1ContactsDebtsTransactionsIdGet200ResponseTransactionsInner
 */
export interface V1ContactsDebtsTransactionsIdGet200ResponseTransactionsInner {
    /**
     * 
     * @type {number}
     * @memberof V1ContactsDebtsTransactionsIdGet200ResponseTransactionsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsDebtsTransactionsIdGet200ResponseTransactionsInner
     */
    'amount_due': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsDebtsTransactionsIdGet200ResponseTransactionsInner
     */
    'amount_paid': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsDebtsTransactionsIdGet200ResponseTransactionsInner
     */
    'amount_free': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsDebtsTransactionsIdGet200ResponseTransactionsInner
     */
    'amount_discount': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsDebtsTransactionsIdGet200ResponseTransactionsInner
     */
    'amount_products': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsDebtsTransactionsIdGet200ResponseTransactionsInner
     */
    'amount_utility': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsDebtsTransactionsIdGet200ResponseTransactionsInner
     */
    'amount_cost': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsDebtsTransactionsIdGet200ResponseTransactionsInner
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsDebtsTransactionsIdGet200ResponseTransactionsInner
     */
    'concept': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsDebtsTransactionsIdGet200ResponseTransactionsInner
     */
    'transaction_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsDebtsTransactionsIdGet200ResponseTransactionsInner
     */
    'pay_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsDebtsTransactionsIdGet200ResponseTransactionsInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsDebtsTransactionsIdGet200ResponseTransactionsInner
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V1ContactsGet200Response
 */
export interface V1ContactsGet200Response {
    /**
     * 
     * @type {Array<V1ContactsGet200ResponseContactsInner>}
     * @memberof V1ContactsGet200Response
     */
    'contacts': Array<V1ContactsGet200ResponseContactsInner>;
    /**
     * 
     * @type {V1ContactsDebtsTransactionsGet200ResponsePagination}
     * @memberof V1ContactsGet200Response
     */
    'pagination': V1ContactsDebtsTransactionsGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1ContactsGet200ResponseContactsInner
 */
export interface V1ContactsGet200ResponseContactsInner {
    /**
     * 
     * @type {number}
     * @memberof V1ContactsGet200ResponseContactsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsGet200ResponseContactsInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsGet200ResponseContactsInner
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsGet200ResponseContactsInner
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsGet200ResponseContactsInner
     */
    'address'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof V1ContactsGet200ResponseContactsInner
     */
    'transaction_count': number;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsGet200ResponseContactsInner
     */
    'amount_total': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsGet200ResponseContactsInner
     */
    'amount_debs': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsGet200ResponseContactsInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsGet200ResponseContactsInner
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V1ContactsGetRequest
 */
export interface V1ContactsGetRequest {
    /**
     * 
     * @type {V1ContactsGetRequestContact}
     * @memberof V1ContactsGetRequest
     */
    'contact': V1ContactsGetRequestContact;
}
/**
 * 
 * @export
 * @interface V1ContactsGetRequestContact
 */
export interface V1ContactsGetRequestContact {
    /**
     * 
     * @type {string}
     * @memberof V1ContactsGetRequestContact
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsGetRequestContact
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsGetRequestContact
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsGetRequestContact
     */
    'address'?: string | null;
}
/**
 * 
 * @export
 * @interface V1ContactsIdProductsGet200Response
 */
export interface V1ContactsIdProductsGet200Response {
    /**
     * 
     * @type {Array<V1ContactsIdProductsGet200ResponseProductsInner>}
     * @memberof V1ContactsIdProductsGet200Response
     */
    'products': Array<V1ContactsIdProductsGet200ResponseProductsInner>;
    /**
     * 
     * @type {V1ContactsDebtsTransactionsGet200ResponsePagination}
     * @memberof V1ContactsIdProductsGet200Response
     */
    'pagination': V1ContactsDebtsTransactionsGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1ContactsIdProductsGet200ResponseProductsInner
 */
export interface V1ContactsIdProductsGet200ResponseProductsInner {
    /**
     * 
     * @type {number}
     * @memberof V1ContactsIdProductsGet200ResponseProductsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsIdProductsGet200ResponseProductsInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsIdProductsGet200ResponseProductsInner
     */
    'description': string | null;
    /**
     * 
     * @type {number}
     * @memberof V1ContactsIdProductsGet200ResponseProductsInner
     */
    'total_quantity': number;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsIdProductsGet200ResponseProductsInner
     */
    'total_sale': string;
    /**
     * 
     * @type {Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>}
     * @memberof V1ContactsIdProductsGet200ResponseProductsInner
     */
    'images': Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner> | null;
}
/**
 * 
 * @export
 * @interface V1ContactsIdProductsGet200ResponseProductsInnerImagesInner
 */
export interface V1ContactsIdProductsGet200ResponseProductsInnerImagesInner {
    /**
     * 
     * @type {number}
     * @memberof V1ContactsIdProductsGet200ResponseProductsInnerImagesInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsIdProductsGet200ResponseProductsInnerImagesInner
     */
    'image_url': string;
}
/**
 * 
 * @export
 * @interface V1ContactsIdTransactionsGet200Response
 */
export interface V1ContactsIdTransactionsGet200Response {
    /**
     * 
     * @type {string}
     * @memberof V1ContactsIdTransactionsGet200Response
     */
    'contact_name': string;
    /**
     * 
     * @type {Array<V1ContactsIdTransactionsGet200ResponseTransactionsInner>}
     * @memberof V1ContactsIdTransactionsGet200Response
     */
    'transactions': Array<V1ContactsIdTransactionsGet200ResponseTransactionsInner>;
    /**
     * 
     * @type {V1ContactsDebtsTransactionsGet200ResponsePagination}
     * @memberof V1ContactsIdTransactionsGet200Response
     */
    'pagination': V1ContactsDebtsTransactionsGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1ContactsIdTransactionsGet200ResponseTransactionsInner
 */
export interface V1ContactsIdTransactionsGet200ResponseTransactionsInner {
    /**
     * 
     * @type {number}
     * @memberof V1ContactsIdTransactionsGet200ResponseTransactionsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsIdTransactionsGet200ResponseTransactionsInner
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsIdTransactionsGet200ResponseTransactionsInner
     */
    'concept': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsIdTransactionsGet200ResponseTransactionsInner
     */
    'payment_method': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsIdTransactionsGet200ResponseTransactionsInner
     */
    'payment_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsIdTransactionsGet200ResponseTransactionsInner
     */
    'transaction_type': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1ContactsIdTransactionsGet200ResponseTransactionsInner
     */
    'skip_cost': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1ContactsIdTransactionsGet200ResponseTransactionsInner
     */
    'is_recurrent'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsIdTransactionsGet200ResponseTransactionsInner
     */
    'amount_total': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsIdTransactionsGet200ResponseTransactionsInner
     */
    'amount_due': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsIdTransactionsGet200ResponseTransactionsInner
     */
    'amount_paid': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsIdTransactionsGet200ResponseTransactionsInner
     */
    'amount_free': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsIdTransactionsGet200ResponseTransactionsInner
     */
    'amount_discount': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsIdTransactionsGet200ResponseTransactionsInner
     */
    'amount_products': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsIdTransactionsGet200ResponseTransactionsInner
     */
    'amount_services': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsIdTransactionsGet200ResponseTransactionsInner
     */
    'amount_utility': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsIdTransactionsGet200ResponseTransactionsInner
     */
    'amount_cost': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsIdTransactionsGet200ResponseTransactionsInner
     */
    'pay_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsIdTransactionsGet200ResponseTransactionsInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1ContactsIdTransactionsGet200ResponseTransactionsInner
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V1CountriesGet200Response
 */
export interface V1CountriesGet200Response {
    /**
     * 
     * @type {Array<V1CountriesGet200ResponseCountriesInner>}
     * @memberof V1CountriesGet200Response
     */
    'countries': Array<V1CountriesGet200ResponseCountriesInner>;
}
/**
 * 
 * @export
 * @interface V1CountriesGet200ResponseCountriesInner
 */
export interface V1CountriesGet200ResponseCountriesInner {
    /**
     * 
     * @type {number}
     * @memberof V1CountriesGet200ResponseCountriesInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1CountriesGet200ResponseCountriesInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1CountriesGet200ResponseCountriesInner
     */
    'iso_code': string;
    /**
     * 
     * @type {string}
     * @memberof V1CountriesGet200ResponseCountriesInner
     */
    'currency_name': string;
    /**
     * 
     * @type {string}
     * @memberof V1CountriesGet200ResponseCountriesInner
     */
    'currency_symbol': string;
    /**
     * 
     * @type {string}
     * @memberof V1CountriesGet200ResponseCountriesInner
     */
    'phone_area_number': string;
}
/**
 * 
 * @export
 * @interface V1CouponsGet200Response
 */
export interface V1CouponsGet200Response {
    /**
     * 
     * @type {Array<V1CouponsGet200ResponseCouponsInner>}
     * @memberof V1CouponsGet200Response
     */
    'coupons': Array<V1CouponsGet200ResponseCouponsInner>;
    /**
     * 
     * @type {V1ContactsDebtsTransactionsGet200ResponsePagination}
     * @memberof V1CouponsGet200Response
     */
    'pagination': V1ContactsDebtsTransactionsGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1CouponsGet200ResponseCouponsInner
 */
export interface V1CouponsGet200ResponseCouponsInner {
    /**
     * 
     * @type {number}
     * @memberof V1CouponsGet200ResponseCouponsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1CouponsGet200ResponseCouponsInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1CouponsGet200ResponseCouponsInner
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof V1CouponsGet200ResponseCouponsInner
     */
    'end_time': string;
    /**
     * 
     * @type {number}
     * @memberof V1CouponsGet200ResponseCouponsInner
     */
    'percentage': number | null;
    /**
     * 
     * @type {string}
     * @memberof V1CouponsGet200ResponseCouponsInner
     */
    'discount': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1CouponsGet200ResponseCouponsInner
     */
    'activation_type': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1CouponsGet200ResponseCouponsInner
     */
    'active': boolean;
    /**
     * 
     * @type {number}
     * @memberof V1CouponsGet200ResponseCouponsInner
     */
    'max_activations': number;
    /**
     * 
     * @type {string}
     * @memberof V1CouponsGet200ResponseCouponsInner
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface V1CouponsGetRequest
 */
export interface V1CouponsGetRequest {
    /**
     * 
     * @type {V1CouponsGetRequestCoupon}
     * @memberof V1CouponsGetRequest
     */
    'coupon': V1CouponsGetRequestCoupon;
}
/**
 * 
 * @export
 * @interface V1CouponsGetRequestCoupon
 */
export interface V1CouponsGetRequestCoupon {
    /**
     * 
     * @type {string}
     * @memberof V1CouponsGetRequestCoupon
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1CouponsGetRequestCoupon
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof V1CouponsGetRequestCoupon
     */
    'end_time': string;
    /**
     * 
     * @type {number}
     * @memberof V1CouponsGetRequestCoupon
     */
    'percentage': number | null;
    /**
     * 
     * @type {number}
     * @memberof V1CouponsGetRequestCoupon
     */
    'discount': number | null;
    /**
     * 
     * @type {string}
     * @memberof V1CouponsGetRequestCoupon
     */
    'activation_type': V1CouponsGetRequestCouponActivationTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof V1CouponsGetRequestCoupon
     */
    'active': boolean;
    /**
     * 
     * @type {number}
     * @memberof V1CouponsGetRequestCoupon
     */
    'max_activations': number;
    /**
     * 
     * @type {string}
     * @memberof V1CouponsGetRequestCoupon
     */
    'code': string;
}

export const V1CouponsGetRequestCouponActivationTypeEnum = {
    Percentage: 'percentage',
    Money: 'money'
} as const;

export type V1CouponsGetRequestCouponActivationTypeEnum = typeof V1CouponsGetRequestCouponActivationTypeEnum[keyof typeof V1CouponsGetRequestCouponActivationTypeEnum];

/**
 * 
 * @export
 * @interface V1DataImportsGet200Response
 */
export interface V1DataImportsGet200Response {
    /**
     * 
     * @type {Array<V1DataImportsGet200ResponseDataImportsInner>}
     * @memberof V1DataImportsGet200Response
     */
    'data_imports': Array<V1DataImportsGet200ResponseDataImportsInner>;
}
/**
 * 
 * @export
 * @interface V1DataImportsGet200ResponseDataImportsInner
 */
export interface V1DataImportsGet200ResponseDataImportsInner {
    /**
     * 
     * @type {number}
     * @memberof V1DataImportsGet200ResponseDataImportsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1DataImportsGet200ResponseDataImportsInner
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof V1DataImportsGet200ResponseDataImportsInner
     */
    'created_at': string;
}
/**
 * 
 * @export
 * @interface V1DebtsTransactionsDebtsTransactionIdDepositsPost201Response
 */
export interface V1DebtsTransactionsDebtsTransactionIdDepositsPost201Response {
    /**
     * 
     * @type {number}
     * @memberof V1DebtsTransactionsDebtsTransactionIdDepositsPost201Response
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsDebtsTransactionIdDepositsPost201Response
     */
    'payment_method': string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsDebtsTransactionIdDepositsPost201Response
     */
    'concept': string;
    /**
     * 
     * @type {number}
     * @memberof V1DebtsTransactionsDebtsTransactionIdDepositsPost201Response
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsDebtsTransactionIdDepositsPost201Response
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsDebtsTransactionIdDepositsPost201Response
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V1DebtsTransactionsDebtsTransactionIdDepositsPostRequest
 */
export interface V1DebtsTransactionsDebtsTransactionIdDepositsPostRequest {
    /**
     * 
     * @type {V1DebtsTransactionsDebtsTransactionIdDepositsPostRequestDeposit}
     * @memberof V1DebtsTransactionsDebtsTransactionIdDepositsPostRequest
     */
    'deposit': V1DebtsTransactionsDebtsTransactionIdDepositsPostRequestDeposit;
}
/**
 * 
 * @export
 * @interface V1DebtsTransactionsDebtsTransactionIdDepositsPostRequestDeposit
 */
export interface V1DebtsTransactionsDebtsTransactionIdDepositsPostRequestDeposit {
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsDebtsTransactionIdDepositsPostRequestDeposit
     */
    'payment_method': V1DebtsTransactionsDebtsTransactionIdDepositsPostRequestDepositPaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsDebtsTransactionIdDepositsPostRequestDeposit
     */
    'concept'?: string;
    /**
     * 
     * @type {number}
     * @memberof V1DebtsTransactionsDebtsTransactionIdDepositsPostRequestDeposit
     */
    'amount': number;
}

export const V1DebtsTransactionsDebtsTransactionIdDepositsPostRequestDepositPaymentMethodEnum = {
    Cash: 'cash',
    Card: 'card',
    Other: 'other',
    Bank: 'bank',
    OnCredit: 'on-credit'
} as const;

export type V1DebtsTransactionsDebtsTransactionIdDepositsPostRequestDepositPaymentMethodEnum = typeof V1DebtsTransactionsDebtsTransactionIdDepositsPostRequestDepositPaymentMethodEnum[keyof typeof V1DebtsTransactionsDebtsTransactionIdDepositsPostRequestDepositPaymentMethodEnum];

/**
 * 
 * @export
 * @interface V1DebtsTransactionsGet200Response
 */
export interface V1DebtsTransactionsGet200Response {
    /**
     * 
     * @type {Array<V1DebtsTransactionsGet200ResponseTransactionsInner>}
     * @memberof V1DebtsTransactionsGet200Response
     */
    'transactions': Array<V1DebtsTransactionsGet200ResponseTransactionsInner>;
    /**
     * 
     * @type {V1CashFlowGet200ResponsePagination}
     * @memberof V1DebtsTransactionsGet200Response
     */
    'pagination': V1CashFlowGet200ResponsePagination;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsGet200Response
     */
    'total_sales': string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsGet200Response
     */
    'total_expenses': string;
}
/**
 * 
 * @export
 * @interface V1DebtsTransactionsGet200ResponseTransactionsInner
 */
export interface V1DebtsTransactionsGet200ResponseTransactionsInner {
    /**
     * 
     * @type {number}
     * @memberof V1DebtsTransactionsGet200ResponseTransactionsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsGet200ResponseTransactionsInner
     */
    'amount_due': string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsGet200ResponseTransactionsInner
     */
    'amount_paid': string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsGet200ResponseTransactionsInner
     */
    'amount_free': string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsGet200ResponseTransactionsInner
     */
    'amount_discount': string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsGet200ResponseTransactionsInner
     */
    'amount_products': string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsGet200ResponseTransactionsInner
     */
    'amount_utility': string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsGet200ResponseTransactionsInner
     */
    'amount_cost': string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsGet200ResponseTransactionsInner
     */
    'code'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof V1DebtsTransactionsGet200ResponseTransactionsInner
     */
    'skip_cost': boolean;
    /**
     * 
     * @type {V1DebtsTransactionsGet200ResponseTransactionsInnerContact}
     * @memberof V1DebtsTransactionsGet200ResponseTransactionsInner
     */
    'contact'?: V1DebtsTransactionsGet200ResponseTransactionsInnerContact | null;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsGet200ResponseTransactionsInner
     */
    'concept': string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsGet200ResponseTransactionsInner
     */
    'transaction_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsGet200ResponseTransactionsInner
     */
    'pay_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsGet200ResponseTransactionsInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsGet200ResponseTransactionsInner
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V1DebtsTransactionsGet200ResponseTransactionsInnerContact
 */
export interface V1DebtsTransactionsGet200ResponseTransactionsInnerContact {
    /**
     * 
     * @type {number}
     * @memberof V1DebtsTransactionsGet200ResponseTransactionsInnerContact
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsGet200ResponseTransactionsInnerContact
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsGet200ResponseTransactionsInnerContact
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface V1DebtsTransactionsIdGet200Response
 */
export interface V1DebtsTransactionsIdGet200Response {
    /**
     * 
     * @type {number}
     * @memberof V1DebtsTransactionsIdGet200Response
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsIdGet200Response
     */
    'amount_due': string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsIdGet200Response
     */
    'amount_paid': string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsIdGet200Response
     */
    'amount_free': string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsIdGet200Response
     */
    'amount_discount': string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsIdGet200Response
     */
    'amount_products': string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsIdGet200Response
     */
    'code'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof V1DebtsTransactionsIdGet200Response
     */
    'skip_cost': boolean;
    /**
     * 
     * @type {V1DebtsTransactionsGet200ResponseTransactionsInnerContact}
     * @memberof V1DebtsTransactionsIdGet200Response
     */
    'contact'?: V1DebtsTransactionsGet200ResponseTransactionsInnerContact | null;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsIdGet200Response
     */
    'concept': string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsIdGet200Response
     */
    'transaction_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsIdGet200Response
     */
    'transaction_status': string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsIdGet200Response
     */
    'payment_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsIdGet200Response
     */
    'payment_method': string;
    /**
     * 
     * @type {Array<V1DebtsTransactionsIdGet200ResponseDepositsInner>}
     * @memberof V1DebtsTransactionsIdGet200Response
     */
    'deposits': Array<V1DebtsTransactionsIdGet200ResponseDepositsInner>;
    /**
     * 
     * @type {Array<V1DebtsTransactionsIdGet200ResponseProductsInner>}
     * @memberof V1DebtsTransactionsIdGet200Response
     */
    'products': Array<V1DebtsTransactionsIdGet200ResponseProductsInner>;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsIdGet200Response
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsIdGet200Response
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsIdGet200Response
     */
    'pay_at': string | null;
}
/**
 * 
 * @export
 * @interface V1DebtsTransactionsIdGet200ResponseDepositsInner
 */
export interface V1DebtsTransactionsIdGet200ResponseDepositsInner {
    /**
     * 
     * @type {number}
     * @memberof V1DebtsTransactionsIdGet200ResponseDepositsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsIdGet200ResponseDepositsInner
     */
    'concept': string;
    /**
     * 
     * @type {number}
     * @memberof V1DebtsTransactionsIdGet200ResponseDepositsInner
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsIdGet200ResponseDepositsInner
     */
    'payment_method': string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsIdGet200ResponseDepositsInner
     */
    'created_at': string;
}
/**
 * 
 * @export
 * @interface V1DebtsTransactionsIdGet200ResponseProductsInner
 */
export interface V1DebtsTransactionsIdGet200ResponseProductsInner {
    /**
     * 
     * @type {number}
     * @memberof V1DebtsTransactionsIdGet200ResponseProductsInner
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsIdGet200ResponseProductsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsIdGet200ResponseProductsInner
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsIdGet200ResponseProductsInner
     */
    'cost'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsIdGet200ResponseProductsInner
     */
    'price'?: string;
    /**
     * 
     * @type {number}
     * @memberof V1DebtsTransactionsIdGet200ResponseProductsInner
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsIdGet200ResponseProductsInner
     */
    'barcode'?: string | null;
    /**
     * 
     * @type {Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>}
     * @memberof V1DebtsTransactionsIdGet200ResponseProductsInner
     */
    'images'?: Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>;
    /**
     * 
     * @type {V1DebtsTransactionsIdGet200ResponseProductsInnerCategory}
     * @memberof V1DebtsTransactionsIdGet200ResponseProductsInner
     */
    'category'?: V1DebtsTransactionsIdGet200ResponseProductsInnerCategory;
}
/**
 * 
 * @export
 * @interface V1DebtsTransactionsIdGet200ResponseProductsInnerCategory
 */
export interface V1DebtsTransactionsIdGet200ResponseProductsInnerCategory {
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsIdGet200ResponseProductsInnerCategory
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1DebtsTransactionsIdGet200ResponseProductsInnerCategory
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface V1DeliveryAreasGet200Response
 */
export interface V1DeliveryAreasGet200Response {
    /**
     * 
     * @type {Array<V1DeliveryAreasGet200ResponseDeliveryAreasInner>}
     * @memberof V1DeliveryAreasGet200Response
     */
    'delivery_areas': Array<V1DeliveryAreasGet200ResponseDeliveryAreasInner>;
    /**
     * 
     * @type {V1ContactsDebtsTransactionsGet200ResponsePagination}
     * @memberof V1DeliveryAreasGet200Response
     */
    'pagination': V1ContactsDebtsTransactionsGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1DeliveryAreasGet200ResponseDeliveryAreasInner
 */
export interface V1DeliveryAreasGet200ResponseDeliveryAreasInner {
    /**
     * 
     * @type {number}
     * @memberof V1DeliveryAreasGet200ResponseDeliveryAreasInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1DeliveryAreasGet200ResponseDeliveryAreasInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1DeliveryAreasGet200ResponseDeliveryAreasInner
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1DeliveryAreasGet200ResponseDeliveryAreasInner
     */
    'amount': string;
    /**
     * 
     * @type {string}
     * @memberof V1DeliveryAreasGet200ResponseDeliveryAreasInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1DeliveryAreasGet200ResponseDeliveryAreasInner
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V1DeliveryAreasGetRequest
 */
export interface V1DeliveryAreasGetRequest {
    /**
     * 
     * @type {V1DeliveryAreasGetRequestDeliveryArea}
     * @memberof V1DeliveryAreasGetRequest
     */
    'delivery_area': V1DeliveryAreasGetRequestDeliveryArea;
}
/**
 * 
 * @export
 * @interface V1DeliveryAreasGetRequestDeliveryArea
 */
export interface V1DeliveryAreasGetRequestDeliveryArea {
    /**
     * 
     * @type {string}
     * @memberof V1DeliveryAreasGetRequestDeliveryArea
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1DeliveryAreasGetRequestDeliveryArea
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof V1DeliveryAreasGetRequestDeliveryArea
     */
    'amount': string;
}
/**
 * 
 * @export
 * @interface V1DeviceInfosPostRequest
 */
export interface V1DeviceInfosPostRequest {
    /**
     * 
     * @type {V1DeviceInfosPostRequestDeviceInfo}
     * @memberof V1DeviceInfosPostRequest
     */
    'device_info': V1DeviceInfosPostRequestDeviceInfo;
}
/**
 * 
 * @export
 * @interface V1DeviceInfosPostRequestDeviceInfo
 */
export interface V1DeviceInfosPostRequestDeviceInfo {
    /**
     * 
     * @type {string}
     * @memberof V1DeviceInfosPostRequestDeviceInfo
     */
    'fcm_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1DeviceInfosPostRequestDeviceInfo
     */
    'provider': V1DeviceInfosPostRequestDeviceInfoProviderEnum;
}

export const V1DeviceInfosPostRequestDeviceInfoProviderEnum = {
    Firebase: 'firebase',
    OneSignal: 'one-signal'
} as const;

export type V1DeviceInfosPostRequestDeviceInfoProviderEnum = typeof V1DeviceInfosPostRequestDeviceInfoProviderEnum[keyof typeof V1DeviceInfosPostRequestDeviceInfoProviderEnum];

/**
 * 
 * @export
 * @interface V1DirectUploadPost201Response
 */
export interface V1DirectUploadPost201Response {
    /**
     * 
     * @type {V1DirectUploadPost201ResponseDirectUpload}
     * @memberof V1DirectUploadPost201Response
     */
    'direct_upload': V1DirectUploadPost201ResponseDirectUpload;
    /**
     * 
     * @type {string}
     * @memberof V1DirectUploadPost201Response
     */
    'blob_signed_id': string;
    /**
     * 
     * @type {number}
     * @memberof V1DirectUploadPost201Response
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface V1DirectUploadPost201ResponseDirectUpload
 */
export interface V1DirectUploadPost201ResponseDirectUpload {
    /**
     * 
     * @type {string}
     * @memberof V1DirectUploadPost201ResponseDirectUpload
     */
    'url'?: string;
    /**
     * 
     * @type {V1DirectUploadPost201ResponseDirectUploadHeaders}
     * @memberof V1DirectUploadPost201ResponseDirectUpload
     */
    'headers'?: V1DirectUploadPost201ResponseDirectUploadHeaders;
}
/**
 * 
 * @export
 * @interface V1DirectUploadPost201ResponseDirectUploadHeaders
 */
export interface V1DirectUploadPost201ResponseDirectUploadHeaders {
    /**
     * 
     * @type {string}
     * @memberof V1DirectUploadPost201ResponseDirectUploadHeaders
     */
    'Content-Type'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1DirectUploadPost201ResponseDirectUploadHeaders
     */
    'Content-MD5'?: string;
}
/**
 * 
 * @export
 * @interface V1DirectUploadPostRequest
 */
export interface V1DirectUploadPostRequest {
    /**
     * 
     * @type {V1DirectUploadPostRequestFile}
     * @memberof V1DirectUploadPostRequest
     */
    'file': V1DirectUploadPostRequestFile;
}
/**
 * 
 * @export
 * @interface V1DirectUploadPostRequestFile
 */
export interface V1DirectUploadPostRequestFile {
    /**
     * 
     * @type {string}
     * @memberof V1DirectUploadPostRequestFile
     */
    'filename': string;
    /**
     * 
     * @type {number}
     * @memberof V1DirectUploadPostRequestFile
     */
    'byte_size': number;
    /**
     * 
     * @type {string}
     * @memberof V1DirectUploadPostRequestFile
     */
    'checksum': string;
    /**
     * 
     * @type {string}
     * @memberof V1DirectUploadPostRequestFile
     */
    'content_type': string;
}
/**
 * 
 * @export
 * @interface V1ExpensesTransactionsGet200Response
 */
export interface V1ExpensesTransactionsGet200Response {
    /**
     * 
     * @type {Array<V1ExpensesTransactionsGet200ResponseTransactionsInner>}
     * @memberof V1ExpensesTransactionsGet200Response
     */
    'transactions': Array<V1ExpensesTransactionsGet200ResponseTransactionsInner>;
    /**
     * 
     * @type {V1CashFlowGet200ResponsePagination}
     * @memberof V1ExpensesTransactionsGet200Response
     */
    'pagination': V1CashFlowGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1ExpensesTransactionsGet200ResponseTransactionsInner
 */
export interface V1ExpensesTransactionsGet200ResponseTransactionsInner {
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'amount_due': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'amount_paid': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'amount_free': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'amount_products': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'amount_utility': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'amount_cost': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'amount_total': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'skip_cost': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'is_recurrent': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'recurrent_as_pending'?: boolean | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'pos_metadata': V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata | null;
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'recurrency_times': number | null;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'recurrency_days_span': V1ExpensesTransactionsGet200ResponseTransactionsInnerRecurrencyDaysSpanEnum;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'last_recurrency_at': string | null;
    /**
     * 
     * @type {V1DebtsTransactionsGet200ResponseTransactionsInnerContact}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'contact': V1DebtsTransactionsGet200ResponseTransactionsInnerContact | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'transaction_category': V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory | null;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'concept': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'transaction_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'transaction_status': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'payment_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'payment_method': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'transaction_origin': string;
    /**
     * 
     * @type {Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'products': Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInner
     */
    'pay_at': string | null;
}

export const V1ExpensesTransactionsGet200ResponseTransactionsInnerRecurrencyDaysSpanEnum = {
    FirstOfMonth: 'first_of_month',
    LastOfMonth: 'last_of_month',
    MiddleOfMonth: 'middle_of_month',
    _1: '1',
    _2: '2',
    _3: '3',
    _4: '4',
    _5: '5',
    _6: '6',
    _7: '7',
    _8: '8',
    _9: '9',
    _10: '10',
    _11: '11',
    _12: '12',
    _13: '13',
    _14: '14',
    _15: '15',
    _16: '16',
    _17: '17',
    _18: '18',
    _19: '19',
    _20: '20',
    _21: '21',
    _22: '22',
    _23: '23',
    _24: '24',
    _25: '25',
    _26: '26',
    _27: '27',
    _28: '28',
    _29: '29',
    _30: '30',
    _31: '31'
} as const;

export type V1ExpensesTransactionsGet200ResponseTransactionsInnerRecurrencyDaysSpanEnum = typeof V1ExpensesTransactionsGet200ResponseTransactionsInnerRecurrencyDaysSpanEnum[keyof typeof V1ExpensesTransactionsGet200ResponseTransactionsInnerRecurrencyDaysSpanEnum];

/**
 * 
 * @export
 * @interface V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata
 */
export interface V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata {
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata
     */
    'custom_id': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata
     */
    'name': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata
     */
    'table_identifier': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata
     */
    'comments': string | null;
}
/**
 * 
 * @export
 * @interface V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner
 */
export interface V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner {
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner
     */
    'transaction_product_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner
     */
    'cost': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner
     */
    'price': string;
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner
     */
    'stock': number;
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner
     */
    'barcode': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner
     */
    'show_in_store': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner
     */
    'show_in_pos': boolean;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner
     */
    'color': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner
     */
    'size': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner
     */
    'style': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor | null;
    /**
     * 
     * @type {Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner
     */
    'images': Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner
     */
    'product_category'?: V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory | null;
    /**
     * 
     * @type {Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerExtrasInner>}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner
     */
    'extras': Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerExtrasInner> | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner
     */
    'collection'?: V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory | null;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor
 */
export interface V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor {
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerExtrasInner
 */
export interface V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerExtrasInner {
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerExtrasInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerExtrasInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerExtrasInner
     */
    'color': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerExtrasInner
     */
    'price': string;
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerExtrasInner
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory
 */
export interface V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory {
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory
 */
export interface V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory {
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory
     */
    'icon'?: string;
}
/**
 * 
 * @export
 * @interface V1ExpensesTransactionsGet201Response
 */
export interface V1ExpensesTransactionsGet201Response {
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'amount_total': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'amount_due': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'amount_paid': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'amount_free': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'amount_products': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'amount_utility': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'amount_cost': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'skip_cost': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'is_recurrent': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'recurrent_as_pending'?: boolean | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'pos_metadata': V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata | null;
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'recurrency_times': number | null;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'recurrency_days_span': V1ExpensesTransactionsGet201ResponseRecurrencyDaysSpanEnum;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'last_recurrency_at': string | null;
    /**
     * 
     * @type {V1DebtsTransactionsGet200ResponseTransactionsInnerContact}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'contact': V1DebtsTransactionsGet200ResponseTransactionsInnerContact | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'transaction_category': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor | null;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'concept': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'transaction_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'transaction_status': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'payment_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'payment_method': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'transaction_origin': string;
    /**
     * 
     * @type {Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'products': Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGet201Response
     */
    'pay_at': string | null;
}

export const V1ExpensesTransactionsGet201ResponseRecurrencyDaysSpanEnum = {
    FirstOfMonth: 'first_of_month',
    LastOfMonth: 'last_of_month',
    MiddleOfMonth: 'middle_of_month',
    _1: '1',
    _2: '2',
    _3: '3',
    _4: '4',
    _5: '5',
    _6: '6',
    _7: '7',
    _8: '8',
    _9: '9',
    _10: '10',
    _11: '11',
    _12: '12',
    _13: '13',
    _14: '14',
    _15: '15',
    _16: '16',
    _17: '17',
    _18: '18',
    _19: '19',
    _20: '20',
    _21: '21',
    _22: '22',
    _23: '23',
    _24: '24',
    _25: '25',
    _26: '26',
    _27: '27',
    _28: '28',
    _29: '29',
    _30: '30',
    _31: '31'
} as const;

export type V1ExpensesTransactionsGet201ResponseRecurrencyDaysSpanEnum = typeof V1ExpensesTransactionsGet201ResponseRecurrencyDaysSpanEnum[keyof typeof V1ExpensesTransactionsGet201ResponseRecurrencyDaysSpanEnum];

/**
 * 
 * @export
 * @interface V1ExpensesTransactionsGetRequest
 */
export interface V1ExpensesTransactionsGetRequest {
    /**
     * 
     * @type {V1ExpensesTransactionsGetRequestExpense}
     * @memberof V1ExpensesTransactionsGetRequest
     */
    'expense': V1ExpensesTransactionsGetRequestExpense;
}
/**
 * 
 * @export
 * @interface V1ExpensesTransactionsGetRequestExpense
 */
export interface V1ExpensesTransactionsGetRequestExpense {
    /**
     * 
     * @type {Array<V1ExpensesTransactionsGetRequestExpenseProductsInner>}
     * @memberof V1ExpensesTransactionsGetRequestExpense
     */
    'products': Array<V1ExpensesTransactionsGetRequestExpenseProductsInner>;
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsGetRequestExpense
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGetRequestExpense
     */
    'concept'?: string;
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsGetRequestExpense
     */
    'contact_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGetRequestExpense
     */
    'payment_type': V1ExpensesTransactionsGetRequestExpensePaymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGetRequestExpense
     */
    'payment_method': V1ExpensesTransactionsGetRequestExpensePaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGetRequestExpense
     */
    'created_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGetRequestExpense
     */
    'pay_at'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof V1ExpensesTransactionsGetRequestExpense
     */
    'skip_cost'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsGetRequestExpense
     */
    'recurrency_times'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGetRequestExpense
     */
    'recurrency_days_span'?: V1ExpensesTransactionsGetRequestExpenseRecurrencyDaysSpanEnum;
    /**
     * 
     * @type {boolean}
     * @memberof V1ExpensesTransactionsGetRequestExpense
     */
    'recurrent_as_pending'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsGetRequestExpense
     */
    'transaction_category_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGetRequestExpense
     */
    'transaction_origin'?: V1ExpensesTransactionsGetRequestExpenseTransactionOriginEnum;
}

export const V1ExpensesTransactionsGetRequestExpensePaymentTypeEnum = {
    InCash: 'in-cash',
    Credit: 'credit'
} as const;

export type V1ExpensesTransactionsGetRequestExpensePaymentTypeEnum = typeof V1ExpensesTransactionsGetRequestExpensePaymentTypeEnum[keyof typeof V1ExpensesTransactionsGetRequestExpensePaymentTypeEnum];
export const V1ExpensesTransactionsGetRequestExpensePaymentMethodEnum = {
    Cash: 'cash',
    Card: 'card',
    Other: 'other',
    Bank: 'bank',
    OnCredit: 'on-credit'
} as const;

export type V1ExpensesTransactionsGetRequestExpensePaymentMethodEnum = typeof V1ExpensesTransactionsGetRequestExpensePaymentMethodEnum[keyof typeof V1ExpensesTransactionsGetRequestExpensePaymentMethodEnum];
export const V1ExpensesTransactionsGetRequestExpenseRecurrencyDaysSpanEnum = {
    FirstOfMonth: 'first_of_month',
    LastOfMonth: 'last_of_month',
    MiddleOfMonth: 'middle_of_month',
    _1: '1',
    _2: '2',
    _3: '3',
    _4: '4',
    _5: '5',
    _6: '6',
    _7: '7',
    _8: '8',
    _9: '9',
    _10: '10',
    _11: '11',
    _12: '12',
    _13: '13',
    _14: '14',
    _15: '15',
    _16: '16',
    _17: '17',
    _18: '18',
    _19: '19',
    _20: '20',
    _21: '21',
    _22: '22',
    _23: '23',
    _24: '24',
    _25: '25',
    _26: '26',
    _27: '27',
    _28: '28',
    _29: '29',
    _30: '30',
    _31: '31'
} as const;

export type V1ExpensesTransactionsGetRequestExpenseRecurrencyDaysSpanEnum = typeof V1ExpensesTransactionsGetRequestExpenseRecurrencyDaysSpanEnum[keyof typeof V1ExpensesTransactionsGetRequestExpenseRecurrencyDaysSpanEnum];
export const V1ExpensesTransactionsGetRequestExpenseTransactionOriginEnum = {
    App: 'app',
    AppWeb: 'app-web'
} as const;

export type V1ExpensesTransactionsGetRequestExpenseTransactionOriginEnum = typeof V1ExpensesTransactionsGetRequestExpenseTransactionOriginEnum[keyof typeof V1ExpensesTransactionsGetRequestExpenseTransactionOriginEnum];

/**
 * 
 * @export
 * @interface V1ExpensesTransactionsGetRequestExpenseProductsInner
 */
export interface V1ExpensesTransactionsGetRequestExpenseProductsInner {
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsGetRequestExpenseProductsInner
     */
    'product_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsGetRequestExpenseProductsInner
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsGetRequestExpenseProductsInner
     */
    'cost'?: string;
}
/**
 * 
 * @export
 * @interface V1ExpensesTransactionsIdDelete200Response
 */
export interface V1ExpensesTransactionsIdDelete200Response {
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'amount_due': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'amount_paid': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'amount_free': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'amount_products': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'amount_utility': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'amount_cost': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'amount_total': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'skip_cost': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'is_recurrent': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'recurrent_as_pending'?: boolean | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'pos_metadata': V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata | null;
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'recurrency_times': number | null;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'recurrency_days_span': V1ExpensesTransactionsIdDelete200ResponseRecurrencyDaysSpanEnum;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'last_recurrency_at': string | null;
    /**
     * 
     * @type {V1DebtsTransactionsGet200ResponseTransactionsInnerContact}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'contact': V1DebtsTransactionsGet200ResponseTransactionsInnerContact | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'transaction_category': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor | null;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'concept': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'transaction_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'transaction_status': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'payment_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'payment_method': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'transaction_origin': string;
    /**
     * 
     * @type {Array<V1ExpensesTransactionsIdDelete200ResponseDepositsInner>}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'deposits': Array<V1ExpensesTransactionsIdDelete200ResponseDepositsInner>;
    /**
     * 
     * @type {Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'products': Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDelete200Response
     */
    'pay_at': string | null;
}

export const V1ExpensesTransactionsIdDelete200ResponseRecurrencyDaysSpanEnum = {
    FirstOfMonth: 'first_of_month',
    LastOfMonth: 'last_of_month',
    MiddleOfMonth: 'middle_of_month',
    _1: '1',
    _2: '2',
    _3: '3',
    _4: '4',
    _5: '5',
    _6: '6',
    _7: '7',
    _8: '8',
    _9: '9',
    _10: '10',
    _11: '11',
    _12: '12',
    _13: '13',
    _14: '14',
    _15: '15',
    _16: '16',
    _17: '17',
    _18: '18',
    _19: '19',
    _20: '20',
    _21: '21',
    _22: '22',
    _23: '23',
    _24: '24',
    _25: '25',
    _26: '26',
    _27: '27',
    _28: '28',
    _29: '29',
    _30: '30',
    _31: '31'
} as const;

export type V1ExpensesTransactionsIdDelete200ResponseRecurrencyDaysSpanEnum = typeof V1ExpensesTransactionsIdDelete200ResponseRecurrencyDaysSpanEnum[keyof typeof V1ExpensesTransactionsIdDelete200ResponseRecurrencyDaysSpanEnum];

/**
 * 
 * @export
 * @interface V1ExpensesTransactionsIdDelete200ResponseDepositsInner
 */
export interface V1ExpensesTransactionsIdDelete200ResponseDepositsInner {
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsIdDelete200ResponseDepositsInner
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDelete200ResponseDepositsInner
     */
    'concept'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDelete200ResponseDepositsInner
     */
    'amount'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDelete200ResponseDepositsInner
     */
    'payment_method'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDelete200ResponseDepositsInner
     */
    'created_at'?: string;
}
/**
 * 
 * @export
 * @interface V1ExpensesTransactionsIdDeleteRequest
 */
export interface V1ExpensesTransactionsIdDeleteRequest {
    /**
     * 
     * @type {V1ExpensesTransactionsIdDeleteRequestExpense}
     * @memberof V1ExpensesTransactionsIdDeleteRequest
     */
    'expense': V1ExpensesTransactionsIdDeleteRequestExpense;
}
/**
 * 
 * @export
 * @interface V1ExpensesTransactionsIdDeleteRequestExpense
 */
export interface V1ExpensesTransactionsIdDeleteRequestExpense {
    /**
     * 
     * @type {Array<V1ExpensesTransactionsIdDeleteRequestExpenseProductsInner>}
     * @memberof V1ExpensesTransactionsIdDeleteRequestExpense
     */
    'products': Array<V1ExpensesTransactionsIdDeleteRequestExpenseProductsInner>;
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsIdDeleteRequestExpense
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDeleteRequestExpense
     */
    'concept'?: string;
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsIdDeleteRequestExpense
     */
    'contact_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDeleteRequestExpense
     */
    'payment_type': V1ExpensesTransactionsIdDeleteRequestExpensePaymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDeleteRequestExpense
     */
    'payment_method': V1ExpensesTransactionsIdDeleteRequestExpensePaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDeleteRequestExpense
     */
    'created_at': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof V1ExpensesTransactionsIdDeleteRequestExpense
     */
    'skip_cost'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsIdDeleteRequestExpense
     */
    'recurrency_times'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDeleteRequestExpense
     */
    'recurrency_days_span'?: V1ExpensesTransactionsIdDeleteRequestExpenseRecurrencyDaysSpanEnum;
    /**
     * 
     * @type {boolean}
     * @memberof V1ExpensesTransactionsIdDeleteRequestExpense
     */
    'recurrent_as_pending'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsIdDeleteRequestExpense
     */
    'transaction_category_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDeleteRequestExpense
     */
    'pay_at'?: string | null;
}

export const V1ExpensesTransactionsIdDeleteRequestExpensePaymentTypeEnum = {
    InCash: 'in-cash',
    Credit: 'credit'
} as const;

export type V1ExpensesTransactionsIdDeleteRequestExpensePaymentTypeEnum = typeof V1ExpensesTransactionsIdDeleteRequestExpensePaymentTypeEnum[keyof typeof V1ExpensesTransactionsIdDeleteRequestExpensePaymentTypeEnum];
export const V1ExpensesTransactionsIdDeleteRequestExpensePaymentMethodEnum = {
    Cash: 'cash',
    Card: 'card',
    Other: 'other',
    Bank: 'bank',
    OnCredit: 'on-credit'
} as const;

export type V1ExpensesTransactionsIdDeleteRequestExpensePaymentMethodEnum = typeof V1ExpensesTransactionsIdDeleteRequestExpensePaymentMethodEnum[keyof typeof V1ExpensesTransactionsIdDeleteRequestExpensePaymentMethodEnum];
export const V1ExpensesTransactionsIdDeleteRequestExpenseRecurrencyDaysSpanEnum = {
    FirstOfMonth: 'first_of_month',
    LastOfMonth: 'last_of_month',
    MiddleOfMonth: 'middle_of_month',
    _1: '1',
    _2: '2',
    _3: '3',
    _4: '4',
    _5: '5',
    _6: '6',
    _7: '7',
    _8: '8',
    _9: '9',
    _10: '10',
    _11: '11',
    _12: '12',
    _13: '13',
    _14: '14',
    _15: '15',
    _16: '16',
    _17: '17',
    _18: '18',
    _19: '19',
    _20: '20',
    _21: '21',
    _22: '22',
    _23: '23',
    _24: '24',
    _25: '25',
    _26: '26',
    _27: '27',
    _28: '28',
    _29: '29',
    _30: '30',
    _31: '31'
} as const;

export type V1ExpensesTransactionsIdDeleteRequestExpenseRecurrencyDaysSpanEnum = typeof V1ExpensesTransactionsIdDeleteRequestExpenseRecurrencyDaysSpanEnum[keyof typeof V1ExpensesTransactionsIdDeleteRequestExpenseRecurrencyDaysSpanEnum];

/**
 * 
 * @export
 * @interface V1ExpensesTransactionsIdDeleteRequestExpenseProductsInner
 */
export interface V1ExpensesTransactionsIdDeleteRequestExpenseProductsInner {
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsIdDeleteRequestExpenseProductsInner
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsIdDeleteRequestExpenseProductsInner
     */
    'product_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1ExpensesTransactionsIdDeleteRequestExpenseProductsInner
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1ExpensesTransactionsIdDeleteRequestExpenseProductsInner
     */
    'cost'?: string;
}
/**
 * 
 * @export
 * @interface V1FinancialDataAssistantGet200Response
 */
export interface V1FinancialDataAssistantGet200Response {
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseData}
     * @memberof V1FinancialDataAssistantGet200Response
     */
    'data'?: V1FinancialDataAssistantGet200ResponseData;
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseSales}
     * @memberof V1FinancialDataAssistantGet200Response
     */
    'sales': V1FinancialDataAssistantGet200ResponseSales;
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseCosts}
     * @memberof V1FinancialDataAssistantGet200Response
     */
    'costs': V1FinancialDataAssistantGet200ResponseCosts;
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseExpenses}
     * @memberof V1FinancialDataAssistantGet200Response
     */
    'expenses': V1FinancialDataAssistantGet200ResponseExpenses;
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseResume}
     * @memberof V1FinancialDataAssistantGet200Response
     */
    'resume': V1FinancialDataAssistantGet200ResponseResume;
}
/**
 * 
 * @export
 * @interface V1FinancialDataAssistantGet200ResponseCosts
 */
export interface V1FinancialDataAssistantGet200ResponseCosts {
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseSalesLastYearSales}
     * @memberof V1FinancialDataAssistantGet200ResponseCosts
     */
    'last_year_sales': V1FinancialDataAssistantGet200ResponseSalesLastYearSales;
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseSalesLastYearSales}
     * @memberof V1FinancialDataAssistantGet200ResponseCosts
     */
    'year_costs_plan': V1FinancialDataAssistantGet200ResponseSalesLastYearSales;
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility}
     * @memberof V1FinancialDataAssistantGet200ResponseCosts
     */
    'final_brute_utility': V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility;
}
/**
 * 
 * @export
 * @interface V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility
 */
export interface V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility {
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1}
     * @memberof V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility
     */
    '1': V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1;
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1}
     * @memberof V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility
     */
    '2': V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1;
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1}
     * @memberof V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility
     */
    '3': V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1;
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1}
     * @memberof V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility
     */
    '4': V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1;
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1}
     * @memberof V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility
     */
    '5': V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1;
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1}
     * @memberof V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility
     */
    '6': V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1;
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1}
     * @memberof V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility
     */
    '7': V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1;
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1}
     * @memberof V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility
     */
    '8': V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1;
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1}
     * @memberof V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility
     */
    '9': V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1;
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1}
     * @memberof V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility
     */
    '10': V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1;
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1}
     * @memberof V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility
     */
    '11': V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1;
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1}
     * @memberof V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility
     */
    '12': V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1;
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1}
     * @memberof V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility
     */
    'total': V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1;
}
/**
 * 
 * @export
 * @interface V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1
 */
export interface V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1 {
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataAssistantGet200ResponseCostsFinalBruteUtility1
     */
    'percentage': number;
}
/**
 * 
 * @export
 * @interface V1FinancialDataAssistantGet200ResponseData
 */
export interface V1FinancialDataAssistantGet200ResponseData {
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataAssistantGet200ResponseData
     */
    'sales_proyection_type'?: V1FinancialDataAssistantGet200ResponseDataSalesProyectionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataAssistantGet200ResponseData
     */
    'sales_growth_type'?: V1FinancialDataAssistantGet200ResponseDataSalesGrowthTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataAssistantGet200ResponseData
     */
    'sales_growth_amount'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof V1FinancialDataAssistantGet200ResponseData
     */
    'sales_individual_amounts'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataAssistantGet200ResponseData
     */
    'cost_percentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataAssistantGet200ResponseData
     */
    'expenses_proyection_type'?: V1FinancialDataAssistantGet200ResponseDataExpensesProyectionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataAssistantGet200ResponseData
     */
    'expenses_growth_type'?: V1FinancialDataAssistantGet200ResponseDataExpensesGrowthTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataAssistantGet200ResponseData
     */
    'expenses_growth_amount'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof V1FinancialDataAssistantGet200ResponseData
     */
    'expenses_individual_amounts'?: Array<number>;
}

export const V1FinancialDataAssistantGet200ResponseDataSalesProyectionTypeEnum = {
    Incremental: 'incremental',
    ZeroBase: 'zero_base'
} as const;

export type V1FinancialDataAssistantGet200ResponseDataSalesProyectionTypeEnum = typeof V1FinancialDataAssistantGet200ResponseDataSalesProyectionTypeEnum[keyof typeof V1FinancialDataAssistantGet200ResponseDataSalesProyectionTypeEnum];
export const V1FinancialDataAssistantGet200ResponseDataSalesGrowthTypeEnum = {
    Percentage: 'percentage',
    Money: 'money'
} as const;

export type V1FinancialDataAssistantGet200ResponseDataSalesGrowthTypeEnum = typeof V1FinancialDataAssistantGet200ResponseDataSalesGrowthTypeEnum[keyof typeof V1FinancialDataAssistantGet200ResponseDataSalesGrowthTypeEnum];
export const V1FinancialDataAssistantGet200ResponseDataExpensesProyectionTypeEnum = {
    Incremental: 'incremental',
    ZeroBase: 'zero_base'
} as const;

export type V1FinancialDataAssistantGet200ResponseDataExpensesProyectionTypeEnum = typeof V1FinancialDataAssistantGet200ResponseDataExpensesProyectionTypeEnum[keyof typeof V1FinancialDataAssistantGet200ResponseDataExpensesProyectionTypeEnum];
export const V1FinancialDataAssistantGet200ResponseDataExpensesGrowthTypeEnum = {
    Percentage: 'percentage',
    Money: 'money'
} as const;

export type V1FinancialDataAssistantGet200ResponseDataExpensesGrowthTypeEnum = typeof V1FinancialDataAssistantGet200ResponseDataExpensesGrowthTypeEnum[keyof typeof V1FinancialDataAssistantGet200ResponseDataExpensesGrowthTypeEnum];

/**
 * 
 * @export
 * @interface V1FinancialDataAssistantGet200ResponseExpenses
 */
export interface V1FinancialDataAssistantGet200ResponseExpenses {
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseSalesLastYearSales}
     * @memberof V1FinancialDataAssistantGet200ResponseExpenses
     */
    'last_year_expenses': V1FinancialDataAssistantGet200ResponseSalesLastYearSales;
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseSalesLastYearSales}
     * @memberof V1FinancialDataAssistantGet200ResponseExpenses
     */
    'year_expenses_plan': V1FinancialDataAssistantGet200ResponseSalesLastYearSales;
    /**
     * 
     * @type {Array<number>}
     * @memberof V1FinancialDataAssistantGet200ResponseExpenses
     */
    'expenses_plan_adjustments': Array<number>;
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseSalesLastYearSales}
     * @memberof V1FinancialDataAssistantGet200ResponseExpenses
     */
    'final_expenses_plan': V1FinancialDataAssistantGet200ResponseSalesLastYearSales;
}
/**
 * 
 * @export
 * @interface V1FinancialDataAssistantGet200ResponseResume
 */
export interface V1FinancialDataAssistantGet200ResponseResume {
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseSalesLastYearSales}
     * @memberof V1FinancialDataAssistantGet200ResponseResume
     */
    'final_operative_expenses': V1FinancialDataAssistantGet200ResponseSalesLastYearSales;
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseSalesLastYearSales}
     * @memberof V1FinancialDataAssistantGet200ResponseResume
     */
    'final_utility_revenue': V1FinancialDataAssistantGet200ResponseSalesLastYearSales;
}
/**
 * 
 * @export
 * @interface V1FinancialDataAssistantGet200ResponseSales
 */
export interface V1FinancialDataAssistantGet200ResponseSales {
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseSalesLastYearSales}
     * @memberof V1FinancialDataAssistantGet200ResponseSales
     */
    'last_year_sales': V1FinancialDataAssistantGet200ResponseSalesLastYearSales;
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseSalesLastYearSales}
     * @memberof V1FinancialDataAssistantGet200ResponseSales
     */
    'year_sales_plan': V1FinancialDataAssistantGet200ResponseSalesLastYearSales;
    /**
     * 
     * @type {Array<number>}
     * @memberof V1FinancialDataAssistantGet200ResponseSales
     */
    'sales_plan_adjustments': Array<number>;
    /**
     * 
     * @type {V1FinancialDataAssistantGet200ResponseSalesLastYearSales}
     * @memberof V1FinancialDataAssistantGet200ResponseSales
     */
    'final_sales_plan': V1FinancialDataAssistantGet200ResponseSalesLastYearSales;
}
/**
 * 
 * @export
 * @interface V1FinancialDataAssistantGet200ResponseSalesLastYearSales
 */
export interface V1FinancialDataAssistantGet200ResponseSalesLastYearSales {
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataAssistantGet200ResponseSalesLastYearSales
     */
    '1': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataAssistantGet200ResponseSalesLastYearSales
     */
    '2': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataAssistantGet200ResponseSalesLastYearSales
     */
    '3': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataAssistantGet200ResponseSalesLastYearSales
     */
    '4': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataAssistantGet200ResponseSalesLastYearSales
     */
    '5': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataAssistantGet200ResponseSalesLastYearSales
     */
    '6': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataAssistantGet200ResponseSalesLastYearSales
     */
    '7': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataAssistantGet200ResponseSalesLastYearSales
     */
    '8': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataAssistantGet200ResponseSalesLastYearSales
     */
    '9': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataAssistantGet200ResponseSalesLastYearSales
     */
    '10': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataAssistantGet200ResponseSalesLastYearSales
     */
    '11': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataAssistantGet200ResponseSalesLastYearSales
     */
    '12': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataAssistantGet200ResponseSalesLastYearSales
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface V1FinancialDataGet200Response
 */
export interface V1FinancialDataGet200Response {
    /**
     * 
     * @type {V1FinancialDataGet200ResponseStatementOfIncome}
     * @memberof V1FinancialDataGet200Response
     */
    'statement_of_income': V1FinancialDataGet200ResponseStatementOfIncome;
    /**
     * 
     * @type {V1FinancialDataGet200ResponseBalance}
     * @memberof V1FinancialDataGet200Response
     */
    'balance': V1FinancialDataGet200ResponseBalance;
    /**
     * 
     * @type {V1FinancialDataGet200ResponseCashFlow}
     * @memberof V1FinancialDataGet200Response
     */
    'cash_flow': V1FinancialDataGet200ResponseCashFlow;
    /**
     * 
     * @type {V1FinancialDataGet200ResponseIndicators}
     * @memberof V1FinancialDataGet200Response
     */
    'indicators': V1FinancialDataGet200ResponseIndicators;
}
/**
 * 
 * @export
 * @interface V1FinancialDataGet200ResponseBalance
 */
export interface V1FinancialDataGet200ResponseBalance {
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseBalance
     */
    'available_cash': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseBalance
     */
    'own_resources'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseBalance
     */
    'atc': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseBalance
     */
    'stock': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseBalance
     */
    'furniture_and_equipment': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseBalance
     */
    'active_assets': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseBalance
     */
    'atp': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseBalance
     */
    'passive_assets': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseBalance
     */
    'sum_utility': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseBalance
     */
    'exercise_utility': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseBalance
     */
    'total_heritage': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseBalance
     */
    'total_passive_heritage': string;
}
/**
 * 
 * @export
 * @interface V1FinancialDataGet200ResponseCashFlow
 */
export interface V1FinancialDataGet200ResponseCashFlow {
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseCashFlow
     */
    'acc_utility': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseCashFlow
     */
    'own_resources'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseCashFlow
     */
    'atc': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseCashFlow
     */
    'stock': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseCashFlow
     */
    'atp': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseCashFlow
     */
    'operative_flow': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseCashFlow
     */
    'furniture_and_equipment': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseCashFlow
     */
    'net_period': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseCashFlow
     */
    'initial_box': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseCashFlow
     */
    'final_box': string;
}
/**
 * 
 * @export
 * @interface V1FinancialDataGet200ResponseIndicators
 */
export interface V1FinancialDataGet200ResponseIndicators {
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataGet200ResponseIndicators
     */
    'days_atc': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataGet200ResponseIndicators
     */
    'days_stock': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataGet200ResponseIndicators
     */
    'days_atp': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataGet200ResponseIndicators
     */
    'box_cycle': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataGet200ResponseIndicators
     */
    'roe': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataGet200ResponseIndicators
     */
    'roa': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataGet200ResponseIndicators
     */
    'total_sales': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataGet200ResponseIndicators
     */
    'sales_cost': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataGet200ResponseIndicators
     */
    'discounts': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataGet200ResponseIndicators
     */
    'brute_margin': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataGet200ResponseIndicators
     */
    'operative_expenses': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataGet200ResponseIndicators
     */
    'ebitda': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataGet200ResponseIndicators
     */
    'other_incomes': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataGet200ResponseIndicators
     */
    'other_expenses': number;
    /**
     * 
     * @type {number}
     * @memberof V1FinancialDataGet200ResponseIndicators
     */
    'net_margin': number;
}
/**
 * 
 * @export
 * @interface V1FinancialDataGet200ResponseStatementOfIncome
 */
export interface V1FinancialDataGet200ResponseStatementOfIncome {
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseStatementOfIncome
     */
    'sales_in_cash': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseStatementOfIncome
     */
    'sales_credit': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseStatementOfIncome
     */
    'sales_web': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseStatementOfIncome
     */
    'discounts': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseStatementOfIncome
     */
    'sales_costs': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseStatementOfIncome
     */
    'expenses_in_cash': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseStatementOfIncome
     */
    'expenses_credit': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseStatementOfIncome
     */
    'other_incomes': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseStatementOfIncome
     */
    'other_expenses': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseStatementOfIncome
     */
    'furniture_and_equipment': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseStatementOfIncome
     */
    'stock': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseStatementOfIncome
     */
    'total_sales': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseStatementOfIncome
     */
    'profits': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseStatementOfIncome
     */
    'total_expenses': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseStatementOfIncome
     */
    'ebitda': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataGet200ResponseStatementOfIncome
     */
    'acc_utility': string;
}
/**
 * 
 * @export
 * @interface V1FinancialDataStatsGet200Response
 */
export interface V1FinancialDataStatsGet200Response {
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataStatsGet200Response
     */
    'average_expenses': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataStatsGet200Response
     */
    'average_product_cost': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataStatsGet200Response
     */
    'average_product_sells': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataStatsGet200Response
     */
    'average_profits': string;
    /**
     * 
     * @type {string}
     * @memberof V1FinancialDataStatsGet200Response
     */
    'average_sales': string;
}
/**
 * 
 * @export
 * @interface V1GamificationBasicRoadmapGet200Response
 */
export interface V1GamificationBasicRoadmapGet200Response {
    /**
     * 
     * @type {boolean}
     * @memberof V1GamificationBasicRoadmapGet200Response
     */
    'org_settings': boolean;
    /**
     * 
     * @type {number}
     * @memberof V1GamificationBasicRoadmapGet200Response
     */
    'products_count': number;
    /**
     * 
     * @type {number}
     * @memberof V1GamificationBasicRoadmapGet200Response
     */
    'sales_count': number;
    /**
     * 
     * @type {number}
     * @memberof V1GamificationBasicRoadmapGet200Response
     */
    'expenses_count': number;
}
/**
 * 
 * @export
 * @interface V1IndustriesGet200Response
 */
export interface V1IndustriesGet200Response {
    /**
     * 
     * @type {Array<string>}
     * @memberof V1IndustriesGet200Response
     */
    'industries': Array<string>;
}
/**
 * 
 * @export
 * @interface V1InitialBalanceAmountsGet200ResponseInner
 */
export interface V1InitialBalanceAmountsGet200ResponseInner {
    /**
     * 
     * @type {V1InitialBalanceAmountsGet200ResponseInnerInitialBalanceAmount}
     * @memberof V1InitialBalanceAmountsGet200ResponseInner
     */
    'initial_balance_amount': V1InitialBalanceAmountsGet200ResponseInnerInitialBalanceAmount;
}
/**
 * 
 * @export
 * @interface V1InitialBalanceAmountsGet200ResponseInnerInitialBalanceAmount
 */
export interface V1InitialBalanceAmountsGet200ResponseInnerInitialBalanceAmount {
    /**
     * 
     * @type {number}
     * @memberof V1InitialBalanceAmountsGet200ResponseInnerInitialBalanceAmount
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1InitialBalanceAmountsGet200ResponseInnerInitialBalanceAmount
     */
    'own_resources': string;
    /**
     * 
     * @type {string}
     * @memberof V1InitialBalanceAmountsGet200ResponseInnerInitialBalanceAmount
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1InitialBalanceAmountsGet200ResponseInnerInitialBalanceAmount
     */
    'updated_at': string;
    /**
     * 
     * @type {number}
     * @memberof V1InitialBalanceAmountsGet200ResponseInnerInitialBalanceAmount
     */
    'organization_id': number;
}
/**
 * 
 * @export
 * @interface V1InitialBalanceAmountsGetRequest
 */
export interface V1InitialBalanceAmountsGetRequest {
    /**
     * 
     * @type {V1InitialBalanceAmountsGetRequestInitialBalanceAmounts}
     * @memberof V1InitialBalanceAmountsGetRequest
     */
    'initial_balance_amounts': V1InitialBalanceAmountsGetRequestInitialBalanceAmounts;
}
/**
 * 
 * @export
 * @interface V1InitialBalanceAmountsGetRequestInitialBalanceAmounts
 */
export interface V1InitialBalanceAmountsGetRequestInitialBalanceAmounts {
    /**
     * 
     * @type {string}
     * @memberof V1InitialBalanceAmountsGetRequestInitialBalanceAmounts
     */
    'own_resources': string;
}
/**
 * 
 * @export
 * @interface V1InviteesGet200Response
 */
export interface V1InviteesGet200Response {
    /**
     * 
     * @type {Array<V1InviteesGet200ResponseInviteesInner>}
     * @memberof V1InviteesGet200Response
     */
    'invitees': Array<V1InviteesGet200ResponseInviteesInner>;
}
/**
 * 
 * @export
 * @interface V1InviteesGet200ResponseInviteesInner
 */
export interface V1InviteesGet200ResponseInviteesInner {
    /**
     * 
     * @type {number}
     * @memberof V1InviteesGet200ResponseInviteesInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1InviteesGet200ResponseInviteesInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1InviteesGet200ResponseInviteesInner
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof V1InviteesGet200ResponseInviteesInner
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof V1InviteesGet200ResponseInviteesInner
     */
    'main_role'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1InviteesGet200ResponseInviteesInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1InviteesGet200ResponseInviteesInner
     */
    'accepted_at': string | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory}
     * @memberof V1InviteesGet200ResponseInviteesInner
     */
    'role': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory | null;
}
/**
 * 
 * @export
 * @interface V1JoinPostRequest
 */
export interface V1JoinPostRequest {
    /**
     * 
     * @type {string}
     * @memberof V1JoinPostRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface V1ModifiersPost201Response
 */
export interface V1ModifiersPost201Response {
    /**
     * 
     * @type {number}
     * @memberof V1ModifiersPost201Response
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ModifiersPost201Response
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1ModifiersPost201Response
     */
    'color': string;
    /**
     * 
     * @type {string}
     * @memberof V1ModifiersPost201Response
     */
    'price': string;
    /**
     * 
     * @type {string}
     * @memberof V1ModifiersPost201Response
     */
    'type': V1ModifiersPost201ResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof V1ModifiersPost201Response
     */
    'created_at': string;
}

export const V1ModifiersPost201ResponseTypeEnum = {
    ModifierProduct: 'ModifierProduct',
    ModifierService: 'ModifierService'
} as const;

export type V1ModifiersPost201ResponseTypeEnum = typeof V1ModifiersPost201ResponseTypeEnum[keyof typeof V1ModifiersPost201ResponseTypeEnum];

/**
 * 
 * @export
 * @interface V1ModifiersPostRequest
 */
export interface V1ModifiersPostRequest {
    /**
     * 
     * @type {V1ModifiersPostRequestModifier}
     * @memberof V1ModifiersPostRequest
     */
    'modifier': V1ModifiersPostRequestModifier;
}
/**
 * 
 * @export
 * @interface V1ModifiersPostRequestModifier
 */
export interface V1ModifiersPostRequestModifier {
    /**
     * 
     * @type {string}
     * @memberof V1ModifiersPostRequestModifier
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1ModifiersPostRequestModifier
     */
    'color': string;
    /**
     * 
     * @type {string}
     * @memberof V1ModifiersPostRequestModifier
     */
    'price': string;
    /**
     * 
     * @type {string}
     * @memberof V1ModifiersPostRequestModifier
     */
    'type': V1ModifiersPostRequestModifierTypeEnum;
}

export const V1ModifiersPostRequestModifierTypeEnum = {
    ModifierProduct: 'ModifierProduct',
    ModifierService: 'ModifierService'
} as const;

export type V1ModifiersPostRequestModifierTypeEnum = typeof V1ModifiersPostRequestModifierTypeEnum[keyof typeof V1ModifiersPostRequestModifierTypeEnum];

/**
 * 
 * @export
 * @interface V1ModifiersProductGet200Response
 */
export interface V1ModifiersProductGet200Response {
    /**
     * 
     * @type {Array<V1ModifiersPost201Response>}
     * @memberof V1ModifiersProductGet200Response
     */
    'modifiers': Array<V1ModifiersPost201Response>;
    /**
     * 
     * @type {V1ContactsDebtsTransactionsGet200ResponsePagination}
     * @memberof V1ModifiersProductGet200Response
     */
    'pagination': V1ContactsDebtsTransactionsGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1NotificationsOrganizationGet200Response
 */
export interface V1NotificationsOrganizationGet200Response {
    /**
     * 
     * @type {Array<V1NotificationsOrganizationGet200ResponseNotificationsInner>}
     * @memberof V1NotificationsOrganizationGet200Response
     */
    'notifications': Array<V1NotificationsOrganizationGet200ResponseNotificationsInner>;
    /**
     * 
     * @type {V1ContactsDebtsTransactionsGet200ResponsePagination}
     * @memberof V1NotificationsOrganizationGet200Response
     */
    'pagination': V1ContactsDebtsTransactionsGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1NotificationsOrganizationGet200ResponseNotificationsInner
 */
export interface V1NotificationsOrganizationGet200ResponseNotificationsInner {
    /**
     * 
     * @type {number}
     * @memberof V1NotificationsOrganizationGet200ResponseNotificationsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1NotificationsOrganizationGet200ResponseNotificationsInner
     */
    'content': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1NotificationsOrganizationGet200ResponseNotificationsInner
     */
    'seen'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof V1NotificationsOrganizationGet200ResponseNotificationsInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1NotificationsOrganizationGet200ResponseNotificationsInner
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V1NotificationsPlatformGet200Response
 */
export interface V1NotificationsPlatformGet200Response {
    /**
     * 
     * @type {Array<V1NotificationsPlatformGet200ResponseNotificationsInner>}
     * @memberof V1NotificationsPlatformGet200Response
     */
    'notifications': Array<V1NotificationsPlatformGet200ResponseNotificationsInner>;
    /**
     * 
     * @type {V1ContactsDebtsTransactionsGet200ResponsePagination}
     * @memberof V1NotificationsPlatformGet200Response
     */
    'pagination': V1ContactsDebtsTransactionsGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1NotificationsPlatformGet200ResponseNotificationsInner
 */
export interface V1NotificationsPlatformGet200ResponseNotificationsInner {
    /**
     * 
     * @type {number}
     * @memberof V1NotificationsPlatformGet200ResponseNotificationsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1NotificationsPlatformGet200ResponseNotificationsInner
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof V1NotificationsPlatformGet200ResponseNotificationsInner
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof V1NotificationsPlatformGet200ResponseNotificationsInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1NotificationsPlatformGet200ResponseNotificationsInner
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V1OffersGet200Response
 */
export interface V1OffersGet200Response {
    /**
     * 
     * @type {Array<V1OffersGet200ResponseOffersInner>}
     * @memberof V1OffersGet200Response
     */
    'offers': Array<V1OffersGet200ResponseOffersInner>;
    /**
     * 
     * @type {V1ContactsDebtsTransactionsGet200ResponsePagination}
     * @memberof V1OffersGet200Response
     */
    'pagination': V1ContactsDebtsTransactionsGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1OffersGet200ResponseOffersInner
 */
export interface V1OffersGet200ResponseOffersInner {
    /**
     * 
     * @type {number}
     * @memberof V1OffersGet200ResponseOffersInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1OffersGet200ResponseOffersInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1OffersGet200ResponseOffersInner
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof V1OffersGet200ResponseOffersInner
     */
    'end_time': string;
    /**
     * 
     * @type {number}
     * @memberof V1OffersGet200ResponseOffersInner
     */
    'percentage': number | null;
    /**
     * 
     * @type {string}
     * @memberof V1OffersGet200ResponseOffersInner
     */
    'discount': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OffersGet200ResponseOffersInner
     */
    'activation_type': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1OffersGet200ResponseOffersInner
     */
    'active': boolean;
    /**
     * 
     * @type {Array<V1OffersGet200ResponseOffersInnerProductsInner>}
     * @memberof V1OffersGet200ResponseOffersInner
     */
    'products': Array<V1OffersGet200ResponseOffersInnerProductsInner>;
}
/**
 * 
 * @export
 * @interface V1OffersGet200ResponseOffersInnerProductsInner
 */
export interface V1OffersGet200ResponseOffersInnerProductsInner {
    /**
     * 
     * @type {number}
     * @memberof V1OffersGet200ResponseOffersInnerProductsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1OffersGet200ResponseOffersInnerProductsInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1OffersGet200ResponseOffersInnerProductsInner
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OffersGet200ResponseOffersInnerProductsInner
     */
    'cost': string;
    /**
     * 
     * @type {string}
     * @memberof V1OffersGet200ResponseOffersInnerProductsInner
     */
    'price': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1OffersGet200ResponseOffersInnerProductsInner
     */
    'is_avg_cost'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof V1OffersGet200ResponseOffersInnerProductsInner
     */
    'discount_price': string | null;
    /**
     * 
     * @type {number}
     * @memberof V1OffersGet200ResponseOffersInnerProductsInner
     */
    'stock': number;
    /**
     * 
     * @type {string}
     * @memberof V1OffersGet200ResponseOffersInnerProductsInner
     */
    'barcode': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof V1OffersGet200ResponseOffersInnerProductsInner
     */
    'show_in_store': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1OffersGet200ResponseOffersInnerProductsInner
     */
    'show_in_pos': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1OffersGet200ResponseOffersInnerProductsInner
     */
    'has_modifiers': boolean | null;
    /**
     * 
     * @type {Array<V1OffersGet200ResponseOffersInnerProductsInnerModifiersInner>}
     * @memberof V1OffersGet200ResponseOffersInnerProductsInner
     */
    'modifiers'?: Array<V1OffersGet200ResponseOffersInnerProductsInnerModifiersInner> | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor}
     * @memberof V1OffersGet200ResponseOffersInnerProductsInner
     */
    'color': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor}
     * @memberof V1OffersGet200ResponseOffersInnerProductsInner
     */
    'size': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor}
     * @memberof V1OffersGet200ResponseOffersInnerProductsInner
     */
    'style': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor | null;
    /**
     * 
     * @type {Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>}
     * @memberof V1OffersGet200ResponseOffersInnerProductsInner
     */
    'images': Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory}
     * @memberof V1OffersGet200ResponseOffersInnerProductsInner
     */
    'product_category'?: V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory}
     * @memberof V1OffersGet200ResponseOffersInnerProductsInner
     */
    'collection': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory | null;
    /**
     * 
     * @type {string}
     * @memberof V1OffersGet200ResponseOffersInnerProductsInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1OffersGet200ResponseOffersInnerProductsInner
     */
    'updated_at': string;
    /**
     * 
     * @type {number}
     * @memberof V1OffersGet200ResponseOffersInnerProductsInner
     */
    'product_variants_count'?: number;
}
/**
 * 
 * @export
 * @interface V1OffersGet200ResponseOffersInnerProductsInnerModifiersInner
 */
export interface V1OffersGet200ResponseOffersInnerProductsInnerModifiersInner {
    /**
     * 
     * @type {number}
     * @memberof V1OffersGet200ResponseOffersInnerProductsInnerModifiersInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1OffersGet200ResponseOffersInnerProductsInnerModifiersInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1OffersGet200ResponseOffersInnerProductsInnerModifiersInner
     */
    'color': string;
    /**
     * 
     * @type {string}
     * @memberof V1OffersGet200ResponseOffersInnerProductsInnerModifiersInner
     */
    'price': string;
}
/**
 * 
 * @export
 * @interface V1OffersGetRequest
 */
export interface V1OffersGetRequest {
    /**
     * 
     * @type {V1OffersGetRequestOffer}
     * @memberof V1OffersGetRequest
     */
    'offer': V1OffersGetRequestOffer;
}
/**
 * 
 * @export
 * @interface V1OffersGetRequestOffer
 */
export interface V1OffersGetRequestOffer {
    /**
     * 
     * @type {string}
     * @memberof V1OffersGetRequestOffer
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1OffersGetRequestOffer
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof V1OffersGetRequestOffer
     */
    'end_time': string;
    /**
     * 
     * @type {number}
     * @memberof V1OffersGetRequestOffer
     */
    'percentage': number | null;
    /**
     * 
     * @type {number}
     * @memberof V1OffersGetRequestOffer
     */
    'discount': number | null;
    /**
     * 
     * @type {string}
     * @memberof V1OffersGetRequestOffer
     */
    'activation_type': V1OffersGetRequestOfferActivationTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof V1OffersGetRequestOffer
     */
    'active': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1OffersGetRequestOffer
     */
    'all_products': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof V1OffersGetRequestOffer
     */
    'product_ids': Array<number>;
}

export const V1OffersGetRequestOfferActivationTypeEnum = {
    Percentage: 'percentage',
    Money: 'money'
} as const;

export type V1OffersGetRequestOfferActivationTypeEnum = typeof V1OffersGetRequestOfferActivationTypeEnum[keyof typeof V1OffersGetRequestOfferActivationTypeEnum];

/**
 * 
 * @export
 * @interface V1OffersProductsGet200Response
 */
export interface V1OffersProductsGet200Response {
    /**
     * 
     * @type {Array<V1OffersGet200ResponseOffersInnerProductsInner>}
     * @memberof V1OffersProductsGet200Response
     */
    'products': Array<V1OffersGet200ResponseOffersInnerProductsInner>;
    /**
     * 
     * @type {V1ContactsDebtsTransactionsGet200ResponsePagination}
     * @memberof V1OffersProductsGet200Response
     */
    'pagination': V1ContactsDebtsTransactionsGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1OrganizationSettingGet200Response
 */
export interface V1OrganizationSettingGet200Response {
    /**
     * 
     * @type {boolean}
     * @memberof V1OrganizationSettingGet200Response
     */
    'pick_in_store': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1OrganizationSettingGet200Response
     */
    'delivery': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1OrganizationSettingGet200Response
     */
    'pay_with_cash': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1OrganizationSettingGet200Response
     */
    'pay_with_card': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1OrganizationSettingGet200Response
     */
    'pay_with_bank': boolean;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response
     */
    'monday_from': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response
     */
    'monday_to': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response
     */
    'tuesday_from': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response
     */
    'tuesday_to': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response
     */
    'wednesday_from': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response
     */
    'wednesday_to': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response
     */
    'thursday_from': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response
     */
    'thursday_to': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response
     */
    'friday_from': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response
     */
    'friday_to': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response
     */
    'saturday_from': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response
     */
    'saturday_to': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response
     */
    'sunday_from': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response
     */
    'sunday_to': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response
     */
    'image': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response
     */
    'facebook': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response
     */
    'instagram': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response
     */
    'twitter': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response
     */
    'tik_tok': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response
     */
    'fixed_rate': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof V1OrganizationSettingGet200Response
     */
    'shareable': boolean;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response
     */
    'payment_link': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response
     */
    'whatsapp_contact': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof V1OrganizationSettingGet200Response
     */
    'out_of_stock_last': boolean;
}
/**
 * 
 * @export
 * @interface V1OrganizationSettingGet200Response1
 */
export interface V1OrganizationSettingGet200Response1 {
    /**
     * 
     * @type {boolean}
     * @memberof V1OrganizationSettingGet200Response1
     */
    'pick_in_store': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1OrganizationSettingGet200Response1
     */
    'delivery': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1OrganizationSettingGet200Response1
     */
    'pay_with_cash': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1OrganizationSettingGet200Response1
     */
    'pay_with_card': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1OrganizationSettingGet200Response1
     */
    'pay_with_bank': boolean;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response1
     */
    'monday_from': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response1
     */
    'monday_to': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response1
     */
    'tuesday_from': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response1
     */
    'tuesday_to': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response1
     */
    'wednesday_from': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response1
     */
    'wednesday_to': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response1
     */
    'thursday_from': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response1
     */
    'thursday_to': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response1
     */
    'friday_from': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response1
     */
    'friday_to': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response1
     */
    'saturday_from': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response1
     */
    'saturday_to': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response1
     */
    'sunday_from': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response1
     */
    'sunday_to': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response1
     */
    'image': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response1
     */
    'fixed_rate': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof V1OrganizationSettingGet200Response1
     */
    'shareable': boolean;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response1
     */
    'payment_link': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationSettingGet200Response1
     */
    'whatsapp_contact': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof V1OrganizationSettingGet200Response1
     */
    'out_of_stock_last': boolean;
}
/**
 * 
 * @export
 * @interface V1OrganizationsIndustryPatchRequest
 */
export interface V1OrganizationsIndustryPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsIndustryPatchRequest
     */
    'industry': string;
}
/**
 * 
 * @export
 * @interface V1OrganizationsPatch201Response
 */
export interface V1OrganizationsPatch201Response {
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPatch201Response
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPatch201Response
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPatch201Response
     */
    'money_symbol': V1OrganizationsPatch201ResponseMoneySymbolEnum;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPatch201Response
     */
    'industry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPatch201Response
     */
    'business_type'?: V1OrganizationsPatch201ResponseBusinessTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPatch201Response
     */
    'avatar': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPatch201Response
     */
    'custom_image': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPatch201Response
     */
    'primary_color': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPatch201Response
     */
    'secondary_color': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPatch201Response
     */
    'thank_you_message': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof V1OrganizationsPatch201Response
     */
    'ticket_hide_logo': boolean;
    /**
     * 
     * @type {number}
     * @memberof V1OrganizationsPatch201Response
     */
    'low_stock': number;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPatch201Response
     */
    'receipt_line_1': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPatch201Response
     */
    'receipt_line_2': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPatch201Response
     */
    'receipt_line_3': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPatch201Response
     */
    'receipt_line_4': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof V1OrganizationsPatch201Response
     */
    'unified_transactions': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1OrganizationsPatch201Response
     */
    'is_dollar': boolean | null;
    /**
     * 
     * @type {V1OrganizationsPatch201ResponseCountry}
     * @memberof V1OrganizationsPatch201Response
     */
    'country': V1OrganizationsPatch201ResponseCountry | null;
}

export const V1OrganizationsPatch201ResponseMoneySymbolEnum = {
    Dot: 'dot',
    Comma: 'comma'
} as const;

export type V1OrganizationsPatch201ResponseMoneySymbolEnum = typeof V1OrganizationsPatch201ResponseMoneySymbolEnum[keyof typeof V1OrganizationsPatch201ResponseMoneySymbolEnum];
export const V1OrganizationsPatch201ResponseBusinessTypeEnum = {
    Person: 'person',
    Business: 'business'
} as const;

export type V1OrganizationsPatch201ResponseBusinessTypeEnum = typeof V1OrganizationsPatch201ResponseBusinessTypeEnum[keyof typeof V1OrganizationsPatch201ResponseBusinessTypeEnum];

/**
 * 
 * @export
 * @interface V1OrganizationsPatch201ResponseCountry
 */
export interface V1OrganizationsPatch201ResponseCountry {
    /**
     * 
     * @type {number}
     * @memberof V1OrganizationsPatch201ResponseCountry
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPatch201ResponseCountry
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPatch201ResponseCountry
     */
    'currency_symbol': string;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPatch201ResponseCountry
     */
    'currency_name': string;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPatch201ResponseCountry
     */
    'phone_area_number': string;
}
/**
 * 
 * @export
 * @interface V1OrganizationsPlusSettingsPatch200Response
 */
export interface V1OrganizationsPlusSettingsPatch200Response {
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPlusSettingsPatch200Response
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPlusSettingsPatch200Response
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPlusSettingsPatch200Response
     */
    'money_symbol': V1OrganizationsPlusSettingsPatch200ResponseMoneySymbolEnum;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPlusSettingsPatch200Response
     */
    'industry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPlusSettingsPatch200Response
     */
    'business_type'?: V1OrganizationsPlusSettingsPatch200ResponseBusinessTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPlusSettingsPatch200Response
     */
    'avatar': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPlusSettingsPatch200Response
     */
    'custom_image': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPlusSettingsPatch200Response
     */
    'primary_color': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPlusSettingsPatch200Response
     */
    'secondary_color': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPlusSettingsPatch200Response
     */
    'thank_you_message': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof V1OrganizationsPlusSettingsPatch200Response
     */
    'ticket_hide_logo': boolean;
    /**
     * 
     * @type {number}
     * @memberof V1OrganizationsPlusSettingsPatch200Response
     */
    'low_stock': number;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPlusSettingsPatch200Response
     */
    'receipt_line_1': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPlusSettingsPatch200Response
     */
    'receipt_line_2': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPlusSettingsPatch200Response
     */
    'receipt_line_3': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsPlusSettingsPatch200Response
     */
    'receipt_line_4': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof V1OrganizationsPlusSettingsPatch200Response
     */
    'unified_transactions': boolean;
    /**
     * 
     * @type {V1OrganizationsPatch201ResponseCountry}
     * @memberof V1OrganizationsPlusSettingsPatch200Response
     */
    'country': V1OrganizationsPatch201ResponseCountry | null;
}

export const V1OrganizationsPlusSettingsPatch200ResponseMoneySymbolEnum = {
    Dot: 'dot',
    Comma: 'comma'
} as const;

export type V1OrganizationsPlusSettingsPatch200ResponseMoneySymbolEnum = typeof V1OrganizationsPlusSettingsPatch200ResponseMoneySymbolEnum[keyof typeof V1OrganizationsPlusSettingsPatch200ResponseMoneySymbolEnum];
export const V1OrganizationsPlusSettingsPatch200ResponseBusinessTypeEnum = {
    Person: 'person',
    Business: 'business'
} as const;

export type V1OrganizationsPlusSettingsPatch200ResponseBusinessTypeEnum = typeof V1OrganizationsPlusSettingsPatch200ResponseBusinessTypeEnum[keyof typeof V1OrganizationsPlusSettingsPatch200ResponseBusinessTypeEnum];

/**
 * 
 * @export
 * @interface V1OrganizationsUsersGet200Response
 */
export interface V1OrganizationsUsersGet200Response {
    /**
     * 
     * @type {Array<V1OrganizationsUsersGet200ResponseUsersInner>}
     * @memberof V1OrganizationsUsersGet200Response
     */
    'users'?: Array<V1OrganizationsUsersGet200ResponseUsersInner>;
}
/**
 * 
 * @export
 * @interface V1OrganizationsUsersGet200ResponseUsersInner
 */
export interface V1OrganizationsUsersGet200ResponseUsersInner {
    /**
     * 
     * @type {number}
     * @memberof V1OrganizationsUsersGet200ResponseUsersInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1OrganizationsUsersGet200ResponseUsersInner
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface V1PaymentInfoGet200Response
 */
export interface V1PaymentInfoGet200Response {
    /**
     * 
     * @type {V1PaymentInfoGet200ResponsePaymentInfo}
     * @memberof V1PaymentInfoGet200Response
     */
    'payment_info': V1PaymentInfoGet200ResponsePaymentInfo | null;
}
/**
 * 
 * @export
 * @interface V1PaymentInfoGet200ResponsePaymentInfo
 */
export interface V1PaymentInfoGet200ResponsePaymentInfo {
    /**
     * 
     * @type {string}
     * @memberof V1PaymentInfoGet200ResponsePaymentInfo
     */
    'bank_name': string;
    /**
     * 
     * @type {string}
     * @memberof V1PaymentInfoGet200ResponsePaymentInfo
     */
    'bank_number': string;
    /**
     * 
     * @type {string}
     * @memberof V1PaymentInfoGet200ResponsePaymentInfo
     */
    'address': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1PaymentInfoGet200ResponsePaymentInfo
     */
    'front_id_image_sent': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1PaymentInfoGet200ResponsePaymentInfo
     */
    'back_id_image_sent': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1PaymentInfoGet200ResponsePaymentInfo
     */
    'personal_image_sent': boolean;
    /**
     * 
     * @type {string}
     * @memberof V1PaymentInfoGet200ResponsePaymentInfo
     */
    'process_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1PaymentInfoGet200ResponsePaymentInfo
     */
    'admin_message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V1PaymentInfoGet200ResponsePaymentInfo
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1PaymentInfoGet200ResponsePaymentInfo
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V1PaymentInfoGetRequest
 */
export interface V1PaymentInfoGetRequest {
    /**
     * 
     * @type {V1PaymentInfoGetRequestPaymentInfo}
     * @memberof V1PaymentInfoGetRequest
     */
    'payment_info': V1PaymentInfoGetRequestPaymentInfo;
}
/**
 * 
 * @export
 * @interface V1PaymentInfoGetRequestPaymentInfo
 */
export interface V1PaymentInfoGetRequestPaymentInfo {
    /**
     * 
     * @type {string}
     * @memberof V1PaymentInfoGetRequestPaymentInfo
     */
    'bank_name': string;
    /**
     * 
     * @type {string}
     * @memberof V1PaymentInfoGetRequestPaymentInfo
     */
    'bank_number': string;
    /**
     * 
     * @type {string}
     * @memberof V1PaymentInfoGetRequestPaymentInfo
     */
    'address': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1PaymentInfoGetRequestPaymentInfo
     */
    'front_id_image': string;
    /**
     * 
     * @type {string}
     * @memberof V1PaymentInfoGetRequestPaymentInfo
     */
    'back_id_image': string;
    /**
     * 
     * @type {string}
     * @memberof V1PaymentInfoGetRequestPaymentInfo
     */
    'personal_image': string;
}
/**
 * 
 * @export
 * @interface V1PosTransactionsGet200Response
 */
export interface V1PosTransactionsGet200Response {
    /**
     * 
     * @type {Array<V1PosTransactionsGet200ResponseTransactionsInner>}
     * @memberof V1PosTransactionsGet200Response
     */
    'transactions': Array<V1PosTransactionsGet200ResponseTransactionsInner>;
    /**
     * 
     * @type {V1CashFlowGet200ResponsePagination}
     * @memberof V1PosTransactionsGet200Response
     */
    'pagination': V1CashFlowGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1PosTransactionsGet200ResponseTransactionsInner
 */
export interface V1PosTransactionsGet200ResponseTransactionsInner {
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'origin': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'amount_due': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'amount_paid': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'amount_free': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'amount_discount': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'amount_products': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'amount_modifiers': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'amount_services': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'amount_utility': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'amount_cost': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'amount_total': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'delivery_amount': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'amount_delivery': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'amount_total_with_delivery': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'concept': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'transaction_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'transaction_status': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'transaction_origin': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'payment_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'payment_method': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'process_status': string;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'transaction_category': V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory | null;
    /**
     * 
     * @type {Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'products': Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>;
    /**
     * 
     * @type {Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'services': Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'pos_metadata': V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata | null;
    /**
     * 
     * @type {V1PosTransactionsGet200ResponseTransactionsInnerContact}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'contact': V1PosTransactionsGet200ResponseTransactionsInnerContact | null;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInner
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V1PosTransactionsGet200ResponseTransactionsInnerContact
 */
export interface V1PosTransactionsGet200ResponseTransactionsInnerContact {
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInnerContact
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInnerContact
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInnerContact
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInnerContact
     */
    'address'?: string;
}
/**
 * 
 * @export
 * @interface V1PosTransactionsGet200ResponseTransactionsInnerServicesInner
 */
export interface V1PosTransactionsGet200ResponseTransactionsInnerServicesInner {
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInnerServicesInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInnerServicesInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInnerServicesInner
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInnerServicesInner
     */
    'price': string;
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInnerServicesInner
     */
    'quantity': number;
    /**
     * 
     * @type {boolean}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInnerServicesInner
     */
    'show_in_store': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInnerServicesInner
     */
    'show_in_pos': boolean;
    /**
     * 
     * @type {Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerExtrasInner>}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInnerServicesInner
     */
    'extras': Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerExtrasInner> | null;
    /**
     * 
     * @type {Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInnerServicesInner
     */
    'images': Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInnerServicesInner
     */
    'service_category'?: V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory | null;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInnerServicesInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet200ResponseTransactionsInnerServicesInner
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V1PosTransactionsGet201Response
 */
export interface V1PosTransactionsGet201Response {
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsGet201Response
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet201Response
     */
    'origin': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet201Response
     */
    'amount_due': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet201Response
     */
    'amount_paid': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet201Response
     */
    'amount_free': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet201Response
     */
    'amount_discount': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet201Response
     */
    'amount_products': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet201Response
     */
    'amount_modifiers': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet201Response
     */
    'amount_services': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet201Response
     */
    'amount_utility': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet201Response
     */
    'amount_cost': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet201Response
     */
    'amount_total': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet201Response
     */
    'delivery_amount': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet201Response
     */
    'amount_delivery'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet201Response
     */
    'amount_total_with_delivery': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet201Response
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet201Response
     */
    'concept': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet201Response
     */
    'transaction_origin': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet201Response
     */
    'transaction_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet201Response
     */
    'transaction_status': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet201Response
     */
    'payment_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet201Response
     */
    'payment_method': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet201Response
     */
    'process_status': string;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory}
     * @memberof V1PosTransactionsGet201Response
     */
    'transaction_category': V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory | null;
    /**
     * 
     * @type {Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>}
     * @memberof V1PosTransactionsGet201Response
     */
    'products': Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>;
    /**
     * 
     * @type {Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>}
     * @memberof V1PosTransactionsGet201Response
     */
    'services': Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata}
     * @memberof V1PosTransactionsGet201Response
     */
    'pos_metadata': V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata | null;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet201Response
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGet201Response
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V1PosTransactionsGetRequest
 */
export interface V1PosTransactionsGetRequest {
    /**
     * 
     * @type {V1PosTransactionsGetRequestPos}
     * @memberof V1PosTransactionsGetRequest
     */
    'pos': V1PosTransactionsGetRequestPos;
}
/**
 * 
 * @export
 * @interface V1PosTransactionsGetRequestPos
 */
export interface V1PosTransactionsGetRequestPos {
    /**
     * 
     * @type {Array<V1PosTransactionsGetRequestPosProductsInner>}
     * @memberof V1PosTransactionsGetRequestPos
     */
    'products': Array<V1PosTransactionsGetRequestPosProductsInner>;
    /**
     * 
     * @type {Array<V1PosTransactionsGetRequestPosServicesInner>}
     * @memberof V1PosTransactionsGetRequestPos
     */
    'services': Array<V1PosTransactionsGetRequestPosServicesInner>;
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsGetRequestPos
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsGetRequestPos
     */
    'delivery_amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsGetRequestPos
     */
    'discount'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGetRequestPos
     */
    'concept'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGetRequestPos
     */
    'payment_method': V1PosTransactionsGetRequestPosPaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGetRequestPos
     */
    'created_at': string | null;
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsGetRequestPos
     */
    'transaction_category_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGetRequestPos
     */
    'transaction_origin': V1PosTransactionsGetRequestPosTransactionOriginEnum;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGetRequestPos
     */
    'transaction_status': V1PosTransactionsGetRequestPosTransactionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGetRequestPos
     */
    'process_status': V1PosTransactionsGetRequestPosProcessStatusEnum;
    /**
     * 
     * @type {V1PosTransactionsGetRequestPosPosMetadatum}
     * @memberof V1PosTransactionsGetRequestPos
     */
    'pos_metadatum': V1PosTransactionsGetRequestPosPosMetadatum | null;
}

export const V1PosTransactionsGetRequestPosPaymentMethodEnum = {
    Cash: 'cash',
    Card: 'card',
    Other: 'other',
    Bank: 'bank',
    OnCredit: 'on-credit'
} as const;

export type V1PosTransactionsGetRequestPosPaymentMethodEnum = typeof V1PosTransactionsGetRequestPosPaymentMethodEnum[keyof typeof V1PosTransactionsGetRequestPosPaymentMethodEnum];
export const V1PosTransactionsGetRequestPosTransactionOriginEnum = {
    App: 'app',
    AppWeb: 'app-web'
} as const;

export type V1PosTransactionsGetRequestPosTransactionOriginEnum = typeof V1PosTransactionsGetRequestPosTransactionOriginEnum[keyof typeof V1PosTransactionsGetRequestPosTransactionOriginEnum];
export const V1PosTransactionsGetRequestPosTransactionStatusEnum = {
    Paid: 'paid',
    Unpaid: 'unpaid'
} as const;

export type V1PosTransactionsGetRequestPosTransactionStatusEnum = typeof V1PosTransactionsGetRequestPosTransactionStatusEnum[keyof typeof V1PosTransactionsGetRequestPosTransactionStatusEnum];
export const V1PosTransactionsGetRequestPosProcessStatusEnum = {
    InProgress: 'in-progress',
    Finished: 'finished'
} as const;

export type V1PosTransactionsGetRequestPosProcessStatusEnum = typeof V1PosTransactionsGetRequestPosProcessStatusEnum[keyof typeof V1PosTransactionsGetRequestPosProcessStatusEnum];

/**
 * 
 * @export
 * @interface V1PosTransactionsGetRequestPosPosMetadatum
 */
export interface V1PosTransactionsGetRequestPosPosMetadatum {
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGetRequestPosPosMetadatum
     */
    'name': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGetRequestPosPosMetadatum
     */
    'table_identifier': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGetRequestPosPosMetadatum
     */
    'comments': string | null;
}
/**
 * 
 * @export
 * @interface V1PosTransactionsGetRequestPosProductsInner
 */
export interface V1PosTransactionsGetRequestPosProductsInner {
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsGetRequestPosProductsInner
     */
    'product_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsGetRequestPosProductsInner
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsGetRequestPosProductsInner
     */
    'custom_price'?: string | null;
    /**
     * 
     * @type {Array<V1PosTransactionsGetRequestPosProductsInnerModifiersInner>}
     * @memberof V1PosTransactionsGetRequestPosProductsInner
     */
    'modifiers': Array<V1PosTransactionsGetRequestPosProductsInnerModifiersInner> | null;
}
/**
 * 
 * @export
 * @interface V1PosTransactionsGetRequestPosProductsInnerModifiersInner
 */
export interface V1PosTransactionsGetRequestPosProductsInnerModifiersInner {
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsGetRequestPosProductsInnerModifiersInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsGetRequestPosProductsInnerModifiersInner
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface V1PosTransactionsGetRequestPosServicesInner
 */
export interface V1PosTransactionsGetRequestPosServicesInner {
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsGetRequestPosServicesInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsGetRequestPosServicesInner
     */
    'quantity': number;
    /**
     * 
     * @type {Array<V1PosTransactionsGetRequestPosProductsInnerModifiersInner>}
     * @memberof V1PosTransactionsGetRequestPosServicesInner
     */
    'modifiers': Array<V1PosTransactionsGetRequestPosProductsInnerModifiersInner> | null;
}
/**
 * 
 * @export
 * @interface V1PosTransactionsIdDelete200Response
 */
export interface V1PosTransactionsIdDelete200Response {
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'origin': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'amount_due': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'amount_paid': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'amount_free': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'amount_discount': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'amount_products': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'amount_services': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'amount_utility': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'amount_cost': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'amount_total': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'delivery_amount': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'amount_delivery'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'amount_total_with_delivery': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'concept': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'transaction_origin': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'transaction_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'transaction_status': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'payment_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'payment_method': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'process_status': string;
    /**
     * 
     * @type {Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'products': Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>;
    /**
     * 
     * @type {Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'services': Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'transaction_category': V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'pos_metadata': V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata | null;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDelete200Response
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V1PosTransactionsIdDeleteRequest
 */
export interface V1PosTransactionsIdDeleteRequest {
    /**
     * 
     * @type {V1PosTransactionsIdDeleteRequestPos}
     * @memberof V1PosTransactionsIdDeleteRequest
     */
    'pos': V1PosTransactionsIdDeleteRequestPos;
}
/**
 * 
 * @export
 * @interface V1PosTransactionsIdDeleteRequestPos
 */
export interface V1PosTransactionsIdDeleteRequestPos {
    /**
     * 
     * @type {Array<V1PosTransactionsIdDeleteRequestPosProductsInner>}
     * @memberof V1PosTransactionsIdDeleteRequestPos
     */
    'products': Array<V1PosTransactionsIdDeleteRequestPosProductsInner>;
    /**
     * 
     * @type {Array<V1PosTransactionsIdDeleteRequestPosServicesInner>}
     * @memberof V1PosTransactionsIdDeleteRequestPos
     */
    'services': Array<V1PosTransactionsIdDeleteRequestPosServicesInner>;
    /**
     * 
     * @type {V1PosTransactionsIdDeleteRequestPosPosMetadata}
     * @memberof V1PosTransactionsIdDeleteRequestPos
     */
    'pos_metadata': V1PosTransactionsIdDeleteRequestPosPosMetadata | null;
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsIdDeleteRequestPos
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsIdDeleteRequestPos
     */
    'delivery_amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsIdDeleteRequestPos
     */
    'discount'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDeleteRequestPos
     */
    'concept'?: string;
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsIdDeleteRequestPos
     */
    'transaction_category_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDeleteRequestPos
     */
    'payment_method': V1PosTransactionsIdDeleteRequestPosPaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDeleteRequestPos
     */
    'created_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDeleteRequestPos
     */
    'transaction_origin': V1PosTransactionsIdDeleteRequestPosTransactionOriginEnum;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDeleteRequestPos
     */
    'transaction_status': V1PosTransactionsIdDeleteRequestPosTransactionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDeleteRequestPos
     */
    'process_status': V1PosTransactionsIdDeleteRequestPosProcessStatusEnum;
}

export const V1PosTransactionsIdDeleteRequestPosPaymentMethodEnum = {
    Cash: 'cash',
    Card: 'card',
    Other: 'other',
    Bank: 'bank',
    OnCredit: 'on-credit'
} as const;

export type V1PosTransactionsIdDeleteRequestPosPaymentMethodEnum = typeof V1PosTransactionsIdDeleteRequestPosPaymentMethodEnum[keyof typeof V1PosTransactionsIdDeleteRequestPosPaymentMethodEnum];
export const V1PosTransactionsIdDeleteRequestPosTransactionOriginEnum = {
    App: 'app',
    AppWeb: 'app-web'
} as const;

export type V1PosTransactionsIdDeleteRequestPosTransactionOriginEnum = typeof V1PosTransactionsIdDeleteRequestPosTransactionOriginEnum[keyof typeof V1PosTransactionsIdDeleteRequestPosTransactionOriginEnum];
export const V1PosTransactionsIdDeleteRequestPosTransactionStatusEnum = {
    Paid: 'paid',
    Unpaid: 'unpaid'
} as const;

export type V1PosTransactionsIdDeleteRequestPosTransactionStatusEnum = typeof V1PosTransactionsIdDeleteRequestPosTransactionStatusEnum[keyof typeof V1PosTransactionsIdDeleteRequestPosTransactionStatusEnum];
export const V1PosTransactionsIdDeleteRequestPosProcessStatusEnum = {
    InProgress: 'in-progress',
    Finished: 'finished'
} as const;

export type V1PosTransactionsIdDeleteRequestPosProcessStatusEnum = typeof V1PosTransactionsIdDeleteRequestPosProcessStatusEnum[keyof typeof V1PosTransactionsIdDeleteRequestPosProcessStatusEnum];

/**
 * 
 * @export
 * @interface V1PosTransactionsIdDeleteRequestPosPosMetadata
 */
export interface V1PosTransactionsIdDeleteRequestPosPosMetadata {
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDeleteRequestPosPosMetadata
     */
    'name': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDeleteRequestPosPosMetadata
     */
    'table_identifier': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDeleteRequestPosPosMetadata
     */
    'comments': string | null;
}
/**
 * 
 * @export
 * @interface V1PosTransactionsIdDeleteRequestPosProductsInner
 */
export interface V1PosTransactionsIdDeleteRequestPosProductsInner {
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsIdDeleteRequestPosProductsInner
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsIdDeleteRequestPosProductsInner
     */
    'product_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsIdDeleteRequestPosProductsInner
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsIdDeleteRequestPosProductsInner
     */
    'custom_price'?: string | null;
    /**
     * 
     * @type {Array<V1PosTransactionsIdDeleteRequestPosProductsInnerModifiersInner>}
     * @memberof V1PosTransactionsIdDeleteRequestPosProductsInner
     */
    'modifiers'?: Array<V1PosTransactionsIdDeleteRequestPosProductsInnerModifiersInner>;
}
/**
 * 
 * @export
 * @interface V1PosTransactionsIdDeleteRequestPosProductsInnerModifiersInner
 */
export interface V1PosTransactionsIdDeleteRequestPosProductsInnerModifiersInner {
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsIdDeleteRequestPosProductsInnerModifiersInner
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsIdDeleteRequestPosProductsInnerModifiersInner
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface V1PosTransactionsIdDeleteRequestPosServicesInner
 */
export interface V1PosTransactionsIdDeleteRequestPosServicesInner {
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsIdDeleteRequestPosServicesInner
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsIdDeleteRequestPosServicesInner
     */
    'quantity'?: number;
    /**
     * 
     * @type {Array<V1PosTransactionsIdDeleteRequestPosProductsInnerModifiersInner>}
     * @memberof V1PosTransactionsIdDeleteRequestPosServicesInner
     */
    'modifiers'?: Array<V1PosTransactionsIdDeleteRequestPosProductsInnerModifiersInner>;
}
/**
 * 
 * @export
 * @interface V1PosTransactionsStatsGet200Response
 */
export interface V1PosTransactionsStatsGet200Response {
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsStatsGet200Response
     */
    'brute_sales': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsStatsGet200Response
     */
    'discounts': string;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsStatsGet200Response
     */
    'net_sales': string;
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsStatsGet200Response
     */
    'total_orders': number;
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsStatsGet200Response
     */
    'finished_orders': number;
    /**
     * 
     * @type {string}
     * @memberof V1PosTransactionsStatsGet200Response
     */
    'avg_ticket': string;
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsStatsGet200Response
     */
    'sales_in_cash_cash': number;
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsStatsGet200Response
     */
    'sales_in_cash_cash_percentage': number;
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsStatsGet200Response
     */
    'sales_in_cash_card': number;
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsStatsGet200Response
     */
    'sales_in_cash_card_percentage': number;
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsStatsGet200Response
     */
    'sales_in_cash_bank': number;
    /**
     * 
     * @type {number}
     * @memberof V1PosTransactionsStatsGet200Response
     */
    'sales_in_cash_bank_percentage': number;
    /**
     * 
     * @type {Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>}
     * @memberof V1PosTransactionsStatsGet200Response
     */
    'top_products': Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>;
    /**
     * 
     * @type {Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>}
     * @memberof V1PosTransactionsStatsGet200Response
     */
    'top_services': Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>;
}
/**
 * 
 * @export
 * @interface V1ProductCategoriesGet200Response
 */
export interface V1ProductCategoriesGet200Response {
    /**
     * 
     * @type {Array<V1ProductCategoriesGet200ResponseProductCategoriesInner>}
     * @memberof V1ProductCategoriesGet200Response
     */
    'product_categories': Array<V1ProductCategoriesGet200ResponseProductCategoriesInner>;
    /**
     * 
     * @type {V1ContactsDebtsTransactionsGet200ResponsePagination}
     * @memberof V1ProductCategoriesGet200Response
     */
    'pagination': V1ContactsDebtsTransactionsGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1ProductCategoriesGet200ResponseProductCategoriesInner
 */
export interface V1ProductCategoriesGet200ResponseProductCategoriesInner {
    /**
     * 
     * @type {number}
     * @memberof V1ProductCategoriesGet200ResponseProductCategoriesInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ProductCategoriesGet200ResponseProductCategoriesInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1ProductCategoriesGet200ResponseProductCategoriesInner
     */
    'description': string | null;
    /**
     * 
     * @type {number}
     * @memberof V1ProductCategoriesGet200ResponseProductCategoriesInner
     */
    'products_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1ProductCategoriesGet200ResponseProductCategoriesInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1ProductCategoriesGet200ResponseProductCategoriesInner
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V1ProductCategoriesGet201Response
 */
export interface V1ProductCategoriesGet201Response {
    /**
     * 
     * @type {number}
     * @memberof V1ProductCategoriesGet201Response
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ProductCategoriesGet201Response
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1ProductCategoriesGet201Response
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1ProductCategoriesGet201Response
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1ProductCategoriesGet201Response
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V1ProductCategoriesGetRequest
 */
export interface V1ProductCategoriesGetRequest {
    /**
     * 
     * @type {V1DebtsTransactionsIdGet200ResponseProductsInnerCategory}
     * @memberof V1ProductCategoriesGetRequest
     */
    'product_category': V1DebtsTransactionsIdGet200ResponseProductsInnerCategory;
}
/**
 * @type V1ProductCategoriesIdBatchUpdatePatchRequest
 * @export
 */
export type V1ProductCategoriesIdBatchUpdatePatchRequest = V1ProductCategoriesIdBatchUpdatePatchRequestOneOf | V1ProductCategoriesIdBatchUpdatePatchRequestOneOf1;

/**
 * 
 * @export
 * @interface V1ProductCategoriesIdBatchUpdatePatchRequestOneOf
 */
export interface V1ProductCategoriesIdBatchUpdatePatchRequestOneOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof V1ProductCategoriesIdBatchUpdatePatchRequestOneOf
     */
    'product_ids': Array<string>;
}
/**
 * 
 * @export
 * @interface V1ProductCategoriesIdBatchUpdatePatchRequestOneOf1
 */
export interface V1ProductCategoriesIdBatchUpdatePatchRequestOneOf1 {
    /**
     * 
     * @type {boolean}
     * @memberof V1ProductCategoriesIdBatchUpdatePatchRequestOneOf1
     */
    'all_products': boolean;
}
/**
 * 
 * @export
 * @interface V1ProductCategoriesIdProductsGet200Response
 */
export interface V1ProductCategoriesIdProductsGet200Response {
    /**
     * 
     * @type {V1ProductCategoriesIdProductsGet200ResponseProductCategory}
     * @memberof V1ProductCategoriesIdProductsGet200Response
     */
    'product_category': V1ProductCategoriesIdProductsGet200ResponseProductCategory;
    /**
     * 
     * @type {V1ContactsDebtsTransactionsGet200ResponsePagination}
     * @memberof V1ProductCategoriesIdProductsGet200Response
     */
    'pagination': V1ContactsDebtsTransactionsGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1ProductCategoriesIdProductsGet200ResponseProductCategory
 */
export interface V1ProductCategoriesIdProductsGet200ResponseProductCategory {
    /**
     * 
     * @type {number}
     * @memberof V1ProductCategoriesIdProductsGet200ResponseProductCategory
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ProductCategoriesIdProductsGet200ResponseProductCategory
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1ProductCategoriesIdProductsGet200ResponseProductCategory
     */
    'description': string | null;
    /**
     * 
     * @type {number}
     * @memberof V1ProductCategoriesIdProductsGet200ResponseProductCategory
     */
    'products_count': number;
    /**
     * 
     * @type {Array<V1OffersGet200ResponseOffersInnerProductsInner>}
     * @memberof V1ProductCategoriesIdProductsGet200ResponseProductCategory
     */
    'products': Array<V1OffersGet200ResponseOffersInnerProductsInner>;
}
/**
 * 
 * @export
 * @interface V1ProductModifiersPost201Response
 */
export interface V1ProductModifiersPost201Response {
    /**
     * 
     * @type {number}
     * @memberof V1ProductModifiersPost201Response
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof V1ProductModifiersPost201Response
     */
    'product_id': number;
    /**
     * 
     * @type {number}
     * @memberof V1ProductModifiersPost201Response
     */
    'modifier_id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ProductModifiersPost201Response
     */
    'created_at': string;
}
/**
 * 
 * @export
 * @interface V1ProductModifiersPostRequest
 */
export interface V1ProductModifiersPostRequest {
    /**
     * 
     * @type {V1ProductModifiersPostRequestProductModifier}
     * @memberof V1ProductModifiersPostRequest
     */
    'product_modifier': V1ProductModifiersPostRequestProductModifier;
}
/**
 * 
 * @export
 * @interface V1ProductModifiersPostRequestProductModifier
 */
export interface V1ProductModifiersPostRequestProductModifier {
    /**
     * 
     * @type {number}
     * @memberof V1ProductModifiersPostRequestProductModifier
     */
    'product_id': number;
    /**
     * 
     * @type {number}
     * @memberof V1ProductModifiersPostRequestProductModifier
     */
    'modifier_id': number;
}
/**
 * 
 * @export
 * @interface V1ProductsGetRequest
 */
export interface V1ProductsGetRequest {
    /**
     * 
     * @type {V1ProductsGetRequestProduct}
     * @memberof V1ProductsGetRequest
     */
    'product': V1ProductsGetRequestProduct;
}
/**
 * 
 * @export
 * @interface V1ProductsGetRequestProduct
 */
export interface V1ProductsGetRequestProduct {
    /**
     * 
     * @type {string}
     * @memberof V1ProductsGetRequestProduct
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsGetRequestProduct
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsGetRequestProduct
     */
    'cost': string;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsGetRequestProduct
     */
    'price': string;
    /**
     * 
     * @type {number}
     * @memberof V1ProductsGetRequestProduct
     */
    'stock': number;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsGetRequestProduct
     */
    'barcode': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof V1ProductsGetRequestProduct
     */
    'show_in_store': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1ProductsGetRequestProduct
     */
    'show_in_pos': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof V1ProductsGetRequestProduct
     */
    'images': Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof V1ProductsGetRequestProduct
     */
    'product_category_id': number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof V1ProductsGetRequestProduct
     */
    'modifier_ids': Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof V1ProductsGetRequestProduct
     */
    'product_variants_count'?: number;
}
/**
 * 
 * @export
 * @interface V1ProductsIdDelete200Response
 */
export interface V1ProductsIdDelete200Response {
    /**
     * 
     * @type {number}
     * @memberof V1ProductsIdDelete200Response
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsIdDelete200Response
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsIdDelete200Response
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsIdDelete200Response
     */
    'cost': string;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsIdDelete200Response
     */
    'price': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1ProductsIdDelete200Response
     */
    'is_avg_cost'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsIdDelete200Response
     */
    'discount_price': string | null;
    /**
     * 
     * @type {number}
     * @memberof V1ProductsIdDelete200Response
     */
    'stock': number;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsIdDelete200Response
     */
    'barcode': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof V1ProductsIdDelete200Response
     */
    'show_in_store': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1ProductsIdDelete200Response
     */
    'show_in_pos': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1ProductsIdDelete200Response
     */
    'has_modifiers': boolean | null;
    /**
     * 
     * @type {Array<V1OffersGet200ResponseOffersInnerProductsInnerModifiersInner>}
     * @memberof V1ProductsIdDelete200Response
     */
    'modifiers'?: Array<V1OffersGet200ResponseOffersInnerProductsInnerModifiersInner> | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor}
     * @memberof V1ProductsIdDelete200Response
     */
    'color': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor}
     * @memberof V1ProductsIdDelete200Response
     */
    'size': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor}
     * @memberof V1ProductsIdDelete200Response
     */
    'style': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor | null;
    /**
     * 
     * @type {Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>}
     * @memberof V1ProductsIdDelete200Response
     */
    'images': Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory}
     * @memberof V1ProductsIdDelete200Response
     */
    'product_category'?: V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory}
     * @memberof V1ProductsIdDelete200Response
     */
    'collection': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory | null;
    /**
     * 
     * @type {Array<V1OffersGet200ResponseOffersInnerProductsInner>}
     * @memberof V1ProductsIdDelete200Response
     */
    'related_products'?: Array<V1OffersGet200ResponseOffersInnerProductsInner> | null;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsIdDelete200Response
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsIdDelete200Response
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V1ProductsIdDeleteRequest
 */
export interface V1ProductsIdDeleteRequest {
    /**
     * 
     * @type {V1ProductsIdDeleteRequestProduct}
     * @memberof V1ProductsIdDeleteRequest
     */
    'product': V1ProductsIdDeleteRequestProduct;
}
/**
 * 
 * @export
 * @interface V1ProductsIdDeleteRequestProduct
 */
export interface V1ProductsIdDeleteRequestProduct {
    /**
     * 
     * @type {string}
     * @memberof V1ProductsIdDeleteRequestProduct
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsIdDeleteRequestProduct
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsIdDeleteRequestProduct
     */
    'cost': string;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsIdDeleteRequestProduct
     */
    'price': string;
    /**
     * 
     * @type {number}
     * @memberof V1ProductsIdDeleteRequestProduct
     */
    'stock': number;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsIdDeleteRequestProduct
     */
    'barcode': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof V1ProductsIdDeleteRequestProduct
     */
    'show_in_store': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1ProductsIdDeleteRequestProduct
     */
    'show_in_pos': boolean;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsIdDeleteRequestProduct
     */
    'size': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsIdDeleteRequestProduct
     */
    'color': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsIdDeleteRequestProduct
     */
    'style': string | null;
    /**
     * 
     * @type {number}
     * @memberof V1ProductsIdDeleteRequestProduct
     */
    'stock_variant'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsIdDeleteRequestProduct
     */
    'stock_variant_action'?: V1ProductsIdDeleteRequestProductStockVariantActionEnum;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsIdDeleteRequestProduct
     */
    'stock_variant_reason'?: V1ProductsIdDeleteRequestProductStockVariantReasonEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof V1ProductsIdDeleteRequestProduct
     */
    'images': Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof V1ProductsIdDeleteRequestProduct
     */
    'product_category_id': number | null;
    /**
     * 
     * @type {Array<V1ProductsMultiplePostRequestVariationsInner>}
     * @memberof V1ProductsIdDeleteRequestProduct
     */
    'variations': Array<V1ProductsMultiplePostRequestVariationsInner>;
    /**
     * 
     * @type {Array<number>}
     * @memberof V1ProductsIdDeleteRequestProduct
     */
    'modifier_ids': Array<number> | null;
}

export const V1ProductsIdDeleteRequestProductStockVariantActionEnum = {
    Increase: 'increase',
    Decrease: 'decrease'
} as const;

export type V1ProductsIdDeleteRequestProductStockVariantActionEnum = typeof V1ProductsIdDeleteRequestProductStockVariantActionEnum[keyof typeof V1ProductsIdDeleteRequestProductStockVariantActionEnum];
export const V1ProductsIdDeleteRequestProductStockVariantReasonEnum = {
    Sale: 'sale',
    Damage: 'damage',
    Error: 'error'
} as const;

export type V1ProductsIdDeleteRequestProductStockVariantReasonEnum = typeof V1ProductsIdDeleteRequestProductStockVariantReasonEnum[keyof typeof V1ProductsIdDeleteRequestProductStockVariantReasonEnum];

/**
 * 
 * @export
 * @interface V1ProductsIdImagesOrderPostRequest
 */
export interface V1ProductsIdImagesOrderPostRequest {
    /**
     * 
     * @type {V1ProductsIdImagesOrderPostRequestProduct}
     * @memberof V1ProductsIdImagesOrderPostRequest
     */
    'product': V1ProductsIdImagesOrderPostRequestProduct;
}
/**
 * 
 * @export
 * @interface V1ProductsIdImagesOrderPostRequestProduct
 */
export interface V1ProductsIdImagesOrderPostRequestProduct {
    /**
     * 
     * @type {Array<number>}
     * @memberof V1ProductsIdImagesOrderPostRequestProduct
     */
    'ordered_images_ids': Array<number>;
}
/**
 * 
 * @export
 * @interface V1ProductsIdModifiersGet200Response
 */
export interface V1ProductsIdModifiersGet200Response {
    /**
     * 
     * @type {Array<V1ProductsIdModifiersGet200ResponseModifiersInner>}
     * @memberof V1ProductsIdModifiersGet200Response
     */
    'modifiers': Array<V1ProductsIdModifiersGet200ResponseModifiersInner>;
}
/**
 * 
 * @export
 * @interface V1ProductsIdModifiersGet200ResponseModifiersInner
 */
export interface V1ProductsIdModifiersGet200ResponseModifiersInner {
    /**
     * 
     * @type {number}
     * @memberof V1ProductsIdModifiersGet200ResponseModifiersInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsIdModifiersGet200ResponseModifiersInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsIdModifiersGet200ResponseModifiersInner
     */
    'color': string;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsIdModifiersGet200ResponseModifiersInner
     */
    'price': string;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsIdModifiersGet200ResponseModifiersInner
     */
    'type': V1ProductsIdModifiersGet200ResponseModifiersInnerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsIdModifiersGet200ResponseModifiersInner
     */
    'created_at': string;
}

export const V1ProductsIdModifiersGet200ResponseModifiersInnerTypeEnum = {
    ModifierProduct: 'ModifierProduct'
} as const;

export type V1ProductsIdModifiersGet200ResponseModifiersInnerTypeEnum = typeof V1ProductsIdModifiersGet200ResponseModifiersInnerTypeEnum[keyof typeof V1ProductsIdModifiersGet200ResponseModifiersInnerTypeEnum];

/**
 * 
 * @export
 * @interface V1ProductsMultiplePostRequest
 */
export interface V1ProductsMultiplePostRequest {
    /**
     * 
     * @type {V1ProductsMultiplePostRequestBaseProduct}
     * @memberof V1ProductsMultiplePostRequest
     */
    'base_product': V1ProductsMultiplePostRequestBaseProduct;
    /**
     * 
     * @type {Array<V1ProductsMultiplePostRequestVariationsInner>}
     * @memberof V1ProductsMultiplePostRequest
     */
    'variations': Array<V1ProductsMultiplePostRequestVariationsInner>;
    /**
     * 
     * @type {Array<number>}
     * @memberof V1ProductsMultiplePostRequest
     */
    'modifier_ids': Array<number> | null;
}
/**
 * 
 * @export
 * @interface V1ProductsMultiplePostRequestBaseProduct
 */
export interface V1ProductsMultiplePostRequestBaseProduct {
    /**
     * 
     * @type {string}
     * @memberof V1ProductsMultiplePostRequestBaseProduct
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsMultiplePostRequestBaseProduct
     */
    'description': string | null;
    /**
     * 
     * @type {number}
     * @memberof V1ProductsMultiplePostRequestBaseProduct
     */
    'product_category_id': number | null;
}
/**
 * 
 * @export
 * @interface V1ProductsMultiplePostRequestVariationsInner
 */
export interface V1ProductsMultiplePostRequestVariationsInner {
    /**
     * 
     * @type {string}
     * @memberof V1ProductsMultiplePostRequestVariationsInner
     */
    'cost': string;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsMultiplePostRequestVariationsInner
     */
    'price': string;
    /**
     * 
     * @type {number}
     * @memberof V1ProductsMultiplePostRequestVariationsInner
     */
    'stock': number;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsMultiplePostRequestVariationsInner
     */
    'barcode': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsMultiplePostRequestVariationsInner
     */
    'size': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsMultiplePostRequestVariationsInner
     */
    'color': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1ProductsMultiplePostRequestVariationsInner
     */
    'style': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof V1ProductsMultiplePostRequestVariationsInner
     */
    'images': Array<string> | null;
}
/**
 * 
 * @export
 * @interface V1ProductsPosGet200Response
 */
export interface V1ProductsPosGet200Response {
    /**
     * 
     * @type {string}
     * @memberof V1ProductsPosGet200Response
     */
    'inventory_total'?: string;
    /**
     * 
     * @type {Array<V1OffersGet200ResponseOffersInnerProductsInner>}
     * @memberof V1ProductsPosGet200Response
     */
    'products': Array<V1OffersGet200ResponseOffersInnerProductsInner>;
    /**
     * 
     * @type {V1ContactsDebtsTransactionsGet200ResponsePagination}
     * @memberof V1ProductsPosGet200Response
     */
    'pagination': V1ContactsDebtsTransactionsGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1ReportsTransactionsCashCutDetailsGet200Response
 */
export interface V1ReportsTransactionsCashCutDetailsGet200Response {
    /**
     * 
     * @type {string}
     * @memberof V1ReportsTransactionsCashCutDetailsGet200Response
     */
    'sales_in_cash': string;
    /**
     * 
     * @type {string}
     * @memberof V1ReportsTransactionsCashCutDetailsGet200Response
     */
    'sales_bank': string;
    /**
     * 
     * @type {string}
     * @memberof V1ReportsTransactionsCashCutDetailsGet200Response
     */
    'sales_card': string;
    /**
     * 
     * @type {string}
     * @memberof V1ReportsTransactionsCashCutDetailsGet200Response
     */
    'sales_web': string;
    /**
     * 
     * @type {string}
     * @memberof V1ReportsTransactionsCashCutDetailsGet200Response
     */
    'total_sales_cash': string;
    /**
     * 
     * @type {string}
     * @memberof V1ReportsTransactionsCashCutDetailsGet200Response
     */
    'sales_credit': string;
    /**
     * 
     * @type {string}
     * @memberof V1ReportsTransactionsCashCutDetailsGet200Response
     */
    'all_sales': string;
    /**
     * 
     * @type {string}
     * @memberof V1ReportsTransactionsCashCutDetailsGet200Response
     */
    'debts_to_charge_paid_cash': string;
    /**
     * 
     * @type {string}
     * @memberof V1ReportsTransactionsCashCutDetailsGet200Response
     */
    'debts_to_charge_paid_bank': string;
    /**
     * 
     * @type {string}
     * @memberof V1ReportsTransactionsCashCutDetailsGet200Response
     */
    'debts_to_charge_paid_card': string;
    /**
     * 
     * @type {string}
     * @memberof V1ReportsTransactionsCashCutDetailsGet200Response
     */
    'total_cash_generated': string;
    /**
     * 
     * @type {string}
     * @memberof V1ReportsTransactionsCashCutDetailsGet200Response
     */
    'total_pending_charge': string;
    /**
     * 
     * @type {string}
     * @memberof V1ReportsTransactionsCashCutDetailsGet200Response
     */
    'expenses_cash_in_cash': string;
    /**
     * 
     * @type {string}
     * @memberof V1ReportsTransactionsCashCutDetailsGet200Response
     */
    'expenses_cash_bank': string;
    /**
     * 
     * @type {string}
     * @memberof V1ReportsTransactionsCashCutDetailsGet200Response
     */
    'expenses_cash_card': string;
    /**
     * 
     * @type {string}
     * @memberof V1ReportsTransactionsCashCutDetailsGet200Response
     */
    'total_expenses_cash': string;
    /**
     * 
     * @type {string}
     * @memberof V1ReportsTransactionsCashCutDetailsGet200Response
     */
    'total_expenses_credit': string;
    /**
     * 
     * @type {string}
     * @memberof V1ReportsTransactionsCashCutDetailsGet200Response
     */
    'all_expenses': string;
    /**
     * 
     * @type {string}
     * @memberof V1ReportsTransactionsCashCutDetailsGet200Response
     */
    'debts_to_pay_paid_cash': string;
    /**
     * 
     * @type {string}
     * @memberof V1ReportsTransactionsCashCutDetailsGet200Response
     */
    'debts_to_pay_paid_bank': string;
    /**
     * 
     * @type {string}
     * @memberof V1ReportsTransactionsCashCutDetailsGet200Response
     */
    'debts_to_pay_paid_card': string;
    /**
     * 
     * @type {string}
     * @memberof V1ReportsTransactionsCashCutDetailsGet200Response
     */
    'total_cash_outcome': string;
    /**
     * 
     * @type {string}
     * @memberof V1ReportsTransactionsCashCutDetailsGet200Response
     */
    'total_pending_pay': string;
    /**
     * 
     * @type {string}
     * @memberof V1ReportsTransactionsCashCutDetailsGet200Response
     */
    'total_balance_cash': string;
}
/**
 * 
 * @export
 * @interface V1RolesGet200Response
 */
export interface V1RolesGet200Response {
    /**
     * 
     * @type {Array<V1RolesGet200ResponseRolesInner>}
     * @memberof V1RolesGet200Response
     */
    'roles': Array<V1RolesGet200ResponseRolesInner>;
}
/**
 * 
 * @export
 * @interface V1RolesGet200ResponseRolesInner
 */
export interface V1RolesGet200ResponseRolesInner {
    /**
     * 
     * @type {V1RolesGet200ResponseRolesInnerRole}
     * @memberof V1RolesGet200ResponseRolesInner
     */
    'role': V1RolesGet200ResponseRolesInnerRole;
    /**
     * 
     * @type {Array<string>}
     * @memberof V1RolesGet200ResponseRolesInner
     */
    'disable': Array<string>;
}
/**
 * 
 * @export
 * @interface V1RolesGet200ResponseRolesInnerRole
 */
export interface V1RolesGet200ResponseRolesInnerRole {
    /**
     * 
     * @type {number}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'pdv_delete_orders': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'pdv_edit_orders': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'pdv_see_analytics': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'acc_discounts': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'acc_credit_sales': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'pdv_push_notifications': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'massive_import': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'acc_credit_expenses': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'acc_create_expenses': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'acc_financial_statement': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'acc_list_categories': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'acc_create_categories': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'acc_update_categories': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'acc_delete_categories': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'acc_budget_assistant': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'acc_delete_transactions': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'acc_see_analytics': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'inventory_see_catalog': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'inventory_see_costs': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'inventory_create_products': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'inventory_delete_products': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'inventory_edit_products': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'inventory_stock_analytics': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'web_delete_transactions': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'web_configure_store': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'web_see_analytics': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'web_manage_offers': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'service_see_catalog': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'service_create': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'service_delete': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'service_edit': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'acc_list_service_categories': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'acc_create_service_categories': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'acc_update_service_categories': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'acc_delete_service_categories': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'inventory_bulk_update': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'inventory_bulk_delete': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesGet200ResponseRolesInnerRole
     */
    'inventory_modifiers': boolean;
}
/**
 * 
 * @export
 * @interface V1RolesIdGetRequest
 */
export interface V1RolesIdGetRequest {
    /**
     * 
     * @type {V1RolesIdGetRequestRole}
     * @memberof V1RolesIdGetRequest
     */
    'role': V1RolesIdGetRequestRole;
}
/**
 * 
 * @export
 * @interface V1RolesIdGetRequestRole
 */
export interface V1RolesIdGetRequestRole {
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'pdv_delete_orders': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'pdv_edit_orders': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'pdv_see_analytics': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'acc_discounts': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'acc_credit_sales': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'pdv_push_notifications': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'massive_import': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'acc_credit_expenses': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'acc_list_categories': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'acc_create_categories': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'acc_update_categories': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'acc_delete_categories': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'acc_create_expenses': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'acc_see_analytics': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'acc_budget_assistant': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'inventory_see_catalog': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'inventory_see_costs': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'inventory_create_products': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'inventory_delete_products': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'inventory_edit_products': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'web_delete_transactions': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'web_configure_store': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'web_see_analytics': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'web_manage_offers': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'inventory_stock_analytics': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'service_see_catalog': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'service_create': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'service_delete': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'service_edit': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'acc_list_service_categories': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'acc_create_service_categories': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'acc_update_service_categories': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'acc_delete_service_categories': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'inventory_bulk_update': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'inventory_bulk_delete': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1RolesIdGetRequestRole
     */
    'inventory_modifiers': boolean;
}
/**
 * 
 * @export
 * @interface V1RolesRoleIdInvitesGet200Response
 */
export interface V1RolesRoleIdInvitesGet200Response {
    /**
     * 
     * @type {Array<V1RolesRoleIdInvitesGet200ResponseInvitesInner>}
     * @memberof V1RolesRoleIdInvitesGet200Response
     */
    'invites': Array<V1RolesRoleIdInvitesGet200ResponseInvitesInner>;
}
/**
 * 
 * @export
 * @interface V1RolesRoleIdInvitesGet200ResponseInvitesInner
 */
export interface V1RolesRoleIdInvitesGet200ResponseInvitesInner {
    /**
     * 
     * @type {number}
     * @memberof V1RolesRoleIdInvitesGet200ResponseInvitesInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1RolesRoleIdInvitesGet200ResponseInvitesInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1RolesRoleIdInvitesGet200ResponseInvitesInner
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof V1RolesRoleIdInvitesGet200ResponseInvitesInner
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof V1RolesRoleIdInvitesGet200ResponseInvitesInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1RolesRoleIdInvitesGet200ResponseInvitesInner
     */
    'accepted_at': string | null;
}
/**
 * 
 * @export
 * @interface V1RolesRoleIdInvitesGetRequest
 */
export interface V1RolesRoleIdInvitesGetRequest {
    /**
     * 
     * @type {V1RolesRoleIdInvitesGetRequestInvitation}
     * @memberof V1RolesRoleIdInvitesGetRequest
     */
    'invitation': V1RolesRoleIdInvitesGetRequestInvitation;
}
/**
 * 
 * @export
 * @interface V1RolesRoleIdInvitesGetRequestInvitation
 */
export interface V1RolesRoleIdInvitesGetRequestInvitation {
    /**
     * 
     * @type {string}
     * @memberof V1RolesRoleIdInvitesGetRequestInvitation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1RolesRoleIdInvitesGetRequestInvitation
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface V1RolesRoleIdInvitesIdDelete200Response
 */
export interface V1RolesRoleIdInvitesIdDelete200Response {
    /**
     * 
     * @type {V1RolesRoleIdInvitesGet200ResponseInvitesInner}
     * @memberof V1RolesRoleIdInvitesIdDelete200Response
     */
    'invite': V1RolesRoleIdInvitesGet200ResponseInvitesInner;
}
/**
 * 
 * @export
 * @interface V1RolesRoleIdInvitesMultiplePostRequest
 */
export interface V1RolesRoleIdInvitesMultiplePostRequest {
    /**
     * 
     * @type {Array<V1RolesRoleIdInvitesGetRequestInvitation>}
     * @memberof V1RolesRoleIdInvitesMultiplePostRequest
     */
    'invitations': Array<V1RolesRoleIdInvitesGetRequestInvitation>;
}
/**
 * 
 * @export
 * @interface V1SalesTransactionsGet200Response
 */
export interface V1SalesTransactionsGet200Response {
    /**
     * 
     * @type {Array<V1SalesTransactionsGet200ResponseTransactionsInner>}
     * @memberof V1SalesTransactionsGet200Response
     */
    'transactions': Array<V1SalesTransactionsGet200ResponseTransactionsInner>;
    /**
     * 
     * @type {V1CashFlowGet200ResponsePagination}
     * @memberof V1SalesTransactionsGet200Response
     */
    'pagination': V1CashFlowGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1SalesTransactionsGet200ResponseTransactionsInner
 */
export interface V1SalesTransactionsGet200ResponseTransactionsInner {
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'origin': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'amount_due': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'amount_paid': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'amount_free': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'amount_discount': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'amount_products': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'amount_services': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'amount_utility': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'amount_cost'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'amount_total': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'amount_delivery': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'amount_total_with_delivery': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'is_recurrent': boolean;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'recurrency_times': number | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'recurrency_days_span': V1SalesTransactionsGet200ResponseTransactionsInnerRecurrencyDaysSpanEnum;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'last_recurrency_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'code': string;
    /**
     * 
     * @type {V1DebtsTransactionsGet200ResponseTransactionsInnerContact}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'contact': V1DebtsTransactionsGet200ResponseTransactionsInnerContact | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'transaction_category': V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'pos_metadata': V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'concept': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'comment': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'transaction_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'transaction_status': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'transaction_origin': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'payment_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'payment_method': string;
    /**
     * 
     * @type {Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'products': Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>;
    /**
     * 
     * @type {Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'services': Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet200ResponseTransactionsInner
     */
    'pay_at': string | null;
}

export const V1SalesTransactionsGet200ResponseTransactionsInnerRecurrencyDaysSpanEnum = {
    FirstOfMonth: 'first_of_month',
    LastOfMonth: 'last_of_month',
    MiddleOfMonth: 'middle_of_month',
    _1: '1',
    _2: '2',
    _3: '3',
    _4: '4',
    _5: '5',
    _6: '6',
    _7: '7',
    _8: '8',
    _9: '9',
    _10: '10',
    _11: '11',
    _12: '12',
    _13: '13',
    _14: '14',
    _15: '15',
    _16: '16',
    _17: '17',
    _18: '18',
    _19: '19',
    _20: '20',
    _21: '21',
    _22: '22',
    _23: '23',
    _24: '24',
    _25: '25',
    _26: '26',
    _27: '27',
    _28: '28',
    _29: '29',
    _30: '30',
    _31: '31'
} as const;

export type V1SalesTransactionsGet200ResponseTransactionsInnerRecurrencyDaysSpanEnum = typeof V1SalesTransactionsGet200ResponseTransactionsInnerRecurrencyDaysSpanEnum[keyof typeof V1SalesTransactionsGet200ResponseTransactionsInnerRecurrencyDaysSpanEnum];

/**
 * 
 * @export
 * @interface V1SalesTransactionsGet201Response
 */
export interface V1SalesTransactionsGet201Response {
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsGet201Response
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201Response
     */
    'origin': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201Response
     */
    'amount_due': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201Response
     */
    'amount_paid': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201Response
     */
    'amount_free': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201Response
     */
    'amount_discount': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201Response
     */
    'amount_products': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201Response
     */
    'amount_services': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201Response
     */
    'amount_utility': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201Response
     */
    'amount_cost': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201Response
     */
    'amount_total': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201Response
     */
    'amount_delivery'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201Response
     */
    'amount_total_with_delivery': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1SalesTransactionsGet201Response
     */
    'is_recurrent': boolean;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsGet201Response
     */
    'recurrency_times': number | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201Response
     */
    'recurrency_days_span': V1SalesTransactionsGet201ResponseRecurrencyDaysSpanEnum;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201Response
     */
    'last_recurrency_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201Response
     */
    'code': string;
    /**
     * 
     * @type {V1SalesTransactionsGet201ResponseContact}
     * @memberof V1SalesTransactionsGet201Response
     */
    'contact': V1SalesTransactionsGet201ResponseContact | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory}
     * @memberof V1SalesTransactionsGet201Response
     */
    'transaction_category': V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201Response
     */
    'concept': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201Response
     */
    'comment': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201Response
     */
    'transaction_origin': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201Response
     */
    'transaction_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201Response
     */
    'transaction_status': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201Response
     */
    'payment_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201Response
     */
    'payment_method': string;
    /**
     * 
     * @type {Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>}
     * @memberof V1SalesTransactionsGet201Response
     */
    'products': Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>;
    /**
     * 
     * @type {Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>}
     * @memberof V1SalesTransactionsGet201Response
     */
    'services': Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201Response
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201Response
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201Response
     */
    'pay_at': string | null;
}

export const V1SalesTransactionsGet201ResponseRecurrencyDaysSpanEnum = {
    FirstOfMonth: 'first_of_month',
    LastOfMonth: 'last_of_month',
    MiddleOfMonth: 'middle_of_month',
    _1: '1',
    _2: '2',
    _3: '3',
    _4: '4',
    _5: '5',
    _6: '6',
    _7: '7',
    _8: '8',
    _9: '9',
    _10: '10',
    _11: '11',
    _12: '12',
    _13: '13',
    _14: '14',
    _15: '15',
    _16: '16',
    _17: '17',
    _18: '18',
    _19: '19',
    _20: '20',
    _21: '21',
    _22: '22',
    _23: '23',
    _24: '24',
    _25: '25',
    _26: '26',
    _27: '27',
    _28: '28',
    _29: '29',
    _30: '30',
    _31: '31'
} as const;

export type V1SalesTransactionsGet201ResponseRecurrencyDaysSpanEnum = typeof V1SalesTransactionsGet201ResponseRecurrencyDaysSpanEnum[keyof typeof V1SalesTransactionsGet201ResponseRecurrencyDaysSpanEnum];

/**
 * 
 * @export
 * @interface V1SalesTransactionsGet201ResponseContact
 */
export interface V1SalesTransactionsGet201ResponseContact {
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsGet201ResponseContact
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201ResponseContact
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201ResponseContact
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGet201ResponseContact
     */
    'address'?: string | null;
}
/**
 * 
 * @export
 * @interface V1SalesTransactionsGetRequest
 */
export interface V1SalesTransactionsGetRequest {
    /**
     * 
     * @type {V1SalesTransactionsGetRequestSale}
     * @memberof V1SalesTransactionsGetRequest
     */
    'sale': V1SalesTransactionsGetRequestSale;
}
/**
 * 
 * @export
 * @interface V1SalesTransactionsGetRequestSale
 */
export interface V1SalesTransactionsGetRequestSale {
    /**
     * 
     * @type {Array<V1SalesTransactionsGetRequestSaleProductsInner>}
     * @memberof V1SalesTransactionsGetRequestSale
     */
    'products': Array<V1SalesTransactionsGetRequestSaleProductsInner>;
    /**
     * 
     * @type {Array<V1PosTransactionsIdDeleteRequestPosProductsInnerModifiersInner>}
     * @memberof V1SalesTransactionsGetRequestSale
     */
    'services': Array<V1PosTransactionsIdDeleteRequestPosProductsInnerModifiersInner>;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsGetRequestSale
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsGetRequestSale
     */
    'discount'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGetRequestSale
     */
    'concept'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGetRequestSale
     */
    'comment'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsGetRequestSale
     */
    'contact_id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsGetRequestSale
     */
    'transaction_category_id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsGetRequestSale
     */
    'recurrency_times'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGetRequestSale
     */
    'recurrency_days_span'?: V1SalesTransactionsGetRequestSaleRecurrencyDaysSpanEnum;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata}
     * @memberof V1SalesTransactionsGetRequestSale
     */
    'pos_metadata': V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGetRequestSale
     */
    'payment_type': V1SalesTransactionsGetRequestSalePaymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGetRequestSale
     */
    'payment_method': V1SalesTransactionsGetRequestSalePaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGetRequestSale
     */
    'created_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGetRequestSale
     */
    'pay_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGetRequestSale
     */
    'transaction_origin'?: V1SalesTransactionsGetRequestSaleTransactionOriginEnum;
}

export const V1SalesTransactionsGetRequestSaleRecurrencyDaysSpanEnum = {
    FirstOfMonth: 'first_of_month',
    LastOfMonth: 'last_of_month',
    MiddleOfMonth: 'middle_of_month',
    _1: '1',
    _2: '2',
    _3: '3',
    _4: '4',
    _5: '5',
    _6: '6',
    _7: '7',
    _8: '8',
    _9: '9',
    _10: '10',
    _11: '11',
    _12: '12',
    _13: '13',
    _14: '14',
    _15: '15',
    _16: '16',
    _17: '17',
    _18: '18',
    _19: '19',
    _20: '20',
    _21: '21',
    _22: '22',
    _23: '23',
    _24: '24',
    _25: '25',
    _26: '26',
    _27: '27',
    _28: '28',
    _29: '29',
    _30: '30',
    _31: '31'
} as const;

export type V1SalesTransactionsGetRequestSaleRecurrencyDaysSpanEnum = typeof V1SalesTransactionsGetRequestSaleRecurrencyDaysSpanEnum[keyof typeof V1SalesTransactionsGetRequestSaleRecurrencyDaysSpanEnum];
export const V1SalesTransactionsGetRequestSalePaymentTypeEnum = {
    InCash: 'in-cash',
    Credit: 'credit'
} as const;

export type V1SalesTransactionsGetRequestSalePaymentTypeEnum = typeof V1SalesTransactionsGetRequestSalePaymentTypeEnum[keyof typeof V1SalesTransactionsGetRequestSalePaymentTypeEnum];
export const V1SalesTransactionsGetRequestSalePaymentMethodEnum = {
    Cash: 'cash',
    Card: 'card',
    Other: 'other',
    Bank: 'bank',
    OnCredit: 'on-credit'
} as const;

export type V1SalesTransactionsGetRequestSalePaymentMethodEnum = typeof V1SalesTransactionsGetRequestSalePaymentMethodEnum[keyof typeof V1SalesTransactionsGetRequestSalePaymentMethodEnum];
export const V1SalesTransactionsGetRequestSaleTransactionOriginEnum = {
    App: 'app',
    AppWeb: 'app-web'
} as const;

export type V1SalesTransactionsGetRequestSaleTransactionOriginEnum = typeof V1SalesTransactionsGetRequestSaleTransactionOriginEnum[keyof typeof V1SalesTransactionsGetRequestSaleTransactionOriginEnum];

/**
 * 
 * @export
 * @interface V1SalesTransactionsGetRequestSaleProductsInner
 */
export interface V1SalesTransactionsGetRequestSaleProductsInner {
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsGetRequestSaleProductsInner
     */
    'product_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsGetRequestSaleProductsInner
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsGetRequestSaleProductsInner
     */
    'custom_price'?: string | null;
}
/**
 * 
 * @export
 * @interface V1SalesTransactionsIdDelete200Response
 */
export interface V1SalesTransactionsIdDelete200Response {
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'amount_due': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'amount_paid': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'amount_free': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'amount_discount': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'amount_products': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'amount_services': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'amount_utility': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'amount_cost': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'amount_total': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'amount_delivery': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'amount_total_with_delivery': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'is_recurrent': boolean;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'recurrency_times': number | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'recurrency_days_span': V1SalesTransactionsIdDelete200ResponseRecurrencyDaysSpanEnum;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'last_recurrency_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'code': string;
    /**
     * 
     * @type {V1SalesTransactionsGet201ResponseContact}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'contact': V1SalesTransactionsGet201ResponseContact | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'transaction_category': V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'concept': string;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'pos_metadata': V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'comment': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'transaction_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'transaction_status': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'transaction_origin': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'payment_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'payment_method': string;
    /**
     * 
     * @type {Array<V1ExpensesTransactionsIdDelete200ResponseDepositsInner>}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'deposits': Array<V1ExpensesTransactionsIdDelete200ResponseDepositsInner>;
    /**
     * 
     * @type {Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'products': Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>;
    /**
     * 
     * @type {Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'services': Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response
     */
    'pay_at': string | null;
}

export const V1SalesTransactionsIdDelete200ResponseRecurrencyDaysSpanEnum = {
    FirstOfMonth: 'first_of_month',
    LastOfMonth: 'last_of_month',
    MiddleOfMonth: 'middle_of_month',
    _1: '1',
    _2: '2',
    _3: '3',
    _4: '4',
    _5: '5',
    _6: '6',
    _7: '7',
    _8: '8',
    _9: '9',
    _10: '10',
    _11: '11',
    _12: '12',
    _13: '13',
    _14: '14',
    _15: '15',
    _16: '16',
    _17: '17',
    _18: '18',
    _19: '19',
    _20: '20',
    _21: '21',
    _22: '22',
    _23: '23',
    _24: '24',
    _25: '25',
    _26: '26',
    _27: '27',
    _28: '28',
    _29: '29',
    _30: '30',
    _31: '31'
} as const;

export type V1SalesTransactionsIdDelete200ResponseRecurrencyDaysSpanEnum = typeof V1SalesTransactionsIdDelete200ResponseRecurrencyDaysSpanEnum[keyof typeof V1SalesTransactionsIdDelete200ResponseRecurrencyDaysSpanEnum];

/**
 * 
 * @export
 * @interface V1SalesTransactionsIdDelete200Response1
 */
export interface V1SalesTransactionsIdDelete200Response1 {
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'origin': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'amount_total': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'amount_due': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'amount_paid': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'amount_free': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'amount_discount': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'amount_products': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'amount_services': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'amount_utility': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'amount_cost'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'amount_delivery': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'amount_total_with_delivery': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'is_recurrent': boolean;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'pos_metadata': V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata | null;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'recurrency_times': number | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'recurrency_days_span': V1SalesTransactionsIdDelete200Response1RecurrencyDaysSpanEnum;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'last_recurrency_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'code': string;
    /**
     * 
     * @type {V1DebtsTransactionsGet200ResponseTransactionsInnerContact}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'contact': V1DebtsTransactionsGet200ResponseTransactionsInnerContact | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'transaction_category': V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'concept': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'comment': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'transaction_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'transaction_status': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'transaction_origin': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'payment_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'payment_method': string;
    /**
     * 
     * @type {Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'products': Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>;
    /**
     * 
     * @type {Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'services': Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDelete200Response1
     */
    'pay_at': string | null;
}

export const V1SalesTransactionsIdDelete200Response1RecurrencyDaysSpanEnum = {
    FirstOfMonth: 'first_of_month',
    LastOfMonth: 'last_of_month',
    MiddleOfMonth: 'middle_of_month',
    _1: '1',
    _2: '2',
    _3: '3',
    _4: '4',
    _5: '5',
    _6: '6',
    _7: '7',
    _8: '8',
    _9: '9',
    _10: '10',
    _11: '11',
    _12: '12',
    _13: '13',
    _14: '14',
    _15: '15',
    _16: '16',
    _17: '17',
    _18: '18',
    _19: '19',
    _20: '20',
    _21: '21',
    _22: '22',
    _23: '23',
    _24: '24',
    _25: '25',
    _26: '26',
    _27: '27',
    _28: '28',
    _29: '29',
    _30: '30',
    _31: '31'
} as const;

export type V1SalesTransactionsIdDelete200Response1RecurrencyDaysSpanEnum = typeof V1SalesTransactionsIdDelete200Response1RecurrencyDaysSpanEnum[keyof typeof V1SalesTransactionsIdDelete200Response1RecurrencyDaysSpanEnum];

/**
 * 
 * @export
 * @interface V1SalesTransactionsIdDeleteRequest
 */
export interface V1SalesTransactionsIdDeleteRequest {
    /**
     * 
     * @type {V1SalesTransactionsIdDeleteRequestSale}
     * @memberof V1SalesTransactionsIdDeleteRequest
     */
    'sale': V1SalesTransactionsIdDeleteRequestSale;
}
/**
 * 
 * @export
 * @interface V1SalesTransactionsIdDeleteRequestSale
 */
export interface V1SalesTransactionsIdDeleteRequestSale {
    /**
     * 
     * @type {Array<V1SalesTransactionsQuoteIdDeleteRequestQuoteProductsInner>}
     * @memberof V1SalesTransactionsIdDeleteRequestSale
     */
    'products': Array<V1SalesTransactionsQuoteIdDeleteRequestQuoteProductsInner>;
    /**
     * 
     * @type {Array<V1SalesTransactionsQuoteIdDeleteRequestQuoteServicesInner>}
     * @memberof V1SalesTransactionsIdDeleteRequestSale
     */
    'services': Array<V1SalesTransactionsQuoteIdDeleteRequestQuoteServicesInner>;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsIdDeleteRequestSale
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsIdDeleteRequestSale
     */
    'discount'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDeleteRequestSale
     */
    'concept'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDeleteRequestSale
     */
    'comment'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsIdDeleteRequestSale
     */
    'contact_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsIdDeleteRequestSale
     */
    'recurrency_times'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDeleteRequestSale
     */
    'recurrency_days_span'?: V1SalesTransactionsIdDeleteRequestSaleRecurrencyDaysSpanEnum;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsIdDeleteRequestSale
     */
    'transaction_category_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDeleteRequestSale
     */
    'payment_type': V1SalesTransactionsIdDeleteRequestSalePaymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDeleteRequestSale
     */
    'payment_method': V1SalesTransactionsIdDeleteRequestSalePaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDeleteRequestSale
     */
    'created_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsIdDeleteRequestSale
     */
    'pay_at'?: string | null;
}

export const V1SalesTransactionsIdDeleteRequestSaleRecurrencyDaysSpanEnum = {
    FirstOfMonth: 'first_of_month',
    LastOfMonth: 'last_of_month',
    MiddleOfMonth: 'middle_of_month',
    _1: '1',
    _2: '2',
    _3: '3',
    _4: '4',
    _5: '5',
    _6: '6',
    _7: '7',
    _8: '8',
    _9: '9',
    _10: '10',
    _11: '11',
    _12: '12',
    _13: '13',
    _14: '14',
    _15: '15',
    _16: '16',
    _17: '17',
    _18: '18',
    _19: '19',
    _20: '20',
    _21: '21',
    _22: '22',
    _23: '23',
    _24: '24',
    _25: '25',
    _26: '26',
    _27: '27',
    _28: '28',
    _29: '29',
    _30: '30',
    _31: '31'
} as const;

export type V1SalesTransactionsIdDeleteRequestSaleRecurrencyDaysSpanEnum = typeof V1SalesTransactionsIdDeleteRequestSaleRecurrencyDaysSpanEnum[keyof typeof V1SalesTransactionsIdDeleteRequestSaleRecurrencyDaysSpanEnum];
export const V1SalesTransactionsIdDeleteRequestSalePaymentTypeEnum = {
    InCash: 'in-cash',
    Credit: 'credit'
} as const;

export type V1SalesTransactionsIdDeleteRequestSalePaymentTypeEnum = typeof V1SalesTransactionsIdDeleteRequestSalePaymentTypeEnum[keyof typeof V1SalesTransactionsIdDeleteRequestSalePaymentTypeEnum];
export const V1SalesTransactionsIdDeleteRequestSalePaymentMethodEnum = {
    Cash: 'cash',
    Card: 'card',
    Other: 'other',
    Bank: 'bank',
    OnCredit: 'on-credit'
} as const;

export type V1SalesTransactionsIdDeleteRequestSalePaymentMethodEnum = typeof V1SalesTransactionsIdDeleteRequestSalePaymentMethodEnum[keyof typeof V1SalesTransactionsIdDeleteRequestSalePaymentMethodEnum];

/**
 * 
 * @export
 * @interface V1SalesTransactionsQuoteIdDelete200Response
 */
export interface V1SalesTransactionsQuoteIdDelete200Response {
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'due_days': number;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'amount_due': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'amount_paid': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'amount_free': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'amount_discount': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'amount_products': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'amount_services': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'amount_utility': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'amount_cost': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'amount_total': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'amount_delivery': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'amount_total_with_delivery': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'is_recurrent': boolean;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'recurrency_times': number | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'recurrency_days_span': V1SalesTransactionsQuoteIdDelete200ResponseRecurrencyDaysSpanEnum;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'last_recurrency_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'code': string;
    /**
     * 
     * @type {V1SalesTransactionsGet201ResponseContact}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'contact': V1SalesTransactionsGet201ResponseContact | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'transaction_category': V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'concept': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'comment': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'transaction_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'transaction_status': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'transaction_origin': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'payment_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'payment_method': string;
    /**
     * 
     * @type {Array<V1ExpensesTransactionsIdDelete200ResponseDepositsInner>}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'deposits': Array<V1ExpensesTransactionsIdDelete200ResponseDepositsInner>;
    /**
     * 
     * @type {Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'products': Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>;
    /**
     * 
     * @type {Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'services': Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response
     */
    'pay_at': string | null;
}

export const V1SalesTransactionsQuoteIdDelete200ResponseRecurrencyDaysSpanEnum = {
    FirstOfMonth: 'first_of_month',
    LastOfMonth: 'last_of_month',
    MiddleOfMonth: 'middle_of_month',
    _1: '1',
    _2: '2',
    _3: '3',
    _4: '4',
    _5: '5',
    _6: '6',
    _7: '7',
    _8: '8',
    _9: '9',
    _10: '10',
    _11: '11',
    _12: '12',
    _13: '13',
    _14: '14',
    _15: '15',
    _16: '16',
    _17: '17',
    _18: '18',
    _19: '19',
    _20: '20',
    _21: '21',
    _22: '22',
    _23: '23',
    _24: '24',
    _25: '25',
    _26: '26',
    _27: '27',
    _28: '28',
    _29: '29',
    _30: '30',
    _31: '31'
} as const;

export type V1SalesTransactionsQuoteIdDelete200ResponseRecurrencyDaysSpanEnum = typeof V1SalesTransactionsQuoteIdDelete200ResponseRecurrencyDaysSpanEnum[keyof typeof V1SalesTransactionsQuoteIdDelete200ResponseRecurrencyDaysSpanEnum];

/**
 * 
 * @export
 * @interface V1SalesTransactionsQuoteIdDelete200Response1
 */
export interface V1SalesTransactionsQuoteIdDelete200Response1 {
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'origin': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'amount_total': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'amount_due': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'amount_paid': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'amount_free': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'amount_discount': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'amount_products': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'amount_services': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'amount_utility': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'amount_cost'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'amount_delivery': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'amount_total_with_delivery': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'is_recurrent': boolean;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'pos_metadata': V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata | null;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'recurrency_times': number | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'recurrency_days_span': V1SalesTransactionsQuoteIdDelete200Response1RecurrencyDaysSpanEnum;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'last_recurrency_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'due_days'?: number;
    /**
     * 
     * @type {V1DebtsTransactionsGet200ResponseTransactionsInnerContact}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'contact': V1DebtsTransactionsGet200ResponseTransactionsInnerContact | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'transaction_category': V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'concept': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'comment': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'transaction_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'transaction_status': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'transaction_origin': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'payment_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'payment_method': string;
    /**
     * 
     * @type {Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'products': Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>;
    /**
     * 
     * @type {Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'services': Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDelete200Response1
     */
    'pay_at': string | null;
}

export const V1SalesTransactionsQuoteIdDelete200Response1RecurrencyDaysSpanEnum = {
    FirstOfMonth: 'first_of_month',
    LastOfMonth: 'last_of_month',
    MiddleOfMonth: 'middle_of_month',
    _1: '1',
    _2: '2',
    _3: '3',
    _4: '4',
    _5: '5',
    _6: '6',
    _7: '7',
    _8: '8',
    _9: '9',
    _10: '10',
    _11: '11',
    _12: '12',
    _13: '13',
    _14: '14',
    _15: '15',
    _16: '16',
    _17: '17',
    _18: '18',
    _19: '19',
    _20: '20',
    _21: '21',
    _22: '22',
    _23: '23',
    _24: '24',
    _25: '25',
    _26: '26',
    _27: '27',
    _28: '28',
    _29: '29',
    _30: '30',
    _31: '31'
} as const;

export type V1SalesTransactionsQuoteIdDelete200Response1RecurrencyDaysSpanEnum = typeof V1SalesTransactionsQuoteIdDelete200Response1RecurrencyDaysSpanEnum[keyof typeof V1SalesTransactionsQuoteIdDelete200Response1RecurrencyDaysSpanEnum];

/**
 * 
 * @export
 * @interface V1SalesTransactionsQuoteIdDeleteRequest
 */
export interface V1SalesTransactionsQuoteIdDeleteRequest {
    /**
     * 
     * @type {V1SalesTransactionsQuoteIdDeleteRequestQuote}
     * @memberof V1SalesTransactionsQuoteIdDeleteRequest
     */
    'quote': V1SalesTransactionsQuoteIdDeleteRequestQuote;
}
/**
 * 
 * @export
 * @interface V1SalesTransactionsQuoteIdDeleteRequestQuote
 */
export interface V1SalesTransactionsQuoteIdDeleteRequestQuote {
    /**
     * 
     * @type {Array<V1SalesTransactionsQuoteIdDeleteRequestQuoteProductsInner>}
     * @memberof V1SalesTransactionsQuoteIdDeleteRequestQuote
     */
    'products': Array<V1SalesTransactionsQuoteIdDeleteRequestQuoteProductsInner>;
    /**
     * 
     * @type {Array<V1SalesTransactionsQuoteIdDeleteRequestQuoteServicesInner>}
     * @memberof V1SalesTransactionsQuoteIdDeleteRequestQuote
     */
    'services': Array<V1SalesTransactionsQuoteIdDeleteRequestQuoteServicesInner>;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuoteIdDeleteRequestQuote
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuoteIdDeleteRequestQuote
     */
    'due_days': number;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuoteIdDeleteRequestQuote
     */
    'discount'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDeleteRequestQuote
     */
    'concept'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDeleteRequestQuote
     */
    'comment'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuoteIdDeleteRequestQuote
     */
    'contact_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuoteIdDeleteRequestQuote
     */
    'recurrency_times'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuoteIdDeleteRequestQuote
     */
    'transaction_category_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDeleteRequestQuote
     */
    'recurrency_days_span'?: V1SalesTransactionsQuoteIdDeleteRequestQuoteRecurrencyDaysSpanEnum;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDeleteRequestQuote
     */
    'payment_type': V1SalesTransactionsQuoteIdDeleteRequestQuotePaymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDeleteRequestQuote
     */
    'payment_method': V1SalesTransactionsQuoteIdDeleteRequestQuotePaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDeleteRequestQuote
     */
    'created_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDeleteRequestQuote
     */
    'pay_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDeleteRequestQuote
     */
    'transaction_origin'?: V1SalesTransactionsQuoteIdDeleteRequestQuoteTransactionOriginEnum;
}

export const V1SalesTransactionsQuoteIdDeleteRequestQuoteRecurrencyDaysSpanEnum = {
    FirstOfMonth: 'first_of_month',
    LastOfMonth: 'last_of_month',
    MiddleOfMonth: 'middle_of_month',
    _1: '1',
    _2: '2',
    _3: '3',
    _4: '4',
    _5: '5',
    _6: '6',
    _7: '7',
    _8: '8',
    _9: '9',
    _10: '10',
    _11: '11',
    _12: '12',
    _13: '13',
    _14: '14',
    _15: '15',
    _16: '16',
    _17: '17',
    _18: '18',
    _19: '19',
    _20: '20',
    _21: '21',
    _22: '22',
    _23: '23',
    _24: '24',
    _25: '25',
    _26: '26',
    _27: '27',
    _28: '28',
    _29: '29',
    _30: '30',
    _31: '31'
} as const;

export type V1SalesTransactionsQuoteIdDeleteRequestQuoteRecurrencyDaysSpanEnum = typeof V1SalesTransactionsQuoteIdDeleteRequestQuoteRecurrencyDaysSpanEnum[keyof typeof V1SalesTransactionsQuoteIdDeleteRequestQuoteRecurrencyDaysSpanEnum];
export const V1SalesTransactionsQuoteIdDeleteRequestQuotePaymentTypeEnum = {
    InCash: 'in-cash',
    Credit: 'credit'
} as const;

export type V1SalesTransactionsQuoteIdDeleteRequestQuotePaymentTypeEnum = typeof V1SalesTransactionsQuoteIdDeleteRequestQuotePaymentTypeEnum[keyof typeof V1SalesTransactionsQuoteIdDeleteRequestQuotePaymentTypeEnum];
export const V1SalesTransactionsQuoteIdDeleteRequestQuotePaymentMethodEnum = {
    Cash: 'cash',
    Card: 'card',
    Other: 'other',
    Bank: 'bank',
    OnCredit: 'on-credit'
} as const;

export type V1SalesTransactionsQuoteIdDeleteRequestQuotePaymentMethodEnum = typeof V1SalesTransactionsQuoteIdDeleteRequestQuotePaymentMethodEnum[keyof typeof V1SalesTransactionsQuoteIdDeleteRequestQuotePaymentMethodEnum];
export const V1SalesTransactionsQuoteIdDeleteRequestQuoteTransactionOriginEnum = {
    App: 'app',
    AppWeb: 'app-web'
} as const;

export type V1SalesTransactionsQuoteIdDeleteRequestQuoteTransactionOriginEnum = typeof V1SalesTransactionsQuoteIdDeleteRequestQuoteTransactionOriginEnum[keyof typeof V1SalesTransactionsQuoteIdDeleteRequestQuoteTransactionOriginEnum];

/**
 * 
 * @export
 * @interface V1SalesTransactionsQuoteIdDeleteRequestQuoteProductsInner
 */
export interface V1SalesTransactionsQuoteIdDeleteRequestQuoteProductsInner {
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuoteIdDeleteRequestQuoteProductsInner
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuoteIdDeleteRequestQuoteProductsInner
     */
    'product_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuoteIdDeleteRequestQuoteProductsInner
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDeleteRequestQuoteProductsInner
     */
    'custom_price'?: string | null;
}
/**
 * 
 * @export
 * @interface V1SalesTransactionsQuoteIdDeleteRequestQuoteServicesInner
 */
export interface V1SalesTransactionsQuoteIdDeleteRequestQuoteServicesInner {
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuoteIdDeleteRequestQuoteServicesInner
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuoteIdDeleteRequestQuoteServicesInner
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdDeleteRequestQuoteServicesInner
     */
    'price'?: string;
}
/**
 * 
 * @export
 * @interface V1SalesTransactionsQuoteIdValidateProductStockGet200Response
 */
export interface V1SalesTransactionsQuoteIdValidateProductStockGet200Response {
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuoteIdValidateProductStockGet200Response
     */
    'id': number;
    /**
     * 
     * @type {Array<V1SalesTransactionsQuoteIdValidateProductStockGet200ResponseNotEnoeughStockInner>}
     * @memberof V1SalesTransactionsQuoteIdValidateProductStockGet200Response
     */
    'not_enoeugh_stock'?: Array<V1SalesTransactionsQuoteIdValidateProductStockGet200ResponseNotEnoeughStockInner>;
}
/**
 * 
 * @export
 * @interface V1SalesTransactionsQuoteIdValidateProductStockGet200ResponseNotEnoeughStockInner
 */
export interface V1SalesTransactionsQuoteIdValidateProductStockGet200ResponseNotEnoeughStockInner {
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuoteIdValidateProductStockGet200ResponseNotEnoeughStockInner
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuoteIdValidateProductStockGet200ResponseNotEnoeughStockInner
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface V1SalesTransactionsQuotePost201Response
 */
export interface V1SalesTransactionsQuotePost201Response {
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'origin': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'amount_due': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'amount_paid': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'amount_free': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'amount_discount': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'amount_products': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'amount_services': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'amount_utility': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'amount_cost': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'amount_total': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'amount_delivery'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'amount_total_with_delivery': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'is_recurrent': boolean;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'recurrency_times': number | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'recurrency_days_span': V1SalesTransactionsQuotePost201ResponseRecurrencyDaysSpanEnum;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'last_recurrency_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'due_days': number;
    /**
     * 
     * @type {V1SalesTransactionsGet201ResponseContact}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'contact': V1SalesTransactionsGet201ResponseContact | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'transaction_category': V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'concept': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'comment': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'transaction_origin': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'transaction_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'transaction_status': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'payment_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'payment_method': string;
    /**
     * 
     * @type {Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'products': Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>;
    /**
     * 
     * @type {Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'services': Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePost201Response
     */
    'pay_at': string | null;
}

export const V1SalesTransactionsQuotePost201ResponseRecurrencyDaysSpanEnum = {
    FirstOfMonth: 'first_of_month',
    LastOfMonth: 'last_of_month',
    MiddleOfMonth: 'middle_of_month',
    _1: '1',
    _2: '2',
    _3: '3',
    _4: '4',
    _5: '5',
    _6: '6',
    _7: '7',
    _8: '8',
    _9: '9',
    _10: '10',
    _11: '11',
    _12: '12',
    _13: '13',
    _14: '14',
    _15: '15',
    _16: '16',
    _17: '17',
    _18: '18',
    _19: '19',
    _20: '20',
    _21: '21',
    _22: '22',
    _23: '23',
    _24: '24',
    _25: '25',
    _26: '26',
    _27: '27',
    _28: '28',
    _29: '29',
    _30: '30',
    _31: '31'
} as const;

export type V1SalesTransactionsQuotePost201ResponseRecurrencyDaysSpanEnum = typeof V1SalesTransactionsQuotePost201ResponseRecurrencyDaysSpanEnum[keyof typeof V1SalesTransactionsQuotePost201ResponseRecurrencyDaysSpanEnum];

/**
 * 
 * @export
 * @interface V1SalesTransactionsQuotePostRequest
 */
export interface V1SalesTransactionsQuotePostRequest {
    /**
     * 
     * @type {V1SalesTransactionsQuotePostRequestQuote}
     * @memberof V1SalesTransactionsQuotePostRequest
     */
    'quote'?: V1SalesTransactionsQuotePostRequestQuote;
}
/**
 * 
 * @export
 * @interface V1SalesTransactionsQuotePostRequestQuote
 */
export interface V1SalesTransactionsQuotePostRequestQuote {
    /**
     * 
     * @type {Array<V1SalesTransactionsGetRequestSaleProductsInner>}
     * @memberof V1SalesTransactionsQuotePostRequestQuote
     */
    'products': Array<V1SalesTransactionsGetRequestSaleProductsInner>;
    /**
     * 
     * @type {Array<V1PosTransactionsIdDeleteRequestPosProductsInnerModifiersInner>}
     * @memberof V1SalesTransactionsQuotePostRequestQuote
     */
    'services': Array<V1PosTransactionsIdDeleteRequestPosProductsInnerModifiersInner>;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuotePostRequestQuote
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuotePostRequestQuote
     */
    'due_days': number;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuotePostRequestQuote
     */
    'discount'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePostRequestQuote
     */
    'concept'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePostRequestQuote
     */
    'comment'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuotePostRequestQuote
     */
    'contact_id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuotePostRequestQuote
     */
    'transaction_category_id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuotePostRequestQuote
     */
    'recurrency_times'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePostRequestQuote
     */
    'recurrency_days_span'?: V1SalesTransactionsQuotePostRequestQuoteRecurrencyDaysSpanEnum;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePostRequestQuote
     */
    'payment_type': V1SalesTransactionsQuotePostRequestQuotePaymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePostRequestQuote
     */
    'payment_method': V1SalesTransactionsQuotePostRequestQuotePaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePostRequestQuote
     */
    'created_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePostRequestQuote
     */
    'pay_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotePostRequestQuote
     */
    'transaction_origin'?: V1SalesTransactionsQuotePostRequestQuoteTransactionOriginEnum;
}

export const V1SalesTransactionsQuotePostRequestQuoteRecurrencyDaysSpanEnum = {
    FirstOfMonth: 'first_of_month',
    LastOfMonth: 'last_of_month',
    MiddleOfMonth: 'middle_of_month',
    _1: '1',
    _2: '2',
    _3: '3',
    _4: '4',
    _5: '5',
    _6: '6',
    _7: '7',
    _8: '8',
    _9: '9',
    _10: '10',
    _11: '11',
    _12: '12',
    _13: '13',
    _14: '14',
    _15: '15',
    _16: '16',
    _17: '17',
    _18: '18',
    _19: '19',
    _20: '20',
    _21: '21',
    _22: '22',
    _23: '23',
    _24: '24',
    _25: '25',
    _26: '26',
    _27: '27',
    _28: '28',
    _29: '29',
    _30: '30',
    _31: '31'
} as const;

export type V1SalesTransactionsQuotePostRequestQuoteRecurrencyDaysSpanEnum = typeof V1SalesTransactionsQuotePostRequestQuoteRecurrencyDaysSpanEnum[keyof typeof V1SalesTransactionsQuotePostRequestQuoteRecurrencyDaysSpanEnum];
export const V1SalesTransactionsQuotePostRequestQuotePaymentTypeEnum = {
    InCash: 'in-cash',
    Credit: 'credit'
} as const;

export type V1SalesTransactionsQuotePostRequestQuotePaymentTypeEnum = typeof V1SalesTransactionsQuotePostRequestQuotePaymentTypeEnum[keyof typeof V1SalesTransactionsQuotePostRequestQuotePaymentTypeEnum];
export const V1SalesTransactionsQuotePostRequestQuotePaymentMethodEnum = {
    Cash: 'cash',
    Card: 'card',
    Other: 'other',
    Bank: 'bank',
    OnCredit: 'on-credit'
} as const;

export type V1SalesTransactionsQuotePostRequestQuotePaymentMethodEnum = typeof V1SalesTransactionsQuotePostRequestQuotePaymentMethodEnum[keyof typeof V1SalesTransactionsQuotePostRequestQuotePaymentMethodEnum];
export const V1SalesTransactionsQuotePostRequestQuoteTransactionOriginEnum = {
    App: 'app',
    AppWeb: 'app-web'
} as const;

export type V1SalesTransactionsQuotePostRequestQuoteTransactionOriginEnum = typeof V1SalesTransactionsQuotePostRequestQuoteTransactionOriginEnum[keyof typeof V1SalesTransactionsQuotePostRequestQuoteTransactionOriginEnum];

/**
 * 
 * @export
 * @interface V1SalesTransactionsQuotesGet200Response
 */
export interface V1SalesTransactionsQuotesGet200Response {
    /**
     * 
     * @type {Array<V1SalesTransactionsQuotesGet200ResponseQuotesInner>}
     * @memberof V1SalesTransactionsQuotesGet200Response
     */
    'quotes': Array<V1SalesTransactionsQuotesGet200ResponseQuotesInner>;
    /**
     * 
     * @type {V1CashFlowGet200ResponsePagination}
     * @memberof V1SalesTransactionsQuotesGet200Response
     */
    'pagination': V1CashFlowGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1SalesTransactionsQuotesGet200ResponseQuotesInner
 */
export interface V1SalesTransactionsQuotesGet200ResponseQuotesInner {
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'origin': string;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'due_days': number;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'amount_due': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'amount_paid': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'amount_free': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'amount_discount': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'amount_products': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'amount_services': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'amount_utility': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'amount_cost'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'amount_total': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'amount_delivery': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'amount_total_with_delivery': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'is_recurrent': boolean;
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'recurrency_times': number | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'recurrency_days_span': V1SalesTransactionsQuotesGet200ResponseQuotesInnerRecurrencyDaysSpanEnum;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'last_recurrency_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'code': string;
    /**
     * 
     * @type {V1SalesTransactionsQuotesGet200ResponseQuotesInnerContact}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'contact': V1SalesTransactionsQuotesGet200ResponseQuotesInnerContact | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'transaction_category': V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'concept': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'comment': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'transaction_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'transaction_status': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'transaction_origin': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'payment_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'payment_method': string;
    /**
     * 
     * @type {Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'products': Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>;
    /**
     * 
     * @type {Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'services': Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInner
     */
    'pay_at': string | null;
}

export const V1SalesTransactionsQuotesGet200ResponseQuotesInnerRecurrencyDaysSpanEnum = {
    FirstOfMonth: 'first_of_month',
    LastOfMonth: 'last_of_month',
    MiddleOfMonth: 'middle_of_month',
    _1: '1',
    _2: '2',
    _3: '3',
    _4: '4',
    _5: '5',
    _6: '6',
    _7: '7',
    _8: '8',
    _9: '9',
    _10: '10',
    _11: '11',
    _12: '12',
    _13: '13',
    _14: '14',
    _15: '15',
    _16: '16',
    _17: '17',
    _18: '18',
    _19: '19',
    _20: '20',
    _21: '21',
    _22: '22',
    _23: '23',
    _24: '24',
    _25: '25',
    _26: '26',
    _27: '27',
    _28: '28',
    _29: '29',
    _30: '30',
    _31: '31'
} as const;

export type V1SalesTransactionsQuotesGet200ResponseQuotesInnerRecurrencyDaysSpanEnum = typeof V1SalesTransactionsQuotesGet200ResponseQuotesInnerRecurrencyDaysSpanEnum[keyof typeof V1SalesTransactionsQuotesGet200ResponseQuotesInnerRecurrencyDaysSpanEnum];

/**
 * 
 * @export
 * @interface V1SalesTransactionsQuotesGet200ResponseQuotesInnerContact
 */
export interface V1SalesTransactionsQuotesGet200ResponseQuotesInnerContact {
    /**
     * 
     * @type {number}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInnerContact
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInnerContact
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInnerContact
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof V1SalesTransactionsQuotesGet200ResponseQuotesInnerContact
     */
    'address': string | null;
}
/**
 * 
 * @export
 * @interface V1ServiceCategoriesGet200Response
 */
export interface V1ServiceCategoriesGet200Response {
    /**
     * 
     * @type {Array<V1ServiceCategoriesGet200ResponseServiceCategoriesInner>}
     * @memberof V1ServiceCategoriesGet200Response
     */
    'service_categories': Array<V1ServiceCategoriesGet200ResponseServiceCategoriesInner>;
    /**
     * 
     * @type {V1ContactsDebtsTransactionsGet200ResponsePagination}
     * @memberof V1ServiceCategoriesGet200Response
     */
    'pagination': V1ContactsDebtsTransactionsGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1ServiceCategoriesGet200ResponseServiceCategoriesInner
 */
export interface V1ServiceCategoriesGet200ResponseServiceCategoriesInner {
    /**
     * 
     * @type {number}
     * @memberof V1ServiceCategoriesGet200ResponseServiceCategoriesInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ServiceCategoriesGet200ResponseServiceCategoriesInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1ServiceCategoriesGet200ResponseServiceCategoriesInner
     */
    'description': string | null;
    /**
     * 
     * @type {number}
     * @memberof V1ServiceCategoriesGet200ResponseServiceCategoriesInner
     */
    'services_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1ServiceCategoriesGet200ResponseServiceCategoriesInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1ServiceCategoriesGet200ResponseServiceCategoriesInner
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V1ServiceCategoriesGetRequest
 */
export interface V1ServiceCategoriesGetRequest {
    /**
     * 
     * @type {V1DebtsTransactionsIdGet200ResponseProductsInnerCategory}
     * @memberof V1ServiceCategoriesGetRequest
     */
    'service_category': V1DebtsTransactionsIdGet200ResponseProductsInnerCategory;
}
/**
 * 
 * @export
 * @interface V1ServiceCategoriesIdBatchUpdatePatchRequest
 */
export interface V1ServiceCategoriesIdBatchUpdatePatchRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof V1ServiceCategoriesIdBatchUpdatePatchRequest
     */
    'service_ids': Array<string>;
}
/**
 * 
 * @export
 * @interface V1ServiceCategoriesIdServicesGet200Response
 */
export interface V1ServiceCategoriesIdServicesGet200Response {
    /**
     * 
     * @type {V1ServiceCategoriesIdServicesGet200ResponseServiceCategory}
     * @memberof V1ServiceCategoriesIdServicesGet200Response
     */
    'service_category': V1ServiceCategoriesIdServicesGet200ResponseServiceCategory;
    /**
     * 
     * @type {V1ContactsDebtsTransactionsGet200ResponsePagination}
     * @memberof V1ServiceCategoriesIdServicesGet200Response
     */
    'pagination': V1ContactsDebtsTransactionsGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1ServiceCategoriesIdServicesGet200ResponseServiceCategory
 */
export interface V1ServiceCategoriesIdServicesGet200ResponseServiceCategory {
    /**
     * 
     * @type {number}
     * @memberof V1ServiceCategoriesIdServicesGet200ResponseServiceCategory
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ServiceCategoriesIdServicesGet200ResponseServiceCategory
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1ServiceCategoriesIdServicesGet200ResponseServiceCategory
     */
    'description': string | null;
    /**
     * 
     * @type {number}
     * @memberof V1ServiceCategoriesIdServicesGet200ResponseServiceCategory
     */
    'services_count': number;
    /**
     * 
     * @type {Array<V1ServiceCategoriesIdServicesGet200ResponseServiceCategoryServicesInner>}
     * @memberof V1ServiceCategoriesIdServicesGet200ResponseServiceCategory
     */
    'services': Array<V1ServiceCategoriesIdServicesGet200ResponseServiceCategoryServicesInner>;
}
/**
 * 
 * @export
 * @interface V1ServiceCategoriesIdServicesGet200ResponseServiceCategoryServicesInner
 */
export interface V1ServiceCategoriesIdServicesGet200ResponseServiceCategoryServicesInner {
    /**
     * 
     * @type {number}
     * @memberof V1ServiceCategoriesIdServicesGet200ResponseServiceCategoryServicesInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ServiceCategoriesIdServicesGet200ResponseServiceCategoryServicesInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1ServiceCategoriesIdServicesGet200ResponseServiceCategoryServicesInner
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1ServiceCategoriesIdServicesGet200ResponseServiceCategoryServicesInner
     */
    'price': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1ServiceCategoriesIdServicesGet200ResponseServiceCategoryServicesInner
     */
    'show_in_store': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1ServiceCategoriesIdServicesGet200ResponseServiceCategoryServicesInner
     */
    'show_in_pos': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1ServiceCategoriesIdServicesGet200ResponseServiceCategoryServicesInner
     */
    'has_modifiers': boolean | null;
    /**
     * 
     * @type {Array<V1OffersGet200ResponseOffersInnerProductsInnerModifiersInner>}
     * @memberof V1ServiceCategoriesIdServicesGet200ResponseServiceCategoryServicesInner
     */
    'modifiers'?: Array<V1OffersGet200ResponseOffersInnerProductsInnerModifiersInner> | null;
    /**
     * 
     * @type {Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>}
     * @memberof V1ServiceCategoriesIdServicesGet200ResponseServiceCategoryServicesInner
     */
    'images': Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory}
     * @memberof V1ServiceCategoriesIdServicesGet200ResponseServiceCategoryServicesInner
     */
    'service_category'?: V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory | null;
    /**
     * 
     * @type {string}
     * @memberof V1ServiceCategoriesIdServicesGet200ResponseServiceCategoryServicesInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1ServiceCategoriesIdServicesGet200ResponseServiceCategoryServicesInner
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V1ServicesGet200Response
 */
export interface V1ServicesGet200Response {
    /**
     * 
     * @type {string}
     * @memberof V1ServicesGet200Response
     */
    'inventory_total'?: string;
    /**
     * 
     * @type {Array<V1ServicesGet200ResponseServicesInner>}
     * @memberof V1ServicesGet200Response
     */
    'services': Array<V1ServicesGet200ResponseServicesInner>;
    /**
     * 
     * @type {V1ContactsDebtsTransactionsGet200ResponsePagination}
     * @memberof V1ServicesGet200Response
     */
    'pagination': V1ContactsDebtsTransactionsGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1ServicesGet200ResponseServicesInner
 */
export interface V1ServicesGet200ResponseServicesInner {
    /**
     * 
     * @type {number}
     * @memberof V1ServicesGet200ResponseServicesInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ServicesGet200ResponseServicesInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1ServicesGet200ResponseServicesInner
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1ServicesGet200ResponseServicesInner
     */
    'price': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1ServicesGet200ResponseServicesInner
     */
    'show_in_store': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1ServicesGet200ResponseServicesInner
     */
    'show_in_pos': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1ServicesGet200ResponseServicesInner
     */
    'has_modifiers': boolean | null;
    /**
     * 
     * @type {Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>}
     * @memberof V1ServicesGet200ResponseServicesInner
     */
    'images': Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory}
     * @memberof V1ServicesGet200ResponseServicesInner
     */
    'service_category'?: V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory | null;
    /**
     * 
     * @type {string}
     * @memberof V1ServicesGet200ResponseServicesInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1ServicesGet200ResponseServicesInner
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V1ServicesGetRequest
 */
export interface V1ServicesGetRequest {
    /**
     * 
     * @type {V1ServicesGetRequestService}
     * @memberof V1ServicesGetRequest
     */
    'service': V1ServicesGetRequestService;
}
/**
 * 
 * @export
 * @interface V1ServicesGetRequestService
 */
export interface V1ServicesGetRequestService {
    /**
     * 
     * @type {string}
     * @memberof V1ServicesGetRequestService
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1ServicesGetRequestService
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1ServicesGetRequestService
     */
    'price': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1ServicesGetRequestService
     */
    'show_in_store': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1ServicesGetRequestService
     */
    'show_in_pos': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof V1ServicesGetRequestService
     */
    'images': Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof V1ServicesGetRequestService
     */
    'service_category_id': number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof V1ServicesGetRequestService
     */
    'modifier_ids': Array<number> | null;
}
/**
 * 
 * @export
 * @interface V1ServicesIdImagesOrderPostRequest
 */
export interface V1ServicesIdImagesOrderPostRequest {
    /**
     * 
     * @type {V1ProductsIdImagesOrderPostRequestProduct}
     * @memberof V1ServicesIdImagesOrderPostRequest
     */
    'service': V1ProductsIdImagesOrderPostRequestProduct;
}
/**
 * 
 * @export
 * @interface V1ServicesIdModifiersGet200Response
 */
export interface V1ServicesIdModifiersGet200Response {
    /**
     * 
     * @type {Array<V1ServicesIdModifiersGet200ResponseModifiersInner>}
     * @memberof V1ServicesIdModifiersGet200Response
     */
    'modifiers': Array<V1ServicesIdModifiersGet200ResponseModifiersInner>;
}
/**
 * 
 * @export
 * @interface V1ServicesIdModifiersGet200ResponseModifiersInner
 */
export interface V1ServicesIdModifiersGet200ResponseModifiersInner {
    /**
     * 
     * @type {number}
     * @memberof V1ServicesIdModifiersGet200ResponseModifiersInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1ServicesIdModifiersGet200ResponseModifiersInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1ServicesIdModifiersGet200ResponseModifiersInner
     */
    'color': string;
    /**
     * 
     * @type {string}
     * @memberof V1ServicesIdModifiersGet200ResponseModifiersInner
     */
    'price': string;
    /**
     * 
     * @type {string}
     * @memberof V1ServicesIdModifiersGet200ResponseModifiersInner
     */
    'type': V1ServicesIdModifiersGet200ResponseModifiersInnerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof V1ServicesIdModifiersGet200ResponseModifiersInner
     */
    'created_at': string;
}

export const V1ServicesIdModifiersGet200ResponseModifiersInnerTypeEnum = {
    ModifierService: 'ModifierService'
} as const;

export type V1ServicesIdModifiersGet200ResponseModifiersInnerTypeEnum = typeof V1ServicesIdModifiersGet200ResponseModifiersInnerTypeEnum[keyof typeof V1ServicesIdModifiersGet200ResponseModifiersInnerTypeEnum];

/**
 * 
 * @export
 * @interface V1ServicesUncategorizedGet200Response
 */
export interface V1ServicesUncategorizedGet200Response {
    /**
     * 
     * @type {Array<V1ServiceCategoriesIdServicesGet200ResponseServiceCategoryServicesInner>}
     * @memberof V1ServicesUncategorizedGet200Response
     */
    'services': Array<V1ServiceCategoriesIdServicesGet200ResponseServiceCategoryServicesInner>;
    /**
     * 
     * @type {V1ContactsDebtsTransactionsGet200ResponsePagination}
     * @memberof V1ServicesUncategorizedGet200Response
     */
    'pagination': V1ContactsDebtsTransactionsGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1StatsGet200Response
 */
export interface V1StatsGet200Response {
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'sales_credit': string;
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'sales_web': string;
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'discounts': string;
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'total_sales': string;
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'sales_costs': string;
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'sales_costs_cash': string;
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'sales_costs_credit': string;
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'sales_costs_web': string;
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'expenses_in_cash': string;
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'expenses_credit': string;
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'expenses_skipped_costs': string;
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'profits': string;
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'available_cash': string;
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'debts_to_charge_paid': string;
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'debts_to_pay_paid': string;
    /**
     * 
     * @type {number}
     * @memberof V1StatsGet200Response
     */
    'sales_in_cash_cash': number;
    /**
     * 
     * @type {number}
     * @memberof V1StatsGet200Response
     */
    'sales_in_cash_card': number;
    /**
     * 
     * @type {number}
     * @memberof V1StatsGet200Response
     */
    'sales_in_cash_bank': number;
    /**
     * 
     * @type {number}
     * @memberof V1StatsGet200Response
     */
    'sales_in_cash_total': number;
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'sales_credit_cash': string;
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'sales_credit_card': string;
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'sales_credit_bank': string;
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'sales_credit_total': string;
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'debts_to_charge_pending': string;
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'debts_to_charge_total': string;
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'debts_to_pay_pending': string;
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'debts_paid_total'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'debts_to_pay_total': string;
    /**
     * 
     * @type {number}
     * @memberof V1StatsGet200Response
     */
    'stock_detail_categories': number;
    /**
     * 
     * @type {number}
     * @memberof V1StatsGet200Response
     */
    'stock_detail_products': number;
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'stock_cost': string;
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'stock_price': string;
    /**
     * 
     * @type {string}
     * @memberof V1StatsGet200Response
     */
    'sales_in_cash': string;
    /**
     * 
     * @type {number}
     * @memberof V1StatsGet200Response
     */
    'web_in_approval': number;
    /**
     * 
     * @type {number}
     * @memberof V1StatsGet200Response
     */
    'web_in_progress': number;
    /**
     * 
     * @type {number}
     * @memberof V1StatsGet200Response
     */
    'web_finished': number;
}
/**
 * 
 * @export
 * @interface V1StockAnalyticsGet200Response
 */
export interface V1StockAnalyticsGet200Response {
    /**
     * 
     * @type {Array<V1OffersGet200ResponseOffersInnerProductsInner>}
     * @memberof V1StockAnalyticsGet200Response
     */
    'best_sellers': Array<V1OffersGet200ResponseOffersInnerProductsInner>;
    /**
     * 
     * @type {Array<V1OffersGet200ResponseOffersInnerProductsInner>}
     * @memberof V1StockAnalyticsGet200Response
     */
    'worst_sellers': Array<V1OffersGet200ResponseOffersInnerProductsInner>;
    /**
     * 
     * @type {string}
     * @memberof V1StockAnalyticsGet200Response
     */
    'cost_vs_price_ratio': string;
    /**
     * 
     * @type {string}
     * @memberof V1StockAnalyticsGet200Response
     */
    'gross_cost_margin': string;
    /**
     * 
     * @type {string}
     * @memberof V1StockAnalyticsGet200Response
     */
    'stock_over_time': string;
    /**
     * 
     * @type {number}
     * @memberof V1StockAnalyticsGet200Response
     */
    'unsold_stock_quantity': number;
}
/**
 * 
 * @export
 * @interface V1StripeChargesIdDelete200Response
 */
export interface V1StripeChargesIdDelete200Response {
    /**
     * 
     * @type {string}
     * @memberof V1StripeChargesIdDelete200Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface V1StripeChargesPost201Response
 */
export interface V1StripeChargesPost201Response {
    /**
     * 
     * @type {string}
     * @memberof V1StripeChargesPost201Response
     */
    'subscription_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1StripeChargesPost201Response
     */
    'client_secret'?: string;
}
/**
 * 
 * @export
 * @interface V1StripeChargesPostRequest
 */
export interface V1StripeChargesPostRequest {
    /**
     * 
     * @type {boolean}
     * @memberof V1StripeChargesPostRequest
     */
    'create_paid_subscription'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof V1StripeChargesPostRequest
     */
    'plan_type'?: V1StripeChargesPostRequestPlanTypeEnum;
}

export const V1StripeChargesPostRequestPlanTypeEnum = {
    Monthly: 'monthly',
    Month3: 'month-3',
    Anual: 'anual',
    Anual40: 'anual-40',
    AnualCourse: 'anual-course',
    Month399: 'month_399',
    Month299: 'month_299'
} as const;

export type V1StripeChargesPostRequestPlanTypeEnum = typeof V1StripeChargesPostRequestPlanTypeEnum[keyof typeof V1StripeChargesPostRequestPlanTypeEnum];

/**
 * 
 * @export
 * @interface V1TagsGet200Response
 */
export interface V1TagsGet200Response {
    /**
     * 
     * @type {Array<V1TagsGet200ResponseTagsInner>}
     * @memberof V1TagsGet200Response
     */
    'tags': Array<V1TagsGet200ResponseTagsInner>;
}
/**
 * 
 * @export
 * @interface V1TagsGet200ResponseTagsInner
 */
export interface V1TagsGet200ResponseTagsInner {
    /**
     * 
     * @type {number}
     * @memberof V1TagsGet200ResponseTagsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1TagsGet200ResponseTagsInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1TagsGet200ResponseTagsInner
     */
    'dimension': string;
}
/**
 * 
 * @export
 * @interface V1TransactionCategoriesGet200Response
 */
export interface V1TransactionCategoriesGet200Response {
    /**
     * 
     * @type {Array<V1TransactionCategoriesGet200ResponseTransactionCategoriesInner>}
     * @memberof V1TransactionCategoriesGet200Response
     */
    'transaction_categories': Array<V1TransactionCategoriesGet200ResponseTransactionCategoriesInner>;
    /**
     * 
     * @type {V1ContactsDebtsTransactionsGet200ResponsePagination}
     * @memberof V1TransactionCategoriesGet200Response
     */
    'pagination': V1ContactsDebtsTransactionsGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1TransactionCategoriesGet200ResponseTransactionCategoriesInner
 */
export interface V1TransactionCategoriesGet200ResponseTransactionCategoriesInner {
    /**
     * 
     * @type {number}
     * @memberof V1TransactionCategoriesGet200ResponseTransactionCategoriesInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesGet200ResponseTransactionCategoriesInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesGet200ResponseTransactionCategoriesInner
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesGet200ResponseTransactionCategoriesInner
     */
    'icon': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesGet200ResponseTransactionCategoriesInner
     */
    'color': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesGet200ResponseTransactionCategoriesInner
     */
    'budget'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesGet200ResponseTransactionCategoriesInner
     */
    'amount_total': string;
    /**
     * 
     * @type {number}
     * @memberof V1TransactionCategoriesGet200ResponseTransactionCategoriesInner
     */
    'transactions_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesGet200ResponseTransactionCategoriesInner
     */
    'transaction_type': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1TransactionCategoriesGet200ResponseTransactionCategoriesInner
     */
    'is_deleteable': boolean;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesGet200ResponseTransactionCategoriesInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesGet200ResponseTransactionCategoriesInner
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V1TransactionCategoriesGet201Response
 */
export interface V1TransactionCategoriesGet201Response {
    /**
     * 
     * @type {number}
     * @memberof V1TransactionCategoriesGet201Response
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesGet201Response
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesGet201Response
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesGet201Response
     */
    'icon': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesGet201Response
     */
    'color': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesGet201Response
     */
    'transaction_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesGet201Response
     */
    'budget': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesGet201Response
     */
    'amount_total': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1TransactionCategoriesGet201Response
     */
    'is_deleteable': boolean;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesGet201Response
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesGet201Response
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V1TransactionCategoriesGetRequest
 */
export interface V1TransactionCategoriesGetRequest {
    /**
     * 
     * @type {V1TransactionCategoriesGetRequestTransactionCategory}
     * @memberof V1TransactionCategoriesGetRequest
     */
    'transaction_category': V1TransactionCategoriesGetRequestTransactionCategory;
}
/**
 * 
 * @export
 * @interface V1TransactionCategoriesGetRequestTransactionCategory
 */
export interface V1TransactionCategoriesGetRequestTransactionCategory {
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesGetRequestTransactionCategory
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesGetRequestTransactionCategory
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesGetRequestTransactionCategory
     */
    'icon'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesGetRequestTransactionCategory
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesGetRequestTransactionCategory
     */
    'transaction_type_id': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesGetRequestTransactionCategory
     */
    'budget'?: string | null;
}
/**
 * 
 * @export
 * @interface V1TransactionCategoriesIdAddBudgetPatchRequest
 */
export interface V1TransactionCategoriesIdAddBudgetPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdAddBudgetPatchRequest
     */
    'budget': string;
}
/**
 * 
 * @export
 * @interface V1TransactionCategoriesIdBatchUpdatePatchRequest
 */
export interface V1TransactionCategoriesIdBatchUpdatePatchRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof V1TransactionCategoriesIdBatchUpdatePatchRequest
     */
    'transaction_ids': Array<number>;
}
/**
 * 
 * @export
 * @interface V1TransactionCategoriesIdDelete200Response
 */
export interface V1TransactionCategoriesIdDelete200Response {
    /**
     * 
     * @type {number}
     * @memberof V1TransactionCategoriesIdDelete200Response
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdDelete200Response
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdDelete200Response
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdDelete200Response
     */
    'icon'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdDelete200Response
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdDelete200Response
     */
    'transaction_type': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1TransactionCategoriesIdDelete200Response
     */
    'is_deleteable': boolean;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdDelete200Response
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdDelete200Response
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V1TransactionCategoriesIdDeleteRequest
 */
export interface V1TransactionCategoriesIdDeleteRequest {
    /**
     * 
     * @type {V1TransactionCategoriesIdDeleteRequestTransactionCategory}
     * @memberof V1TransactionCategoriesIdDeleteRequest
     */
    'transaction_category': V1TransactionCategoriesIdDeleteRequestTransactionCategory;
}
/**
 * 
 * @export
 * @interface V1TransactionCategoriesIdDeleteRequestTransactionCategory
 */
export interface V1TransactionCategoriesIdDeleteRequestTransactionCategory {
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdDeleteRequestTransactionCategory
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdDeleteRequestTransactionCategory
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdDeleteRequestTransactionCategory
     */
    'icon'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdDeleteRequestTransactionCategory
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdDeleteRequestTransactionCategory
     */
    'transaction_type_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdDeleteRequestTransactionCategory
     */
    'budget'?: string | null;
}
/**
 * 
 * @export
 * @interface V1TransactionCategoriesIdTransactionsGet200Response
 */
export interface V1TransactionCategoriesIdTransactionsGet200Response {
    /**
     * 
     * @type {V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategory}
     * @memberof V1TransactionCategoriesIdTransactionsGet200Response
     */
    'transaction_category': V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategory;
    /**
     * 
     * @type {V1ContactsDebtsTransactionsGet200ResponsePagination}
     * @memberof V1TransactionCategoriesIdTransactionsGet200Response
     */
    'pagination': V1ContactsDebtsTransactionsGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategory
 */
export interface V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategory {
    /**
     * 
     * @type {number}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategory
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategory
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategory
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategory
     */
    'icon': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategory
     */
    'color': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategory
     */
    'budget'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategory
     */
    'transaction_type': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategory
     */
    'is_deleteable': boolean;
    /**
     * 
     * @type {number}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategory
     */
    'transactions_count': number;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategory
     */
    'amount_total'?: string;
    /**
     * 
     * @type {Array<V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner>}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategory
     */
    'transactions': Array<V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner>;
}
/**
 * 
 * @export
 * @interface V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner
 */
export interface V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner {
    /**
     * 
     * @type {number}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner
     */
    'amount_due': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner
     */
    'amount_paid': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner
     */
    'amount_free': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner
     */
    'amount_discount': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner
     */
    'amount_products': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner
     */
    'amount_utility': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner
     */
    'amount_cost'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner
     */
    'amount_total': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner
     */
    'amount_delivery': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner
     */
    'amount_total_with_delivery': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner
     */
    'code': string;
    /**
     * 
     * @type {V1DebtsTransactionsGet200ResponseTransactionsInnerContact}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner
     */
    'contact': V1DebtsTransactionsGet200ResponseTransactionsInnerContact | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner
     */
    'transaction_category': V1ExpensesTransactionsGet200ResponseTransactionsInnerTransactionCategory | null;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner
     */
    'concept': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner
     */
    'transaction_transaction_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner
     */
    'transaction_status': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner
     */
    'transaction_origin': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner
     */
    'payment_transaction_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner
     */
    'payment_method': string;
    /**
     * 
     * @type {Array<V1OffersGet200ResponseOffersInnerProductsInner>}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner
     */
    'products': Array<V1OffersGet200ResponseOffersInnerProductsInner>;
    /**
     * 
     * @type {boolean}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner
     */
    'skip_cost'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesIdTransactionsGet200ResponseTransactionCategoryTransactionsInner
     */
    'pay_at': string | null;
}
/**
 * 
 * @export
 * @interface V1TransactionCategoriesTopGet200Response
 */
export interface V1TransactionCategoriesTopGet200Response {
    /**
     * 
     * @type {Array<V1TransactionCategoriesTopGet200ResponseTransactionCategoriesInner>}
     * @memberof V1TransactionCategoriesTopGet200Response
     */
    'transaction_categories': Array<V1TransactionCategoriesTopGet200ResponseTransactionCategoriesInner>;
}
/**
 * 
 * @export
 * @interface V1TransactionCategoriesTopGet200ResponseTransactionCategoriesInner
 */
export interface V1TransactionCategoriesTopGet200ResponseTransactionCategoriesInner {
    /**
     * 
     * @type {number}
     * @memberof V1TransactionCategoriesTopGet200ResponseTransactionCategoriesInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesTopGet200ResponseTransactionCategoriesInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesTopGet200ResponseTransactionCategoriesInner
     */
    'icon': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesTopGet200ResponseTransactionCategoriesInner
     */
    'color': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionCategoriesTopGet200ResponseTransactionCategoriesInner
     */
    'total': string;
}
/**
 * 
 * @export
 * @interface V1TransactionsByDayGet200Response
 */
export interface V1TransactionsByDayGet200Response {
    /**
     * 
     * @type {Array<V1TransactionsByDayGet200ResponseDaysInner>}
     * @memberof V1TransactionsByDayGet200Response
     */
    'days'?: Array<V1TransactionsByDayGet200ResponseDaysInner>;
}
/**
 * 
 * @export
 * @interface V1TransactionsByDayGet200ResponseDaysInner
 */
export interface V1TransactionsByDayGet200ResponseDaysInner {
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsByDayGet200ResponseDaysInner
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsByDayGet200ResponseDaysInner
     */
    'total_day': string;
    /**
     * 
     * @type {Array<V1TransactionsByDayGet200ResponseDaysInnerTransactionsInner>}
     * @memberof V1TransactionsByDayGet200ResponseDaysInner
     */
    'transactions': Array<V1TransactionsByDayGet200ResponseDaysInnerTransactionsInner>;
}
/**
 * 
 * @export
 * @interface V1TransactionsByDayGet200ResponseDaysInnerTransactionsInner
 */
export interface V1TransactionsByDayGet200ResponseDaysInnerTransactionsInner {
    /**
     * 
     * @type {number}
     * @memberof V1TransactionsByDayGet200ResponseDaysInnerTransactionsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsByDayGet200ResponseDaysInnerTransactionsInner
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsByDayGet200ResponseDaysInnerTransactionsInner
     */
    'amount_total': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsByDayGet200ResponseDaysInnerTransactionsInner
     */
    'concept': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsByDayGet200ResponseDaysInnerTransactionsInner
     */
    'transaction_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsByDayGet200ResponseDaysInnerTransactionsInner
     */
    'payment_method'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsByDayGet200ResponseDaysInnerTransactionsInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsByDayGet200ResponseDaysInnerTransactionsInner
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsByDayGet200ResponseDaysInnerTransactionsInner
     */
    'pay_at': string | null;
    /**
     * 
     * @type {number}
     * @memberof V1TransactionsByDayGet200ResponseDaysInnerTransactionsInner
     */
    'recurrency_times': number | null;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsByDayGet200ResponseDaysInnerTransactionsInner
     */
    'recurrency_days_span': V1TransactionsByDayGet200ResponseDaysInnerTransactionsInnerRecurrencyDaysSpanEnum;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsByDayGet200ResponseDaysInnerTransactionsInner
     */
    'last_recurrency_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsByDayGet200ResponseDaysInnerTransactionsInner
     */
    'payment_type': string;
    /**
     * 
     * @type {V1DebtsTransactionsGet200ResponseTransactionsInnerContact}
     * @memberof V1TransactionsByDayGet200ResponseDaysInnerTransactionsInner
     */
    'contact': V1DebtsTransactionsGet200ResponseTransactionsInnerContact | null;
}

export const V1TransactionsByDayGet200ResponseDaysInnerTransactionsInnerRecurrencyDaysSpanEnum = {
    FirstOfMonth: 'first_of_month',
    LastOfMonth: 'last_of_month',
    MiddleOfMonth: 'middle_of_month',
    _1: '1',
    _2: '2',
    _3: '3',
    _4: '4',
    _5: '5',
    _6: '6',
    _7: '7',
    _8: '8',
    _9: '9',
    _10: '10',
    _11: '11',
    _12: '12',
    _13: '13',
    _14: '14',
    _15: '15',
    _16: '16',
    _17: '17',
    _18: '18',
    _19: '19',
    _20: '20',
    _21: '21',
    _22: '22',
    _23: '23',
    _24: '24',
    _25: '25',
    _26: '26',
    _27: '27',
    _28: '28',
    _29: '29',
    _30: '30',
    _31: '31'
} as const;

export type V1TransactionsByDayGet200ResponseDaysInnerTransactionsInnerRecurrencyDaysSpanEnum = typeof V1TransactionsByDayGet200ResponseDaysInnerTransactionsInnerRecurrencyDaysSpanEnum[keyof typeof V1TransactionsByDayGet200ResponseDaysInnerTransactionsInnerRecurrencyDaysSpanEnum];

/**
 * 
 * @export
 * @interface V1TransactionsDetailsGet200Response
 */
export interface V1TransactionsDetailsGet200Response {
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsDetailsGet200Response
     */
    'available_cash': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsDetailsGet200Response
     */
    'profits'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsDetailsGet200Response
     */
    'sales_in_cash': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsDetailsGet200Response
     */
    'sales_credit': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsDetailsGet200Response
     */
    'deposits': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsDetailsGet200Response
     */
    'sales_online': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsDetailsGet200Response
     */
    'shopify_sales': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsDetailsGet200Response
     */
    'shopify_costs'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsDetailsGet200Response
     */
    'discounts': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsDetailsGet200Response
     */
    'costs': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsDetailsGet200Response
     */
    'expenses_in_cash': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsDetailsGet200Response
     */
    'expenses_credit': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsDetailsGet200Response
     */
    'skipped_costs': string;
    /**
     * 
     * @type {number}
     * @memberof V1TransactionsDetailsGet200Response
     */
    'low_stock_products': number;
    /**
     * 
     * @type {number}
     * @memberof V1TransactionsDetailsGet200Response
     */
    'zero_stock_products': number;
}
/**
 * 
 * @export
 * @interface V1TransactionsGet200Response
 */
export interface V1TransactionsGet200Response {
    /**
     * 
     * @type {Array<V1TransactionsGet200ResponseTransactionsInner>}
     * @memberof V1TransactionsGet200Response
     */
    'transactions': Array<V1TransactionsGet200ResponseTransactionsInner>;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsGet200Response
     */
    'total_sales'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsGet200Response
     */
    'total_expenses'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsGet200Response
     */
    'total_earnings'?: string;
    /**
     * 
     * @type {V1CashFlowGet200ResponsePagination}
     * @memberof V1TransactionsGet200Response
     */
    'pagination': V1CashFlowGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1TransactionsGet200ResponseTransactionsInner
 */
export interface V1TransactionsGet200ResponseTransactionsInner {
    /**
     * 
     * @type {number}
     * @memberof V1TransactionsGet200ResponseTransactionsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsGet200ResponseTransactionsInner
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsGet200ResponseTransactionsInner
     */
    'amount_total': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsGet200ResponseTransactionsInner
     */
    'concept': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsGet200ResponseTransactionsInner
     */
    'transaction_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsGet200ResponseTransactionsInner
     */
    'payment_method'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsGet200ResponseTransactionsInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsGet200ResponseTransactionsInner
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1TransactionsGet200ResponseTransactionsInner
     */
    'pay_at': string | null;
}
/**
 * 
 * @export
 * @interface V1UserLanguagePatchRequest
 */
export interface V1UserLanguagePatchRequest {
    /**
     * 
     * @type {V1UserLanguagePatchRequestUser}
     * @memberof V1UserLanguagePatchRequest
     */
    'user'?: V1UserLanguagePatchRequestUser;
}
/**
 * 
 * @export
 * @interface V1UserLanguagePatchRequestUser
 */
export interface V1UserLanguagePatchRequestUser {
    /**
     * 
     * @type {string}
     * @memberof V1UserLanguagePatchRequestUser
     */
    'language': string;
}
/**
 * 
 * @export
 * @interface V1UserMeGet200Response
 */
export interface V1UserMeGet200Response {
    /**
     * 
     * @type {V1UserMeGet200ResponseUser}
     * @memberof V1UserMeGet200Response
     */
    'user': V1UserMeGet200ResponseUser;
    /**
     * 
     * @type {V1UserMeGet200ResponseCurrentOrganization}
     * @memberof V1UserMeGet200Response
     */
    'current_organization': V1UserMeGet200ResponseCurrentOrganization;
    /**
     * 
     * @type {V1UserMeGet200ResponseCurrentPaymentInfo}
     * @memberof V1UserMeGet200Response
     */
    'current_payment_info'?: V1UserMeGet200ResponseCurrentPaymentInfo | null;
    /**
     * 
     * @type {V1UserMeGet200ResponseCurrentPermissions}
     * @memberof V1UserMeGet200Response
     */
    'current_permissions'?: V1UserMeGet200ResponseCurrentPermissions | null;
    /**
     * 
     * @type {Array<V1UserMeGet200ResponseCurrentOrganization>}
     * @memberof V1UserMeGet200Response
     */
    'organizations': Array<V1UserMeGet200ResponseCurrentOrganization>;
}
/**
 * 
 * @export
 * @interface V1UserMeGet200ResponseCurrentOrganization
 */
export interface V1UserMeGet200ResponseCurrentOrganization {
    /**
     * 
     * @type {number}
     * @memberof V1UserMeGet200ResponseCurrentOrganization
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseCurrentOrganization
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseCurrentOrganization
     */
    'main_role': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentOrganization
     */
    'has_paid_access': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentOrganization
     */
    'is_dollar'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseCurrentOrganization
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseCurrentOrganization
     */
    'money_symbol': V1UserMeGet200ResponseCurrentOrganizationMoneySymbolEnum;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseCurrentOrganization
     */
    'business_type': V1UserMeGet200ResponseCurrentOrganizationBusinessTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseCurrentOrganization
     */
    'industry': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseCurrentOrganization
     */
    'avatar': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseCurrentOrganization
     */
    'primary_color': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseCurrentOrganization
     */
    'secondary_color': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseCurrentOrganization
     */
    'thank_you_message': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentOrganization
     */
    'ticket_hide_logo': boolean;
    /**
     * 
     * @type {number}
     * @memberof V1UserMeGet200ResponseCurrentOrganization
     */
    'low_stock': number;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseCurrentOrganization
     */
    'receipt_line_1': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseCurrentOrganization
     */
    'receipt_line_2': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseCurrentOrganization
     */
    'receipt_line_3': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseCurrentOrganization
     */
    'receipt_line_4': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentOrganization
     */
    'unified_transactions': boolean;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseCurrentOrganization
     */
    'custom_image': string | null;
    /**
     * 
     * @type {V1OrganizationsPatch201ResponseCountry}
     * @memberof V1UserMeGet200ResponseCurrentOrganization
     */
    'country': V1OrganizationsPatch201ResponseCountry | null;
}

export const V1UserMeGet200ResponseCurrentOrganizationMoneySymbolEnum = {
    Dot: 'dot',
    Comma: 'comma'
} as const;

export type V1UserMeGet200ResponseCurrentOrganizationMoneySymbolEnum = typeof V1UserMeGet200ResponseCurrentOrganizationMoneySymbolEnum[keyof typeof V1UserMeGet200ResponseCurrentOrganizationMoneySymbolEnum];
export const V1UserMeGet200ResponseCurrentOrganizationBusinessTypeEnum = {
    Person: 'person',
    Business: 'business'
} as const;

export type V1UserMeGet200ResponseCurrentOrganizationBusinessTypeEnum = typeof V1UserMeGet200ResponseCurrentOrganizationBusinessTypeEnum[keyof typeof V1UserMeGet200ResponseCurrentOrganizationBusinessTypeEnum];

/**
 * 
 * @export
 * @interface V1UserMeGet200ResponseCurrentPaymentInfo
 */
export interface V1UserMeGet200ResponseCurrentPaymentInfo {
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseCurrentPaymentInfo
     */
    'bank_name': string;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseCurrentPaymentInfo
     */
    'bank_number': string;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseCurrentPaymentInfo
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseCurrentPaymentInfo
     */
    'admin_message': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseCurrentPaymentInfo
     */
    'process_status': string;
}
/**
 * 
 * @export
 * @interface V1UserMeGet200ResponseCurrentPermissions
 */
export interface V1UserMeGet200ResponseCurrentPermissions {
    /**
     * 
     * @type {number}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'pdv_delete_orders': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'pdv_edit_orders': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'pdv_see_analytics': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'acc_discounts': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'acc_credit_sales': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'pdv_push_notifications': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'massive_import': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'acc_credit_expenses': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'acc_create_expenses': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'acc_financial_statement': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'acc_list_categories': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'acc_create_categories': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'acc_update_categories': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'acc_delete_categories': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'acc_budget_assistant': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'acc_delete_transactions': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'acc_see_analytics': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'inventory_see_catalog': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'inventory_see_costs': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'inventory_create_products': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'inventory_delete_products': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'inventory_edit_products': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'inventory_stock_analytics': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'web_delete_transactions': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'web_configure_store': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'web_see_analytics': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'web_manage_offers': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'service_see_catalog': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'service_create': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'service_delete': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'service_edit': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'acc_list_service_categories': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'acc_create_service_categories': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'acc_update_service_categories': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'acc_delete_service_categories': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'inventory_bulk_update': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'inventory_bulk_delete': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseCurrentPermissions
     */
    'inventory_modifiers': boolean;
}
/**
 * 
 * @export
 * @interface V1UserMeGet200ResponseUser
 */
export interface V1UserMeGet200ResponseUser {
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseUser
     */
    'first_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseUser
     */
    'last_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseUser
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseUser
     */
    'provider'?: V1UserMeGet200ResponseUserProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseUser
     */
    'phone': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseUser
     */
    'time_zone': string;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseUser
     */
    'language': string;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseUser
     */
    'destroy_at': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseUser
     */
    'is_guest'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserMeGet200ResponseUser
     */
    'paid_customer': boolean;
    /**
     * 
     * @type {string}
     * @memberof V1UserMeGet200ResponseUser
     */
    'uuid'?: string;
    /**
     * 
     * @type {V1OrganizationsPatch201ResponseCountry}
     * @memberof V1UserMeGet200ResponseUser
     */
    'country': V1OrganizationsPatch201ResponseCountry | null;
}

export const V1UserMeGet200ResponseUserProviderEnum = {
    Ios: 'ios',
    Android: 'android',
    Email: 'email'
} as const;

export type V1UserMeGet200ResponseUserProviderEnum = typeof V1UserMeGet200ResponseUserProviderEnum[keyof typeof V1UserMeGet200ResponseUserProviderEnum];

/**
 * 
 * @export
 * @interface V1UserSubscriptionDelete200Response
 */
export interface V1UserSubscriptionDelete200Response {
    /**
     * 
     * @type {string}
     * @memberof V1UserSubscriptionDelete200Response
     */
    'provider'?: V1UserSubscriptionDelete200ResponseProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof V1UserSubscriptionDelete200Response
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V1UserSubscriptionDelete200Response
     */
    'price'?: string | null;
    /**
     * 
     * @type {V1UserSubscriptionDelete200ResponseStatus}
     * @memberof V1UserSubscriptionDelete200Response
     */
    'status'?: V1UserSubscriptionDelete200ResponseStatus | null;
    /**
     * 
     * @type {string}
     * @memberof V1UserSubscriptionDelete200Response
     */
    'created_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V1UserSubscriptionDelete200Response
     */
    'start_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V1UserSubscriptionDelete200Response
     */
    'end_date'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof V1UserSubscriptionDelete200Response
     */
    'to_cancel_subscription'?: boolean;
}

export const V1UserSubscriptionDelete200ResponseProviderEnum = {
    RevenueCat: 'revenue-cat',
    Stripe: 'stripe',
    Shopify: 'shopify',
    CabalTrial: 'cabal-trial',
    Appsumo: 'appsumo'
} as const;

export type V1UserSubscriptionDelete200ResponseProviderEnum = typeof V1UserSubscriptionDelete200ResponseProviderEnum[keyof typeof V1UserSubscriptionDelete200ResponseProviderEnum];

/**
 * @type V1UserSubscriptionDelete200ResponseStatus
 * @export
 */
export type V1UserSubscriptionDelete200ResponseStatus = V1UserSubscriptionDelete200ResponseStatusOneOf | number;

/**
 * 
 * @export
 * @enum {string}
 */

export const V1UserSubscriptionDelete200ResponseStatusOneOf = {
    Canceled: 'canceled',
    Cancelled: 'cancelled',
    Declined: 'declined',
    Frozen: 'frozen',
    Accepted: 'accepted',
    Active: 'active',
    PaymentFailed: 'payment-failed',
    Trial: 'trial',
    Expired: 'expired',
    Pending: 'pending',
    Unpaid: 'unpaid',
    Expiration: 'expiration',
    IncompleteExpired: 'incomplete_expired'
} as const;

export type V1UserSubscriptionDelete200ResponseStatusOneOf = typeof V1UserSubscriptionDelete200ResponseStatusOneOf[keyof typeof V1UserSubscriptionDelete200ResponseStatusOneOf];


/**
 * 
 * @export
 * @interface V1UserUpdatePasswordPutRequest
 */
export interface V1UserUpdatePasswordPutRequest {
    /**
     * 
     * @type {V1UserUpdatePasswordPutRequestUser}
     * @memberof V1UserUpdatePasswordPutRequest
     */
    'user': V1UserUpdatePasswordPutRequestUser;
}
/**
 * 
 * @export
 * @interface V1UserUpdatePasswordPutRequestUser
 */
export interface V1UserUpdatePasswordPutRequestUser {
    /**
     * 
     * @type {string}
     * @memberof V1UserUpdatePasswordPutRequestUser
     */
    'current_password'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1UserUpdatePasswordPutRequestUser
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof V1UserUpdatePasswordPutRequestUser
     */
    'confirm_password'?: string;
}
/**
 * 
 * @export
 * @interface V1UserUpdateSinglePatchRequest
 */
export interface V1UserUpdateSinglePatchRequest {
    /**
     * 
     * @type {string}
     * @memberof V1UserUpdateSinglePatchRequest
     */
    'user_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof V1UserUpdateSinglePatchRequest
     */
    'user_last_name': string;
}
/**
 * 
 * @export
 * @interface V1VersionGet200Response
 */
export interface V1VersionGet200Response {
    /**
     * 
     * @type {string}
     * @memberof V1VersionGet200Response
     */
    'version': string;
}
/**
 * 
 * @export
 * @interface V1VoiceCommandsExecutePost201Response
 */
export interface V1VoiceCommandsExecutePost201Response {
    /**
     * 
     * @type {Array<number>}
     * @memberof V1VoiceCommandsExecutePost201Response
     */
    'ids': Array<number>;
}
/**
 * 
 * @export
 * @interface V1VoiceCommandsExecutePost406Response
 */
export interface V1VoiceCommandsExecutePost406Response {
    /**
     * 
     * @type {string}
     * @memberof V1VoiceCommandsExecutePost406Response
     */
    'error': string;
}
/**
 * 
 * @export
 * @interface V1VoiceCommandsExecutePostRequest
 */
export interface V1VoiceCommandsExecutePostRequest {
    /**
     * 
     * @type {string}
     * @memberof V1VoiceCommandsExecutePostRequest
     */
    'transcription': string;
    /**
     * 
     * @type {string}
     * @memberof V1VoiceCommandsExecutePostRequest
     */
    'audio_id': string;
}
/**
 * 
 * @export
 * @interface V1VoiceCommandsTranscribeIdGet200Response
 */
export interface V1VoiceCommandsTranscribeIdGet200Response {
    /**
     * 
     * @type {string}
     * @memberof V1VoiceCommandsTranscribeIdGet200Response
     */
    'transcription': string;
}
/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugCouponsValidateGet200Response
 */
export interface V1WebOrganizationsSlugCouponsValidateGet200Response {
    /**
     * 
     * @type {Array<V1OffersGet200ResponseOffersInnerProductsInner>}
     * @memberof V1WebOrganizationsSlugCouponsValidateGet200Response
     */
    'products': Array<V1OffersGet200ResponseOffersInnerProductsInner>;
}
/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugGet200Response
 */
export interface V1WebOrganizationsSlugGet200Response {
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200Response
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200Response
     */
    'slug': string;
    /**
     * 
     * @type {number}
     * @memberof V1WebOrganizationsSlugGet200Response
     */
    'avg_rating': number;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200Response
     */
    'primary_color'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200Response
     */
    'secondary_color'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200Response
     */
    'custom_image'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof V1WebOrganizationsSlugGet200Response
     */
    'has_paid_access'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200Response
     */
    'thank_you_message'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof V1WebOrganizationsSlugGet200Response
     */
    'unified_transactions'?: boolean;
    /**
     * 
     * @type {V1WebOrganizationsSlugGet200ResponseCountry}
     * @memberof V1WebOrganizationsSlugGet200Response
     */
    'country': V1WebOrganizationsSlugGet200ResponseCountry;
    /**
     * 
     * @type {V1UserMeGet200ResponseCurrentPaymentInfo}
     * @memberof V1WebOrganizationsSlugGet200Response
     */
    'payment_info': V1UserMeGet200ResponseCurrentPaymentInfo | null;
    /**
     * 
     * @type {Array<V1WebOrganizationsSlugGet200ResponsePaymentMethodsInner>}
     * @memberof V1WebOrganizationsSlugGet200Response
     */
    'payment_methods': Array<V1WebOrganizationsSlugGet200ResponsePaymentMethodsInner> | null;
    /**
     * 
     * @type {Array<V1WebOrganizationsSlugGet200ResponsePaymentMethodsInner>}
     * @memberof V1WebOrganizationsSlugGet200Response
     */
    'delivery_methods': Array<V1WebOrganizationsSlugGet200ResponsePaymentMethodsInner> | null;
    /**
     * 
     * @type {V1WebOrganizationsSlugGet200ResponseOrganizationSettings}
     * @memberof V1WebOrganizationsSlugGet200Response
     */
    'organization_settings': V1WebOrganizationsSlugGet200ResponseOrganizationSettings;
}
/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugGet200ResponseCountry
 */
export interface V1WebOrganizationsSlugGet200ResponseCountry {
    /**
     * 
     * @type {number}
     * @memberof V1WebOrganizationsSlugGet200ResponseCountry
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseCountry
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseCountry
     */
    'currency_symbol': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseCountry
     */
    'currency_name': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseCountry
     */
    'phone_area_number': string;
}
/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugGet200ResponseOrganizationSettings
 */
export interface V1WebOrganizationsSlugGet200ResponseOrganizationSettings {
    /**
     * 
     * @type {boolean}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'pick_in_store': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'delivery': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'pay_with_cash': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'pay_with_card': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'pay_with_bank': boolean;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'fixed_rate': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'monday_from': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'monday_to': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'tuesday_from': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'tuesday_to': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'wednesday_from': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'wednesday_to': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'thursday_from': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'thursday_to': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'friday_from': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'friday_to': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'saturday_from': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'saturday_to': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'sunday_from': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'sunday_to': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'image': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'facebook': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'instagram': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'twitter': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'tik_tok': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'payment_link': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'whatsapp_contact': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof V1WebOrganizationsSlugGet200ResponseOrganizationSettings
     */
    'out_of_stock_last': boolean;
}
/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugGet200ResponsePaymentMethodsInner
 */
export interface V1WebOrganizationsSlugGet200ResponsePaymentMethodsInner {
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponsePaymentMethodsInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugGet200ResponsePaymentMethodsInner
     */
    'slug': string;
}
/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugPaidSalesTransactionsPost201Response
 */
export interface V1WebOrganizationsSlugPaidSalesTransactionsPost201Response {
    /**
     * 
     * @type {number}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201Response
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201Response
     */
    'origin': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201Response
     */
    'amount_total': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201Response
     */
    'code': string;
    /**
     * 
     * @type {V1WebOrganizationsSlugPaidSalesTransactionsPost201ResponseContact}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201Response
     */
    'contact'?: V1WebOrganizationsSlugPaidSalesTransactionsPost201ResponseContact;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201Response
     */
    'delivery_method': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201Response
     */
    'transaction_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201Response
     */
    'transaction_status': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201Response
     */
    'transaction_origin': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201Response
     */
    'process_status': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201Response
     */
    'payment_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201Response
     */
    'payment_method': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201Response
     */
    'concept': string;
    /**
     * 
     * @type {Array<V1WebOrganizationsSlugPaidSalesTransactionsPost201ResponseProductsInner>}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201Response
     */
    'products': Array<V1WebOrganizationsSlugPaidSalesTransactionsPost201ResponseProductsInner>;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201Response
     */
    'created_at': string;
}
/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugPaidSalesTransactionsPost201ResponseContact
 */
export interface V1WebOrganizationsSlugPaidSalesTransactionsPost201ResponseContact {
    /**
     * 
     * @type {number}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201ResponseContact
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201ResponseContact
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201ResponseContact
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201ResponseContact
     */
    'email': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201ResponseContact
     */
    'address': string | null;
}
/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugPaidSalesTransactionsPost201ResponseProductsInner
 */
export interface V1WebOrganizationsSlugPaidSalesTransactionsPost201ResponseProductsInner {
    /**
     * 
     * @type {number}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201ResponseProductsInner
     */
    'transaction_product_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201ResponseProductsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201ResponseProductsInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201ResponseProductsInner
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201ResponseProductsInner
     */
    'price': string;
    /**
     * 
     * @type {Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201ResponseProductsInner
     */
    'images': Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPost201ResponseProductsInner
     */
    'product_category'?: V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory | null;
}
/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugPaidSalesTransactionsPostRequest
 */
export interface V1WebOrganizationsSlugPaidSalesTransactionsPostRequest {
    /**
     * 
     * @type {V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSale}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPostRequest
     */
    'sale': V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSale;
}
/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSale
 */
export interface V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSale {
    /**
     * 
     * @type {Array<V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleProductsInner>}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSale
     */
    'products': Array<V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleProductsInner>;
    /**
     * 
     * @type {V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleContact}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSale
     */
    'contact': V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleContact;
    /**
     * 
     * @type {V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleCard}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSale
     */
    'card'?: V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleCard;
    /**
     * 
     * @type {V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleBillingAddress}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSale
     */
    'billing_address': V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleBillingAddress;
    /**
     * 
     * @type {V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleBrowser}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSale
     */
    'browser'?: V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleBrowser;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSale
     */
    'payment_type': V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSalePaymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSale
     */
    'payment_method': V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSalePaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSale
     */
    'delivery_method': V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleDeliveryMethodEnum;
}

export const V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSalePaymentTypeEnum = {
    InCash: 'in-cash',
    Credit: 'credit'
} as const;

export type V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSalePaymentTypeEnum = typeof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSalePaymentTypeEnum[keyof typeof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSalePaymentTypeEnum];
export const V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSalePaymentMethodEnum = {
    Cash: 'cash',
    Card: 'card',
    Other: 'other',
    Bank: 'bank',
    OnCredit: 'on-credit'
} as const;

export type V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSalePaymentMethodEnum = typeof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSalePaymentMethodEnum[keyof typeof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSalePaymentMethodEnum];
export const V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleDeliveryMethodEnum = {
    StorePickup: 'store-pickup',
    Delivery: 'delivery'
} as const;

export type V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleDeliveryMethodEnum = typeof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleDeliveryMethodEnum[keyof typeof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleDeliveryMethodEnum];

/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleBillingAddress
 */
export interface V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleBillingAddress {
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleBillingAddress
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleBillingAddress
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleBillingAddress
     */
    'line1': string;
}
/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleBrowser
 */
export interface V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleBrowser {
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleBrowser
     */
    'device_fingerprint_id': string;
}
/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleCard
 */
export interface V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleCard {
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleCard
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleCard
     */
    'expiration_date': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleCard
     */
    'cvv': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleCard
     */
    'card_holder_name': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleCard
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleCard
     */
    'last_name': string;
}
/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleContact
 */
export interface V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleContact {
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleContact
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleContact
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleContact
     */
    'email': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleContact
     */
    'address': string | null;
}
/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleProductsInner
 */
export interface V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleProductsInner {
    /**
     * 
     * @type {number}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleProductsInner
     */
    'product_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1WebOrganizationsSlugPaidSalesTransactionsPostRequestSaleProductsInner
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugProductCategoriesGet200Response
 */
export interface V1WebOrganizationsSlugProductCategoriesGet200Response {
    /**
     * 
     * @type {Array<V1ProductCategoriesGet200ResponseProductCategoriesInner>}
     * @memberof V1WebOrganizationsSlugProductCategoriesGet200Response
     */
    'product_categories': Array<V1ProductCategoriesGet200ResponseProductCategoriesInner>;
}
/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugProductsGet200Response
 */
export interface V1WebOrganizationsSlugProductsGet200Response {
    /**
     * 
     * @type {Array<V1WebOrganizationsSlugProductsGet200ResponseProductsInner>}
     * @memberof V1WebOrganizationsSlugProductsGet200Response
     */
    'products': Array<V1WebOrganizationsSlugProductsGet200ResponseProductsInner>;
    /**
     * 
     * @type {V1ContactsDebtsTransactionsGet200ResponsePagination}
     * @memberof V1WebOrganizationsSlugProductsGet200Response
     */
    'pagination': V1ContactsDebtsTransactionsGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugProductsGet200ResponseProductsInner
 */
export interface V1WebOrganizationsSlugProductsGet200ResponseProductsInner {
    /**
     * 
     * @type {number}
     * @memberof V1WebOrganizationsSlugProductsGet200ResponseProductsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugProductsGet200ResponseProductsInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugProductsGet200ResponseProductsInner
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugProductsGet200ResponseProductsInner
     */
    'cost': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugProductsGet200ResponseProductsInner
     */
    'price': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1WebOrganizationsSlugProductsGet200ResponseProductsInner
     */
    'is_avg_cost'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugProductsGet200ResponseProductsInner
     */
    'discount_price': string | null;
    /**
     * 
     * @type {number}
     * @memberof V1WebOrganizationsSlugProductsGet200ResponseProductsInner
     */
    'stock': number;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugProductsGet200ResponseProductsInner
     */
    'barcode': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof V1WebOrganizationsSlugProductsGet200ResponseProductsInner
     */
    'show_in_store': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1WebOrganizationsSlugProductsGet200ResponseProductsInner
     */
    'show_in_pos': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1WebOrganizationsSlugProductsGet200ResponseProductsInner
     */
    'has_modifiers': boolean | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor}
     * @memberof V1WebOrganizationsSlugProductsGet200ResponseProductsInner
     */
    'color': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor}
     * @memberof V1WebOrganizationsSlugProductsGet200ResponseProductsInner
     */
    'size': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor}
     * @memberof V1WebOrganizationsSlugProductsGet200ResponseProductsInner
     */
    'style': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor | null;
    /**
     * 
     * @type {Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>}
     * @memberof V1WebOrganizationsSlugProductsGet200ResponseProductsInner
     */
    'images': Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory}
     * @memberof V1WebOrganizationsSlugProductsGet200ResponseProductsInner
     */
    'product_category'?: V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory}
     * @memberof V1WebOrganizationsSlugProductsGet200ResponseProductsInner
     */
    'collection': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugProductsGet200ResponseProductsInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugProductsGet200ResponseProductsInner
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugSalesTransactionsPost201Response
 */
export interface V1WebOrganizationsSlugSalesTransactionsPost201Response {
    /**
     * 
     * @type {number}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPost201Response
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPost201Response
     */
    'origin': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPost201Response
     */
    'amount_total': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPost201Response
     */
    'code': string;
    /**
     * 
     * @type {V1WebOrganizationsSlugSalesTransactionsPost201ResponseContact}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPost201Response
     */
    'contact': V1WebOrganizationsSlugSalesTransactionsPost201ResponseContact;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPost201Response
     */
    'delivery_method': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPost201Response
     */
    'transaction_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPost201Response
     */
    'transaction_status': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPost201Response
     */
    'transaction_origin': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPost201Response
     */
    'process_status': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPost201Response
     */
    'payment_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPost201Response
     */
    'payment_method': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPost201Response
     */
    'concept': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPost201Response
     */
    'coupon_code': string | null;
    /**
     * 
     * @type {Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPost201Response
     */
    'products': Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>;
    /**
     * 
     * @type {Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPost201Response
     */
    'services': Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPost201Response
     */
    'pos_metadata'?: V1ExpensesTransactionsGet200ResponseTransactionsInnerPosMetadata | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPost201Response
     */
    'created_at': string;
}
/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugSalesTransactionsPost201ResponseContact
 */
export interface V1WebOrganizationsSlugSalesTransactionsPost201ResponseContact {
    /**
     * 
     * @type {number}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPost201ResponseContact
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPost201ResponseContact
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPost201ResponseContact
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPost201ResponseContact
     */
    'email': string | null;
}
/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugSalesTransactionsPostRequest
 */
export interface V1WebOrganizationsSlugSalesTransactionsPostRequest {
    /**
     * 
     * @type {V1WebOrganizationsSlugSalesTransactionsPostRequestSale}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPostRequest
     */
    'sale': V1WebOrganizationsSlugSalesTransactionsPostRequestSale;
}
/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugSalesTransactionsPostRequestSale
 */
export interface V1WebOrganizationsSlugSalesTransactionsPostRequestSale {
    /**
     * 
     * @type {Array<V1WebOrganizationsSlugSalesTransactionsPostRequestSaleProductsInner>}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPostRequestSale
     */
    'products': Array<V1WebOrganizationsSlugSalesTransactionsPostRequestSaleProductsInner>;
    /**
     * 
     * @type {Array<V1PosTransactionsIdDeleteRequestPosServicesInner>}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPostRequestSale
     */
    'services': Array<V1PosTransactionsIdDeleteRequestPosServicesInner>;
    /**
     * 
     * @type {V1ContactsGetRequestContact}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPostRequestSale
     */
    'contact': V1ContactsGetRequestContact;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPostRequestSale
     */
    'payment_type': V1WebOrganizationsSlugSalesTransactionsPostRequestSalePaymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPostRequestSale
     */
    'payment_method': V1WebOrganizationsSlugSalesTransactionsPostRequestSalePaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPostRequestSale
     */
    'delivery_method': V1WebOrganizationsSlugSalesTransactionsPostRequestSaleDeliveryMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPostRequestSale
     */
    'coupon_code': string | null;
}

export const V1WebOrganizationsSlugSalesTransactionsPostRequestSalePaymentTypeEnum = {
    InCash: 'in-cash',
    Credit: 'credit'
} as const;

export type V1WebOrganizationsSlugSalesTransactionsPostRequestSalePaymentTypeEnum = typeof V1WebOrganizationsSlugSalesTransactionsPostRequestSalePaymentTypeEnum[keyof typeof V1WebOrganizationsSlugSalesTransactionsPostRequestSalePaymentTypeEnum];
export const V1WebOrganizationsSlugSalesTransactionsPostRequestSalePaymentMethodEnum = {
    Cash: 'cash',
    Card: 'card',
    Other: 'other',
    Bank: 'bank',
    OnCredit: 'on-credit'
} as const;

export type V1WebOrganizationsSlugSalesTransactionsPostRequestSalePaymentMethodEnum = typeof V1WebOrganizationsSlugSalesTransactionsPostRequestSalePaymentMethodEnum[keyof typeof V1WebOrganizationsSlugSalesTransactionsPostRequestSalePaymentMethodEnum];
export const V1WebOrganizationsSlugSalesTransactionsPostRequestSaleDeliveryMethodEnum = {
    StorePickup: 'store-pickup',
    Delivery: 'delivery'
} as const;

export type V1WebOrganizationsSlugSalesTransactionsPostRequestSaleDeliveryMethodEnum = typeof V1WebOrganizationsSlugSalesTransactionsPostRequestSaleDeliveryMethodEnum[keyof typeof V1WebOrganizationsSlugSalesTransactionsPostRequestSaleDeliveryMethodEnum];

/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugSalesTransactionsPostRequestSaleProductsInner
 */
export interface V1WebOrganizationsSlugSalesTransactionsPostRequestSaleProductsInner {
    /**
     * 
     * @type {number}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPostRequestSaleProductsInner
     */
    'product_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPostRequestSaleProductsInner
     */
    'quantity'?: number;
    /**
     * 
     * @type {Array<V1PosTransactionsIdDeleteRequestPosProductsInnerModifiersInner>}
     * @memberof V1WebOrganizationsSlugSalesTransactionsPostRequestSaleProductsInner
     */
    'modifiers'?: Array<V1PosTransactionsIdDeleteRequestPosProductsInnerModifiersInner>;
}
/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugServiceCategoriesGet200Response
 */
export interface V1WebOrganizationsSlugServiceCategoriesGet200Response {
    /**
     * 
     * @type {Array<V1ServiceCategoriesGet200ResponseServiceCategoriesInner>}
     * @memberof V1WebOrganizationsSlugServiceCategoriesGet200Response
     */
    'service_categories': Array<V1ServiceCategoriesGet200ResponseServiceCategoriesInner>;
}
/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugServicesGet200Response
 */
export interface V1WebOrganizationsSlugServicesGet200Response {
    /**
     * 
     * @type {Array<V1WebOrganizationsSlugServicesGet200ResponseResultsInner>}
     * @memberof V1WebOrganizationsSlugServicesGet200Response
     */
    'results': Array<V1WebOrganizationsSlugServicesGet200ResponseResultsInner> | null;
    /**
     * 
     * @type {V1ContactsDebtsTransactionsGet200ResponsePagination}
     * @memberof V1WebOrganizationsSlugServicesGet200Response
     */
    'pagination': V1ContactsDebtsTransactionsGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugServicesGet200ResponseResultsInner
 */
export interface V1WebOrganizationsSlugServicesGet200ResponseResultsInner {
    /**
     * 
     * @type {V1WebOrganizationsSlugServicesGet200ResponseResultsInnerService}
     * @memberof V1WebOrganizationsSlugServicesGet200ResponseResultsInner
     */
    'service': V1WebOrganizationsSlugServicesGet200ResponseResultsInnerService;
}
/**
 * 
 * @export
 * @interface V1WebOrganizationsSlugServicesGet200ResponseResultsInnerService
 */
export interface V1WebOrganizationsSlugServicesGet200ResponseResultsInnerService {
    /**
     * 
     * @type {number}
     * @memberof V1WebOrganizationsSlugServicesGet200ResponseResultsInnerService
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugServicesGet200ResponseResultsInnerService
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugServicesGet200ResponseResultsInnerService
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugServicesGet200ResponseResultsInnerService
     */
    'price': string;
    /**
     * 
     * @type {boolean}
     * @memberof V1WebOrganizationsSlugServicesGet200ResponseResultsInnerService
     */
    'show_in_store': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1WebOrganizationsSlugServicesGet200ResponseResultsInnerService
     */
    'show_in_pos': boolean;
    /**
     * 
     * @type {Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>}
     * @memberof V1WebOrganizationsSlugServicesGet200ResponseResultsInnerService
     */
    'images': Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory}
     * @memberof V1WebOrganizationsSlugServicesGet200ResponseResultsInnerService
     */
    'service_category'?: V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugServicesGet200ResponseResultsInnerService
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebOrganizationsSlugServicesGet200ResponseResultsInnerService
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V1WebSalesTransactionsCodeRatingsPostRequest
 */
export interface V1WebSalesTransactionsCodeRatingsPostRequest {
    /**
     * 
     * @type {V1WebSalesTransactionsCodeRatingsPostRequestRating}
     * @memberof V1WebSalesTransactionsCodeRatingsPostRequest
     */
    'rating': V1WebSalesTransactionsCodeRatingsPostRequestRating;
}
/**
 * 
 * @export
 * @interface V1WebSalesTransactionsCodeRatingsPostRequestRating
 */
export interface V1WebSalesTransactionsCodeRatingsPostRequestRating {
    /**
     * 
     * @type {number}
     * @memberof V1WebSalesTransactionsCodeRatingsPostRequestRating
     */
    'points': number;
    /**
     * 
     * @type {string}
     * @memberof V1WebSalesTransactionsCodeRatingsPostRequestRating
     */
    'message': string | null;
}
/**
 * 
 * @export
 * @interface V1WebTransactionsCountGet200Response
 */
export interface V1WebTransactionsCountGet200Response {
    /**
     * 
     * @type {number}
     * @memberof V1WebTransactionsCountGet200Response
     */
    'pending_orders'?: number;
}
/**
 * 
 * @export
 * @interface V1WebTransactionsGet200Response
 */
export interface V1WebTransactionsGet200Response {
    /**
     * 
     * @type {Array<V1WebTransactionsGet200ResponseTransactionsInner>}
     * @memberof V1WebTransactionsGet200Response
     */
    'transactions': Array<V1WebTransactionsGet200ResponseTransactionsInner>;
    /**
     * 
     * @type {V1CashFlowGet200ResponsePagination}
     * @memberof V1WebTransactionsGet200Response
     */
    'pagination': V1CashFlowGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V1WebTransactionsGet200ResponseTransactionsInner
 */
export interface V1WebTransactionsGet200ResponseTransactionsInner {
    /**
     * 
     * @type {number}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'amount_free': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'amount_discount': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'amount_products': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'amount_services': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'amount_total': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'amount_total_with_delivery': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'amount_utility': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'amount_cost': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'amount_delivery': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'coupon_code': string | null;
    /**
     * 
     * @type {V1CashFlowGet200ResponseTransactionsInnerPosMetadata}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'pos_metadata': V1CashFlowGet200ResponseTransactionsInnerPosMetadata | null;
    /**
     * 
     * @type {V1SalesTransactionsQuotesGet200ResponseQuotesInnerContact}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'contact': V1SalesTransactionsQuotesGet200ResponseQuotesInnerContact | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'payment_provider': string | null;
    /**
     * 
     * @type {object}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'payment_response': object | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'concept': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'transaction_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'transaction_status': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'process_status': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'payment_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'payment_method': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'delivery_method': string | null;
    /**
     * 
     * @type {Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'products': Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>;
    /**
     * 
     * @type {Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'services': Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsGet200ResponseTransactionsInner
     */
    'pay_at': string | null;
}
/**
 * 
 * @export
 * @interface V1WebTransactionsIdGet200Response
 */
export interface V1WebTransactionsIdGet200Response {
    /**
     * 
     * @type {number}
     * @memberof V1WebTransactionsIdGet200Response
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsIdGet200Response
     */
    'amount_free': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsIdGet200Response
     */
    'amount_discount': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsIdGet200Response
     */
    'amount_products': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsIdGet200Response
     */
    'amount_services': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsIdGet200Response
     */
    'amount_total': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsIdGet200Response
     */
    'amount_delivery': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsIdGet200Response
     */
    'amount_total_with_delivery': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsIdGet200Response
     */
    'delivery_method': string | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsIdGet200Response
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsIdGet200Response
     */
    'coupon_code': string | null;
    /**
     * 
     * @type {V1SalesTransactionsQuotesGet200ResponseQuotesInnerContact}
     * @memberof V1WebTransactionsIdGet200Response
     */
    'contact'?: V1SalesTransactionsQuotesGet200ResponseQuotesInnerContact | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsIdGet200Response
     */
    'concept': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsIdGet200Response
     */
    'transaction_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsIdGet200Response
     */
    'transaction_status': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsIdGet200Response
     */
    'process_status': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsIdGet200Response
     */
    'payment_type': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsIdGet200Response
     */
    'payment_method': string;
    /**
     * 
     * @type {Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>}
     * @memberof V1WebTransactionsIdGet200Response
     */
    'products': Array<V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInner>;
    /**
     * 
     * @type {Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>}
     * @memberof V1WebTransactionsIdGet200Response
     */
    'services': Array<V1PosTransactionsGet200ResponseTransactionsInnerServicesInner>;
    /**
     * 
     * @type {V1CashFlowGet200ResponseTransactionsInnerPosMetadata}
     * @memberof V1WebTransactionsIdGet200Response
     */
    'pos_metadata': V1CashFlowGet200ResponseTransactionsInnerPosMetadata | null;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsIdGet200Response
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsIdGet200Response
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof V1WebTransactionsIdGet200Response
     */
    'pay_at': string | null;
}
/**
 * 
 * @export
 * @interface V2Group
 */
export interface V2Group {
    /**
     * 
     * @type {V2GroupGroup}
     * @memberof V2Group
     */
    'group': V2GroupGroup;
}
/**
 * 
 * @export
 * @interface V2GroupGroup
 */
export interface V2GroupGroup {
    /**
     * 
     * @type {number}
     * @memberof V2GroupGroup
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V2GroupGroup
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V2GroupGroup
     */
    'description': string | null;
    /**
     * 
     * @type {Array<V1OffersGet200ResponseOffersInnerProductsInner>}
     * @memberof V2GroupGroup
     */
    'products': Array<V1OffersGet200ResponseOffersInnerProductsInner>;
}
/**
 * 
 * @export
 * @interface V2OffersProductsGet200Response
 */
export interface V2OffersProductsGet200Response {
    /**
     * 
     * @type {Array<V2OffersProductsGet200ResponseResultsInner>}
     * @memberof V2OffersProductsGet200Response
     */
    'results': Array<V2OffersProductsGet200ResponseResultsInner> | null;
    /**
     * 
     * @type {V1ContactsDebtsTransactionsGet200ResponsePagination}
     * @memberof V2OffersProductsGet200Response
     */
    'pagination': V1ContactsDebtsTransactionsGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V2OffersProductsGet200ResponseResultsInner
 */
export interface V2OffersProductsGet200ResponseResultsInner {
    /**
     * 
     * @type {number}
     * @memberof V2OffersProductsGet200ResponseResultsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V2OffersProductsGet200ResponseResultsInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V2OffersProductsGet200ResponseResultsInner
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof V2OffersProductsGet200ResponseResultsInner
     */
    'cost': string;
    /**
     * 
     * @type {string}
     * @memberof V2OffersProductsGet200ResponseResultsInner
     */
    'price': string;
    /**
     * 
     * @type {boolean}
     * @memberof V2OffersProductsGet200ResponseResultsInner
     */
    'is_avg_cost'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof V2OffersProductsGet200ResponseResultsInner
     */
    'discount_price': string | null;
    /**
     * 
     * @type {number}
     * @memberof V2OffersProductsGet200ResponseResultsInner
     */
    'stock': number;
    /**
     * 
     * @type {string}
     * @memberof V2OffersProductsGet200ResponseResultsInner
     */
    'barcode': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof V2OffersProductsGet200ResponseResultsInner
     */
    'show_in_store': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V2OffersProductsGet200ResponseResultsInner
     */
    'show_in_pos': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V2OffersProductsGet200ResponseResultsInner
     */
    'has_modifiers': boolean | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor}
     * @memberof V2OffersProductsGet200ResponseResultsInner
     */
    'color': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor}
     * @memberof V2OffersProductsGet200ResponseResultsInner
     */
    'size': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor}
     * @memberof V2OffersProductsGet200ResponseResultsInner
     */
    'style': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor | null;
    /**
     * 
     * @type {Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>}
     * @memberof V2OffersProductsGet200ResponseResultsInner
     */
    'images': Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory}
     * @memberof V2OffersProductsGet200ResponseResultsInner
     */
    'product_category'?: V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory}
     * @memberof V2OffersProductsGet200ResponseResultsInner
     */
    'collection': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory | null;
    /**
     * 
     * @type {string}
     * @memberof V2OffersProductsGet200ResponseResultsInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V2OffersProductsGet200ResponseResultsInner
     */
    'updated_at': string;
    /**
     * 
     * @type {number}
     * @memberof V2OffersProductsGet200ResponseResultsInner
     */
    'product_variants_count'?: number;
}
/**
 * 
 * @export
 * @interface V2Product
 */
export interface V2Product {
    /**
     * 
     * @type {number}
     * @memberof V2Product
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V2Product
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V2Product
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof V2Product
     */
    'cost': string;
    /**
     * 
     * @type {string}
     * @memberof V2Product
     */
    'price': string;
    /**
     * 
     * @type {boolean}
     * @memberof V2Product
     */
    'is_avg_cost'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof V2Product
     */
    'discount_price': string | null;
    /**
     * 
     * @type {number}
     * @memberof V2Product
     */
    'stock': number;
    /**
     * 
     * @type {string}
     * @memberof V2Product
     */
    'barcode': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof V2Product
     */
    'show_in_store': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V2Product
     */
    'show_in_pos': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V2Product
     */
    'has_modifiers': boolean | null;
    /**
     * 
     * @type {Array<V1OffersGet200ResponseOffersInnerProductsInnerModifiersInner>}
     * @memberof V2Product
     */
    'modifiers'?: Array<V1OffersGet200ResponseOffersInnerProductsInnerModifiersInner> | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor}
     * @memberof V2Product
     */
    'color': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor}
     * @memberof V2Product
     */
    'size': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor}
     * @memberof V2Product
     */
    'style': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor | null;
    /**
     * 
     * @type {Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>}
     * @memberof V2Product
     */
    'images': Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory}
     * @memberof V2Product
     */
    'product_category'?: V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory}
     * @memberof V2Product
     */
    'collection': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory | null;
    /**
     * 
     * @type {string}
     * @memberof V2Product
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V2Product
     */
    'updated_at': string;
    /**
     * 
     * @type {number}
     * @memberof V2Product
     */
    'product_variants_count'?: number;
}
/**
 * 
 * @export
 * @interface V2ProductsPosGet200Response
 */
export interface V2ProductsPosGet200Response {
    /**
     * 
     * @type {string}
     * @memberof V2ProductsPosGet200Response
     */
    'inventory_total': string;
    /**
     * 
     * @type {Array<V2ProductsPosGet200ResponseResultsInner>}
     * @memberof V2ProductsPosGet200Response
     */
    'results': Array<V2ProductsPosGet200ResponseResultsInner> | null;
    /**
     * 
     * @type {V1ContactsDebtsTransactionsGet200ResponsePagination}
     * @memberof V2ProductsPosGet200Response
     */
    'pagination': V1ContactsDebtsTransactionsGet200ResponsePagination;
}
/**
 * 
 * @export
 * @interface V2ProductsPosGet200ResponseResultsInner
 */
export interface V2ProductsPosGet200ResponseResultsInner {
    /**
     * 
     * @type {number}
     * @memberof V2ProductsPosGet200ResponseResultsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V2ProductsPosGet200ResponseResultsInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V2ProductsPosGet200ResponseResultsInner
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof V2ProductsPosGet200ResponseResultsInner
     */
    'cost': string;
    /**
     * 
     * @type {string}
     * @memberof V2ProductsPosGet200ResponseResultsInner
     */
    'price': string;
    /**
     * 
     * @type {boolean}
     * @memberof V2ProductsPosGet200ResponseResultsInner
     */
    'is_avg_cost'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof V2ProductsPosGet200ResponseResultsInner
     */
    'discount_price': string | null;
    /**
     * 
     * @type {number}
     * @memberof V2ProductsPosGet200ResponseResultsInner
     */
    'stock': number;
    /**
     * 
     * @type {string}
     * @memberof V2ProductsPosGet200ResponseResultsInner
     */
    'barcode': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof V2ProductsPosGet200ResponseResultsInner
     */
    'show_in_store': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V2ProductsPosGet200ResponseResultsInner
     */
    'show_in_pos': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V2ProductsPosGet200ResponseResultsInner
     */
    'has_modifiers': boolean | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor}
     * @memberof V2ProductsPosGet200ResponseResultsInner
     */
    'color': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor}
     * @memberof V2ProductsPosGet200ResponseResultsInner
     */
    'size': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor}
     * @memberof V2ProductsPosGet200ResponseResultsInner
     */
    'style': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerColor | null;
    /**
     * 
     * @type {Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>}
     * @memberof V2ProductsPosGet200ResponseResultsInner
     */
    'images': Array<V1ContactsIdProductsGet200ResponseProductsInnerImagesInner>;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory}
     * @memberof V2ProductsPosGet200ResponseResultsInner
     */
    'product_category'?: V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory | null;
    /**
     * 
     * @type {V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory}
     * @memberof V2ProductsPosGet200ResponseResultsInner
     */
    'collection': V1ExpensesTransactionsGet200ResponseTransactionsInnerProductsInnerProductCategory | null;
    /**
     * 
     * @type {number}
     * @memberof V2ProductsPosGet200ResponseResultsInner
     */
    'product_variants_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof V2ProductsPosGet200ResponseResultsInner
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof V2ProductsPosGet200ResponseResultsInner
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface V2ShopifyTokenOrganizationsGet200Response
 */
export interface V2ShopifyTokenOrganizationsGet200Response {
    /**
     * 
     * @type {Array<V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInner>}
     * @memberof V2ShopifyTokenOrganizationsGet200Response
     */
    'organizations': Array<V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInner>;
}
/**
 * 
 * @export
 * @interface V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInner
 */
export interface V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInner {
    /**
     * 
     * @type {number}
     * @memberof V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInner
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInner
     */
    'money_symbol': V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInnerMoneySymbolEnum;
    /**
     * 
     * @type {string}
     * @memberof V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInner
     */
    'industry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInner
     */
    'business_type': V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInnerBusinessTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInner
     */
    'avatar'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInner
     */
    'custom_image'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInner
     */
    'primary_color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInner
     */
    'secondary_color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInner
     */
    'thank_you_message'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInner
     */
    'unified_transactions'?: boolean;
    /**
     * 
     * @type {V1OrganizationsPatch201ResponseCountry}
     * @memberof V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInner
     */
    'country'?: V1OrganizationsPatch201ResponseCountry | null;
}

export const V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInnerMoneySymbolEnum = {
    Dot: 'dot',
    Comma: 'comma'
} as const;

export type V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInnerMoneySymbolEnum = typeof V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInnerMoneySymbolEnum[keyof typeof V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInnerMoneySymbolEnum];
export const V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInnerBusinessTypeEnum = {
    Person: 'person',
    Business: 'business'
} as const;

export type V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInnerBusinessTypeEnum = typeof V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInnerBusinessTypeEnum[keyof typeof V2ShopifyTokenOrganizationsGet200ResponseOrganizationsInnerBusinessTypeEnum];

/**
 * 
 * @export
 * @interface V2ShopifyTokenSalesTransactionsPostRequest
 */
export interface V2ShopifyTokenSalesTransactionsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof V2ShopifyTokenSalesTransactionsPostRequest
     */
    'organization_id': number;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof V2ShopifyTokenSalesTransactionsPostRequest
     */
    'payload': { [key: string]: any; };
}

/**
 * ApiKeysApi - axios parameter creator
 * @export
 */
export const ApiKeysApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary api_keys#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ApiKeysGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/api_keys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api_keys#create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ApiKeysPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/api_keys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api_keys#destroy
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ApiKeysTokenDelete: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('v1ApiKeysTokenDelete', 'token', token)
            const localVarPath = `/v1/api_keys/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiKeysApi - functional programming interface
 * @export
 */
export const ApiKeysApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiKeysApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary api_keys#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ApiKeysGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ApiKeysGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ApiKeysGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary api_keys#create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ApiKeysPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ApiKeysGet200ResponseApiKeysInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ApiKeysPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary api_keys#destroy
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ApiKeysTokenDelete(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ApiKeysTokenDelete(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiKeysApi - factory interface
 * @export
 */
export const ApiKeysApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiKeysApiFp(configuration)
    return {
        /**
         * 
         * @summary api_keys#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ApiKeysGet(options?: any): AxiosPromise<V1ApiKeysGet200Response> {
            return localVarFp.v1ApiKeysGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api_keys#create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ApiKeysPost(options?: any): AxiosPromise<V1ApiKeysGet200ResponseApiKeysInner> {
            return localVarFp.v1ApiKeysPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api_keys#destroy
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ApiKeysTokenDelete(token: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1ApiKeysTokenDelete(token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1ApiKeysTokenDelete operation in ApiKeysApi.
 * @export
 * @interface ApiKeysApiV1ApiKeysTokenDeleteRequest
 */
export interface ApiKeysApiV1ApiKeysTokenDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiKeysApiV1ApiKeysTokenDelete
     */
    readonly token: string
}

/**
 * ApiKeysApi - object-oriented interface
 * @export
 * @class ApiKeysApi
 * @extends {BaseAPI}
 */
export class ApiKeysApi extends BaseAPI {
    /**
     * 
     * @summary api_keys#index
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApi
     */
    public v1ApiKeysGet(options?: AxiosRequestConfig) {
        return ApiKeysApiFp(this.configuration).v1ApiKeysGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary api_keys#create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApi
     */
    public v1ApiKeysPost(options?: AxiosRequestConfig) {
        return ApiKeysApiFp(this.configuration).v1ApiKeysPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary api_keys#destroy
     * @param {ApiKeysApiV1ApiKeysTokenDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeysApi
     */
    public v1ApiKeysTokenDelete(requestParameters: ApiKeysApiV1ApiKeysTokenDeleteRequest, options?: AxiosRequestConfig) {
        return ApiKeysApiFp(this.configuration).v1ApiKeysTokenDelete(requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CabalCardsApi - axios parameter creator
 * @export
 */
export const CabalCardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary cabal_cards#activate
         * @param {V1CabalCardsActivatePostRequest} [v1CabalCardsActivatePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsActivatePost: async (v1CabalCardsActivatePostRequest?: V1CabalCardsActivatePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/cabal_cards/activate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1CabalCardsActivatePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary cabal_cards#block_reasons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsBlockReasonsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/cabal_cards/block_reasons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary cabal_cards#enabled_phones
         * @param {V1CabalCardsEnabledPhonesPostRequest} [v1CabalCardsEnabledPhonesPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsEnabledPhonesPost: async (v1CabalCardsEnabledPhonesPostRequest?: V1CabalCardsEnabledPhonesPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/cabal_cards/enabled_phones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1CabalCardsEnabledPhonesPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary cabal_cards#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/cabal_cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary cabal_cards#block
         * @param {string} id 
         * @param {V1CabalCardsIdBlockPostRequest} [v1CabalCardsIdBlockPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsIdBlockPost: async (id: string, v1CabalCardsIdBlockPostRequest?: V1CabalCardsIdBlockPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1CabalCardsIdBlockPost', 'id', id)
            const localVarPath = `/v1/cabal_cards/{id}/block`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1CabalCardsIdBlockPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary cabal_cards#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1CabalCardsIdGet', 'id', id)
            const localVarPath = `/v1/cabal_cards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary cabal_cards#reassign
         * @param {string} id 
         * @param {V1CabalCardsIdReassignPatchRequest} [v1CabalCardsIdReassignPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsIdReassignPatch: async (id: string, v1CabalCardsIdReassignPatchRequest?: V1CabalCardsIdReassignPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1CabalCardsIdReassignPatch', 'id', id)
            const localVarPath = `/v1/cabal_cards/{id}/reassign`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1CabalCardsIdReassignPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary cabal_cards#recharge_balance
         * @param {string} id 
         * @param {V1CabalCardsIdRechargeBalancePostRequest} [v1CabalCardsIdRechargeBalancePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsIdRechargeBalancePost: async (id: string, v1CabalCardsIdRechargeBalancePostRequest?: V1CabalCardsIdRechargeBalancePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1CabalCardsIdRechargeBalancePost', 'id', id)
            const localVarPath = `/v1/cabal_cards/{id}/recharge-balance`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1CabalCardsIdRechargeBalancePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary cabal_cards#transactions
         * @param {string} id 
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsIdTransactionsGet: async (id: string, from?: string, until?: string, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1CabalCardsIdTransactionsGet', 'id', id)
            const localVarPath = `/v1/cabal_cards/{id}/transactions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary cabal_cards#transfer
         * @param {string} id 
         * @param {V1CabalCardsIdTransferPatchRequest} [v1CabalCardsIdTransferPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsIdTransferPatch: async (id: string, v1CabalCardsIdTransferPatchRequest?: V1CabalCardsIdTransferPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1CabalCardsIdTransferPatch', 'id', id)
            const localVarPath = `/v1/cabal_cards/{id}/transfer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1CabalCardsIdTransferPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary cabal_cards#unlock
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsIdUnlockPatch: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1CabalCardsIdUnlockPatch', 'id', id)
            const localVarPath = `/v1/cabal_cards/{id}/unlock`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary cabal_cards#unmask_number
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsIdUnmaskNumberGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1CabalCardsIdUnmaskNumberGet', 'id', id)
            const localVarPath = `/v1/cabal_cards/{id}/unmask_number`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary cabal_cards#identity_document
         * @param {File} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsIdentityDocumentPost: async (document: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'document' is not null or undefined
            assertParamExists('v1CabalCardsIdentityDocumentPost', 'document', document)
            const localVarPath = `/v1/cabal_cards/identity_document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


            if (document !== undefined) { 
                localVarFormParams.append('document', document as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary cabal_cards#onboarding
         * @param {V1CabalCardsOnboardingPostRequest} [v1CabalCardsOnboardingPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsOnboardingPost: async (v1CabalCardsOnboardingPostRequest?: V1CabalCardsOnboardingPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/cabal_cards/onboarding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1CabalCardsOnboardingPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary cabal_cards#selfie
         * @param {File} selfie 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsSelfiePost: async (selfie: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'selfie' is not null or undefined
            assertParamExists('v1CabalCardsSelfiePost', 'selfie', selfie)
            const localVarPath = `/v1/cabal_cards/selfie`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


            if (selfie !== undefined) { 
                localVarFormParams.append('selfie', selfie as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CabalCardsApi - functional programming interface
 * @export
 */
export const CabalCardsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CabalCardsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary cabal_cards#activate
         * @param {V1CabalCardsActivatePostRequest} [v1CabalCardsActivatePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CabalCardsActivatePost(v1CabalCardsActivatePostRequest?: V1CabalCardsActivatePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1CabalCardsActivatePost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CabalCardsActivatePost(v1CabalCardsActivatePostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary cabal_cards#block_reasons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CabalCardsBlockReasonsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CabalCardsBlockReasonsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary cabal_cards#enabled_phones
         * @param {V1CabalCardsEnabledPhonesPostRequest} [v1CabalCardsEnabledPhonesPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CabalCardsEnabledPhonesPost(v1CabalCardsEnabledPhonesPostRequest?: V1CabalCardsEnabledPhonesPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1CabalCardsEnabledPhonesPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CabalCardsEnabledPhonesPost(v1CabalCardsEnabledPhonesPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary cabal_cards#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CabalCardsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1CabalCardsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CabalCardsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary cabal_cards#block
         * @param {string} id 
         * @param {V1CabalCardsIdBlockPostRequest} [v1CabalCardsIdBlockPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CabalCardsIdBlockPost(id: string, v1CabalCardsIdBlockPostRequest?: V1CabalCardsIdBlockPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1CabalCardsIdBlockPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CabalCardsIdBlockPost(id, v1CabalCardsIdBlockPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary cabal_cards#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CabalCardsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1CabalCardsIdGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CabalCardsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary cabal_cards#reassign
         * @param {string} id 
         * @param {V1CabalCardsIdReassignPatchRequest} [v1CabalCardsIdReassignPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CabalCardsIdReassignPatch(id: string, v1CabalCardsIdReassignPatchRequest?: V1CabalCardsIdReassignPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1CabalCardsActivatePost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CabalCardsIdReassignPatch(id, v1CabalCardsIdReassignPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary cabal_cards#recharge_balance
         * @param {string} id 
         * @param {V1CabalCardsIdRechargeBalancePostRequest} [v1CabalCardsIdRechargeBalancePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CabalCardsIdRechargeBalancePost(id: string, v1CabalCardsIdRechargeBalancePostRequest?: V1CabalCardsIdRechargeBalancePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1CabalCardsIdRechargeBalancePost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CabalCardsIdRechargeBalancePost(id, v1CabalCardsIdRechargeBalancePostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary cabal_cards#transactions
         * @param {string} id 
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CabalCardsIdTransactionsGet(id: string, from?: string, until?: string, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1CabalCardsIdTransactionsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CabalCardsIdTransactionsGet(id, from, until, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary cabal_cards#transfer
         * @param {string} id 
         * @param {V1CabalCardsIdTransferPatchRequest} [v1CabalCardsIdTransferPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CabalCardsIdTransferPatch(id: string, v1CabalCardsIdTransferPatchRequest?: V1CabalCardsIdTransferPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1CabalCardsIdTransferPatch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CabalCardsIdTransferPatch(id, v1CabalCardsIdTransferPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary cabal_cards#unlock
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CabalCardsIdUnlockPatch(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1CabalCardsIdUnlockPatch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CabalCardsIdUnlockPatch(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary cabal_cards#unmask_number
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CabalCardsIdUnmaskNumberGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1CabalCardsIdUnmaskNumberGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CabalCardsIdUnmaskNumberGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary cabal_cards#identity_document
         * @param {File} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CabalCardsIdentityDocumentPost(document: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1CabalCardsIdentityDocumentPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CabalCardsIdentityDocumentPost(document, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary cabal_cards#onboarding
         * @param {V1CabalCardsOnboardingPostRequest} [v1CabalCardsOnboardingPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CabalCardsOnboardingPost(v1CabalCardsOnboardingPostRequest?: V1CabalCardsOnboardingPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1CabalCardsActivatePost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CabalCardsOnboardingPost(v1CabalCardsOnboardingPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary cabal_cards#selfie
         * @param {File} selfie 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CabalCardsSelfiePost(selfie: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1CabalCardsSelfiePost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CabalCardsSelfiePost(selfie, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CabalCardsApi - factory interface
 * @export
 */
export const CabalCardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CabalCardsApiFp(configuration)
    return {
        /**
         * 
         * @summary cabal_cards#activate
         * @param {V1CabalCardsActivatePostRequest} [v1CabalCardsActivatePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsActivatePost(v1CabalCardsActivatePostRequest?: V1CabalCardsActivatePostRequest, options?: any): AxiosPromise<V1CabalCardsActivatePost200Response> {
            return localVarFp.v1CabalCardsActivatePost(v1CabalCardsActivatePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary cabal_cards#block_reasons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsBlockReasonsGet(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.v1CabalCardsBlockReasonsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary cabal_cards#enabled_phones
         * @param {V1CabalCardsEnabledPhonesPostRequest} [v1CabalCardsEnabledPhonesPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsEnabledPhonesPost(v1CabalCardsEnabledPhonesPostRequest?: V1CabalCardsEnabledPhonesPostRequest, options?: any): AxiosPromise<V1CabalCardsEnabledPhonesPost200Response> {
            return localVarFp.v1CabalCardsEnabledPhonesPost(v1CabalCardsEnabledPhonesPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary cabal_cards#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsGet(options?: any): AxiosPromise<V1CabalCardsGet200Response> {
            return localVarFp.v1CabalCardsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary cabal_cards#block
         * @param {string} id 
         * @param {V1CabalCardsIdBlockPostRequest} [v1CabalCardsIdBlockPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsIdBlockPost(id: string, v1CabalCardsIdBlockPostRequest?: V1CabalCardsIdBlockPostRequest, options?: any): AxiosPromise<V1CabalCardsIdBlockPost200Response> {
            return localVarFp.v1CabalCardsIdBlockPost(id, v1CabalCardsIdBlockPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary cabal_cards#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsIdGet(id: string, options?: any): AxiosPromise<V1CabalCardsIdGet200Response> {
            return localVarFp.v1CabalCardsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary cabal_cards#reassign
         * @param {string} id 
         * @param {V1CabalCardsIdReassignPatchRequest} [v1CabalCardsIdReassignPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsIdReassignPatch(id: string, v1CabalCardsIdReassignPatchRequest?: V1CabalCardsIdReassignPatchRequest, options?: any): AxiosPromise<V1CabalCardsActivatePost200Response> {
            return localVarFp.v1CabalCardsIdReassignPatch(id, v1CabalCardsIdReassignPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary cabal_cards#recharge_balance
         * @param {string} id 
         * @param {V1CabalCardsIdRechargeBalancePostRequest} [v1CabalCardsIdRechargeBalancePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsIdRechargeBalancePost(id: string, v1CabalCardsIdRechargeBalancePostRequest?: V1CabalCardsIdRechargeBalancePostRequest, options?: any): AxiosPromise<V1CabalCardsIdRechargeBalancePost200Response> {
            return localVarFp.v1CabalCardsIdRechargeBalancePost(id, v1CabalCardsIdRechargeBalancePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary cabal_cards#transactions
         * @param {string} id 
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsIdTransactionsGet(id: string, from?: string, until?: string, page?: number, options?: any): AxiosPromise<V1CabalCardsIdTransactionsGet200Response> {
            return localVarFp.v1CabalCardsIdTransactionsGet(id, from, until, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary cabal_cards#transfer
         * @param {string} id 
         * @param {V1CabalCardsIdTransferPatchRequest} [v1CabalCardsIdTransferPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsIdTransferPatch(id: string, v1CabalCardsIdTransferPatchRequest?: V1CabalCardsIdTransferPatchRequest, options?: any): AxiosPromise<V1CabalCardsIdTransferPatch200Response> {
            return localVarFp.v1CabalCardsIdTransferPatch(id, v1CabalCardsIdTransferPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary cabal_cards#unlock
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsIdUnlockPatch(id: string, options?: any): AxiosPromise<V1CabalCardsIdUnlockPatch200Response> {
            return localVarFp.v1CabalCardsIdUnlockPatch(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary cabal_cards#unmask_number
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsIdUnmaskNumberGet(id: string, options?: any): AxiosPromise<V1CabalCardsIdUnmaskNumberGet200Response> {
            return localVarFp.v1CabalCardsIdUnmaskNumberGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary cabal_cards#identity_document
         * @param {File} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsIdentityDocumentPost(document: File, options?: any): AxiosPromise<V1CabalCardsIdentityDocumentPost200Response> {
            return localVarFp.v1CabalCardsIdentityDocumentPost(document, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary cabal_cards#onboarding
         * @param {V1CabalCardsOnboardingPostRequest} [v1CabalCardsOnboardingPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsOnboardingPost(v1CabalCardsOnboardingPostRequest?: V1CabalCardsOnboardingPostRequest, options?: any): AxiosPromise<V1CabalCardsActivatePost200Response> {
            return localVarFp.v1CabalCardsOnboardingPost(v1CabalCardsOnboardingPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary cabal_cards#selfie
         * @param {File} selfie 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CabalCardsSelfiePost(selfie: File, options?: any): AxiosPromise<V1CabalCardsSelfiePost200Response> {
            return localVarFp.v1CabalCardsSelfiePost(selfie, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1CabalCardsActivatePost operation in CabalCardsApi.
 * @export
 * @interface CabalCardsApiV1CabalCardsActivatePostRequest
 */
export interface CabalCardsApiV1CabalCardsActivatePostRequest {
    /**
     * 
     * @type {V1CabalCardsActivatePostRequest}
     * @memberof CabalCardsApiV1CabalCardsActivatePost
     */
    readonly v1CabalCardsActivatePostRequest?: V1CabalCardsActivatePostRequest
}

/**
 * Request parameters for v1CabalCardsEnabledPhonesPost operation in CabalCardsApi.
 * @export
 * @interface CabalCardsApiV1CabalCardsEnabledPhonesPostRequest
 */
export interface CabalCardsApiV1CabalCardsEnabledPhonesPostRequest {
    /**
     * 
     * @type {V1CabalCardsEnabledPhonesPostRequest}
     * @memberof CabalCardsApiV1CabalCardsEnabledPhonesPost
     */
    readonly v1CabalCardsEnabledPhonesPostRequest?: V1CabalCardsEnabledPhonesPostRequest
}

/**
 * Request parameters for v1CabalCardsIdBlockPost operation in CabalCardsApi.
 * @export
 * @interface CabalCardsApiV1CabalCardsIdBlockPostRequest
 */
export interface CabalCardsApiV1CabalCardsIdBlockPostRequest {
    /**
     * 
     * @type {string}
     * @memberof CabalCardsApiV1CabalCardsIdBlockPost
     */
    readonly id: string

    /**
     * 
     * @type {V1CabalCardsIdBlockPostRequest}
     * @memberof CabalCardsApiV1CabalCardsIdBlockPost
     */
    readonly v1CabalCardsIdBlockPostRequest?: V1CabalCardsIdBlockPostRequest
}

/**
 * Request parameters for v1CabalCardsIdGet operation in CabalCardsApi.
 * @export
 * @interface CabalCardsApiV1CabalCardsIdGetRequest
 */
export interface CabalCardsApiV1CabalCardsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CabalCardsApiV1CabalCardsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for v1CabalCardsIdReassignPatch operation in CabalCardsApi.
 * @export
 * @interface CabalCardsApiV1CabalCardsIdReassignPatchRequest
 */
export interface CabalCardsApiV1CabalCardsIdReassignPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof CabalCardsApiV1CabalCardsIdReassignPatch
     */
    readonly id: string

    /**
     * 
     * @type {V1CabalCardsIdReassignPatchRequest}
     * @memberof CabalCardsApiV1CabalCardsIdReassignPatch
     */
    readonly v1CabalCardsIdReassignPatchRequest?: V1CabalCardsIdReassignPatchRequest
}

/**
 * Request parameters for v1CabalCardsIdRechargeBalancePost operation in CabalCardsApi.
 * @export
 * @interface CabalCardsApiV1CabalCardsIdRechargeBalancePostRequest
 */
export interface CabalCardsApiV1CabalCardsIdRechargeBalancePostRequest {
    /**
     * 
     * @type {string}
     * @memberof CabalCardsApiV1CabalCardsIdRechargeBalancePost
     */
    readonly id: string

    /**
     * 
     * @type {V1CabalCardsIdRechargeBalancePostRequest}
     * @memberof CabalCardsApiV1CabalCardsIdRechargeBalancePost
     */
    readonly v1CabalCardsIdRechargeBalancePostRequest?: V1CabalCardsIdRechargeBalancePostRequest
}

/**
 * Request parameters for v1CabalCardsIdTransactionsGet operation in CabalCardsApi.
 * @export
 * @interface CabalCardsApiV1CabalCardsIdTransactionsGetRequest
 */
export interface CabalCardsApiV1CabalCardsIdTransactionsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CabalCardsApiV1CabalCardsIdTransactionsGet
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CabalCardsApiV1CabalCardsIdTransactionsGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof CabalCardsApiV1CabalCardsIdTransactionsGet
     */
    readonly until?: string

    /**
     * 
     * @type {number}
     * @memberof CabalCardsApiV1CabalCardsIdTransactionsGet
     */
    readonly page?: number
}

/**
 * Request parameters for v1CabalCardsIdTransferPatch operation in CabalCardsApi.
 * @export
 * @interface CabalCardsApiV1CabalCardsIdTransferPatchRequest
 */
export interface CabalCardsApiV1CabalCardsIdTransferPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof CabalCardsApiV1CabalCardsIdTransferPatch
     */
    readonly id: string

    /**
     * 
     * @type {V1CabalCardsIdTransferPatchRequest}
     * @memberof CabalCardsApiV1CabalCardsIdTransferPatch
     */
    readonly v1CabalCardsIdTransferPatchRequest?: V1CabalCardsIdTransferPatchRequest
}

/**
 * Request parameters for v1CabalCardsIdUnlockPatch operation in CabalCardsApi.
 * @export
 * @interface CabalCardsApiV1CabalCardsIdUnlockPatchRequest
 */
export interface CabalCardsApiV1CabalCardsIdUnlockPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof CabalCardsApiV1CabalCardsIdUnlockPatch
     */
    readonly id: string
}

/**
 * Request parameters for v1CabalCardsIdUnmaskNumberGet operation in CabalCardsApi.
 * @export
 * @interface CabalCardsApiV1CabalCardsIdUnmaskNumberGetRequest
 */
export interface CabalCardsApiV1CabalCardsIdUnmaskNumberGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CabalCardsApiV1CabalCardsIdUnmaskNumberGet
     */
    readonly id: string
}

/**
 * Request parameters for v1CabalCardsIdentityDocumentPost operation in CabalCardsApi.
 * @export
 * @interface CabalCardsApiV1CabalCardsIdentityDocumentPostRequest
 */
export interface CabalCardsApiV1CabalCardsIdentityDocumentPostRequest {
    /**
     * 
     * @type {File}
     * @memberof CabalCardsApiV1CabalCardsIdentityDocumentPost
     */
    readonly document: File
}

/**
 * Request parameters for v1CabalCardsOnboardingPost operation in CabalCardsApi.
 * @export
 * @interface CabalCardsApiV1CabalCardsOnboardingPostRequest
 */
export interface CabalCardsApiV1CabalCardsOnboardingPostRequest {
    /**
     * 
     * @type {V1CabalCardsOnboardingPostRequest}
     * @memberof CabalCardsApiV1CabalCardsOnboardingPost
     */
    readonly v1CabalCardsOnboardingPostRequest?: V1CabalCardsOnboardingPostRequest
}

/**
 * Request parameters for v1CabalCardsSelfiePost operation in CabalCardsApi.
 * @export
 * @interface CabalCardsApiV1CabalCardsSelfiePostRequest
 */
export interface CabalCardsApiV1CabalCardsSelfiePostRequest {
    /**
     * 
     * @type {File}
     * @memberof CabalCardsApiV1CabalCardsSelfiePost
     */
    readonly selfie: File
}

/**
 * CabalCardsApi - object-oriented interface
 * @export
 * @class CabalCardsApi
 * @extends {BaseAPI}
 */
export class CabalCardsApi extends BaseAPI {
    /**
     * 
     * @summary cabal_cards#activate
     * @param {CabalCardsApiV1CabalCardsActivatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CabalCardsApi
     */
    public v1CabalCardsActivatePost(requestParameters: CabalCardsApiV1CabalCardsActivatePostRequest = {}, options?: AxiosRequestConfig) {
        return CabalCardsApiFp(this.configuration).v1CabalCardsActivatePost(requestParameters.v1CabalCardsActivatePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary cabal_cards#block_reasons
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CabalCardsApi
     */
    public v1CabalCardsBlockReasonsGet(options?: AxiosRequestConfig) {
        return CabalCardsApiFp(this.configuration).v1CabalCardsBlockReasonsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary cabal_cards#enabled_phones
     * @param {CabalCardsApiV1CabalCardsEnabledPhonesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CabalCardsApi
     */
    public v1CabalCardsEnabledPhonesPost(requestParameters: CabalCardsApiV1CabalCardsEnabledPhonesPostRequest = {}, options?: AxiosRequestConfig) {
        return CabalCardsApiFp(this.configuration).v1CabalCardsEnabledPhonesPost(requestParameters.v1CabalCardsEnabledPhonesPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary cabal_cards#index
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CabalCardsApi
     */
    public v1CabalCardsGet(options?: AxiosRequestConfig) {
        return CabalCardsApiFp(this.configuration).v1CabalCardsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary cabal_cards#block
     * @param {CabalCardsApiV1CabalCardsIdBlockPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CabalCardsApi
     */
    public v1CabalCardsIdBlockPost(requestParameters: CabalCardsApiV1CabalCardsIdBlockPostRequest, options?: AxiosRequestConfig) {
        return CabalCardsApiFp(this.configuration).v1CabalCardsIdBlockPost(requestParameters.id, requestParameters.v1CabalCardsIdBlockPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary cabal_cards#show
     * @param {CabalCardsApiV1CabalCardsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CabalCardsApi
     */
    public v1CabalCardsIdGet(requestParameters: CabalCardsApiV1CabalCardsIdGetRequest, options?: AxiosRequestConfig) {
        return CabalCardsApiFp(this.configuration).v1CabalCardsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary cabal_cards#reassign
     * @param {CabalCardsApiV1CabalCardsIdReassignPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CabalCardsApi
     */
    public v1CabalCardsIdReassignPatch(requestParameters: CabalCardsApiV1CabalCardsIdReassignPatchRequest, options?: AxiosRequestConfig) {
        return CabalCardsApiFp(this.configuration).v1CabalCardsIdReassignPatch(requestParameters.id, requestParameters.v1CabalCardsIdReassignPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary cabal_cards#recharge_balance
     * @param {CabalCardsApiV1CabalCardsIdRechargeBalancePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CabalCardsApi
     */
    public v1CabalCardsIdRechargeBalancePost(requestParameters: CabalCardsApiV1CabalCardsIdRechargeBalancePostRequest, options?: AxiosRequestConfig) {
        return CabalCardsApiFp(this.configuration).v1CabalCardsIdRechargeBalancePost(requestParameters.id, requestParameters.v1CabalCardsIdRechargeBalancePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary cabal_cards#transactions
     * @param {CabalCardsApiV1CabalCardsIdTransactionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CabalCardsApi
     */
    public v1CabalCardsIdTransactionsGet(requestParameters: CabalCardsApiV1CabalCardsIdTransactionsGetRequest, options?: AxiosRequestConfig) {
        return CabalCardsApiFp(this.configuration).v1CabalCardsIdTransactionsGet(requestParameters.id, requestParameters.from, requestParameters.until, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary cabal_cards#transfer
     * @param {CabalCardsApiV1CabalCardsIdTransferPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CabalCardsApi
     */
    public v1CabalCardsIdTransferPatch(requestParameters: CabalCardsApiV1CabalCardsIdTransferPatchRequest, options?: AxiosRequestConfig) {
        return CabalCardsApiFp(this.configuration).v1CabalCardsIdTransferPatch(requestParameters.id, requestParameters.v1CabalCardsIdTransferPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary cabal_cards#unlock
     * @param {CabalCardsApiV1CabalCardsIdUnlockPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CabalCardsApi
     */
    public v1CabalCardsIdUnlockPatch(requestParameters: CabalCardsApiV1CabalCardsIdUnlockPatchRequest, options?: AxiosRequestConfig) {
        return CabalCardsApiFp(this.configuration).v1CabalCardsIdUnlockPatch(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary cabal_cards#unmask_number
     * @param {CabalCardsApiV1CabalCardsIdUnmaskNumberGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CabalCardsApi
     */
    public v1CabalCardsIdUnmaskNumberGet(requestParameters: CabalCardsApiV1CabalCardsIdUnmaskNumberGetRequest, options?: AxiosRequestConfig) {
        return CabalCardsApiFp(this.configuration).v1CabalCardsIdUnmaskNumberGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary cabal_cards#identity_document
     * @param {CabalCardsApiV1CabalCardsIdentityDocumentPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CabalCardsApi
     */
    public v1CabalCardsIdentityDocumentPost(requestParameters: CabalCardsApiV1CabalCardsIdentityDocumentPostRequest, options?: AxiosRequestConfig) {
        return CabalCardsApiFp(this.configuration).v1CabalCardsIdentityDocumentPost(requestParameters.document, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary cabal_cards#onboarding
     * @param {CabalCardsApiV1CabalCardsOnboardingPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CabalCardsApi
     */
    public v1CabalCardsOnboardingPost(requestParameters: CabalCardsApiV1CabalCardsOnboardingPostRequest = {}, options?: AxiosRequestConfig) {
        return CabalCardsApiFp(this.configuration).v1CabalCardsOnboardingPost(requestParameters.v1CabalCardsOnboardingPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary cabal_cards#selfie
     * @param {CabalCardsApiV1CabalCardsSelfiePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CabalCardsApi
     */
    public v1CabalCardsSelfiePost(requestParameters: CabalCardsApiV1CabalCardsSelfiePostRequest, options?: AxiosRequestConfig) {
        return CabalCardsApiFp(this.configuration).v1CabalCardsSelfiePost(requestParameters.selfie, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CashFlowApi - axios parameter creator
 * @export
 */
export const CashFlowApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary cash_flow#index
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {'sales' | 'expenses' | 'deposit' | 'stock'} [transactionType] 
         * @param {'cash' | 'card' | 'other' | 'bank' | 'on-credit'} [paymentMethod] 
         * @param {number} [userId] 
         * @param {boolean} [recurrent] 
         * @param {number} [page] 
         * @param {string} [ids] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CashFlowGet: async (from?: string, until?: string, transactionType?: 'sales' | 'expenses' | 'deposit' | 'stock', paymentMethod?: 'cash' | 'card' | 'other' | 'bank' | 'on-credit', userId?: number, recurrent?: boolean, page?: number, ids?: string, code?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/cash-flow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }

            if (transactionType !== undefined) {
                localVarQueryParameter['transaction_type'] = transactionType;
            }

            if (paymentMethod !== undefined) {
                localVarQueryParameter['payment_method'] = paymentMethod;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (recurrent !== undefined) {
                localVarQueryParameter['recurrent'] = recurrent;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CashFlowApi - functional programming interface
 * @export
 */
export const CashFlowApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CashFlowApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary cash_flow#index
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {'sales' | 'expenses' | 'deposit' | 'stock'} [transactionType] 
         * @param {'cash' | 'card' | 'other' | 'bank' | 'on-credit'} [paymentMethod] 
         * @param {number} [userId] 
         * @param {boolean} [recurrent] 
         * @param {number} [page] 
         * @param {string} [ids] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CashFlowGet(from?: string, until?: string, transactionType?: 'sales' | 'expenses' | 'deposit' | 'stock', paymentMethod?: 'cash' | 'card' | 'other' | 'bank' | 'on-credit', userId?: number, recurrent?: boolean, page?: number, ids?: string, code?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1CashFlowGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CashFlowGet(from, until, transactionType, paymentMethod, userId, recurrent, page, ids, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CashFlowApi - factory interface
 * @export
 */
export const CashFlowApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CashFlowApiFp(configuration)
    return {
        /**
         * 
         * @summary cash_flow#index
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {'sales' | 'expenses' | 'deposit' | 'stock'} [transactionType] 
         * @param {'cash' | 'card' | 'other' | 'bank' | 'on-credit'} [paymentMethod] 
         * @param {number} [userId] 
         * @param {boolean} [recurrent] 
         * @param {number} [page] 
         * @param {string} [ids] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CashFlowGet(from?: string, until?: string, transactionType?: 'sales' | 'expenses' | 'deposit' | 'stock', paymentMethod?: 'cash' | 'card' | 'other' | 'bank' | 'on-credit', userId?: number, recurrent?: boolean, page?: number, ids?: string, code?: string, options?: any): AxiosPromise<V1CashFlowGet200Response> {
            return localVarFp.v1CashFlowGet(from, until, transactionType, paymentMethod, userId, recurrent, page, ids, code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1CashFlowGet operation in CashFlowApi.
 * @export
 * @interface CashFlowApiV1CashFlowGetRequest
 */
export interface CashFlowApiV1CashFlowGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CashFlowApiV1CashFlowGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof CashFlowApiV1CashFlowGet
     */
    readonly until?: string

    /**
     * 
     * @type {'sales' | 'expenses' | 'deposit' | 'stock'}
     * @memberof CashFlowApiV1CashFlowGet
     */
    readonly transactionType?: 'sales' | 'expenses' | 'deposit' | 'stock'

    /**
     * 
     * @type {'cash' | 'card' | 'other' | 'bank' | 'on-credit'}
     * @memberof CashFlowApiV1CashFlowGet
     */
    readonly paymentMethod?: 'cash' | 'card' | 'other' | 'bank' | 'on-credit'

    /**
     * 
     * @type {number}
     * @memberof CashFlowApiV1CashFlowGet
     */
    readonly userId?: number

    /**
     * 
     * @type {boolean}
     * @memberof CashFlowApiV1CashFlowGet
     */
    readonly recurrent?: boolean

    /**
     * 
     * @type {number}
     * @memberof CashFlowApiV1CashFlowGet
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof CashFlowApiV1CashFlowGet
     */
    readonly ids?: string

    /**
     * 
     * @type {string}
     * @memberof CashFlowApiV1CashFlowGet
     */
    readonly code?: string
}

/**
 * CashFlowApi - object-oriented interface
 * @export
 * @class CashFlowApi
 * @extends {BaseAPI}
 */
export class CashFlowApi extends BaseAPI {
    /**
     * 
     * @summary cash_flow#index
     * @param {CashFlowApiV1CashFlowGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashFlowApi
     */
    public v1CashFlowGet(requestParameters: CashFlowApiV1CashFlowGetRequest = {}, options?: AxiosRequestConfig) {
        return CashFlowApiFp(this.configuration).v1CashFlowGet(requestParameters.from, requestParameters.until, requestParameters.transactionType, requestParameters.paymentMethod, requestParameters.userId, requestParameters.recurrent, requestParameters.page, requestParameters.ids, requestParameters.code, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChangelogsApi - axios parameter creator
 * @export
 */
export const ChangelogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary changelogs#index
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ChangelogsGet: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/changelogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary changelogs#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ChangelogsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ChangelogsIdGet', 'id', id)
            const localVarPath = `/v1/changelogs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChangelogsApi - functional programming interface
 * @export
 */
export const ChangelogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChangelogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary changelogs#index
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ChangelogsGet(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ChangelogsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ChangelogsGet(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary changelogs#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ChangelogsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ChangelogsIdGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ChangelogsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChangelogsApi - factory interface
 * @export
 */
export const ChangelogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChangelogsApiFp(configuration)
    return {
        /**
         * 
         * @summary changelogs#index
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ChangelogsGet(page?: number, options?: any): AxiosPromise<V1ChangelogsGet200Response> {
            return localVarFp.v1ChangelogsGet(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary changelogs#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ChangelogsIdGet(id: string, options?: any): AxiosPromise<V1ChangelogsIdGet200Response> {
            return localVarFp.v1ChangelogsIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1ChangelogsGet operation in ChangelogsApi.
 * @export
 * @interface ChangelogsApiV1ChangelogsGetRequest
 */
export interface ChangelogsApiV1ChangelogsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ChangelogsApiV1ChangelogsGet
     */
    readonly page?: number
}

/**
 * Request parameters for v1ChangelogsIdGet operation in ChangelogsApi.
 * @export
 * @interface ChangelogsApiV1ChangelogsIdGetRequest
 */
export interface ChangelogsApiV1ChangelogsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangelogsApiV1ChangelogsIdGet
     */
    readonly id: string
}

/**
 * ChangelogsApi - object-oriented interface
 * @export
 * @class ChangelogsApi
 * @extends {BaseAPI}
 */
export class ChangelogsApi extends BaseAPI {
    /**
     * 
     * @summary changelogs#index
     * @param {ChangelogsApiV1ChangelogsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangelogsApi
     */
    public v1ChangelogsGet(requestParameters: ChangelogsApiV1ChangelogsGetRequest = {}, options?: AxiosRequestConfig) {
        return ChangelogsApiFp(this.configuration).v1ChangelogsGet(requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary changelogs#show
     * @param {ChangelogsApiV1ChangelogsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangelogsApi
     */
    public v1ChangelogsIdGet(requestParameters: ChangelogsApiV1ChangelogsIdGetRequest, options?: AxiosRequestConfig) {
        return ChangelogsApiFp(this.configuration).v1ChangelogsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChartsApi - axios parameter creator
 * @export
 */
export const ChartsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary charts#index
         * @param {'month' | 'week'} [groupBy] 
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ChartsGet: async (groupBy?: 'month' | 'week', from?: string, until?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/charts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (groupBy !== undefined) {
                localVarQueryParameter['group_by'] = groupBy;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChartsApi - functional programming interface
 * @export
 */
export const ChartsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChartsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary charts#index
         * @param {'month' | 'week'} [groupBy] 
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ChartsGet(groupBy?: 'month' | 'week', from?: string, until?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ChartsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ChartsGet(groupBy, from, until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChartsApi - factory interface
 * @export
 */
export const ChartsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChartsApiFp(configuration)
    return {
        /**
         * 
         * @summary charts#index
         * @param {'month' | 'week'} [groupBy] 
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ChartsGet(groupBy?: 'month' | 'week', from?: string, until?: string, options?: any): AxiosPromise<V1ChartsGet200Response> {
            return localVarFp.v1ChartsGet(groupBy, from, until, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1ChartsGet operation in ChartsApi.
 * @export
 * @interface ChartsApiV1ChartsGetRequest
 */
export interface ChartsApiV1ChartsGetRequest {
    /**
     * 
     * @type {'month' | 'week'}
     * @memberof ChartsApiV1ChartsGet
     */
    readonly groupBy?: 'month' | 'week'

    /**
     * 
     * @type {string}
     * @memberof ChartsApiV1ChartsGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof ChartsApiV1ChartsGet
     */
    readonly until?: string
}

/**
 * ChartsApi - object-oriented interface
 * @export
 * @class ChartsApi
 * @extends {BaseAPI}
 */
export class ChartsApi extends BaseAPI {
    /**
     * 
     * @summary charts#index
     * @param {ChartsApiV1ChartsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartsApi
     */
    public v1ChartsGet(requestParameters: ChartsApiV1ChartsGetRequest = {}, options?: AxiosRequestConfig) {
        return ChartsApiFp(this.configuration).v1ChartsGet(requestParameters.groupBy, requestParameters.from, requestParameters.until, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContactsApi - axios parameter creator
 * @export
 */
export const ContactsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary contacts#index
         * @param {number} [page] 
         * @param {string} [content] The content you want to search. ie: contact name 1, contact name 2
         * @param {string} [order] Order by the field you want. ie: transaction_count,- transaction_count,+ amount,- amount,+ due,- due,+ name,- name,+
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ContactsGet: async (page?: number, content?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary contacts#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ContactsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ContactsIdDelete', 'id', id)
            const localVarPath = `/v1/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary contacts#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ContactsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ContactsIdGet', 'id', id)
            const localVarPath = `/v1/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary contacts#update
         * @param {string} id 
         * @param {V1ContactsGetRequest} [v1ContactsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ContactsIdPatch: async (id: string, v1ContactsGetRequest?: V1ContactsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ContactsIdPatch', 'id', id)
            const localVarPath = `/v1/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1ContactsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary contacts#products
         * @param {string} id 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ContactsIdProductsGet: async (id: string, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ContactsIdProductsGet', 'id', id)
            const localVarPath = `/v1/contacts/{id}/products`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary contacts#transactions
         * @param {string} id 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ContactsIdTransactionsGet: async (id: string, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ContactsIdTransactionsGet', 'id', id)
            const localVarPath = `/v1/contacts/{id}/transactions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary contacts#create
         * @param {V1ContactsGetRequest} [v1ContactsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ContactsPost: async (v1ContactsGetRequest?: V1ContactsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1ContactsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactsApi - functional programming interface
 * @export
 */
export const ContactsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary contacts#index
         * @param {number} [page] 
         * @param {string} [content] The content you want to search. ie: contact name 1, contact name 2
         * @param {string} [order] Order by the field you want. ie: transaction_count,- transaction_count,+ amount,- amount,+ due,- due,+ name,- name,+
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ContactsGet(page?: number, content?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ContactsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ContactsGet(page, content, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary contacts#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ContactsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ContactsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary contacts#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ContactsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ContactsGet200ResponseContactsInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ContactsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary contacts#update
         * @param {string} id 
         * @param {V1ContactsGetRequest} [v1ContactsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ContactsIdPatch(id: string, v1ContactsGetRequest?: V1ContactsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ContactsGet200ResponseContactsInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ContactsIdPatch(id, v1ContactsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary contacts#products
         * @param {string} id 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ContactsIdProductsGet(id: string, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ContactsIdProductsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ContactsIdProductsGet(id, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary contacts#transactions
         * @param {string} id 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ContactsIdTransactionsGet(id: string, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ContactsIdTransactionsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ContactsIdTransactionsGet(id, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary contacts#create
         * @param {V1ContactsGetRequest} [v1ContactsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ContactsPost(v1ContactsGetRequest?: V1ContactsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ContactsGet200ResponseContactsInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ContactsPost(v1ContactsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactsApi - factory interface
 * @export
 */
export const ContactsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactsApiFp(configuration)
    return {
        /**
         * 
         * @summary contacts#index
         * @param {number} [page] 
         * @param {string} [content] The content you want to search. ie: contact name 1, contact name 2
         * @param {string} [order] Order by the field you want. ie: transaction_count,- transaction_count,+ amount,- amount,+ due,- due,+ name,- name,+
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ContactsGet(page?: number, content?: string, order?: string, options?: any): AxiosPromise<V1ContactsGet200Response> {
            return localVarFp.v1ContactsGet(page, content, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary contacts#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ContactsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1ContactsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary contacts#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ContactsIdGet(id: string, options?: any): AxiosPromise<V1ContactsGet200ResponseContactsInner> {
            return localVarFp.v1ContactsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary contacts#update
         * @param {string} id 
         * @param {V1ContactsGetRequest} [v1ContactsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ContactsIdPatch(id: string, v1ContactsGetRequest?: V1ContactsGetRequest, options?: any): AxiosPromise<V1ContactsGet200ResponseContactsInner> {
            return localVarFp.v1ContactsIdPatch(id, v1ContactsGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary contacts#products
         * @param {string} id 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ContactsIdProductsGet(id: string, page?: number, options?: any): AxiosPromise<V1ContactsIdProductsGet200Response> {
            return localVarFp.v1ContactsIdProductsGet(id, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary contacts#transactions
         * @param {string} id 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ContactsIdTransactionsGet(id: string, page?: number, options?: any): AxiosPromise<V1ContactsIdTransactionsGet200Response> {
            return localVarFp.v1ContactsIdTransactionsGet(id, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary contacts#create
         * @param {V1ContactsGetRequest} [v1ContactsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ContactsPost(v1ContactsGetRequest?: V1ContactsGetRequest, options?: any): AxiosPromise<V1ContactsGet200ResponseContactsInner> {
            return localVarFp.v1ContactsPost(v1ContactsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1ContactsGet operation in ContactsApi.
 * @export
 * @interface ContactsApiV1ContactsGetRequest
 */
export interface ContactsApiV1ContactsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ContactsApiV1ContactsGet
     */
    readonly page?: number

    /**
     * The content you want to search. ie: contact name 1, contact name 2
     * @type {string}
     * @memberof ContactsApiV1ContactsGet
     */
    readonly content?: string

    /**
     * Order by the field you want. ie: transaction_count,- transaction_count,+ amount,- amount,+ due,- due,+ name,- name,+
     * @type {string}
     * @memberof ContactsApiV1ContactsGet
     */
    readonly order?: string
}

/**
 * Request parameters for v1ContactsIdDelete operation in ContactsApi.
 * @export
 * @interface ContactsApiV1ContactsIdDeleteRequest
 */
export interface ContactsApiV1ContactsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactsApiV1ContactsIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for v1ContactsIdGet operation in ContactsApi.
 * @export
 * @interface ContactsApiV1ContactsIdGetRequest
 */
export interface ContactsApiV1ContactsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactsApiV1ContactsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for v1ContactsIdPatch operation in ContactsApi.
 * @export
 * @interface ContactsApiV1ContactsIdPatchRequest
 */
export interface ContactsApiV1ContactsIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactsApiV1ContactsIdPatch
     */
    readonly id: string

    /**
     * 
     * @type {V1ContactsGetRequest}
     * @memberof ContactsApiV1ContactsIdPatch
     */
    readonly v1ContactsGetRequest?: V1ContactsGetRequest
}

/**
 * Request parameters for v1ContactsIdProductsGet operation in ContactsApi.
 * @export
 * @interface ContactsApiV1ContactsIdProductsGetRequest
 */
export interface ContactsApiV1ContactsIdProductsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactsApiV1ContactsIdProductsGet
     */
    readonly id: string

    /**
     * 
     * @type {number}
     * @memberof ContactsApiV1ContactsIdProductsGet
     */
    readonly page?: number
}

/**
 * Request parameters for v1ContactsIdTransactionsGet operation in ContactsApi.
 * @export
 * @interface ContactsApiV1ContactsIdTransactionsGetRequest
 */
export interface ContactsApiV1ContactsIdTransactionsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactsApiV1ContactsIdTransactionsGet
     */
    readonly id: string

    /**
     * 
     * @type {number}
     * @memberof ContactsApiV1ContactsIdTransactionsGet
     */
    readonly page?: number
}

/**
 * Request parameters for v1ContactsPost operation in ContactsApi.
 * @export
 * @interface ContactsApiV1ContactsPostRequest
 */
export interface ContactsApiV1ContactsPostRequest {
    /**
     * 
     * @type {V1ContactsGetRequest}
     * @memberof ContactsApiV1ContactsPost
     */
    readonly v1ContactsGetRequest?: V1ContactsGetRequest
}

/**
 * ContactsApi - object-oriented interface
 * @export
 * @class ContactsApi
 * @extends {BaseAPI}
 */
export class ContactsApi extends BaseAPI {
    /**
     * 
     * @summary contacts#index
     * @param {ContactsApiV1ContactsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public v1ContactsGet(requestParameters: ContactsApiV1ContactsGetRequest = {}, options?: AxiosRequestConfig) {
        return ContactsApiFp(this.configuration).v1ContactsGet(requestParameters.page, requestParameters.content, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary contacts#destroy
     * @param {ContactsApiV1ContactsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public v1ContactsIdDelete(requestParameters: ContactsApiV1ContactsIdDeleteRequest, options?: AxiosRequestConfig) {
        return ContactsApiFp(this.configuration).v1ContactsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary contacts#show
     * @param {ContactsApiV1ContactsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public v1ContactsIdGet(requestParameters: ContactsApiV1ContactsIdGetRequest, options?: AxiosRequestConfig) {
        return ContactsApiFp(this.configuration).v1ContactsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary contacts#update
     * @param {ContactsApiV1ContactsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public v1ContactsIdPatch(requestParameters: ContactsApiV1ContactsIdPatchRequest, options?: AxiosRequestConfig) {
        return ContactsApiFp(this.configuration).v1ContactsIdPatch(requestParameters.id, requestParameters.v1ContactsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary contacts#products
     * @param {ContactsApiV1ContactsIdProductsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public v1ContactsIdProductsGet(requestParameters: ContactsApiV1ContactsIdProductsGetRequest, options?: AxiosRequestConfig) {
        return ContactsApiFp(this.configuration).v1ContactsIdProductsGet(requestParameters.id, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary contacts#transactions
     * @param {ContactsApiV1ContactsIdTransactionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public v1ContactsIdTransactionsGet(requestParameters: ContactsApiV1ContactsIdTransactionsGetRequest, options?: AxiosRequestConfig) {
        return ContactsApiFp(this.configuration).v1ContactsIdTransactionsGet(requestParameters.id, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary contacts#create
     * @param {ContactsApiV1ContactsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public v1ContactsPost(requestParameters: ContactsApiV1ContactsPostRequest = {}, options?: AxiosRequestConfig) {
        return ContactsApiFp(this.configuration).v1ContactsPost(requestParameters.v1ContactsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContactsDebtsTransactionsApi - axios parameter creator
 * @export
 */
export const ContactsDebtsTransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary contacts_debts_transactions#index
         * @param {number} [page] 
         * @param {string} [name] Contact name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ContactsDebtsTransactionsGet: async (page?: number, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/contacts-debts-transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary contacts_debts_transactions#show
         * @param {string} id 
         * @param {string} [code] Transaction code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ContactsDebtsTransactionsIdGet: async (id: string, code?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ContactsDebtsTransactionsIdGet', 'id', id)
            const localVarPath = `/v1/contacts-debts-transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactsDebtsTransactionsApi - functional programming interface
 * @export
 */
export const ContactsDebtsTransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactsDebtsTransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary contacts_debts_transactions#index
         * @param {number} [page] 
         * @param {string} [name] Contact name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ContactsDebtsTransactionsGet(page?: number, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ContactsDebtsTransactionsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ContactsDebtsTransactionsGet(page, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary contacts_debts_transactions#show
         * @param {string} id 
         * @param {string} [code] Transaction code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ContactsDebtsTransactionsIdGet(id: string, code?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ContactsDebtsTransactionsIdGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ContactsDebtsTransactionsIdGet(id, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactsDebtsTransactionsApi - factory interface
 * @export
 */
export const ContactsDebtsTransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactsDebtsTransactionsApiFp(configuration)
    return {
        /**
         * 
         * @summary contacts_debts_transactions#index
         * @param {number} [page] 
         * @param {string} [name] Contact name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ContactsDebtsTransactionsGet(page?: number, name?: string, options?: any): AxiosPromise<V1ContactsDebtsTransactionsGet200Response> {
            return localVarFp.v1ContactsDebtsTransactionsGet(page, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary contacts_debts_transactions#show
         * @param {string} id 
         * @param {string} [code] Transaction code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ContactsDebtsTransactionsIdGet(id: string, code?: string, options?: any): AxiosPromise<V1ContactsDebtsTransactionsIdGet200Response> {
            return localVarFp.v1ContactsDebtsTransactionsIdGet(id, code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1ContactsDebtsTransactionsGet operation in ContactsDebtsTransactionsApi.
 * @export
 * @interface ContactsDebtsTransactionsApiV1ContactsDebtsTransactionsGetRequest
 */
export interface ContactsDebtsTransactionsApiV1ContactsDebtsTransactionsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ContactsDebtsTransactionsApiV1ContactsDebtsTransactionsGet
     */
    readonly page?: number

    /**
     * Contact name
     * @type {string}
     * @memberof ContactsDebtsTransactionsApiV1ContactsDebtsTransactionsGet
     */
    readonly name?: string
}

/**
 * Request parameters for v1ContactsDebtsTransactionsIdGet operation in ContactsDebtsTransactionsApi.
 * @export
 * @interface ContactsDebtsTransactionsApiV1ContactsDebtsTransactionsIdGetRequest
 */
export interface ContactsDebtsTransactionsApiV1ContactsDebtsTransactionsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactsDebtsTransactionsApiV1ContactsDebtsTransactionsIdGet
     */
    readonly id: string

    /**
     * Transaction code
     * @type {string}
     * @memberof ContactsDebtsTransactionsApiV1ContactsDebtsTransactionsIdGet
     */
    readonly code?: string
}

/**
 * ContactsDebtsTransactionsApi - object-oriented interface
 * @export
 * @class ContactsDebtsTransactionsApi
 * @extends {BaseAPI}
 */
export class ContactsDebtsTransactionsApi extends BaseAPI {
    /**
     * 
     * @summary contacts_debts_transactions#index
     * @param {ContactsDebtsTransactionsApiV1ContactsDebtsTransactionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsDebtsTransactionsApi
     */
    public v1ContactsDebtsTransactionsGet(requestParameters: ContactsDebtsTransactionsApiV1ContactsDebtsTransactionsGetRequest = {}, options?: AxiosRequestConfig) {
        return ContactsDebtsTransactionsApiFp(this.configuration).v1ContactsDebtsTransactionsGet(requestParameters.page, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary contacts_debts_transactions#show
     * @param {ContactsDebtsTransactionsApiV1ContactsDebtsTransactionsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsDebtsTransactionsApi
     */
    public v1ContactsDebtsTransactionsIdGet(requestParameters: ContactsDebtsTransactionsApiV1ContactsDebtsTransactionsIdGetRequest, options?: AxiosRequestConfig) {
        return ContactsDebtsTransactionsApiFp(this.configuration).v1ContactsDebtsTransactionsIdGet(requestParameters.id, requestParameters.code, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CountriesApi - axios parameter creator
 * @export
 */
export const CountriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary countries#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CountriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountriesApi - functional programming interface
 * @export
 */
export const CountriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CountriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary countries#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CountriesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1CountriesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CountriesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CountriesApi - factory interface
 * @export
 */
export const CountriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CountriesApiFp(configuration)
    return {
        /**
         * 
         * @summary countries#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CountriesGet(options?: any): AxiosPromise<V1CountriesGet200Response> {
            return localVarFp.v1CountriesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CountriesApi - object-oriented interface
 * @export
 * @class CountriesApi
 * @extends {BaseAPI}
 */
export class CountriesApi extends BaseAPI {
    /**
     * 
     * @summary countries#index
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesApi
     */
    public v1CountriesGet(options?: AxiosRequestConfig) {
        return CountriesApiFp(this.configuration).v1CountriesGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CouponsApi - axios parameter creator
 * @export
 */
export const CouponsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary coupons#index
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CouponsGet: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/coupons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary coupons#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CouponsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1CouponsIdDelete', 'id', id)
            const localVarPath = `/v1/coupons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary coupons#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CouponsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1CouponsIdGet', 'id', id)
            const localVarPath = `/v1/coupons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary coupons#update
         * @param {string} id 
         * @param {V1CouponsGetRequest} [v1CouponsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CouponsIdPatch: async (id: string, v1CouponsGetRequest?: V1CouponsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1CouponsIdPatch', 'id', id)
            const localVarPath = `/v1/coupons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1CouponsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary coupons#toggle_active
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CouponsIdToggleActivePatch: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1CouponsIdToggleActivePatch', 'id', id)
            const localVarPath = `/v1/coupons/{id}/toggle-active`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary coupons#create
         * @param {V1CouponsGetRequest} [v1CouponsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CouponsPost: async (v1CouponsGetRequest?: V1CouponsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/coupons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1CouponsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CouponsApi - functional programming interface
 * @export
 */
export const CouponsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CouponsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary coupons#index
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CouponsGet(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1CouponsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CouponsGet(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary coupons#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CouponsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CouponsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary coupons#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CouponsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1CouponsGet200ResponseCouponsInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CouponsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary coupons#update
         * @param {string} id 
         * @param {V1CouponsGetRequest} [v1CouponsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CouponsIdPatch(id: string, v1CouponsGetRequest?: V1CouponsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1CouponsGet200ResponseCouponsInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CouponsIdPatch(id, v1CouponsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary coupons#toggle_active
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CouponsIdToggleActivePatch(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1CouponsGet200ResponseCouponsInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CouponsIdToggleActivePatch(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary coupons#create
         * @param {V1CouponsGetRequest} [v1CouponsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CouponsPost(v1CouponsGetRequest?: V1CouponsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1CouponsGet200ResponseCouponsInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CouponsPost(v1CouponsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CouponsApi - factory interface
 * @export
 */
export const CouponsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CouponsApiFp(configuration)
    return {
        /**
         * 
         * @summary coupons#index
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CouponsGet(page?: number, options?: any): AxiosPromise<V1CouponsGet200Response> {
            return localVarFp.v1CouponsGet(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary coupons#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CouponsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1CouponsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary coupons#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CouponsIdGet(id: string, options?: any): AxiosPromise<V1CouponsGet200ResponseCouponsInner> {
            return localVarFp.v1CouponsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary coupons#update
         * @param {string} id 
         * @param {V1CouponsGetRequest} [v1CouponsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CouponsIdPatch(id: string, v1CouponsGetRequest?: V1CouponsGetRequest, options?: any): AxiosPromise<V1CouponsGet200ResponseCouponsInner> {
            return localVarFp.v1CouponsIdPatch(id, v1CouponsGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary coupons#toggle_active
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CouponsIdToggleActivePatch(id: string, options?: any): AxiosPromise<V1CouponsGet200ResponseCouponsInner> {
            return localVarFp.v1CouponsIdToggleActivePatch(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary coupons#create
         * @param {V1CouponsGetRequest} [v1CouponsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CouponsPost(v1CouponsGetRequest?: V1CouponsGetRequest, options?: any): AxiosPromise<V1CouponsGet200ResponseCouponsInner> {
            return localVarFp.v1CouponsPost(v1CouponsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1CouponsGet operation in CouponsApi.
 * @export
 * @interface CouponsApiV1CouponsGetRequest
 */
export interface CouponsApiV1CouponsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof CouponsApiV1CouponsGet
     */
    readonly page?: number
}

/**
 * Request parameters for v1CouponsIdDelete operation in CouponsApi.
 * @export
 * @interface CouponsApiV1CouponsIdDeleteRequest
 */
export interface CouponsApiV1CouponsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiV1CouponsIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for v1CouponsIdGet operation in CouponsApi.
 * @export
 * @interface CouponsApiV1CouponsIdGetRequest
 */
export interface CouponsApiV1CouponsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiV1CouponsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for v1CouponsIdPatch operation in CouponsApi.
 * @export
 * @interface CouponsApiV1CouponsIdPatchRequest
 */
export interface CouponsApiV1CouponsIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiV1CouponsIdPatch
     */
    readonly id: string

    /**
     * 
     * @type {V1CouponsGetRequest}
     * @memberof CouponsApiV1CouponsIdPatch
     */
    readonly v1CouponsGetRequest?: V1CouponsGetRequest
}

/**
 * Request parameters for v1CouponsIdToggleActivePatch operation in CouponsApi.
 * @export
 * @interface CouponsApiV1CouponsIdToggleActivePatchRequest
 */
export interface CouponsApiV1CouponsIdToggleActivePatchRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiV1CouponsIdToggleActivePatch
     */
    readonly id: string
}

/**
 * Request parameters for v1CouponsPost operation in CouponsApi.
 * @export
 * @interface CouponsApiV1CouponsPostRequest
 */
export interface CouponsApiV1CouponsPostRequest {
    /**
     * 
     * @type {V1CouponsGetRequest}
     * @memberof CouponsApiV1CouponsPost
     */
    readonly v1CouponsGetRequest?: V1CouponsGetRequest
}

/**
 * CouponsApi - object-oriented interface
 * @export
 * @class CouponsApi
 * @extends {BaseAPI}
 */
export class CouponsApi extends BaseAPI {
    /**
     * 
     * @summary coupons#index
     * @param {CouponsApiV1CouponsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public v1CouponsGet(requestParameters: CouponsApiV1CouponsGetRequest = {}, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).v1CouponsGet(requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary coupons#destroy
     * @param {CouponsApiV1CouponsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public v1CouponsIdDelete(requestParameters: CouponsApiV1CouponsIdDeleteRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).v1CouponsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary coupons#show
     * @param {CouponsApiV1CouponsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public v1CouponsIdGet(requestParameters: CouponsApiV1CouponsIdGetRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).v1CouponsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary coupons#update
     * @param {CouponsApiV1CouponsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public v1CouponsIdPatch(requestParameters: CouponsApiV1CouponsIdPatchRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).v1CouponsIdPatch(requestParameters.id, requestParameters.v1CouponsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary coupons#toggle_active
     * @param {CouponsApiV1CouponsIdToggleActivePatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public v1CouponsIdToggleActivePatch(requestParameters: CouponsApiV1CouponsIdToggleActivePatchRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).v1CouponsIdToggleActivePatch(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary coupons#create
     * @param {CouponsApiV1CouponsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public v1CouponsPost(requestParameters: CouponsApiV1CouponsPostRequest = {}, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).v1CouponsPost(requestParameters.v1CouponsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DataImportsApi - axios parameter creator
 * @export
 */
export const DataImportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary data_imports#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DataImportsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/data-imports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary data_imports#create
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DataImportsPost: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('v1DataImportsPost', 'file', file)
            const localVarPath = `/v1/data-imports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataImportsApi - functional programming interface
 * @export
 */
export const DataImportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataImportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary data_imports#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DataImportsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1DataImportsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DataImportsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary data_imports#create
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DataImportsPost(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1DataImportsGet200ResponseDataImportsInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DataImportsPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataImportsApi - factory interface
 * @export
 */
export const DataImportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataImportsApiFp(configuration)
    return {
        /**
         * 
         * @summary data_imports#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DataImportsGet(options?: any): AxiosPromise<V1DataImportsGet200Response> {
            return localVarFp.v1DataImportsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary data_imports#create
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DataImportsPost(file: File, options?: any): AxiosPromise<V1DataImportsGet200ResponseDataImportsInner> {
            return localVarFp.v1DataImportsPost(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1DataImportsPost operation in DataImportsApi.
 * @export
 * @interface DataImportsApiV1DataImportsPostRequest
 */
export interface DataImportsApiV1DataImportsPostRequest {
    /**
     * 
     * @type {File}
     * @memberof DataImportsApiV1DataImportsPost
     */
    readonly file: File
}

/**
 * DataImportsApi - object-oriented interface
 * @export
 * @class DataImportsApi
 * @extends {BaseAPI}
 */
export class DataImportsApi extends BaseAPI {
    /**
     * 
     * @summary data_imports#index
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportsApi
     */
    public v1DataImportsGet(options?: AxiosRequestConfig) {
        return DataImportsApiFp(this.configuration).v1DataImportsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary data_imports#create
     * @param {DataImportsApiV1DataImportsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportsApi
     */
    public v1DataImportsPost(requestParameters: DataImportsApiV1DataImportsPostRequest, options?: AxiosRequestConfig) {
        return DataImportsApiFp(this.configuration).v1DataImportsPost(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DebtTransactionsApi - axios parameter creator
 * @export
 */
export const DebtTransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary debt_transactions#index
         * @param {number} [page] Page number
         * @param {string} [code] Transaction code
         * @param {string} [contactName] Contact name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DebtsTransactionsGet: async (page?: number, code?: string, contactName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/debts-transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (contactName !== undefined) {
                localVarQueryParameter['contact_name'] = contactName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary debt_transactions#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DebtsTransactionsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1DebtsTransactionsIdGet', 'id', id)
            const localVarPath = `/v1/debts-transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DebtTransactionsApi - functional programming interface
 * @export
 */
export const DebtTransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DebtTransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary debt_transactions#index
         * @param {number} [page] Page number
         * @param {string} [code] Transaction code
         * @param {string} [contactName] Contact name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DebtsTransactionsGet(page?: number, code?: string, contactName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1DebtsTransactionsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DebtsTransactionsGet(page, code, contactName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary debt_transactions#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DebtsTransactionsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1DebtsTransactionsIdGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DebtsTransactionsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DebtTransactionsApi - factory interface
 * @export
 */
export const DebtTransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DebtTransactionsApiFp(configuration)
    return {
        /**
         * 
         * @summary debt_transactions#index
         * @param {number} [page] Page number
         * @param {string} [code] Transaction code
         * @param {string} [contactName] Contact name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DebtsTransactionsGet(page?: number, code?: string, contactName?: string, options?: any): AxiosPromise<V1DebtsTransactionsGet200Response> {
            return localVarFp.v1DebtsTransactionsGet(page, code, contactName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary debt_transactions#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DebtsTransactionsIdGet(id: string, options?: any): AxiosPromise<V1DebtsTransactionsIdGet200Response> {
            return localVarFp.v1DebtsTransactionsIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1DebtsTransactionsGet operation in DebtTransactionsApi.
 * @export
 * @interface DebtTransactionsApiV1DebtsTransactionsGetRequest
 */
export interface DebtTransactionsApiV1DebtsTransactionsGetRequest {
    /**
     * Page number
     * @type {number}
     * @memberof DebtTransactionsApiV1DebtsTransactionsGet
     */
    readonly page?: number

    /**
     * Transaction code
     * @type {string}
     * @memberof DebtTransactionsApiV1DebtsTransactionsGet
     */
    readonly code?: string

    /**
     * Contact name
     * @type {string}
     * @memberof DebtTransactionsApiV1DebtsTransactionsGet
     */
    readonly contactName?: string
}

/**
 * Request parameters for v1DebtsTransactionsIdGet operation in DebtTransactionsApi.
 * @export
 * @interface DebtTransactionsApiV1DebtsTransactionsIdGetRequest
 */
export interface DebtTransactionsApiV1DebtsTransactionsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DebtTransactionsApiV1DebtsTransactionsIdGet
     */
    readonly id: string
}

/**
 * DebtTransactionsApi - object-oriented interface
 * @export
 * @class DebtTransactionsApi
 * @extends {BaseAPI}
 */
export class DebtTransactionsApi extends BaseAPI {
    /**
     * 
     * @summary debt_transactions#index
     * @param {DebtTransactionsApiV1DebtsTransactionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebtTransactionsApi
     */
    public v1DebtsTransactionsGet(requestParameters: DebtTransactionsApiV1DebtsTransactionsGetRequest = {}, options?: AxiosRequestConfig) {
        return DebtTransactionsApiFp(this.configuration).v1DebtsTransactionsGet(requestParameters.page, requestParameters.code, requestParameters.contactName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary debt_transactions#show
     * @param {DebtTransactionsApiV1DebtsTransactionsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebtTransactionsApi
     */
    public v1DebtsTransactionsIdGet(requestParameters: DebtTransactionsApiV1DebtsTransactionsIdGetRequest, options?: AxiosRequestConfig) {
        return DebtTransactionsApiFp(this.configuration).v1DebtsTransactionsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeliveryAreasApi - axios parameter creator
 * @export
 */
export const DeliveryAreasApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delivery_areas#index
         * @param {number} [page] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeliveryAreasGet: async (page?: number, content?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/delivery-areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delivery_areas#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeliveryAreasIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1DeliveryAreasIdDelete', 'id', id)
            const localVarPath = `/v1/delivery-areas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delivery_areas#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeliveryAreasIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1DeliveryAreasIdGet', 'id', id)
            const localVarPath = `/v1/delivery-areas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delivery_areas#update
         * @param {string} id 
         * @param {V1DeliveryAreasGetRequest} [v1DeliveryAreasGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeliveryAreasIdPatch: async (id: string, v1DeliveryAreasGetRequest?: V1DeliveryAreasGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1DeliveryAreasIdPatch', 'id', id)
            const localVarPath = `/v1/delivery-areas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1DeliveryAreasGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delivery_areas#create
         * @param {V1DeliveryAreasGetRequest} [v1DeliveryAreasGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeliveryAreasPost: async (v1DeliveryAreasGetRequest?: V1DeliveryAreasGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/delivery-areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1DeliveryAreasGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeliveryAreasApi - functional programming interface
 * @export
 */
export const DeliveryAreasApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeliveryAreasApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary delivery_areas#index
         * @param {number} [page] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DeliveryAreasGet(page?: number, content?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1DeliveryAreasGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DeliveryAreasGet(page, content, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delivery_areas#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DeliveryAreasIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DeliveryAreasIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delivery_areas#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DeliveryAreasIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1DeliveryAreasGet200ResponseDeliveryAreasInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DeliveryAreasIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delivery_areas#update
         * @param {string} id 
         * @param {V1DeliveryAreasGetRequest} [v1DeliveryAreasGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DeliveryAreasIdPatch(id: string, v1DeliveryAreasGetRequest?: V1DeliveryAreasGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1DeliveryAreasGet200ResponseDeliveryAreasInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DeliveryAreasIdPatch(id, v1DeliveryAreasGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delivery_areas#create
         * @param {V1DeliveryAreasGetRequest} [v1DeliveryAreasGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DeliveryAreasPost(v1DeliveryAreasGetRequest?: V1DeliveryAreasGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1DeliveryAreasGet200ResponseDeliveryAreasInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DeliveryAreasPost(v1DeliveryAreasGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeliveryAreasApi - factory interface
 * @export
 */
export const DeliveryAreasApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeliveryAreasApiFp(configuration)
    return {
        /**
         * 
         * @summary delivery_areas#index
         * @param {number} [page] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeliveryAreasGet(page?: number, content?: string, options?: any): AxiosPromise<V1DeliveryAreasGet200Response> {
            return localVarFp.v1DeliveryAreasGet(page, content, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delivery_areas#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeliveryAreasIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1DeliveryAreasIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delivery_areas#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeliveryAreasIdGet(id: string, options?: any): AxiosPromise<V1DeliveryAreasGet200ResponseDeliveryAreasInner> {
            return localVarFp.v1DeliveryAreasIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delivery_areas#update
         * @param {string} id 
         * @param {V1DeliveryAreasGetRequest} [v1DeliveryAreasGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeliveryAreasIdPatch(id: string, v1DeliveryAreasGetRequest?: V1DeliveryAreasGetRequest, options?: any): AxiosPromise<V1DeliveryAreasGet200ResponseDeliveryAreasInner> {
            return localVarFp.v1DeliveryAreasIdPatch(id, v1DeliveryAreasGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delivery_areas#create
         * @param {V1DeliveryAreasGetRequest} [v1DeliveryAreasGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeliveryAreasPost(v1DeliveryAreasGetRequest?: V1DeliveryAreasGetRequest, options?: any): AxiosPromise<V1DeliveryAreasGet200ResponseDeliveryAreasInner> {
            return localVarFp.v1DeliveryAreasPost(v1DeliveryAreasGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1DeliveryAreasGet operation in DeliveryAreasApi.
 * @export
 * @interface DeliveryAreasApiV1DeliveryAreasGetRequest
 */
export interface DeliveryAreasApiV1DeliveryAreasGetRequest {
    /**
     * 
     * @type {number}
     * @memberof DeliveryAreasApiV1DeliveryAreasGet
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof DeliveryAreasApiV1DeliveryAreasGet
     */
    readonly content?: string
}

/**
 * Request parameters for v1DeliveryAreasIdDelete operation in DeliveryAreasApi.
 * @export
 * @interface DeliveryAreasApiV1DeliveryAreasIdDeleteRequest
 */
export interface DeliveryAreasApiV1DeliveryAreasIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DeliveryAreasApiV1DeliveryAreasIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for v1DeliveryAreasIdGet operation in DeliveryAreasApi.
 * @export
 * @interface DeliveryAreasApiV1DeliveryAreasIdGetRequest
 */
export interface DeliveryAreasApiV1DeliveryAreasIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DeliveryAreasApiV1DeliveryAreasIdGet
     */
    readonly id: string
}

/**
 * Request parameters for v1DeliveryAreasIdPatch operation in DeliveryAreasApi.
 * @export
 * @interface DeliveryAreasApiV1DeliveryAreasIdPatchRequest
 */
export interface DeliveryAreasApiV1DeliveryAreasIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof DeliveryAreasApiV1DeliveryAreasIdPatch
     */
    readonly id: string

    /**
     * 
     * @type {V1DeliveryAreasGetRequest}
     * @memberof DeliveryAreasApiV1DeliveryAreasIdPatch
     */
    readonly v1DeliveryAreasGetRequest?: V1DeliveryAreasGetRequest
}

/**
 * Request parameters for v1DeliveryAreasPost operation in DeliveryAreasApi.
 * @export
 * @interface DeliveryAreasApiV1DeliveryAreasPostRequest
 */
export interface DeliveryAreasApiV1DeliveryAreasPostRequest {
    /**
     * 
     * @type {V1DeliveryAreasGetRequest}
     * @memberof DeliveryAreasApiV1DeliveryAreasPost
     */
    readonly v1DeliveryAreasGetRequest?: V1DeliveryAreasGetRequest
}

/**
 * DeliveryAreasApi - object-oriented interface
 * @export
 * @class DeliveryAreasApi
 * @extends {BaseAPI}
 */
export class DeliveryAreasApi extends BaseAPI {
    /**
     * 
     * @summary delivery_areas#index
     * @param {DeliveryAreasApiV1DeliveryAreasGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryAreasApi
     */
    public v1DeliveryAreasGet(requestParameters: DeliveryAreasApiV1DeliveryAreasGetRequest = {}, options?: AxiosRequestConfig) {
        return DeliveryAreasApiFp(this.configuration).v1DeliveryAreasGet(requestParameters.page, requestParameters.content, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delivery_areas#destroy
     * @param {DeliveryAreasApiV1DeliveryAreasIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryAreasApi
     */
    public v1DeliveryAreasIdDelete(requestParameters: DeliveryAreasApiV1DeliveryAreasIdDeleteRequest, options?: AxiosRequestConfig) {
        return DeliveryAreasApiFp(this.configuration).v1DeliveryAreasIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delivery_areas#show
     * @param {DeliveryAreasApiV1DeliveryAreasIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryAreasApi
     */
    public v1DeliveryAreasIdGet(requestParameters: DeliveryAreasApiV1DeliveryAreasIdGetRequest, options?: AxiosRequestConfig) {
        return DeliveryAreasApiFp(this.configuration).v1DeliveryAreasIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delivery_areas#update
     * @param {DeliveryAreasApiV1DeliveryAreasIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryAreasApi
     */
    public v1DeliveryAreasIdPatch(requestParameters: DeliveryAreasApiV1DeliveryAreasIdPatchRequest, options?: AxiosRequestConfig) {
        return DeliveryAreasApiFp(this.configuration).v1DeliveryAreasIdPatch(requestParameters.id, requestParameters.v1DeliveryAreasGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delivery_areas#create
     * @param {DeliveryAreasApiV1DeliveryAreasPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryAreasApi
     */
    public v1DeliveryAreasPost(requestParameters: DeliveryAreasApiV1DeliveryAreasPostRequest = {}, options?: AxiosRequestConfig) {
        return DeliveryAreasApiFp(this.configuration).v1DeliveryAreasPost(requestParameters.v1DeliveryAreasGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DepositsApi - axios parameter creator
 * @export
 */
export const DepositsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deposits#destroy
         * @param {string} debtsTransactionId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DebtsTransactionsDebtsTransactionIdDepositsIdDelete: async (debtsTransactionId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'debtsTransactionId' is not null or undefined
            assertParamExists('v1DebtsTransactionsDebtsTransactionIdDepositsIdDelete', 'debtsTransactionId', debtsTransactionId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1DebtsTransactionsDebtsTransactionIdDepositsIdDelete', 'id', id)
            const localVarPath = `/v1/debts-transactions/{debts_transaction_id}/deposits/{id}`
                .replace(`{${"debts_transaction_id"}}`, encodeURIComponent(String(debtsTransactionId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deposits#create
         * @param {string} debtsTransactionId 
         * @param {V1DebtsTransactionsDebtsTransactionIdDepositsPostRequest} [v1DebtsTransactionsDebtsTransactionIdDepositsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DebtsTransactionsDebtsTransactionIdDepositsPost: async (debtsTransactionId: string, v1DebtsTransactionsDebtsTransactionIdDepositsPostRequest?: V1DebtsTransactionsDebtsTransactionIdDepositsPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'debtsTransactionId' is not null or undefined
            assertParamExists('v1DebtsTransactionsDebtsTransactionIdDepositsPost', 'debtsTransactionId', debtsTransactionId)
            const localVarPath = `/v1/debts-transactions/{debts_transaction_id}/deposits`
                .replace(`{${"debts_transaction_id"}}`, encodeURIComponent(String(debtsTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1DebtsTransactionsDebtsTransactionIdDepositsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepositsApi - functional programming interface
 * @export
 */
export const DepositsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepositsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary deposits#destroy
         * @param {string} debtsTransactionId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DebtsTransactionsDebtsTransactionIdDepositsIdDelete(debtsTransactionId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DebtsTransactionsDebtsTransactionIdDepositsIdDelete(debtsTransactionId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deposits#create
         * @param {string} debtsTransactionId 
         * @param {V1DebtsTransactionsDebtsTransactionIdDepositsPostRequest} [v1DebtsTransactionsDebtsTransactionIdDepositsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DebtsTransactionsDebtsTransactionIdDepositsPost(debtsTransactionId: string, v1DebtsTransactionsDebtsTransactionIdDepositsPostRequest?: V1DebtsTransactionsDebtsTransactionIdDepositsPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1DebtsTransactionsDebtsTransactionIdDepositsPost201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DebtsTransactionsDebtsTransactionIdDepositsPost(debtsTransactionId, v1DebtsTransactionsDebtsTransactionIdDepositsPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DepositsApi - factory interface
 * @export
 */
export const DepositsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepositsApiFp(configuration)
    return {
        /**
         * 
         * @summary deposits#destroy
         * @param {string} debtsTransactionId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DebtsTransactionsDebtsTransactionIdDepositsIdDelete(debtsTransactionId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1DebtsTransactionsDebtsTransactionIdDepositsIdDelete(debtsTransactionId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deposits#create
         * @param {string} debtsTransactionId 
         * @param {V1DebtsTransactionsDebtsTransactionIdDepositsPostRequest} [v1DebtsTransactionsDebtsTransactionIdDepositsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DebtsTransactionsDebtsTransactionIdDepositsPost(debtsTransactionId: string, v1DebtsTransactionsDebtsTransactionIdDepositsPostRequest?: V1DebtsTransactionsDebtsTransactionIdDepositsPostRequest, options?: any): AxiosPromise<V1DebtsTransactionsDebtsTransactionIdDepositsPost201Response> {
            return localVarFp.v1DebtsTransactionsDebtsTransactionIdDepositsPost(debtsTransactionId, v1DebtsTransactionsDebtsTransactionIdDepositsPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1DebtsTransactionsDebtsTransactionIdDepositsIdDelete operation in DepositsApi.
 * @export
 * @interface DepositsApiV1DebtsTransactionsDebtsTransactionIdDepositsIdDeleteRequest
 */
export interface DepositsApiV1DebtsTransactionsDebtsTransactionIdDepositsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DepositsApiV1DebtsTransactionsDebtsTransactionIdDepositsIdDelete
     */
    readonly debtsTransactionId: string

    /**
     * 
     * @type {string}
     * @memberof DepositsApiV1DebtsTransactionsDebtsTransactionIdDepositsIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for v1DebtsTransactionsDebtsTransactionIdDepositsPost operation in DepositsApi.
 * @export
 * @interface DepositsApiV1DebtsTransactionsDebtsTransactionIdDepositsPostRequest
 */
export interface DepositsApiV1DebtsTransactionsDebtsTransactionIdDepositsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DepositsApiV1DebtsTransactionsDebtsTransactionIdDepositsPost
     */
    readonly debtsTransactionId: string

    /**
     * 
     * @type {V1DebtsTransactionsDebtsTransactionIdDepositsPostRequest}
     * @memberof DepositsApiV1DebtsTransactionsDebtsTransactionIdDepositsPost
     */
    readonly v1DebtsTransactionsDebtsTransactionIdDepositsPostRequest?: V1DebtsTransactionsDebtsTransactionIdDepositsPostRequest
}

/**
 * DepositsApi - object-oriented interface
 * @export
 * @class DepositsApi
 * @extends {BaseAPI}
 */
export class DepositsApi extends BaseAPI {
    /**
     * 
     * @summary deposits#destroy
     * @param {DepositsApiV1DebtsTransactionsDebtsTransactionIdDepositsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositsApi
     */
    public v1DebtsTransactionsDebtsTransactionIdDepositsIdDelete(requestParameters: DepositsApiV1DebtsTransactionsDebtsTransactionIdDepositsIdDeleteRequest, options?: AxiosRequestConfig) {
        return DepositsApiFp(this.configuration).v1DebtsTransactionsDebtsTransactionIdDepositsIdDelete(requestParameters.debtsTransactionId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deposits#create
     * @param {DepositsApiV1DebtsTransactionsDebtsTransactionIdDepositsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositsApi
     */
    public v1DebtsTransactionsDebtsTransactionIdDepositsPost(requestParameters: DepositsApiV1DebtsTransactionsDebtsTransactionIdDepositsPostRequest, options?: AxiosRequestConfig) {
        return DepositsApiFp(this.configuration).v1DebtsTransactionsDebtsTransactionIdDepositsPost(requestParameters.debtsTransactionId, requestParameters.v1DebtsTransactionsDebtsTransactionIdDepositsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeviceInfosApi - axios parameter creator
 * @export
 */
export const DeviceInfosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary device_infos#create
         * @param {V1DeviceInfosPostRequest} [v1DeviceInfosPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeviceInfosPost: async (v1DeviceInfosPostRequest?: V1DeviceInfosPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/device-infos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1DeviceInfosPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceInfosApi - functional programming interface
 * @export
 */
export const DeviceInfosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceInfosApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary device_infos#create
         * @param {V1DeviceInfosPostRequest} [v1DeviceInfosPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DeviceInfosPost(v1DeviceInfosPostRequest?: V1DeviceInfosPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DeviceInfosPost(v1DeviceInfosPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviceInfosApi - factory interface
 * @export
 */
export const DeviceInfosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceInfosApiFp(configuration)
    return {
        /**
         * 
         * @summary device_infos#create
         * @param {V1DeviceInfosPostRequest} [v1DeviceInfosPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeviceInfosPost(v1DeviceInfosPostRequest?: V1DeviceInfosPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.v1DeviceInfosPost(v1DeviceInfosPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1DeviceInfosPost operation in DeviceInfosApi.
 * @export
 * @interface DeviceInfosApiV1DeviceInfosPostRequest
 */
export interface DeviceInfosApiV1DeviceInfosPostRequest {
    /**
     * 
     * @type {V1DeviceInfosPostRequest}
     * @memberof DeviceInfosApiV1DeviceInfosPost
     */
    readonly v1DeviceInfosPostRequest?: V1DeviceInfosPostRequest
}

/**
 * DeviceInfosApi - object-oriented interface
 * @export
 * @class DeviceInfosApi
 * @extends {BaseAPI}
 */
export class DeviceInfosApi extends BaseAPI {
    /**
     * 
     * @summary device_infos#create
     * @param {DeviceInfosApiV1DeviceInfosPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceInfosApi
     */
    public v1DeviceInfosPost(requestParameters: DeviceInfosApiV1DeviceInfosPostRequest = {}, options?: AxiosRequestConfig) {
        return DeviceInfosApiFp(this.configuration).v1DeviceInfosPost(requestParameters.v1DeviceInfosPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DirectUploadApi - axios parameter creator
 * @export
 */
export const DirectUploadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary direct_upload#create
         * @param {V1DirectUploadPostRequest} [v1DirectUploadPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DirectUploadPost: async (v1DirectUploadPostRequest?: V1DirectUploadPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/direct-upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1DirectUploadPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DirectUploadApi - functional programming interface
 * @export
 */
export const DirectUploadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DirectUploadApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary direct_upload#create
         * @param {V1DirectUploadPostRequest} [v1DirectUploadPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DirectUploadPost(v1DirectUploadPostRequest?: V1DirectUploadPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1DirectUploadPost201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DirectUploadPost(v1DirectUploadPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DirectUploadApi - factory interface
 * @export
 */
export const DirectUploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DirectUploadApiFp(configuration)
    return {
        /**
         * 
         * @summary direct_upload#create
         * @param {V1DirectUploadPostRequest} [v1DirectUploadPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DirectUploadPost(v1DirectUploadPostRequest?: V1DirectUploadPostRequest, options?: any): AxiosPromise<V1DirectUploadPost201Response> {
            return localVarFp.v1DirectUploadPost(v1DirectUploadPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1DirectUploadPost operation in DirectUploadApi.
 * @export
 * @interface DirectUploadApiV1DirectUploadPostRequest
 */
export interface DirectUploadApiV1DirectUploadPostRequest {
    /**
     * 
     * @type {V1DirectUploadPostRequest}
     * @memberof DirectUploadApiV1DirectUploadPost
     */
    readonly v1DirectUploadPostRequest?: V1DirectUploadPostRequest
}

/**
 * DirectUploadApi - object-oriented interface
 * @export
 * @class DirectUploadApi
 * @extends {BaseAPI}
 */
export class DirectUploadApi extends BaseAPI {
    /**
     * 
     * @summary direct_upload#create
     * @param {DirectUploadApiV1DirectUploadPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectUploadApi
     */
    public v1DirectUploadPost(requestParameters: DirectUploadApiV1DirectUploadPostRequest = {}, options?: AxiosRequestConfig) {
        return DirectUploadApiFp(this.configuration).v1DirectUploadPost(requestParameters.v1DirectUploadPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExpenseTransactionsApi - axios parameter creator
 * @export
 */
export const ExpenseTransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary expense_transactions#index
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExpensesTransactionsGet: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/expenses-transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary expense_transactions#approve
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExpensesTransactionsIdApprovePatch: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ExpensesTransactionsIdApprovePatch', 'id', id)
            const localVarPath = `/v1/expenses-transactions/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary expense_transactions#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExpensesTransactionsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ExpensesTransactionsIdDelete', 'id', id)
            const localVarPath = `/v1/expenses-transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary expense_transactions#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExpensesTransactionsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ExpensesTransactionsIdGet', 'id', id)
            const localVarPath = `/v1/expenses-transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary expense_transactions#update
         * @param {string} id 
         * @param {V1ExpensesTransactionsIdDeleteRequest} [v1ExpensesTransactionsIdDeleteRequest] contact_id is required if payment_type is \&quot;credit\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExpensesTransactionsIdPatch: async (id: string, v1ExpensesTransactionsIdDeleteRequest?: V1ExpensesTransactionsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ExpensesTransactionsIdPatch', 'id', id)
            const localVarPath = `/v1/expenses-transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1ExpensesTransactionsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary expense_transactions#pending_transactions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExpensesTransactionsPendingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/expenses-transactions/pending`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary expense_transactions#create
         * @param {V1ExpensesTransactionsGetRequest} [v1ExpensesTransactionsGetRequest] contact_id is required if payment_type is \&quot;credit\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExpensesTransactionsPost: async (v1ExpensesTransactionsGetRequest?: V1ExpensesTransactionsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/expenses-transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1ExpensesTransactionsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExpenseTransactionsApi - functional programming interface
 * @export
 */
export const ExpenseTransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExpenseTransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary expense_transactions#index
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ExpensesTransactionsGet(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ExpensesTransactionsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ExpensesTransactionsGet(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary expense_transactions#approve
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ExpensesTransactionsIdApprovePatch(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ExpensesTransactionsIdApprovePatch(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary expense_transactions#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ExpensesTransactionsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ExpensesTransactionsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary expense_transactions#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ExpensesTransactionsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ExpensesTransactionsIdDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ExpensesTransactionsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary expense_transactions#update
         * @param {string} id 
         * @param {V1ExpensesTransactionsIdDeleteRequest} [v1ExpensesTransactionsIdDeleteRequest] contact_id is required if payment_type is \&quot;credit\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ExpensesTransactionsIdPatch(id: string, v1ExpensesTransactionsIdDeleteRequest?: V1ExpensesTransactionsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ExpensesTransactionsGet201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ExpensesTransactionsIdPatch(id, v1ExpensesTransactionsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary expense_transactions#pending_transactions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ExpensesTransactionsPendingGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ExpensesTransactionsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ExpensesTransactionsPendingGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary expense_transactions#create
         * @param {V1ExpensesTransactionsGetRequest} [v1ExpensesTransactionsGetRequest] contact_id is required if payment_type is \&quot;credit\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ExpensesTransactionsPost(v1ExpensesTransactionsGetRequest?: V1ExpensesTransactionsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ExpensesTransactionsGet201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ExpensesTransactionsPost(v1ExpensesTransactionsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExpenseTransactionsApi - factory interface
 * @export
 */
export const ExpenseTransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExpenseTransactionsApiFp(configuration)
    return {
        /**
         * 
         * @summary expense_transactions#index
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExpensesTransactionsGet(page?: number, options?: any): AxiosPromise<V1ExpensesTransactionsGet200Response> {
            return localVarFp.v1ExpensesTransactionsGet(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary expense_transactions#approve
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExpensesTransactionsIdApprovePatch(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1ExpensesTransactionsIdApprovePatch(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary expense_transactions#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExpensesTransactionsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1ExpensesTransactionsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary expense_transactions#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExpensesTransactionsIdGet(id: string, options?: any): AxiosPromise<V1ExpensesTransactionsIdDelete200Response> {
            return localVarFp.v1ExpensesTransactionsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary expense_transactions#update
         * @param {string} id 
         * @param {V1ExpensesTransactionsIdDeleteRequest} [v1ExpensesTransactionsIdDeleteRequest] contact_id is required if payment_type is \&quot;credit\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExpensesTransactionsIdPatch(id: string, v1ExpensesTransactionsIdDeleteRequest?: V1ExpensesTransactionsIdDeleteRequest, options?: any): AxiosPromise<V1ExpensesTransactionsGet201Response> {
            return localVarFp.v1ExpensesTransactionsIdPatch(id, v1ExpensesTransactionsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary expense_transactions#pending_transactions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExpensesTransactionsPendingGet(options?: any): AxiosPromise<V1ExpensesTransactionsGet200Response> {
            return localVarFp.v1ExpensesTransactionsPendingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary expense_transactions#create
         * @param {V1ExpensesTransactionsGetRequest} [v1ExpensesTransactionsGetRequest] contact_id is required if payment_type is \&quot;credit\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ExpensesTransactionsPost(v1ExpensesTransactionsGetRequest?: V1ExpensesTransactionsGetRequest, options?: any): AxiosPromise<V1ExpensesTransactionsGet201Response> {
            return localVarFp.v1ExpensesTransactionsPost(v1ExpensesTransactionsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1ExpensesTransactionsGet operation in ExpenseTransactionsApi.
 * @export
 * @interface ExpenseTransactionsApiV1ExpensesTransactionsGetRequest
 */
export interface ExpenseTransactionsApiV1ExpensesTransactionsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExpenseTransactionsApiV1ExpensesTransactionsGet
     */
    readonly page?: number
}

/**
 * Request parameters for v1ExpensesTransactionsIdApprovePatch operation in ExpenseTransactionsApi.
 * @export
 * @interface ExpenseTransactionsApiV1ExpensesTransactionsIdApprovePatchRequest
 */
export interface ExpenseTransactionsApiV1ExpensesTransactionsIdApprovePatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ExpenseTransactionsApiV1ExpensesTransactionsIdApprovePatch
     */
    readonly id: string
}

/**
 * Request parameters for v1ExpensesTransactionsIdDelete operation in ExpenseTransactionsApi.
 * @export
 * @interface ExpenseTransactionsApiV1ExpensesTransactionsIdDeleteRequest
 */
export interface ExpenseTransactionsApiV1ExpensesTransactionsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ExpenseTransactionsApiV1ExpensesTransactionsIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for v1ExpensesTransactionsIdGet operation in ExpenseTransactionsApi.
 * @export
 * @interface ExpenseTransactionsApiV1ExpensesTransactionsIdGetRequest
 */
export interface ExpenseTransactionsApiV1ExpensesTransactionsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ExpenseTransactionsApiV1ExpensesTransactionsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for v1ExpensesTransactionsIdPatch operation in ExpenseTransactionsApi.
 * @export
 * @interface ExpenseTransactionsApiV1ExpensesTransactionsIdPatchRequest
 */
export interface ExpenseTransactionsApiV1ExpensesTransactionsIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ExpenseTransactionsApiV1ExpensesTransactionsIdPatch
     */
    readonly id: string

    /**
     * contact_id is required if payment_type is \&quot;credit\&quot;
     * @type {V1ExpensesTransactionsIdDeleteRequest}
     * @memberof ExpenseTransactionsApiV1ExpensesTransactionsIdPatch
     */
    readonly v1ExpensesTransactionsIdDeleteRequest?: V1ExpensesTransactionsIdDeleteRequest
}

/**
 * Request parameters for v1ExpensesTransactionsPost operation in ExpenseTransactionsApi.
 * @export
 * @interface ExpenseTransactionsApiV1ExpensesTransactionsPostRequest
 */
export interface ExpenseTransactionsApiV1ExpensesTransactionsPostRequest {
    /**
     * contact_id is required if payment_type is \&quot;credit\&quot;
     * @type {V1ExpensesTransactionsGetRequest}
     * @memberof ExpenseTransactionsApiV1ExpensesTransactionsPost
     */
    readonly v1ExpensesTransactionsGetRequest?: V1ExpensesTransactionsGetRequest
}

/**
 * ExpenseTransactionsApi - object-oriented interface
 * @export
 * @class ExpenseTransactionsApi
 * @extends {BaseAPI}
 */
export class ExpenseTransactionsApi extends BaseAPI {
    /**
     * 
     * @summary expense_transactions#index
     * @param {ExpenseTransactionsApiV1ExpensesTransactionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpenseTransactionsApi
     */
    public v1ExpensesTransactionsGet(requestParameters: ExpenseTransactionsApiV1ExpensesTransactionsGetRequest = {}, options?: AxiosRequestConfig) {
        return ExpenseTransactionsApiFp(this.configuration).v1ExpensesTransactionsGet(requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary expense_transactions#approve
     * @param {ExpenseTransactionsApiV1ExpensesTransactionsIdApprovePatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpenseTransactionsApi
     */
    public v1ExpensesTransactionsIdApprovePatch(requestParameters: ExpenseTransactionsApiV1ExpensesTransactionsIdApprovePatchRequest, options?: AxiosRequestConfig) {
        return ExpenseTransactionsApiFp(this.configuration).v1ExpensesTransactionsIdApprovePatch(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary expense_transactions#destroy
     * @param {ExpenseTransactionsApiV1ExpensesTransactionsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpenseTransactionsApi
     */
    public v1ExpensesTransactionsIdDelete(requestParameters: ExpenseTransactionsApiV1ExpensesTransactionsIdDeleteRequest, options?: AxiosRequestConfig) {
        return ExpenseTransactionsApiFp(this.configuration).v1ExpensesTransactionsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary expense_transactions#show
     * @param {ExpenseTransactionsApiV1ExpensesTransactionsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpenseTransactionsApi
     */
    public v1ExpensesTransactionsIdGet(requestParameters: ExpenseTransactionsApiV1ExpensesTransactionsIdGetRequest, options?: AxiosRequestConfig) {
        return ExpenseTransactionsApiFp(this.configuration).v1ExpensesTransactionsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary expense_transactions#update
     * @param {ExpenseTransactionsApiV1ExpensesTransactionsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpenseTransactionsApi
     */
    public v1ExpensesTransactionsIdPatch(requestParameters: ExpenseTransactionsApiV1ExpensesTransactionsIdPatchRequest, options?: AxiosRequestConfig) {
        return ExpenseTransactionsApiFp(this.configuration).v1ExpensesTransactionsIdPatch(requestParameters.id, requestParameters.v1ExpensesTransactionsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary expense_transactions#pending_transactions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpenseTransactionsApi
     */
    public v1ExpensesTransactionsPendingGet(options?: AxiosRequestConfig) {
        return ExpenseTransactionsApiFp(this.configuration).v1ExpensesTransactionsPendingGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary expense_transactions#create
     * @param {ExpenseTransactionsApiV1ExpensesTransactionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpenseTransactionsApi
     */
    public v1ExpensesTransactionsPost(requestParameters: ExpenseTransactionsApiV1ExpensesTransactionsPostRequest = {}, options?: AxiosRequestConfig) {
        return ExpenseTransactionsApiFp(this.configuration).v1ExpensesTransactionsPost(requestParameters.v1ExpensesTransactionsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FCMApi - axios parameter creator
 * @export
 */
export const FCMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary fcm#index
         * @param {string} [screen] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FcmGet: async (screen?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/fcm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (screen !== undefined) {
                localVarQueryParameter['screen'] = screen;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FCMApi - functional programming interface
 * @export
 */
export const FCMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FCMApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary fcm#index
         * @param {string} [screen] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FcmGet(screen?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FcmGet(screen, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FCMApi - factory interface
 * @export
 */
export const FCMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FCMApiFp(configuration)
    return {
        /**
         * 
         * @summary fcm#index
         * @param {string} [screen] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FcmGet(screen?: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1FcmGet(screen, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1FcmGet operation in FCMApi.
 * @export
 * @interface FCMApiV1FcmGetRequest
 */
export interface FCMApiV1FcmGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FCMApiV1FcmGet
     */
    readonly screen?: string
}

/**
 * FCMApi - object-oriented interface
 * @export
 * @class FCMApi
 * @extends {BaseAPI}
 */
export class FCMApi extends BaseAPI {
    /**
     * 
     * @summary fcm#index
     * @param {FCMApiV1FcmGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FCMApi
     */
    public v1FcmGet(requestParameters: FCMApiV1FcmGetRequest = {}, options?: AxiosRequestConfig) {
        return FCMApiFp(this.configuration).v1FcmGet(requestParameters.screen, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FinancialDataApi - axios parameter creator
 * @export
 */
export const FinancialDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary financial_data#assistant
         * @param {'incremental' | 'zero_base'} [salesProyectionType] 
         * @param {'percentage' | 'money'} [salesGrowthType] 
         * @param {number} [salesGrowthAmount] 
         * @param {string} [salesIndividualAmounts] 
         * @param {number} [costPercentage] 
         * @param {'incremental' | 'zero_base'} [growthProyectionType] 
         * @param {'percentage' | 'money'} [expensesGrowthType] 
         * @param {number} [expensesGrowthAmount] 
         * @param {string} [expensesIndividualAmounts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FinancialDataAssistantGet: async (salesProyectionType?: 'incremental' | 'zero_base', salesGrowthType?: 'percentage' | 'money', salesGrowthAmount?: number, salesIndividualAmounts?: string, costPercentage?: number, growthProyectionType?: 'incremental' | 'zero_base', expensesGrowthType?: 'percentage' | 'money', expensesGrowthAmount?: number, expensesIndividualAmounts?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/financial-data/assistant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (salesProyectionType !== undefined) {
                localVarQueryParameter['sales_proyection_type'] = salesProyectionType;
            }

            if (salesGrowthType !== undefined) {
                localVarQueryParameter['sales_growth_type'] = salesGrowthType;
            }

            if (salesGrowthAmount !== undefined) {
                localVarQueryParameter['sales_growth_amount'] = salesGrowthAmount;
            }

            if (salesIndividualAmounts !== undefined) {
                localVarQueryParameter['sales_individual_amounts'] = salesIndividualAmounts;
            }

            if (costPercentage !== undefined) {
                localVarQueryParameter['cost_percentage'] = costPercentage;
            }

            if (growthProyectionType !== undefined) {
                localVarQueryParameter['growth_proyection_type'] = growthProyectionType;
            }

            if (expensesGrowthType !== undefined) {
                localVarQueryParameter['expenses_growth_type'] = expensesGrowthType;
            }

            if (expensesGrowthAmount !== undefined) {
                localVarQueryParameter['expenses_growth_amount'] = expensesGrowthAmount;
            }

            if (expensesIndividualAmounts !== undefined) {
                localVarQueryParameter['expenses_individual_amounts'] = expensesIndividualAmounts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary financial_data#index
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {'day' | 'week' | 'month'} [groupBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FinancialDataGet: async (from?: string, until?: string, groupBy?: 'day' | 'week' | 'month', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/financial-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }

            if (groupBy !== undefined) {
                localVarQueryParameter['group_by'] = groupBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary financial_data#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FinancialDataStatsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/financial-data/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FinancialDataApi - functional programming interface
 * @export
 */
export const FinancialDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FinancialDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary financial_data#assistant
         * @param {'incremental' | 'zero_base'} [salesProyectionType] 
         * @param {'percentage' | 'money'} [salesGrowthType] 
         * @param {number} [salesGrowthAmount] 
         * @param {string} [salesIndividualAmounts] 
         * @param {number} [costPercentage] 
         * @param {'incremental' | 'zero_base'} [growthProyectionType] 
         * @param {'percentage' | 'money'} [expensesGrowthType] 
         * @param {number} [expensesGrowthAmount] 
         * @param {string} [expensesIndividualAmounts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FinancialDataAssistantGet(salesProyectionType?: 'incremental' | 'zero_base', salesGrowthType?: 'percentage' | 'money', salesGrowthAmount?: number, salesIndividualAmounts?: string, costPercentage?: number, growthProyectionType?: 'incremental' | 'zero_base', expensesGrowthType?: 'percentage' | 'money', expensesGrowthAmount?: number, expensesIndividualAmounts?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1FinancialDataAssistantGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FinancialDataAssistantGet(salesProyectionType, salesGrowthType, salesGrowthAmount, salesIndividualAmounts, costPercentage, growthProyectionType, expensesGrowthType, expensesGrowthAmount, expensesIndividualAmounts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary financial_data#index
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {'day' | 'week' | 'month'} [groupBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FinancialDataGet(from?: string, until?: string, groupBy?: 'day' | 'week' | 'month', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1FinancialDataGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FinancialDataGet(from, until, groupBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary financial_data#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1FinancialDataStatsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1FinancialDataStatsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1FinancialDataStatsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FinancialDataApi - factory interface
 * @export
 */
export const FinancialDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FinancialDataApiFp(configuration)
    return {
        /**
         * 
         * @summary financial_data#assistant
         * @param {'incremental' | 'zero_base'} [salesProyectionType] 
         * @param {'percentage' | 'money'} [salesGrowthType] 
         * @param {number} [salesGrowthAmount] 
         * @param {string} [salesIndividualAmounts] 
         * @param {number} [costPercentage] 
         * @param {'incremental' | 'zero_base'} [growthProyectionType] 
         * @param {'percentage' | 'money'} [expensesGrowthType] 
         * @param {number} [expensesGrowthAmount] 
         * @param {string} [expensesIndividualAmounts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FinancialDataAssistantGet(salesProyectionType?: 'incremental' | 'zero_base', salesGrowthType?: 'percentage' | 'money', salesGrowthAmount?: number, salesIndividualAmounts?: string, costPercentage?: number, growthProyectionType?: 'incremental' | 'zero_base', expensesGrowthType?: 'percentage' | 'money', expensesGrowthAmount?: number, expensesIndividualAmounts?: string, options?: any): AxiosPromise<V1FinancialDataAssistantGet200Response> {
            return localVarFp.v1FinancialDataAssistantGet(salesProyectionType, salesGrowthType, salesGrowthAmount, salesIndividualAmounts, costPercentage, growthProyectionType, expensesGrowthType, expensesGrowthAmount, expensesIndividualAmounts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary financial_data#index
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {'day' | 'week' | 'month'} [groupBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FinancialDataGet(from?: string, until?: string, groupBy?: 'day' | 'week' | 'month', options?: any): AxiosPromise<V1FinancialDataGet200Response> {
            return localVarFp.v1FinancialDataGet(from, until, groupBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary financial_data#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1FinancialDataStatsGet(options?: any): AxiosPromise<V1FinancialDataStatsGet200Response> {
            return localVarFp.v1FinancialDataStatsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1FinancialDataAssistantGet operation in FinancialDataApi.
 * @export
 * @interface FinancialDataApiV1FinancialDataAssistantGetRequest
 */
export interface FinancialDataApiV1FinancialDataAssistantGetRequest {
    /**
     * 
     * @type {'incremental' | 'zero_base'}
     * @memberof FinancialDataApiV1FinancialDataAssistantGet
     */
    readonly salesProyectionType?: 'incremental' | 'zero_base'

    /**
     * 
     * @type {'percentage' | 'money'}
     * @memberof FinancialDataApiV1FinancialDataAssistantGet
     */
    readonly salesGrowthType?: 'percentage' | 'money'

    /**
     * 
     * @type {number}
     * @memberof FinancialDataApiV1FinancialDataAssistantGet
     */
    readonly salesGrowthAmount?: number

    /**
     * 
     * @type {string}
     * @memberof FinancialDataApiV1FinancialDataAssistantGet
     */
    readonly salesIndividualAmounts?: string

    /**
     * 
     * @type {number}
     * @memberof FinancialDataApiV1FinancialDataAssistantGet
     */
    readonly costPercentage?: number

    /**
     * 
     * @type {'incremental' | 'zero_base'}
     * @memberof FinancialDataApiV1FinancialDataAssistantGet
     */
    readonly growthProyectionType?: 'incremental' | 'zero_base'

    /**
     * 
     * @type {'percentage' | 'money'}
     * @memberof FinancialDataApiV1FinancialDataAssistantGet
     */
    readonly expensesGrowthType?: 'percentage' | 'money'

    /**
     * 
     * @type {number}
     * @memberof FinancialDataApiV1FinancialDataAssistantGet
     */
    readonly expensesGrowthAmount?: number

    /**
     * 
     * @type {string}
     * @memberof FinancialDataApiV1FinancialDataAssistantGet
     */
    readonly expensesIndividualAmounts?: string
}

/**
 * Request parameters for v1FinancialDataGet operation in FinancialDataApi.
 * @export
 * @interface FinancialDataApiV1FinancialDataGetRequest
 */
export interface FinancialDataApiV1FinancialDataGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FinancialDataApiV1FinancialDataGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof FinancialDataApiV1FinancialDataGet
     */
    readonly until?: string

    /**
     * 
     * @type {'day' | 'week' | 'month'}
     * @memberof FinancialDataApiV1FinancialDataGet
     */
    readonly groupBy?: 'day' | 'week' | 'month'
}

/**
 * FinancialDataApi - object-oriented interface
 * @export
 * @class FinancialDataApi
 * @extends {BaseAPI}
 */
export class FinancialDataApi extends BaseAPI {
    /**
     * 
     * @summary financial_data#assistant
     * @param {FinancialDataApiV1FinancialDataAssistantGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialDataApi
     */
    public v1FinancialDataAssistantGet(requestParameters: FinancialDataApiV1FinancialDataAssistantGetRequest = {}, options?: AxiosRequestConfig) {
        return FinancialDataApiFp(this.configuration).v1FinancialDataAssistantGet(requestParameters.salesProyectionType, requestParameters.salesGrowthType, requestParameters.salesGrowthAmount, requestParameters.salesIndividualAmounts, requestParameters.costPercentage, requestParameters.growthProyectionType, requestParameters.expensesGrowthType, requestParameters.expensesGrowthAmount, requestParameters.expensesIndividualAmounts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary financial_data#index
     * @param {FinancialDataApiV1FinancialDataGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialDataApi
     */
    public v1FinancialDataGet(requestParameters: FinancialDataApiV1FinancialDataGetRequest = {}, options?: AxiosRequestConfig) {
        return FinancialDataApiFp(this.configuration).v1FinancialDataGet(requestParameters.from, requestParameters.until, requestParameters.groupBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary financial_data#index
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialDataApi
     */
    public v1FinancialDataStatsGet(options?: AxiosRequestConfig) {
        return FinancialDataApiFp(this.configuration).v1FinancialDataStatsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GamificationApi - axios parameter creator
 * @export
 */
export const GamificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary gamification#basic-roadmap
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamificationBasicRoadmapGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/gamification/basic-roadmap`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GamificationApi - functional programming interface
 * @export
 */
export const GamificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GamificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary gamification#basic-roadmap
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GamificationBasicRoadmapGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1GamificationBasicRoadmapGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1GamificationBasicRoadmapGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GamificationApi - factory interface
 * @export
 */
export const GamificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GamificationApiFp(configuration)
    return {
        /**
         * 
         * @summary gamification#basic-roadmap
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamificationBasicRoadmapGet(options?: any): AxiosPromise<V1GamificationBasicRoadmapGet200Response> {
            return localVarFp.v1GamificationBasicRoadmapGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GamificationApi - object-oriented interface
 * @export
 * @class GamificationApi
 * @extends {BaseAPI}
 */
export class GamificationApi extends BaseAPI {
    /**
     * 
     * @summary gamification#basic-roadmap
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamificationApi
     */
    public v1GamificationBasicRoadmapGet(options?: AxiosRequestConfig) {
        return GamificationApiFp(this.configuration).v1GamificationBasicRoadmapGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GuestUsersApi - axios parameter creator
 * @export
 */
export const GuestUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary guest_users#pre_validate_subscription
         * @param {UsersPreValidateSubscriptionPostRequest} [usersPreValidateSubscriptionPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPreValidateSubscriptionPost: async (usersPreValidateSubscriptionPostRequest?: UsersPreValidateSubscriptionPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/pre-validate-subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersPreValidateSubscriptionPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary guest_users#redim_appsumo_code
         * @param {UsersRedimAppsumoCodePatchRequest} [usersRedimAppsumoCodePatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersRedimAppsumoCodePatch: async (usersRedimAppsumoCodePatchRequest?: UsersRedimAppsumoCodePatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/redim-appsumo-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersRedimAppsumoCodePatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary guest_users#validate_appsumo_code
         * @param {UsersValidateAppsumoCodePostRequest} [usersValidateAppsumoCodePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersValidateAppsumoCodePost: async (usersValidateAppsumoCodePostRequest?: UsersValidateAppsumoCodePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/validate-appsumo-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersValidateAppsumoCodePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GuestUsersApi - functional programming interface
 * @export
 */
export const GuestUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GuestUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary guest_users#pre_validate_subscription
         * @param {UsersPreValidateSubscriptionPostRequest} [usersPreValidateSubscriptionPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersPreValidateSubscriptionPost(usersPreValidateSubscriptionPostRequest?: UsersPreValidateSubscriptionPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersPreValidateSubscriptionPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersPreValidateSubscriptionPost(usersPreValidateSubscriptionPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary guest_users#redim_appsumo_code
         * @param {UsersRedimAppsumoCodePatchRequest} [usersRedimAppsumoCodePatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersRedimAppsumoCodePatch(usersRedimAppsumoCodePatchRequest?: UsersRedimAppsumoCodePatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersPreValidateSubscriptionPost422Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersRedimAppsumoCodePatch(usersRedimAppsumoCodePatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary guest_users#validate_appsumo_code
         * @param {UsersValidateAppsumoCodePostRequest} [usersValidateAppsumoCodePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersValidateAppsumoCodePost(usersValidateAppsumoCodePostRequest?: UsersValidateAppsumoCodePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersPreValidateSubscriptionPost422Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersValidateAppsumoCodePost(usersValidateAppsumoCodePostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GuestUsersApi - factory interface
 * @export
 */
export const GuestUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GuestUsersApiFp(configuration)
    return {
        /**
         * 
         * @summary guest_users#pre_validate_subscription
         * @param {UsersPreValidateSubscriptionPostRequest} [usersPreValidateSubscriptionPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPreValidateSubscriptionPost(usersPreValidateSubscriptionPostRequest?: UsersPreValidateSubscriptionPostRequest, options?: any): AxiosPromise<UsersPreValidateSubscriptionPost200Response> {
            return localVarFp.usersPreValidateSubscriptionPost(usersPreValidateSubscriptionPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary guest_users#redim_appsumo_code
         * @param {UsersRedimAppsumoCodePatchRequest} [usersRedimAppsumoCodePatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersRedimAppsumoCodePatch(usersRedimAppsumoCodePatchRequest?: UsersRedimAppsumoCodePatchRequest, options?: any): AxiosPromise<UsersPreValidateSubscriptionPost422Response> {
            return localVarFp.usersRedimAppsumoCodePatch(usersRedimAppsumoCodePatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary guest_users#validate_appsumo_code
         * @param {UsersValidateAppsumoCodePostRequest} [usersValidateAppsumoCodePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersValidateAppsumoCodePost(usersValidateAppsumoCodePostRequest?: UsersValidateAppsumoCodePostRequest, options?: any): AxiosPromise<UsersPreValidateSubscriptionPost422Response> {
            return localVarFp.usersValidateAppsumoCodePost(usersValidateAppsumoCodePostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for usersPreValidateSubscriptionPost operation in GuestUsersApi.
 * @export
 * @interface GuestUsersApiUsersPreValidateSubscriptionPostRequest
 */
export interface GuestUsersApiUsersPreValidateSubscriptionPostRequest {
    /**
     * 
     * @type {UsersPreValidateSubscriptionPostRequest}
     * @memberof GuestUsersApiUsersPreValidateSubscriptionPost
     */
    readonly usersPreValidateSubscriptionPostRequest?: UsersPreValidateSubscriptionPostRequest
}

/**
 * Request parameters for usersRedimAppsumoCodePatch operation in GuestUsersApi.
 * @export
 * @interface GuestUsersApiUsersRedimAppsumoCodePatchRequest
 */
export interface GuestUsersApiUsersRedimAppsumoCodePatchRequest {
    /**
     * 
     * @type {UsersRedimAppsumoCodePatchRequest}
     * @memberof GuestUsersApiUsersRedimAppsumoCodePatch
     */
    readonly usersRedimAppsumoCodePatchRequest?: UsersRedimAppsumoCodePatchRequest
}

/**
 * Request parameters for usersValidateAppsumoCodePost operation in GuestUsersApi.
 * @export
 * @interface GuestUsersApiUsersValidateAppsumoCodePostRequest
 */
export interface GuestUsersApiUsersValidateAppsumoCodePostRequest {
    /**
     * 
     * @type {UsersValidateAppsumoCodePostRequest}
     * @memberof GuestUsersApiUsersValidateAppsumoCodePost
     */
    readonly usersValidateAppsumoCodePostRequest?: UsersValidateAppsumoCodePostRequest
}

/**
 * GuestUsersApi - object-oriented interface
 * @export
 * @class GuestUsersApi
 * @extends {BaseAPI}
 */
export class GuestUsersApi extends BaseAPI {
    /**
     * 
     * @summary guest_users#pre_validate_subscription
     * @param {GuestUsersApiUsersPreValidateSubscriptionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuestUsersApi
     */
    public usersPreValidateSubscriptionPost(requestParameters: GuestUsersApiUsersPreValidateSubscriptionPostRequest = {}, options?: AxiosRequestConfig) {
        return GuestUsersApiFp(this.configuration).usersPreValidateSubscriptionPost(requestParameters.usersPreValidateSubscriptionPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary guest_users#redim_appsumo_code
     * @param {GuestUsersApiUsersRedimAppsumoCodePatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuestUsersApi
     */
    public usersRedimAppsumoCodePatch(requestParameters: GuestUsersApiUsersRedimAppsumoCodePatchRequest = {}, options?: AxiosRequestConfig) {
        return GuestUsersApiFp(this.configuration).usersRedimAppsumoCodePatch(requestParameters.usersRedimAppsumoCodePatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary guest_users#validate_appsumo_code
     * @param {GuestUsersApiUsersValidateAppsumoCodePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuestUsersApi
     */
    public usersValidateAppsumoCodePost(requestParameters: GuestUsersApiUsersValidateAppsumoCodePostRequest = {}, options?: AxiosRequestConfig) {
        return GuestUsersApiFp(this.configuration).usersValidateAppsumoCodePost(requestParameters.usersValidateAppsumoCodePostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IndustriesApi - axios parameter creator
 * @export
 */
export const IndustriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary industries#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IndustriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/industries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IndustriesApi - functional programming interface
 * @export
 */
export const IndustriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IndustriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary industries#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1IndustriesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1IndustriesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1IndustriesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IndustriesApi - factory interface
 * @export
 */
export const IndustriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IndustriesApiFp(configuration)
    return {
        /**
         * 
         * @summary industries#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1IndustriesGet(options?: any): AxiosPromise<V1IndustriesGet200Response> {
            return localVarFp.v1IndustriesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IndustriesApi - object-oriented interface
 * @export
 * @class IndustriesApi
 * @extends {BaseAPI}
 */
export class IndustriesApi extends BaseAPI {
    /**
     * 
     * @summary industries#index
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndustriesApi
     */
    public v1IndustriesGet(options?: AxiosRequestConfig) {
        return IndustriesApiFp(this.configuration).v1IndustriesGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InitialBalanceAmountsApi - axios parameter creator
 * @export
 */
export const InitialBalanceAmountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary initial_balance_amounts#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InitialBalanceAmountsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/initial-balance-amounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary initial_balance_amounts#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InitialBalanceAmountsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1InitialBalanceAmountsIdDelete', 'id', id)
            const localVarPath = `/v1/initial-balance-amounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary initial_balance_amounts#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InitialBalanceAmountsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1InitialBalanceAmountsIdGet', 'id', id)
            const localVarPath = `/v1/initial-balance-amounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary initial_balance_amounts#update
         * @param {string} id 
         * @param {V1InitialBalanceAmountsGetRequest} [v1InitialBalanceAmountsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InitialBalanceAmountsIdPatch: async (id: string, v1InitialBalanceAmountsGetRequest?: V1InitialBalanceAmountsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1InitialBalanceAmountsIdPatch', 'id', id)
            const localVarPath = `/v1/initial-balance-amounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1InitialBalanceAmountsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary initial_balance_amounts#create
         * @param {V1InitialBalanceAmountsGetRequest} [v1InitialBalanceAmountsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InitialBalanceAmountsPost: async (v1InitialBalanceAmountsGetRequest?: V1InitialBalanceAmountsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/initial-balance-amounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1InitialBalanceAmountsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InitialBalanceAmountsApi - functional programming interface
 * @export
 */
export const InitialBalanceAmountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InitialBalanceAmountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary initial_balance_amounts#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InitialBalanceAmountsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<V1InitialBalanceAmountsGet200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1InitialBalanceAmountsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary initial_balance_amounts#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InitialBalanceAmountsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1InitialBalanceAmountsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary initial_balance_amounts#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InitialBalanceAmountsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1InitialBalanceAmountsGet200ResponseInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1InitialBalanceAmountsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary initial_balance_amounts#update
         * @param {string} id 
         * @param {V1InitialBalanceAmountsGetRequest} [v1InitialBalanceAmountsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InitialBalanceAmountsIdPatch(id: string, v1InitialBalanceAmountsGetRequest?: V1InitialBalanceAmountsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1InitialBalanceAmountsGet200ResponseInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1InitialBalanceAmountsIdPatch(id, v1InitialBalanceAmountsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary initial_balance_amounts#create
         * @param {V1InitialBalanceAmountsGetRequest} [v1InitialBalanceAmountsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InitialBalanceAmountsPost(v1InitialBalanceAmountsGetRequest?: V1InitialBalanceAmountsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1InitialBalanceAmountsGet200ResponseInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1InitialBalanceAmountsPost(v1InitialBalanceAmountsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InitialBalanceAmountsApi - factory interface
 * @export
 */
export const InitialBalanceAmountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InitialBalanceAmountsApiFp(configuration)
    return {
        /**
         * 
         * @summary initial_balance_amounts#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InitialBalanceAmountsGet(options?: any): AxiosPromise<Array<V1InitialBalanceAmountsGet200ResponseInner>> {
            return localVarFp.v1InitialBalanceAmountsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary initial_balance_amounts#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InitialBalanceAmountsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1InitialBalanceAmountsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary initial_balance_amounts#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InitialBalanceAmountsIdGet(id: string, options?: any): AxiosPromise<V1InitialBalanceAmountsGet200ResponseInner> {
            return localVarFp.v1InitialBalanceAmountsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary initial_balance_amounts#update
         * @param {string} id 
         * @param {V1InitialBalanceAmountsGetRequest} [v1InitialBalanceAmountsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InitialBalanceAmountsIdPatch(id: string, v1InitialBalanceAmountsGetRequest?: V1InitialBalanceAmountsGetRequest, options?: any): AxiosPromise<V1InitialBalanceAmountsGet200ResponseInner> {
            return localVarFp.v1InitialBalanceAmountsIdPatch(id, v1InitialBalanceAmountsGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary initial_balance_amounts#create
         * @param {V1InitialBalanceAmountsGetRequest} [v1InitialBalanceAmountsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InitialBalanceAmountsPost(v1InitialBalanceAmountsGetRequest?: V1InitialBalanceAmountsGetRequest, options?: any): AxiosPromise<V1InitialBalanceAmountsGet200ResponseInner> {
            return localVarFp.v1InitialBalanceAmountsPost(v1InitialBalanceAmountsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1InitialBalanceAmountsIdDelete operation in InitialBalanceAmountsApi.
 * @export
 * @interface InitialBalanceAmountsApiV1InitialBalanceAmountsIdDeleteRequest
 */
export interface InitialBalanceAmountsApiV1InitialBalanceAmountsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof InitialBalanceAmountsApiV1InitialBalanceAmountsIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for v1InitialBalanceAmountsIdGet operation in InitialBalanceAmountsApi.
 * @export
 * @interface InitialBalanceAmountsApiV1InitialBalanceAmountsIdGetRequest
 */
export interface InitialBalanceAmountsApiV1InitialBalanceAmountsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof InitialBalanceAmountsApiV1InitialBalanceAmountsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for v1InitialBalanceAmountsIdPatch operation in InitialBalanceAmountsApi.
 * @export
 * @interface InitialBalanceAmountsApiV1InitialBalanceAmountsIdPatchRequest
 */
export interface InitialBalanceAmountsApiV1InitialBalanceAmountsIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof InitialBalanceAmountsApiV1InitialBalanceAmountsIdPatch
     */
    readonly id: string

    /**
     * 
     * @type {V1InitialBalanceAmountsGetRequest}
     * @memberof InitialBalanceAmountsApiV1InitialBalanceAmountsIdPatch
     */
    readonly v1InitialBalanceAmountsGetRequest?: V1InitialBalanceAmountsGetRequest
}

/**
 * Request parameters for v1InitialBalanceAmountsPost operation in InitialBalanceAmountsApi.
 * @export
 * @interface InitialBalanceAmountsApiV1InitialBalanceAmountsPostRequest
 */
export interface InitialBalanceAmountsApiV1InitialBalanceAmountsPostRequest {
    /**
     * 
     * @type {V1InitialBalanceAmountsGetRequest}
     * @memberof InitialBalanceAmountsApiV1InitialBalanceAmountsPost
     */
    readonly v1InitialBalanceAmountsGetRequest?: V1InitialBalanceAmountsGetRequest
}

/**
 * InitialBalanceAmountsApi - object-oriented interface
 * @export
 * @class InitialBalanceAmountsApi
 * @extends {BaseAPI}
 */
export class InitialBalanceAmountsApi extends BaseAPI {
    /**
     * 
     * @summary initial_balance_amounts#index
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InitialBalanceAmountsApi
     */
    public v1InitialBalanceAmountsGet(options?: AxiosRequestConfig) {
        return InitialBalanceAmountsApiFp(this.configuration).v1InitialBalanceAmountsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary initial_balance_amounts#destroy
     * @param {InitialBalanceAmountsApiV1InitialBalanceAmountsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InitialBalanceAmountsApi
     */
    public v1InitialBalanceAmountsIdDelete(requestParameters: InitialBalanceAmountsApiV1InitialBalanceAmountsIdDeleteRequest, options?: AxiosRequestConfig) {
        return InitialBalanceAmountsApiFp(this.configuration).v1InitialBalanceAmountsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary initial_balance_amounts#show
     * @param {InitialBalanceAmountsApiV1InitialBalanceAmountsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InitialBalanceAmountsApi
     */
    public v1InitialBalanceAmountsIdGet(requestParameters: InitialBalanceAmountsApiV1InitialBalanceAmountsIdGetRequest, options?: AxiosRequestConfig) {
        return InitialBalanceAmountsApiFp(this.configuration).v1InitialBalanceAmountsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary initial_balance_amounts#update
     * @param {InitialBalanceAmountsApiV1InitialBalanceAmountsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InitialBalanceAmountsApi
     */
    public v1InitialBalanceAmountsIdPatch(requestParameters: InitialBalanceAmountsApiV1InitialBalanceAmountsIdPatchRequest, options?: AxiosRequestConfig) {
        return InitialBalanceAmountsApiFp(this.configuration).v1InitialBalanceAmountsIdPatch(requestParameters.id, requestParameters.v1InitialBalanceAmountsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary initial_balance_amounts#create
     * @param {InitialBalanceAmountsApiV1InitialBalanceAmountsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InitialBalanceAmountsApi
     */
    public v1InitialBalanceAmountsPost(requestParameters: InitialBalanceAmountsApiV1InitialBalanceAmountsPostRequest = {}, options?: AxiosRequestConfig) {
        return InitialBalanceAmountsApiFp(this.configuration).v1InitialBalanceAmountsPost(requestParameters.v1InitialBalanceAmountsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ModifiersApi - axios parameter creator
 * @export
 */
export const ModifiersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary modifiers#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ModifiersIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ModifiersIdDelete', 'id', id)
            const localVarPath = `/v1/modifiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary modifiers#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ModifiersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ModifiersIdGet', 'id', id)
            const localVarPath = `/v1/modifiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary modifiers#update
         * @param {string} id 
         * @param {V1ModifiersPostRequest} [v1ModifiersPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ModifiersIdPatch: async (id: string, v1ModifiersPostRequest?: V1ModifiersPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ModifiersIdPatch', 'id', id)
            const localVarPath = `/v1/modifiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1ModifiersPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary modifiers#create
         * @param {V1ModifiersPostRequest} [v1ModifiersPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ModifiersPost: async (v1ModifiersPostRequest?: V1ModifiersPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/modifiers/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1ModifiersPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary modifiers#index
         * @param {number} [page] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ModifiersProductGet: async (page?: number, content?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/modifiers/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary modifiers#index
         * @param {number} [page] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ModifiersServiceGet: async (page?: number, content?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/modifiers/service`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModifiersApi - functional programming interface
 * @export
 */
export const ModifiersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModifiersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary modifiers#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ModifiersIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ModifiersIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary modifiers#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ModifiersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ModifiersPost201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ModifiersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary modifiers#update
         * @param {string} id 
         * @param {V1ModifiersPostRequest} [v1ModifiersPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ModifiersIdPatch(id: string, v1ModifiersPostRequest?: V1ModifiersPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ModifiersPost201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ModifiersIdPatch(id, v1ModifiersPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary modifiers#create
         * @param {V1ModifiersPostRequest} [v1ModifiersPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ModifiersPost(v1ModifiersPostRequest?: V1ModifiersPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ModifiersPost201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ModifiersPost(v1ModifiersPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary modifiers#index
         * @param {number} [page] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ModifiersProductGet(page?: number, content?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ModifiersProductGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ModifiersProductGet(page, content, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary modifiers#index
         * @param {number} [page] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ModifiersServiceGet(page?: number, content?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ModifiersProductGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ModifiersServiceGet(page, content, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ModifiersApi - factory interface
 * @export
 */
export const ModifiersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModifiersApiFp(configuration)
    return {
        /**
         * 
         * @summary modifiers#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ModifiersIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1ModifiersIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary modifiers#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ModifiersIdGet(id: string, options?: any): AxiosPromise<V1ModifiersPost201Response> {
            return localVarFp.v1ModifiersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary modifiers#update
         * @param {string} id 
         * @param {V1ModifiersPostRequest} [v1ModifiersPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ModifiersIdPatch(id: string, v1ModifiersPostRequest?: V1ModifiersPostRequest, options?: any): AxiosPromise<V1ModifiersPost201Response> {
            return localVarFp.v1ModifiersIdPatch(id, v1ModifiersPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary modifiers#create
         * @param {V1ModifiersPostRequest} [v1ModifiersPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ModifiersPost(v1ModifiersPostRequest?: V1ModifiersPostRequest, options?: any): AxiosPromise<V1ModifiersPost201Response> {
            return localVarFp.v1ModifiersPost(v1ModifiersPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary modifiers#index
         * @param {number} [page] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ModifiersProductGet(page?: number, content?: string, options?: any): AxiosPromise<V1ModifiersProductGet200Response> {
            return localVarFp.v1ModifiersProductGet(page, content, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary modifiers#index
         * @param {number} [page] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ModifiersServiceGet(page?: number, content?: string, options?: any): AxiosPromise<V1ModifiersProductGet200Response> {
            return localVarFp.v1ModifiersServiceGet(page, content, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1ModifiersIdDelete operation in ModifiersApi.
 * @export
 * @interface ModifiersApiV1ModifiersIdDeleteRequest
 */
export interface ModifiersApiV1ModifiersIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ModifiersApiV1ModifiersIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for v1ModifiersIdGet operation in ModifiersApi.
 * @export
 * @interface ModifiersApiV1ModifiersIdGetRequest
 */
export interface ModifiersApiV1ModifiersIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ModifiersApiV1ModifiersIdGet
     */
    readonly id: string
}

/**
 * Request parameters for v1ModifiersIdPatch operation in ModifiersApi.
 * @export
 * @interface ModifiersApiV1ModifiersIdPatchRequest
 */
export interface ModifiersApiV1ModifiersIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ModifiersApiV1ModifiersIdPatch
     */
    readonly id: string

    /**
     * 
     * @type {V1ModifiersPostRequest}
     * @memberof ModifiersApiV1ModifiersIdPatch
     */
    readonly v1ModifiersPostRequest?: V1ModifiersPostRequest
}

/**
 * Request parameters for v1ModifiersPost operation in ModifiersApi.
 * @export
 * @interface ModifiersApiV1ModifiersPostRequest
 */
export interface ModifiersApiV1ModifiersPostRequest {
    /**
     * 
     * @type {V1ModifiersPostRequest}
     * @memberof ModifiersApiV1ModifiersPost
     */
    readonly v1ModifiersPostRequest?: V1ModifiersPostRequest
}

/**
 * Request parameters for v1ModifiersProductGet operation in ModifiersApi.
 * @export
 * @interface ModifiersApiV1ModifiersProductGetRequest
 */
export interface ModifiersApiV1ModifiersProductGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ModifiersApiV1ModifiersProductGet
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof ModifiersApiV1ModifiersProductGet
     */
    readonly content?: string
}

/**
 * Request parameters for v1ModifiersServiceGet operation in ModifiersApi.
 * @export
 * @interface ModifiersApiV1ModifiersServiceGetRequest
 */
export interface ModifiersApiV1ModifiersServiceGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ModifiersApiV1ModifiersServiceGet
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof ModifiersApiV1ModifiersServiceGet
     */
    readonly content?: string
}

/**
 * ModifiersApi - object-oriented interface
 * @export
 * @class ModifiersApi
 * @extends {BaseAPI}
 */
export class ModifiersApi extends BaseAPI {
    /**
     * 
     * @summary modifiers#destroy
     * @param {ModifiersApiV1ModifiersIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModifiersApi
     */
    public v1ModifiersIdDelete(requestParameters: ModifiersApiV1ModifiersIdDeleteRequest, options?: AxiosRequestConfig) {
        return ModifiersApiFp(this.configuration).v1ModifiersIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary modifiers#show
     * @param {ModifiersApiV1ModifiersIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModifiersApi
     */
    public v1ModifiersIdGet(requestParameters: ModifiersApiV1ModifiersIdGetRequest, options?: AxiosRequestConfig) {
        return ModifiersApiFp(this.configuration).v1ModifiersIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary modifiers#update
     * @param {ModifiersApiV1ModifiersIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModifiersApi
     */
    public v1ModifiersIdPatch(requestParameters: ModifiersApiV1ModifiersIdPatchRequest, options?: AxiosRequestConfig) {
        return ModifiersApiFp(this.configuration).v1ModifiersIdPatch(requestParameters.id, requestParameters.v1ModifiersPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary modifiers#create
     * @param {ModifiersApiV1ModifiersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModifiersApi
     */
    public v1ModifiersPost(requestParameters: ModifiersApiV1ModifiersPostRequest = {}, options?: AxiosRequestConfig) {
        return ModifiersApiFp(this.configuration).v1ModifiersPost(requestParameters.v1ModifiersPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary modifiers#index
     * @param {ModifiersApiV1ModifiersProductGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModifiersApi
     */
    public v1ModifiersProductGet(requestParameters: ModifiersApiV1ModifiersProductGetRequest = {}, options?: AxiosRequestConfig) {
        return ModifiersApiFp(this.configuration).v1ModifiersProductGet(requestParameters.page, requestParameters.content, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary modifiers#index
     * @param {ModifiersApiV1ModifiersServiceGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModifiersApi
     */
    public v1ModifiersServiceGet(requestParameters: ModifiersApiV1ModifiersServiceGetRequest = {}, options?: AxiosRequestConfig) {
        return ModifiersApiFp(this.configuration).v1ModifiersServiceGet(requestParameters.page, requestParameters.content, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary notifications#seen
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NotificationsIdSeenPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1NotificationsIdSeenPost', 'id', id)
            const localVarPath = `/v1/notifications/{id}/seen`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary notifications#organization
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NotificationsOrganizationGet: async (from?: string, until?: string, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/notifications/organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary notifications#platform
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NotificationsPlatformGet: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/notifications/platform`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary notifications#seen
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NotificationsIdSeenPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1NotificationsIdSeenPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary notifications#organization
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NotificationsOrganizationGet(from?: string, until?: string, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1NotificationsOrganizationGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1NotificationsOrganizationGet(from, until, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary notifications#platform
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NotificationsPlatformGet(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1NotificationsPlatformGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1NotificationsPlatformGet(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 
         * @summary notifications#seen
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NotificationsIdSeenPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1NotificationsIdSeenPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary notifications#organization
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NotificationsOrganizationGet(from?: string, until?: string, page?: number, options?: any): AxiosPromise<V1NotificationsOrganizationGet200Response> {
            return localVarFp.v1NotificationsOrganizationGet(from, until, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary notifications#platform
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NotificationsPlatformGet(page?: number, options?: any): AxiosPromise<V1NotificationsPlatformGet200Response> {
            return localVarFp.v1NotificationsPlatformGet(page, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1NotificationsIdSeenPost operation in NotificationsApi.
 * @export
 * @interface NotificationsApiV1NotificationsIdSeenPostRequest
 */
export interface NotificationsApiV1NotificationsIdSeenPostRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationsApiV1NotificationsIdSeenPost
     */
    readonly id: string
}

/**
 * Request parameters for v1NotificationsOrganizationGet operation in NotificationsApi.
 * @export
 * @interface NotificationsApiV1NotificationsOrganizationGetRequest
 */
export interface NotificationsApiV1NotificationsOrganizationGetRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationsApiV1NotificationsOrganizationGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationsApiV1NotificationsOrganizationGet
     */
    readonly until?: string

    /**
     * 
     * @type {number}
     * @memberof NotificationsApiV1NotificationsOrganizationGet
     */
    readonly page?: number
}

/**
 * Request parameters for v1NotificationsPlatformGet operation in NotificationsApi.
 * @export
 * @interface NotificationsApiV1NotificationsPlatformGetRequest
 */
export interface NotificationsApiV1NotificationsPlatformGetRequest {
    /**
     * 
     * @type {number}
     * @memberof NotificationsApiV1NotificationsPlatformGet
     */
    readonly page?: number
}

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @summary notifications#seen
     * @param {NotificationsApiV1NotificationsIdSeenPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public v1NotificationsIdSeenPost(requestParameters: NotificationsApiV1NotificationsIdSeenPostRequest, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).v1NotificationsIdSeenPost(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary notifications#organization
     * @param {NotificationsApiV1NotificationsOrganizationGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public v1NotificationsOrganizationGet(requestParameters: NotificationsApiV1NotificationsOrganizationGetRequest = {}, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).v1NotificationsOrganizationGet(requestParameters.from, requestParameters.until, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary notifications#platform
     * @param {NotificationsApiV1NotificationsPlatformGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public v1NotificationsPlatformGet(requestParameters: NotificationsApiV1NotificationsPlatformGetRequest = {}, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).v1NotificationsPlatformGet(requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OffersApi - axios parameter creator
 * @export
 */
export const OffersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary offers#index
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OffersGet: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary offers#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OffersIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1OffersIdDelete', 'id', id)
            const localVarPath = `/v1/offers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary offers#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OffersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1OffersIdGet', 'id', id)
            const localVarPath = `/v1/offers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary offers#update
         * @param {string} id 
         * @param {V1OffersGetRequest} [v1OffersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OffersIdPatch: async (id: string, v1OffersGetRequest?: V1OffersGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1OffersIdPatch', 'id', id)
            const localVarPath = `/v1/offers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1OffersGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary offers#toggle_active
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OffersIdToggleActivePatch: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1OffersIdToggleActivePatch', 'id', id)
            const localVarPath = `/v1/offers/{id}/toggle-active`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary offers#create
         * @param {V1OffersGetRequest} [v1OffersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OffersPost: async (v1OffersGetRequest?: V1OffersGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1OffersGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary offers#products
         * @param {number} [page] 
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OffersProductsGet: async (page?: number, from?: string, until?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/offers/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary offers#products
         * @param {number} [page] 
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2OffersProductsGet: async (page?: number, from?: string, until?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/offers/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OffersApi - functional programming interface
 * @export
 */
export const OffersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OffersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary offers#index
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OffersGet(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1OffersGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1OffersGet(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary offers#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OffersIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1OffersIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary offers#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OffersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1OffersGet200ResponseOffersInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1OffersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary offers#update
         * @param {string} id 
         * @param {V1OffersGetRequest} [v1OffersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OffersIdPatch(id: string, v1OffersGetRequest?: V1OffersGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1OffersGet200ResponseOffersInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1OffersIdPatch(id, v1OffersGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary offers#toggle_active
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OffersIdToggleActivePatch(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1OffersGet200ResponseOffersInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1OffersIdToggleActivePatch(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary offers#create
         * @param {V1OffersGetRequest} [v1OffersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OffersPost(v1OffersGetRequest?: V1OffersGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1OffersGet200ResponseOffersInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1OffersPost(v1OffersGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary offers#products
         * @param {number} [page] 
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OffersProductsGet(page?: number, from?: string, until?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1OffersProductsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1OffersProductsGet(page, from, until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary offers#products
         * @param {number} [page] 
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2OffersProductsGet(page?: number, from?: string, until?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2OffersProductsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2OffersProductsGet(page, from, until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OffersApi - factory interface
 * @export
 */
export const OffersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OffersApiFp(configuration)
    return {
        /**
         * 
         * @summary offers#index
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OffersGet(page?: number, options?: any): AxiosPromise<V1OffersGet200Response> {
            return localVarFp.v1OffersGet(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary offers#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OffersIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1OffersIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary offers#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OffersIdGet(id: string, options?: any): AxiosPromise<V1OffersGet200ResponseOffersInner> {
            return localVarFp.v1OffersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary offers#update
         * @param {string} id 
         * @param {V1OffersGetRequest} [v1OffersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OffersIdPatch(id: string, v1OffersGetRequest?: V1OffersGetRequest, options?: any): AxiosPromise<V1OffersGet200ResponseOffersInner> {
            return localVarFp.v1OffersIdPatch(id, v1OffersGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary offers#toggle_active
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OffersIdToggleActivePatch(id: string, options?: any): AxiosPromise<V1OffersGet200ResponseOffersInner> {
            return localVarFp.v1OffersIdToggleActivePatch(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary offers#create
         * @param {V1OffersGetRequest} [v1OffersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OffersPost(v1OffersGetRequest?: V1OffersGetRequest, options?: any): AxiosPromise<V1OffersGet200ResponseOffersInner> {
            return localVarFp.v1OffersPost(v1OffersGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary offers#products
         * @param {number} [page] 
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OffersProductsGet(page?: number, from?: string, until?: string, options?: any): AxiosPromise<V1OffersProductsGet200Response> {
            return localVarFp.v1OffersProductsGet(page, from, until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary offers#products
         * @param {number} [page] 
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2OffersProductsGet(page?: number, from?: string, until?: string, options?: any): AxiosPromise<V2OffersProductsGet200Response> {
            return localVarFp.v2OffersProductsGet(page, from, until, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1OffersGet operation in OffersApi.
 * @export
 * @interface OffersApiV1OffersGetRequest
 */
export interface OffersApiV1OffersGetRequest {
    /**
     * 
     * @type {number}
     * @memberof OffersApiV1OffersGet
     */
    readonly page?: number
}

/**
 * Request parameters for v1OffersIdDelete operation in OffersApi.
 * @export
 * @interface OffersApiV1OffersIdDeleteRequest
 */
export interface OffersApiV1OffersIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof OffersApiV1OffersIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for v1OffersIdGet operation in OffersApi.
 * @export
 * @interface OffersApiV1OffersIdGetRequest
 */
export interface OffersApiV1OffersIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof OffersApiV1OffersIdGet
     */
    readonly id: string
}

/**
 * Request parameters for v1OffersIdPatch operation in OffersApi.
 * @export
 * @interface OffersApiV1OffersIdPatchRequest
 */
export interface OffersApiV1OffersIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof OffersApiV1OffersIdPatch
     */
    readonly id: string

    /**
     * 
     * @type {V1OffersGetRequest}
     * @memberof OffersApiV1OffersIdPatch
     */
    readonly v1OffersGetRequest?: V1OffersGetRequest
}

/**
 * Request parameters for v1OffersIdToggleActivePatch operation in OffersApi.
 * @export
 * @interface OffersApiV1OffersIdToggleActivePatchRequest
 */
export interface OffersApiV1OffersIdToggleActivePatchRequest {
    /**
     * 
     * @type {string}
     * @memberof OffersApiV1OffersIdToggleActivePatch
     */
    readonly id: string
}

/**
 * Request parameters for v1OffersPost operation in OffersApi.
 * @export
 * @interface OffersApiV1OffersPostRequest
 */
export interface OffersApiV1OffersPostRequest {
    /**
     * 
     * @type {V1OffersGetRequest}
     * @memberof OffersApiV1OffersPost
     */
    readonly v1OffersGetRequest?: V1OffersGetRequest
}

/**
 * Request parameters for v1OffersProductsGet operation in OffersApi.
 * @export
 * @interface OffersApiV1OffersProductsGetRequest
 */
export interface OffersApiV1OffersProductsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof OffersApiV1OffersProductsGet
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof OffersApiV1OffersProductsGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof OffersApiV1OffersProductsGet
     */
    readonly until?: string
}

/**
 * Request parameters for v2OffersProductsGet operation in OffersApi.
 * @export
 * @interface OffersApiV2OffersProductsGetRequest
 */
export interface OffersApiV2OffersProductsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof OffersApiV2OffersProductsGet
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof OffersApiV2OffersProductsGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof OffersApiV2OffersProductsGet
     */
    readonly until?: string
}

/**
 * OffersApi - object-oriented interface
 * @export
 * @class OffersApi
 * @extends {BaseAPI}
 */
export class OffersApi extends BaseAPI {
    /**
     * 
     * @summary offers#index
     * @param {OffersApiV1OffersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public v1OffersGet(requestParameters: OffersApiV1OffersGetRequest = {}, options?: AxiosRequestConfig) {
        return OffersApiFp(this.configuration).v1OffersGet(requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary offers#destroy
     * @param {OffersApiV1OffersIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public v1OffersIdDelete(requestParameters: OffersApiV1OffersIdDeleteRequest, options?: AxiosRequestConfig) {
        return OffersApiFp(this.configuration).v1OffersIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary offers#show
     * @param {OffersApiV1OffersIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public v1OffersIdGet(requestParameters: OffersApiV1OffersIdGetRequest, options?: AxiosRequestConfig) {
        return OffersApiFp(this.configuration).v1OffersIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary offers#update
     * @param {OffersApiV1OffersIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public v1OffersIdPatch(requestParameters: OffersApiV1OffersIdPatchRequest, options?: AxiosRequestConfig) {
        return OffersApiFp(this.configuration).v1OffersIdPatch(requestParameters.id, requestParameters.v1OffersGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary offers#toggle_active
     * @param {OffersApiV1OffersIdToggleActivePatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public v1OffersIdToggleActivePatch(requestParameters: OffersApiV1OffersIdToggleActivePatchRequest, options?: AxiosRequestConfig) {
        return OffersApiFp(this.configuration).v1OffersIdToggleActivePatch(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary offers#create
     * @param {OffersApiV1OffersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public v1OffersPost(requestParameters: OffersApiV1OffersPostRequest = {}, options?: AxiosRequestConfig) {
        return OffersApiFp(this.configuration).v1OffersPost(requestParameters.v1OffersGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary offers#products
     * @param {OffersApiV1OffersProductsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public v1OffersProductsGet(requestParameters: OffersApiV1OffersProductsGetRequest = {}, options?: AxiosRequestConfig) {
        return OffersApiFp(this.configuration).v1OffersProductsGet(requestParameters.page, requestParameters.from, requestParameters.until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary offers#products
     * @param {OffersApiV2OffersProductsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public v2OffersProductsGet(requestParameters: OffersApiV2OffersProductsGetRequest = {}, options?: AxiosRequestConfig) {
        return OffersApiFp(this.configuration).v2OffersProductsGet(requestParameters.page, requestParameters.from, requestParameters.until, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary organizations#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrganizationsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1OrganizationsIdDelete', 'id', id)
            const localVarPath = `/v1/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary organizations#industry
         * @param {V1OrganizationsIndustryPatchRequest} [v1OrganizationsIndustryPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrganizationsIndustryPatch: async (v1OrganizationsIndustryPatchRequest?: V1OrganizationsIndustryPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/organizations/industry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1OrganizationsIndustryPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary organizations#update
         * @param {string} name 
         * @param {string} moneySymbol 
         * @param {number} countryId 
         * @param {string} businessType 
         * @param {File} [avatar] 
         * @param {boolean} [isDollar] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrganizationsPatch: async (name: string, moneySymbol: string, countryId: number, businessType: string, avatar?: File, isDollar?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('v1OrganizationsPatch', 'name', name)
            // verify required parameter 'moneySymbol' is not null or undefined
            assertParamExists('v1OrganizationsPatch', 'moneySymbol', moneySymbol)
            // verify required parameter 'countryId' is not null or undefined
            assertParamExists('v1OrganizationsPatch', 'countryId', countryId)
            // verify required parameter 'businessType' is not null or undefined
            assertParamExists('v1OrganizationsPatch', 'businessType', businessType)
            const localVarPath = `/v1/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (moneySymbol !== undefined) { 
                localVarFormParams.append('money_symbol', moneySymbol as any);
            }
    
            if (avatar !== undefined) { 
                localVarFormParams.append('avatar', avatar as any);
            }
    
            if (countryId !== undefined) { 
                localVarFormParams.append('country_id', countryId as any);
            }
    
            if (businessType !== undefined) { 
                localVarFormParams.append('business_type', businessType as any);
            }
    
            if (isDollar !== undefined) { 
                localVarFormParams.append('is_dollar', isDollar as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary organizations#update_plus_settings
         * @param {string} primaryColor 
         * @param {string} secondaryColor 
         * @param {string} slug 
         * @param {File} customImage 
         * @param {string} thankYouMessage 
         * @param {boolean} ticketHideLogo 
         * @param {number} lowStock 
         * @param {string} receiptLine1 
         * @param {string} receiptLine2 
         * @param {string} receiptLine3 
         * @param {string} receiptLine4 
         * @param {boolean} unifiedTransactions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrganizationsPlusSettingsPatch: async (primaryColor: string, secondaryColor: string, slug: string, customImage: File, thankYouMessage: string, ticketHideLogo: boolean, lowStock: number, receiptLine1: string, receiptLine2: string, receiptLine3: string, receiptLine4: string, unifiedTransactions: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'primaryColor' is not null or undefined
            assertParamExists('v1OrganizationsPlusSettingsPatch', 'primaryColor', primaryColor)
            // verify required parameter 'secondaryColor' is not null or undefined
            assertParamExists('v1OrganizationsPlusSettingsPatch', 'secondaryColor', secondaryColor)
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('v1OrganizationsPlusSettingsPatch', 'slug', slug)
            // verify required parameter 'customImage' is not null or undefined
            assertParamExists('v1OrganizationsPlusSettingsPatch', 'customImage', customImage)
            // verify required parameter 'thankYouMessage' is not null or undefined
            assertParamExists('v1OrganizationsPlusSettingsPatch', 'thankYouMessage', thankYouMessage)
            // verify required parameter 'ticketHideLogo' is not null or undefined
            assertParamExists('v1OrganizationsPlusSettingsPatch', 'ticketHideLogo', ticketHideLogo)
            // verify required parameter 'lowStock' is not null or undefined
            assertParamExists('v1OrganizationsPlusSettingsPatch', 'lowStock', lowStock)
            // verify required parameter 'receiptLine1' is not null or undefined
            assertParamExists('v1OrganizationsPlusSettingsPatch', 'receiptLine1', receiptLine1)
            // verify required parameter 'receiptLine2' is not null or undefined
            assertParamExists('v1OrganizationsPlusSettingsPatch', 'receiptLine2', receiptLine2)
            // verify required parameter 'receiptLine3' is not null or undefined
            assertParamExists('v1OrganizationsPlusSettingsPatch', 'receiptLine3', receiptLine3)
            // verify required parameter 'receiptLine4' is not null or undefined
            assertParamExists('v1OrganizationsPlusSettingsPatch', 'receiptLine4', receiptLine4)
            // verify required parameter 'unifiedTransactions' is not null or undefined
            assertParamExists('v1OrganizationsPlusSettingsPatch', 'unifiedTransactions', unifiedTransactions)
            const localVarPath = `/v1/organizations/plus-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


            if (primaryColor !== undefined) { 
                localVarFormParams.append('primary_color', primaryColor as any);
            }
    
            if (secondaryColor !== undefined) { 
                localVarFormParams.append('secondary_color', secondaryColor as any);
            }
    
            if (slug !== undefined) { 
                localVarFormParams.append('slug', slug as any);
            }
    
            if (customImage !== undefined) { 
                localVarFormParams.append('custom_image', customImage as any);
            }
    
            if (thankYouMessage !== undefined) { 
                localVarFormParams.append('thank_you_message', thankYouMessage as any);
            }
    
            if (ticketHideLogo !== undefined) { 
                localVarFormParams.append('ticket_hide_logo', ticketHideLogo as any);
            }
    
            if (lowStock !== undefined) { 
                localVarFormParams.append('low_stock', lowStock as any);
            }
    
            if (receiptLine1 !== undefined) { 
                localVarFormParams.append('receipt_line_1', receiptLine1 as any);
            }
    
            if (receiptLine2 !== undefined) { 
                localVarFormParams.append('receipt_line_2', receiptLine2 as any);
            }
    
            if (receiptLine3 !== undefined) { 
                localVarFormParams.append('receipt_line_3', receiptLine3 as any);
            }
    
            if (receiptLine4 !== undefined) { 
                localVarFormParams.append('receipt_line_4', receiptLine4 as any);
            }
    
            if (unifiedTransactions !== undefined) { 
                localVarFormParams.append('unified_transactions', unifiedTransactions as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary organizations#create
         * @param {string} name 
         * @param {string} moneySymbol 
         * @param {number} countryId 
         * @param {string} businessType 
         * @param {string} industry 
         * @param {File} [avatar] 
         * @param {boolean} [isDollar] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrganizationsPost: async (name: string, moneySymbol: string, countryId: number, businessType: string, industry: string, avatar?: File, isDollar?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('v1OrganizationsPost', 'name', name)
            // verify required parameter 'moneySymbol' is not null or undefined
            assertParamExists('v1OrganizationsPost', 'moneySymbol', moneySymbol)
            // verify required parameter 'countryId' is not null or undefined
            assertParamExists('v1OrganizationsPost', 'countryId', countryId)
            // verify required parameter 'businessType' is not null or undefined
            assertParamExists('v1OrganizationsPost', 'businessType', businessType)
            // verify required parameter 'industry' is not null or undefined
            assertParamExists('v1OrganizationsPost', 'industry', industry)
            const localVarPath = `/v1/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (moneySymbol !== undefined) { 
                localVarFormParams.append('money_symbol', moneySymbol as any);
            }
    
            if (avatar !== undefined) { 
                localVarFormParams.append('avatar', avatar as any);
            }
    
            if (countryId !== undefined) { 
                localVarFormParams.append('country_id', countryId as any);
            }
    
            if (businessType !== undefined) { 
                localVarFormParams.append('business_type', businessType as any);
            }
    
            if (industry !== undefined) { 
                localVarFormParams.append('industry', industry as any);
            }
    
            if (isDollar !== undefined) { 
                localVarFormParams.append('is_dollar', isDollar as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary organization#users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrganizationsUsersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/organizations/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary organizations#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OrganizationsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1OrganizationsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary organizations#industry
         * @param {V1OrganizationsIndustryPatchRequest} [v1OrganizationsIndustryPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OrganizationsIndustryPatch(v1OrganizationsIndustryPatchRequest?: V1OrganizationsIndustryPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1OrganizationsPlusSettingsPatch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1OrganizationsIndustryPatch(v1OrganizationsIndustryPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary organizations#update
         * @param {string} name 
         * @param {string} moneySymbol 
         * @param {number} countryId 
         * @param {string} businessType 
         * @param {File} [avatar] 
         * @param {boolean} [isDollar] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OrganizationsPatch(name: string, moneySymbol: string, countryId: number, businessType: string, avatar?: File, isDollar?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1OrganizationsPatch201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1OrganizationsPatch(name, moneySymbol, countryId, businessType, avatar, isDollar, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary organizations#update_plus_settings
         * @param {string} primaryColor 
         * @param {string} secondaryColor 
         * @param {string} slug 
         * @param {File} customImage 
         * @param {string} thankYouMessage 
         * @param {boolean} ticketHideLogo 
         * @param {number} lowStock 
         * @param {string} receiptLine1 
         * @param {string} receiptLine2 
         * @param {string} receiptLine3 
         * @param {string} receiptLine4 
         * @param {boolean} unifiedTransactions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OrganizationsPlusSettingsPatch(primaryColor: string, secondaryColor: string, slug: string, customImage: File, thankYouMessage: string, ticketHideLogo: boolean, lowStock: number, receiptLine1: string, receiptLine2: string, receiptLine3: string, receiptLine4: string, unifiedTransactions: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1OrganizationsPlusSettingsPatch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1OrganizationsPlusSettingsPatch(primaryColor, secondaryColor, slug, customImage, thankYouMessage, ticketHideLogo, lowStock, receiptLine1, receiptLine2, receiptLine3, receiptLine4, unifiedTransactions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary organizations#create
         * @param {string} name 
         * @param {string} moneySymbol 
         * @param {number} countryId 
         * @param {string} businessType 
         * @param {string} industry 
         * @param {File} [avatar] 
         * @param {boolean} [isDollar] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OrganizationsPost(name: string, moneySymbol: string, countryId: number, businessType: string, industry: string, avatar?: File, isDollar?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1OrganizationsPatch201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1OrganizationsPost(name, moneySymbol, countryId, businessType, industry, avatar, isDollar, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary organization#users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OrganizationsUsersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1OrganizationsUsersGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1OrganizationsUsersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationApiFp(configuration)
    return {
        /**
         * 
         * @summary organizations#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrganizationsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1OrganizationsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary organizations#industry
         * @param {V1OrganizationsIndustryPatchRequest} [v1OrganizationsIndustryPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrganizationsIndustryPatch(v1OrganizationsIndustryPatchRequest?: V1OrganizationsIndustryPatchRequest, options?: any): AxiosPromise<V1OrganizationsPlusSettingsPatch200Response> {
            return localVarFp.v1OrganizationsIndustryPatch(v1OrganizationsIndustryPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary organizations#update
         * @param {string} name 
         * @param {string} moneySymbol 
         * @param {number} countryId 
         * @param {string} businessType 
         * @param {File} [avatar] 
         * @param {boolean} [isDollar] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrganizationsPatch(name: string, moneySymbol: string, countryId: number, businessType: string, avatar?: File, isDollar?: boolean, options?: any): AxiosPromise<V1OrganizationsPatch201Response> {
            return localVarFp.v1OrganizationsPatch(name, moneySymbol, countryId, businessType, avatar, isDollar, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary organizations#update_plus_settings
         * @param {string} primaryColor 
         * @param {string} secondaryColor 
         * @param {string} slug 
         * @param {File} customImage 
         * @param {string} thankYouMessage 
         * @param {boolean} ticketHideLogo 
         * @param {number} lowStock 
         * @param {string} receiptLine1 
         * @param {string} receiptLine2 
         * @param {string} receiptLine3 
         * @param {string} receiptLine4 
         * @param {boolean} unifiedTransactions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrganizationsPlusSettingsPatch(primaryColor: string, secondaryColor: string, slug: string, customImage: File, thankYouMessage: string, ticketHideLogo: boolean, lowStock: number, receiptLine1: string, receiptLine2: string, receiptLine3: string, receiptLine4: string, unifiedTransactions: boolean, options?: any): AxiosPromise<V1OrganizationsPlusSettingsPatch200Response> {
            return localVarFp.v1OrganizationsPlusSettingsPatch(primaryColor, secondaryColor, slug, customImage, thankYouMessage, ticketHideLogo, lowStock, receiptLine1, receiptLine2, receiptLine3, receiptLine4, unifiedTransactions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary organizations#create
         * @param {string} name 
         * @param {string} moneySymbol 
         * @param {number} countryId 
         * @param {string} businessType 
         * @param {string} industry 
         * @param {File} [avatar] 
         * @param {boolean} [isDollar] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrganizationsPost(name: string, moneySymbol: string, countryId: number, businessType: string, industry: string, avatar?: File, isDollar?: boolean, options?: any): AxiosPromise<V1OrganizationsPatch201Response> {
            return localVarFp.v1OrganizationsPost(name, moneySymbol, countryId, businessType, industry, avatar, isDollar, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary organization#users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrganizationsUsersGet(options?: any): AxiosPromise<V1OrganizationsUsersGet200Response> {
            return localVarFp.v1OrganizationsUsersGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1OrganizationsIdDelete operation in OrganizationApi.
 * @export
 * @interface OrganizationApiV1OrganizationsIdDeleteRequest
 */
export interface OrganizationApiV1OrganizationsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationApiV1OrganizationsIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for v1OrganizationsIndustryPatch operation in OrganizationApi.
 * @export
 * @interface OrganizationApiV1OrganizationsIndustryPatchRequest
 */
export interface OrganizationApiV1OrganizationsIndustryPatchRequest {
    /**
     * 
     * @type {V1OrganizationsIndustryPatchRequest}
     * @memberof OrganizationApiV1OrganizationsIndustryPatch
     */
    readonly v1OrganizationsIndustryPatchRequest?: V1OrganizationsIndustryPatchRequest
}

/**
 * Request parameters for v1OrganizationsPatch operation in OrganizationApi.
 * @export
 * @interface OrganizationApiV1OrganizationsPatchRequest
 */
export interface OrganizationApiV1OrganizationsPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationApiV1OrganizationsPatch
     */
    readonly name: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationApiV1OrganizationsPatch
     */
    readonly moneySymbol: string

    /**
     * 
     * @type {number}
     * @memberof OrganizationApiV1OrganizationsPatch
     */
    readonly countryId: number

    /**
     * 
     * @type {string}
     * @memberof OrganizationApiV1OrganizationsPatch
     */
    readonly businessType: string

    /**
     * 
     * @type {File}
     * @memberof OrganizationApiV1OrganizationsPatch
     */
    readonly avatar?: File

    /**
     * 
     * @type {boolean}
     * @memberof OrganizationApiV1OrganizationsPatch
     */
    readonly isDollar?: boolean
}

/**
 * Request parameters for v1OrganizationsPlusSettingsPatch operation in OrganizationApi.
 * @export
 * @interface OrganizationApiV1OrganizationsPlusSettingsPatchRequest
 */
export interface OrganizationApiV1OrganizationsPlusSettingsPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationApiV1OrganizationsPlusSettingsPatch
     */
    readonly primaryColor: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationApiV1OrganizationsPlusSettingsPatch
     */
    readonly secondaryColor: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationApiV1OrganizationsPlusSettingsPatch
     */
    readonly slug: string

    /**
     * 
     * @type {File}
     * @memberof OrganizationApiV1OrganizationsPlusSettingsPatch
     */
    readonly customImage: File

    /**
     * 
     * @type {string}
     * @memberof OrganizationApiV1OrganizationsPlusSettingsPatch
     */
    readonly thankYouMessage: string

    /**
     * 
     * @type {boolean}
     * @memberof OrganizationApiV1OrganizationsPlusSettingsPatch
     */
    readonly ticketHideLogo: boolean

    /**
     * 
     * @type {number}
     * @memberof OrganizationApiV1OrganizationsPlusSettingsPatch
     */
    readonly lowStock: number

    /**
     * 
     * @type {string}
     * @memberof OrganizationApiV1OrganizationsPlusSettingsPatch
     */
    readonly receiptLine1: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationApiV1OrganizationsPlusSettingsPatch
     */
    readonly receiptLine2: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationApiV1OrganizationsPlusSettingsPatch
     */
    readonly receiptLine3: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationApiV1OrganizationsPlusSettingsPatch
     */
    readonly receiptLine4: string

    /**
     * 
     * @type {boolean}
     * @memberof OrganizationApiV1OrganizationsPlusSettingsPatch
     */
    readonly unifiedTransactions: boolean
}

/**
 * Request parameters for v1OrganizationsPost operation in OrganizationApi.
 * @export
 * @interface OrganizationApiV1OrganizationsPostRequest
 */
export interface OrganizationApiV1OrganizationsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationApiV1OrganizationsPost
     */
    readonly name: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationApiV1OrganizationsPost
     */
    readonly moneySymbol: string

    /**
     * 
     * @type {number}
     * @memberof OrganizationApiV1OrganizationsPost
     */
    readonly countryId: number

    /**
     * 
     * @type {string}
     * @memberof OrganizationApiV1OrganizationsPost
     */
    readonly businessType: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationApiV1OrganizationsPost
     */
    readonly industry: string

    /**
     * 
     * @type {File}
     * @memberof OrganizationApiV1OrganizationsPost
     */
    readonly avatar?: File

    /**
     * 
     * @type {boolean}
     * @memberof OrganizationApiV1OrganizationsPost
     */
    readonly isDollar?: boolean
}

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI {
    /**
     * 
     * @summary organizations#destroy
     * @param {OrganizationApiV1OrganizationsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public v1OrganizationsIdDelete(requestParameters: OrganizationApiV1OrganizationsIdDeleteRequest, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).v1OrganizationsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary organizations#industry
     * @param {OrganizationApiV1OrganizationsIndustryPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public v1OrganizationsIndustryPatch(requestParameters: OrganizationApiV1OrganizationsIndustryPatchRequest = {}, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).v1OrganizationsIndustryPatch(requestParameters.v1OrganizationsIndustryPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary organizations#update
     * @param {OrganizationApiV1OrganizationsPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public v1OrganizationsPatch(requestParameters: OrganizationApiV1OrganizationsPatchRequest, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).v1OrganizationsPatch(requestParameters.name, requestParameters.moneySymbol, requestParameters.countryId, requestParameters.businessType, requestParameters.avatar, requestParameters.isDollar, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary organizations#update_plus_settings
     * @param {OrganizationApiV1OrganizationsPlusSettingsPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public v1OrganizationsPlusSettingsPatch(requestParameters: OrganizationApiV1OrganizationsPlusSettingsPatchRequest, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).v1OrganizationsPlusSettingsPatch(requestParameters.primaryColor, requestParameters.secondaryColor, requestParameters.slug, requestParameters.customImage, requestParameters.thankYouMessage, requestParameters.ticketHideLogo, requestParameters.lowStock, requestParameters.receiptLine1, requestParameters.receiptLine2, requestParameters.receiptLine3, requestParameters.receiptLine4, requestParameters.unifiedTransactions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary organizations#create
     * @param {OrganizationApiV1OrganizationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public v1OrganizationsPost(requestParameters: OrganizationApiV1OrganizationsPostRequest, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).v1OrganizationsPost(requestParameters.name, requestParameters.moneySymbol, requestParameters.countryId, requestParameters.businessType, requestParameters.industry, requestParameters.avatar, requestParameters.isDollar, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary organization#users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public v1OrganizationsUsersGet(options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).v1OrganizationsUsersGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationSettingApi - axios parameter creator
 * @export
 */
export const OrganizationSettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary organization_settings#show
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrganizationSettingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/organization-setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary organization_settings#update
         * @param {string} description 
         * @param {number} fixedRate 
         * @param {boolean} [pickInStore] 
         * @param {boolean} [delivery] 
         * @param {boolean} [payWithCash] 
         * @param {boolean} [payWithCard] 
         * @param {boolean} [payWithBank] 
         * @param {string} [mondayFrom] 
         * @param {string} [mondayTo] 
         * @param {string} [tuesdayFrom] 
         * @param {string} [tuesdayTo] 
         * @param {string} [wednesdayFrom] 
         * @param {string} [wednesdayTo] 
         * @param {string} [thursdayFrom] 
         * @param {string} [thursdayTo] 
         * @param {string} [fridayFrom] 
         * @param {string} [fridayTo] 
         * @param {string} [saturdayFrom] 
         * @param {string} [saturdayTo] 
         * @param {string} [sundayFrom] 
         * @param {string} [sundayTo] 
         * @param {string} [facebook] 
         * @param {string} [twitter] 
         * @param {string} [instagram] 
         * @param {string} [tikTok] 
         * @param {File} [image] 
         * @param {string} [paymentLink] 
         * @param {string} [whatsappContact] 
         * @param {boolean} [outOfStockLast] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrganizationSettingPatch: async (description: string, fixedRate: number, pickInStore?: boolean, delivery?: boolean, payWithCash?: boolean, payWithCard?: boolean, payWithBank?: boolean, mondayFrom?: string, mondayTo?: string, tuesdayFrom?: string, tuesdayTo?: string, wednesdayFrom?: string, wednesdayTo?: string, thursdayFrom?: string, thursdayTo?: string, fridayFrom?: string, fridayTo?: string, saturdayFrom?: string, saturdayTo?: string, sundayFrom?: string, sundayTo?: string, facebook?: string, twitter?: string, instagram?: string, tikTok?: string, image?: File, paymentLink?: string, whatsappContact?: string, outOfStockLast?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'description' is not null or undefined
            assertParamExists('v1OrganizationSettingPatch', 'description', description)
            // verify required parameter 'fixedRate' is not null or undefined
            assertParamExists('v1OrganizationSettingPatch', 'fixedRate', fixedRate)
            const localVarPath = `/v1/organization-setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


            if (pickInStore !== undefined) { 
                localVarFormParams.append('pick_in_store', pickInStore as any);
            }
    
            if (delivery !== undefined) { 
                localVarFormParams.append('delivery', delivery as any);
            }
    
            if (payWithCash !== undefined) { 
                localVarFormParams.append('pay_with_cash', payWithCash as any);
            }
    
            if (payWithCard !== undefined) { 
                localVarFormParams.append('pay_with_card', payWithCard as any);
            }
    
            if (payWithBank !== undefined) { 
                localVarFormParams.append('pay_with_bank', payWithBank as any);
            }
    
            if (mondayFrom !== undefined) { 
                localVarFormParams.append('monday_from', mondayFrom as any);
            }
    
            if (mondayTo !== undefined) { 
                localVarFormParams.append('monday_to', mondayTo as any);
            }
    
            if (tuesdayFrom !== undefined) { 
                localVarFormParams.append('tuesday_from', tuesdayFrom as any);
            }
    
            if (tuesdayTo !== undefined) { 
                localVarFormParams.append('tuesday_to', tuesdayTo as any);
            }
    
            if (wednesdayFrom !== undefined) { 
                localVarFormParams.append('wednesday_from', wednesdayFrom as any);
            }
    
            if (wednesdayTo !== undefined) { 
                localVarFormParams.append('wednesday_to', wednesdayTo as any);
            }
    
            if (thursdayFrom !== undefined) { 
                localVarFormParams.append('thursday_from', thursdayFrom as any);
            }
    
            if (thursdayTo !== undefined) { 
                localVarFormParams.append('thursday_to', thursdayTo as any);
            }
    
            if (fridayFrom !== undefined) { 
                localVarFormParams.append('friday_from', fridayFrom as any);
            }
    
            if (fridayTo !== undefined) { 
                localVarFormParams.append('friday_to', fridayTo as any);
            }
    
            if (saturdayFrom !== undefined) { 
                localVarFormParams.append('saturday_from', saturdayFrom as any);
            }
    
            if (saturdayTo !== undefined) { 
                localVarFormParams.append('saturday_to', saturdayTo as any);
            }
    
            if (sundayFrom !== undefined) { 
                localVarFormParams.append('sunday_from', sundayFrom as any);
            }
    
            if (sundayTo !== undefined) { 
                localVarFormParams.append('sunday_to', sundayTo as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
            if (facebook !== undefined) { 
                localVarFormParams.append('facebook', facebook as any);
            }
    
            if (twitter !== undefined) { 
                localVarFormParams.append('twitter', twitter as any);
            }
    
            if (instagram !== undefined) { 
                localVarFormParams.append('instagram', instagram as any);
            }
    
            if (tikTok !== undefined) { 
                localVarFormParams.append('tik_tok', tikTok as any);
            }
    
            if (fixedRate !== undefined) { 
                localVarFormParams.append('fixed_rate', fixedRate as any);
            }
    
            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
            if (paymentLink !== undefined) { 
                localVarFormParams.append('payment_link', paymentLink as any);
            }
    
            if (whatsappContact !== undefined) { 
                localVarFormParams.append('whatsapp_contact', whatsappContact as any);
            }
    
            if (outOfStockLast !== undefined) { 
                localVarFormParams.append('out_of_stock_last', outOfStockLast as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationSettingApi - functional programming interface
 * @export
 */
export const OrganizationSettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationSettingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary organization_settings#show
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OrganizationSettingGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1OrganizationSettingGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1OrganizationSettingGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary organization_settings#update
         * @param {string} description 
         * @param {number} fixedRate 
         * @param {boolean} [pickInStore] 
         * @param {boolean} [delivery] 
         * @param {boolean} [payWithCash] 
         * @param {boolean} [payWithCard] 
         * @param {boolean} [payWithBank] 
         * @param {string} [mondayFrom] 
         * @param {string} [mondayTo] 
         * @param {string} [tuesdayFrom] 
         * @param {string} [tuesdayTo] 
         * @param {string} [wednesdayFrom] 
         * @param {string} [wednesdayTo] 
         * @param {string} [thursdayFrom] 
         * @param {string} [thursdayTo] 
         * @param {string} [fridayFrom] 
         * @param {string} [fridayTo] 
         * @param {string} [saturdayFrom] 
         * @param {string} [saturdayTo] 
         * @param {string} [sundayFrom] 
         * @param {string} [sundayTo] 
         * @param {string} [facebook] 
         * @param {string} [twitter] 
         * @param {string} [instagram] 
         * @param {string} [tikTok] 
         * @param {File} [image] 
         * @param {string} [paymentLink] 
         * @param {string} [whatsappContact] 
         * @param {boolean} [outOfStockLast] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OrganizationSettingPatch(description: string, fixedRate: number, pickInStore?: boolean, delivery?: boolean, payWithCash?: boolean, payWithCard?: boolean, payWithBank?: boolean, mondayFrom?: string, mondayTo?: string, tuesdayFrom?: string, tuesdayTo?: string, wednesdayFrom?: string, wednesdayTo?: string, thursdayFrom?: string, thursdayTo?: string, fridayFrom?: string, fridayTo?: string, saturdayFrom?: string, saturdayTo?: string, sundayFrom?: string, sundayTo?: string, facebook?: string, twitter?: string, instagram?: string, tikTok?: string, image?: File, paymentLink?: string, whatsappContact?: string, outOfStockLast?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1OrganizationSettingGet200Response1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1OrganizationSettingPatch(description, fixedRate, pickInStore, delivery, payWithCash, payWithCard, payWithBank, mondayFrom, mondayTo, tuesdayFrom, tuesdayTo, wednesdayFrom, wednesdayTo, thursdayFrom, thursdayTo, fridayFrom, fridayTo, saturdayFrom, saturdayTo, sundayFrom, sundayTo, facebook, twitter, instagram, tikTok, image, paymentLink, whatsappContact, outOfStockLast, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationSettingApi - factory interface
 * @export
 */
export const OrganizationSettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationSettingApiFp(configuration)
    return {
        /**
         * 
         * @summary organization_settings#show
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrganizationSettingGet(options?: any): AxiosPromise<V1OrganizationSettingGet200Response> {
            return localVarFp.v1OrganizationSettingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary organization_settings#update
         * @param {string} description 
         * @param {number} fixedRate 
         * @param {boolean} [pickInStore] 
         * @param {boolean} [delivery] 
         * @param {boolean} [payWithCash] 
         * @param {boolean} [payWithCard] 
         * @param {boolean} [payWithBank] 
         * @param {string} [mondayFrom] 
         * @param {string} [mondayTo] 
         * @param {string} [tuesdayFrom] 
         * @param {string} [tuesdayTo] 
         * @param {string} [wednesdayFrom] 
         * @param {string} [wednesdayTo] 
         * @param {string} [thursdayFrom] 
         * @param {string} [thursdayTo] 
         * @param {string} [fridayFrom] 
         * @param {string} [fridayTo] 
         * @param {string} [saturdayFrom] 
         * @param {string} [saturdayTo] 
         * @param {string} [sundayFrom] 
         * @param {string} [sundayTo] 
         * @param {string} [facebook] 
         * @param {string} [twitter] 
         * @param {string} [instagram] 
         * @param {string} [tikTok] 
         * @param {File} [image] 
         * @param {string} [paymentLink] 
         * @param {string} [whatsappContact] 
         * @param {boolean} [outOfStockLast] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrganizationSettingPatch(description: string, fixedRate: number, pickInStore?: boolean, delivery?: boolean, payWithCash?: boolean, payWithCard?: boolean, payWithBank?: boolean, mondayFrom?: string, mondayTo?: string, tuesdayFrom?: string, tuesdayTo?: string, wednesdayFrom?: string, wednesdayTo?: string, thursdayFrom?: string, thursdayTo?: string, fridayFrom?: string, fridayTo?: string, saturdayFrom?: string, saturdayTo?: string, sundayFrom?: string, sundayTo?: string, facebook?: string, twitter?: string, instagram?: string, tikTok?: string, image?: File, paymentLink?: string, whatsappContact?: string, outOfStockLast?: boolean, options?: any): AxiosPromise<V1OrganizationSettingGet200Response1> {
            return localVarFp.v1OrganizationSettingPatch(description, fixedRate, pickInStore, delivery, payWithCash, payWithCard, payWithBank, mondayFrom, mondayTo, tuesdayFrom, tuesdayTo, wednesdayFrom, wednesdayTo, thursdayFrom, thursdayTo, fridayFrom, fridayTo, saturdayFrom, saturdayTo, sundayFrom, sundayTo, facebook, twitter, instagram, tikTok, image, paymentLink, whatsappContact, outOfStockLast, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1OrganizationSettingPatch operation in OrganizationSettingApi.
 * @export
 * @interface OrganizationSettingApiV1OrganizationSettingPatchRequest
 */
export interface OrganizationSettingApiV1OrganizationSettingPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly description: string

    /**
     * 
     * @type {number}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly fixedRate: number

    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly pickInStore?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly delivery?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly payWithCash?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly payWithCard?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly payWithBank?: boolean

    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly mondayFrom?: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly mondayTo?: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly tuesdayFrom?: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly tuesdayTo?: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly wednesdayFrom?: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly wednesdayTo?: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly thursdayFrom?: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly thursdayTo?: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly fridayFrom?: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly fridayTo?: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly saturdayFrom?: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly saturdayTo?: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly sundayFrom?: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly sundayTo?: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly facebook?: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly twitter?: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly instagram?: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly tikTok?: string

    /**
     * 
     * @type {File}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly image?: File

    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly paymentLink?: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly whatsappContact?: string

    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSettingApiV1OrganizationSettingPatch
     */
    readonly outOfStockLast?: boolean
}

/**
 * OrganizationSettingApi - object-oriented interface
 * @export
 * @class OrganizationSettingApi
 * @extends {BaseAPI}
 */
export class OrganizationSettingApi extends BaseAPI {
    /**
     * 
     * @summary organization_settings#show
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationSettingApi
     */
    public v1OrganizationSettingGet(options?: AxiosRequestConfig) {
        return OrganizationSettingApiFp(this.configuration).v1OrganizationSettingGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary organization_settings#update
     * @param {OrganizationSettingApiV1OrganizationSettingPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationSettingApi
     */
    public v1OrganizationSettingPatch(requestParameters: OrganizationSettingApiV1OrganizationSettingPatchRequest, options?: AxiosRequestConfig) {
        return OrganizationSettingApiFp(this.configuration).v1OrganizationSettingPatch(requestParameters.description, requestParameters.fixedRate, requestParameters.pickInStore, requestParameters.delivery, requestParameters.payWithCash, requestParameters.payWithCard, requestParameters.payWithBank, requestParameters.mondayFrom, requestParameters.mondayTo, requestParameters.tuesdayFrom, requestParameters.tuesdayTo, requestParameters.wednesdayFrom, requestParameters.wednesdayTo, requestParameters.thursdayFrom, requestParameters.thursdayTo, requestParameters.fridayFrom, requestParameters.fridayTo, requestParameters.saturdayFrom, requestParameters.saturdayTo, requestParameters.sundayFrom, requestParameters.sundayTo, requestParameters.facebook, requestParameters.twitter, requestParameters.instagram, requestParameters.tikTok, requestParameters.image, requestParameters.paymentLink, requestParameters.whatsappContact, requestParameters.outOfStockLast, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaidSalesTransactionsApi - axios parameter creator
 * @export
 */
export const PaidSalesTransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary paid_sales_transactions#create
         * @param {string} slug 
         * @param {V1WebOrganizationsSlugPaidSalesTransactionsPostRequest} [v1WebOrganizationsSlugPaidSalesTransactionsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebOrganizationsSlugPaidSalesTransactionsPost: async (slug: string, v1WebOrganizationsSlugPaidSalesTransactionsPostRequest?: V1WebOrganizationsSlugPaidSalesTransactionsPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('v1WebOrganizationsSlugPaidSalesTransactionsPost', 'slug', slug)
            const localVarPath = `/v1/web/organizations/{slug}/paid-sales-transactions`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1WebOrganizationsSlugPaidSalesTransactionsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaidSalesTransactionsApi - functional programming interface
 * @export
 */
export const PaidSalesTransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaidSalesTransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary paid_sales_transactions#create
         * @param {string} slug 
         * @param {V1WebOrganizationsSlugPaidSalesTransactionsPostRequest} [v1WebOrganizationsSlugPaidSalesTransactionsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1WebOrganizationsSlugPaidSalesTransactionsPost(slug: string, v1WebOrganizationsSlugPaidSalesTransactionsPostRequest?: V1WebOrganizationsSlugPaidSalesTransactionsPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1WebOrganizationsSlugPaidSalesTransactionsPost201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1WebOrganizationsSlugPaidSalesTransactionsPost(slug, v1WebOrganizationsSlugPaidSalesTransactionsPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaidSalesTransactionsApi - factory interface
 * @export
 */
export const PaidSalesTransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaidSalesTransactionsApiFp(configuration)
    return {
        /**
         * 
         * @summary paid_sales_transactions#create
         * @param {string} slug 
         * @param {V1WebOrganizationsSlugPaidSalesTransactionsPostRequest} [v1WebOrganizationsSlugPaidSalesTransactionsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebOrganizationsSlugPaidSalesTransactionsPost(slug: string, v1WebOrganizationsSlugPaidSalesTransactionsPostRequest?: V1WebOrganizationsSlugPaidSalesTransactionsPostRequest, options?: any): AxiosPromise<V1WebOrganizationsSlugPaidSalesTransactionsPost201Response> {
            return localVarFp.v1WebOrganizationsSlugPaidSalesTransactionsPost(slug, v1WebOrganizationsSlugPaidSalesTransactionsPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1WebOrganizationsSlugPaidSalesTransactionsPost operation in PaidSalesTransactionsApi.
 * @export
 * @interface PaidSalesTransactionsApiV1WebOrganizationsSlugPaidSalesTransactionsPostRequest
 */
export interface PaidSalesTransactionsApiV1WebOrganizationsSlugPaidSalesTransactionsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof PaidSalesTransactionsApiV1WebOrganizationsSlugPaidSalesTransactionsPost
     */
    readonly slug: string

    /**
     * 
     * @type {V1WebOrganizationsSlugPaidSalesTransactionsPostRequest}
     * @memberof PaidSalesTransactionsApiV1WebOrganizationsSlugPaidSalesTransactionsPost
     */
    readonly v1WebOrganizationsSlugPaidSalesTransactionsPostRequest?: V1WebOrganizationsSlugPaidSalesTransactionsPostRequest
}

/**
 * PaidSalesTransactionsApi - object-oriented interface
 * @export
 * @class PaidSalesTransactionsApi
 * @extends {BaseAPI}
 */
export class PaidSalesTransactionsApi extends BaseAPI {
    /**
     * 
     * @summary paid_sales_transactions#create
     * @param {PaidSalesTransactionsApiV1WebOrganizationsSlugPaidSalesTransactionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaidSalesTransactionsApi
     */
    public v1WebOrganizationsSlugPaidSalesTransactionsPost(requestParameters: PaidSalesTransactionsApiV1WebOrganizationsSlugPaidSalesTransactionsPostRequest, options?: AxiosRequestConfig) {
        return PaidSalesTransactionsApiFp(this.configuration).v1WebOrganizationsSlugPaidSalesTransactionsPost(requestParameters.slug, requestParameters.v1WebOrganizationsSlugPaidSalesTransactionsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentInfoApi - axios parameter creator
 * @export
 */
export const PaymentInfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary payment_infos#show
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PaymentInfoGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/payment-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary payment_infos#update
         * @param {V1PaymentInfoGetRequest} [v1PaymentInfoGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PaymentInfoPatch: async (v1PaymentInfoGetRequest?: V1PaymentInfoGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/payment-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1PaymentInfoGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary payment_infos#create
         * @param {V1PaymentInfoGetRequest} [v1PaymentInfoGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PaymentInfoPost: async (v1PaymentInfoGetRequest?: V1PaymentInfoGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/payment-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1PaymentInfoGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentInfoApi - functional programming interface
 * @export
 */
export const PaymentInfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentInfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary payment_infos#show
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PaymentInfoGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1PaymentInfoGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PaymentInfoGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary payment_infos#update
         * @param {V1PaymentInfoGetRequest} [v1PaymentInfoGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PaymentInfoPatch(v1PaymentInfoGetRequest?: V1PaymentInfoGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1PaymentInfoGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PaymentInfoPatch(v1PaymentInfoGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary payment_infos#create
         * @param {V1PaymentInfoGetRequest} [v1PaymentInfoGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PaymentInfoPost(v1PaymentInfoGetRequest?: V1PaymentInfoGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1PaymentInfoGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PaymentInfoPost(v1PaymentInfoGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentInfoApi - factory interface
 * @export
 */
export const PaymentInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentInfoApiFp(configuration)
    return {
        /**
         * 
         * @summary payment_infos#show
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PaymentInfoGet(options?: any): AxiosPromise<V1PaymentInfoGet200Response> {
            return localVarFp.v1PaymentInfoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary payment_infos#update
         * @param {V1PaymentInfoGetRequest} [v1PaymentInfoGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PaymentInfoPatch(v1PaymentInfoGetRequest?: V1PaymentInfoGetRequest, options?: any): AxiosPromise<V1PaymentInfoGet200Response> {
            return localVarFp.v1PaymentInfoPatch(v1PaymentInfoGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary payment_infos#create
         * @param {V1PaymentInfoGetRequest} [v1PaymentInfoGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PaymentInfoPost(v1PaymentInfoGetRequest?: V1PaymentInfoGetRequest, options?: any): AxiosPromise<V1PaymentInfoGet200Response> {
            return localVarFp.v1PaymentInfoPost(v1PaymentInfoGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1PaymentInfoPatch operation in PaymentInfoApi.
 * @export
 * @interface PaymentInfoApiV1PaymentInfoPatchRequest
 */
export interface PaymentInfoApiV1PaymentInfoPatchRequest {
    /**
     * 
     * @type {V1PaymentInfoGetRequest}
     * @memberof PaymentInfoApiV1PaymentInfoPatch
     */
    readonly v1PaymentInfoGetRequest?: V1PaymentInfoGetRequest
}

/**
 * Request parameters for v1PaymentInfoPost operation in PaymentInfoApi.
 * @export
 * @interface PaymentInfoApiV1PaymentInfoPostRequest
 */
export interface PaymentInfoApiV1PaymentInfoPostRequest {
    /**
     * 
     * @type {V1PaymentInfoGetRequest}
     * @memberof PaymentInfoApiV1PaymentInfoPost
     */
    readonly v1PaymentInfoGetRequest?: V1PaymentInfoGetRequest
}

/**
 * PaymentInfoApi - object-oriented interface
 * @export
 * @class PaymentInfoApi
 * @extends {BaseAPI}
 */
export class PaymentInfoApi extends BaseAPI {
    /**
     * 
     * @summary payment_infos#show
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentInfoApi
     */
    public v1PaymentInfoGet(options?: AxiosRequestConfig) {
        return PaymentInfoApiFp(this.configuration).v1PaymentInfoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary payment_infos#update
     * @param {PaymentInfoApiV1PaymentInfoPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentInfoApi
     */
    public v1PaymentInfoPatch(requestParameters: PaymentInfoApiV1PaymentInfoPatchRequest = {}, options?: AxiosRequestConfig) {
        return PaymentInfoApiFp(this.configuration).v1PaymentInfoPatch(requestParameters.v1PaymentInfoGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary payment_infos#create
     * @param {PaymentInfoApiV1PaymentInfoPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentInfoApi
     */
    public v1PaymentInfoPost(requestParameters: PaymentInfoApiV1PaymentInfoPostRequest = {}, options?: AxiosRequestConfig) {
        return PaymentInfoApiFp(this.configuration).v1PaymentInfoPost(requestParameters.v1PaymentInfoGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PosTransactionsApi - axios parameter creator
 * @export
 */
export const PosTransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary pos_transactions#done
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PosTransactionsDoneGet: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/pos-transactions/done`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary pos_transactions#index
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PosTransactionsGet: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/pos-transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary pos_transactions#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PosTransactionsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1PosTransactionsIdDelete', 'id', id)
            const localVarPath = `/v1/pos-transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary pos_transactions#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PosTransactionsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1PosTransactionsIdGet', 'id', id)
            const localVarPath = `/v1/pos-transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary pos_transactions#update
         * @param {string} id 
         * @param {V1PosTransactionsIdDeleteRequest} [v1PosTransactionsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PosTransactionsIdPatch: async (id: string, v1PosTransactionsIdDeleteRequest?: V1PosTransactionsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1PosTransactionsIdPatch', 'id', id)
            const localVarPath = `/v1/pos-transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1PosTransactionsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary pos_transactions#in_progress
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PosTransactionsInProgressGet: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/pos-transactions/in-progress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary pos_transactions#create
         * @param {V1PosTransactionsGetRequest} [v1PosTransactionsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PosTransactionsPost: async (v1PosTransactionsGetRequest?: V1PosTransactionsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/pos-transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1PosTransactionsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary pos_transactions#stats
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PosTransactionsStatsGet: async (from?: string, until?: string, userId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/pos-transactions/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PosTransactionsApi - functional programming interface
 * @export
 */
export const PosTransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PosTransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary pos_transactions#done
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PosTransactionsDoneGet(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1PosTransactionsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PosTransactionsDoneGet(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary pos_transactions#index
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PosTransactionsGet(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1PosTransactionsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PosTransactionsGet(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary pos_transactions#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PosTransactionsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PosTransactionsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary pos_transactions#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PosTransactionsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1PosTransactionsGet201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PosTransactionsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary pos_transactions#update
         * @param {string} id 
         * @param {V1PosTransactionsIdDeleteRequest} [v1PosTransactionsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PosTransactionsIdPatch(id: string, v1PosTransactionsIdDeleteRequest?: V1PosTransactionsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1PosTransactionsIdDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PosTransactionsIdPatch(id, v1PosTransactionsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary pos_transactions#in_progress
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PosTransactionsInProgressGet(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1PosTransactionsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PosTransactionsInProgressGet(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary pos_transactions#create
         * @param {V1PosTransactionsGetRequest} [v1PosTransactionsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PosTransactionsPost(v1PosTransactionsGetRequest?: V1PosTransactionsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1PosTransactionsGet201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PosTransactionsPost(v1PosTransactionsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary pos_transactions#stats
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PosTransactionsStatsGet(from?: string, until?: string, userId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1PosTransactionsStatsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PosTransactionsStatsGet(from, until, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PosTransactionsApi - factory interface
 * @export
 */
export const PosTransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PosTransactionsApiFp(configuration)
    return {
        /**
         * 
         * @summary pos_transactions#done
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PosTransactionsDoneGet(page?: number, options?: any): AxiosPromise<V1PosTransactionsGet200Response> {
            return localVarFp.v1PosTransactionsDoneGet(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary pos_transactions#index
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PosTransactionsGet(page?: number, options?: any): AxiosPromise<V1PosTransactionsGet200Response> {
            return localVarFp.v1PosTransactionsGet(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary pos_transactions#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PosTransactionsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1PosTransactionsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary pos_transactions#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PosTransactionsIdGet(id: string, options?: any): AxiosPromise<V1PosTransactionsGet201Response> {
            return localVarFp.v1PosTransactionsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary pos_transactions#update
         * @param {string} id 
         * @param {V1PosTransactionsIdDeleteRequest} [v1PosTransactionsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PosTransactionsIdPatch(id: string, v1PosTransactionsIdDeleteRequest?: V1PosTransactionsIdDeleteRequest, options?: any): AxiosPromise<V1PosTransactionsIdDelete200Response> {
            return localVarFp.v1PosTransactionsIdPatch(id, v1PosTransactionsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary pos_transactions#in_progress
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PosTransactionsInProgressGet(page?: number, options?: any): AxiosPromise<V1PosTransactionsGet200Response> {
            return localVarFp.v1PosTransactionsInProgressGet(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary pos_transactions#create
         * @param {V1PosTransactionsGetRequest} [v1PosTransactionsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PosTransactionsPost(v1PosTransactionsGetRequest?: V1PosTransactionsGetRequest, options?: any): AxiosPromise<V1PosTransactionsGet201Response> {
            return localVarFp.v1PosTransactionsPost(v1PosTransactionsGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary pos_transactions#stats
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PosTransactionsStatsGet(from?: string, until?: string, userId?: string, options?: any): AxiosPromise<V1PosTransactionsStatsGet200Response> {
            return localVarFp.v1PosTransactionsStatsGet(from, until, userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1PosTransactionsDoneGet operation in PosTransactionsApi.
 * @export
 * @interface PosTransactionsApiV1PosTransactionsDoneGetRequest
 */
export interface PosTransactionsApiV1PosTransactionsDoneGetRequest {
    /**
     * 
     * @type {number}
     * @memberof PosTransactionsApiV1PosTransactionsDoneGet
     */
    readonly page?: number
}

/**
 * Request parameters for v1PosTransactionsGet operation in PosTransactionsApi.
 * @export
 * @interface PosTransactionsApiV1PosTransactionsGetRequest
 */
export interface PosTransactionsApiV1PosTransactionsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof PosTransactionsApiV1PosTransactionsGet
     */
    readonly page?: number
}

/**
 * Request parameters for v1PosTransactionsIdDelete operation in PosTransactionsApi.
 * @export
 * @interface PosTransactionsApiV1PosTransactionsIdDeleteRequest
 */
export interface PosTransactionsApiV1PosTransactionsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof PosTransactionsApiV1PosTransactionsIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for v1PosTransactionsIdGet operation in PosTransactionsApi.
 * @export
 * @interface PosTransactionsApiV1PosTransactionsIdGetRequest
 */
export interface PosTransactionsApiV1PosTransactionsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PosTransactionsApiV1PosTransactionsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for v1PosTransactionsIdPatch operation in PosTransactionsApi.
 * @export
 * @interface PosTransactionsApiV1PosTransactionsIdPatchRequest
 */
export interface PosTransactionsApiV1PosTransactionsIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof PosTransactionsApiV1PosTransactionsIdPatch
     */
    readonly id: string

    /**
     * 
     * @type {V1PosTransactionsIdDeleteRequest}
     * @memberof PosTransactionsApiV1PosTransactionsIdPatch
     */
    readonly v1PosTransactionsIdDeleteRequest?: V1PosTransactionsIdDeleteRequest
}

/**
 * Request parameters for v1PosTransactionsInProgressGet operation in PosTransactionsApi.
 * @export
 * @interface PosTransactionsApiV1PosTransactionsInProgressGetRequest
 */
export interface PosTransactionsApiV1PosTransactionsInProgressGetRequest {
    /**
     * 
     * @type {number}
     * @memberof PosTransactionsApiV1PosTransactionsInProgressGet
     */
    readonly page?: number
}

/**
 * Request parameters for v1PosTransactionsPost operation in PosTransactionsApi.
 * @export
 * @interface PosTransactionsApiV1PosTransactionsPostRequest
 */
export interface PosTransactionsApiV1PosTransactionsPostRequest {
    /**
     * 
     * @type {V1PosTransactionsGetRequest}
     * @memberof PosTransactionsApiV1PosTransactionsPost
     */
    readonly v1PosTransactionsGetRequest?: V1PosTransactionsGetRequest
}

/**
 * Request parameters for v1PosTransactionsStatsGet operation in PosTransactionsApi.
 * @export
 * @interface PosTransactionsApiV1PosTransactionsStatsGetRequest
 */
export interface PosTransactionsApiV1PosTransactionsStatsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PosTransactionsApiV1PosTransactionsStatsGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof PosTransactionsApiV1PosTransactionsStatsGet
     */
    readonly until?: string

    /**
     * 
     * @type {string}
     * @memberof PosTransactionsApiV1PosTransactionsStatsGet
     */
    readonly userId?: string
}

/**
 * PosTransactionsApi - object-oriented interface
 * @export
 * @class PosTransactionsApi
 * @extends {BaseAPI}
 */
export class PosTransactionsApi extends BaseAPI {
    /**
     * 
     * @summary pos_transactions#done
     * @param {PosTransactionsApiV1PosTransactionsDoneGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosTransactionsApi
     */
    public v1PosTransactionsDoneGet(requestParameters: PosTransactionsApiV1PosTransactionsDoneGetRequest = {}, options?: AxiosRequestConfig) {
        return PosTransactionsApiFp(this.configuration).v1PosTransactionsDoneGet(requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary pos_transactions#index
     * @param {PosTransactionsApiV1PosTransactionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosTransactionsApi
     */
    public v1PosTransactionsGet(requestParameters: PosTransactionsApiV1PosTransactionsGetRequest = {}, options?: AxiosRequestConfig) {
        return PosTransactionsApiFp(this.configuration).v1PosTransactionsGet(requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary pos_transactions#destroy
     * @param {PosTransactionsApiV1PosTransactionsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosTransactionsApi
     */
    public v1PosTransactionsIdDelete(requestParameters: PosTransactionsApiV1PosTransactionsIdDeleteRequest, options?: AxiosRequestConfig) {
        return PosTransactionsApiFp(this.configuration).v1PosTransactionsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary pos_transactions#show
     * @param {PosTransactionsApiV1PosTransactionsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosTransactionsApi
     */
    public v1PosTransactionsIdGet(requestParameters: PosTransactionsApiV1PosTransactionsIdGetRequest, options?: AxiosRequestConfig) {
        return PosTransactionsApiFp(this.configuration).v1PosTransactionsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary pos_transactions#update
     * @param {PosTransactionsApiV1PosTransactionsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosTransactionsApi
     */
    public v1PosTransactionsIdPatch(requestParameters: PosTransactionsApiV1PosTransactionsIdPatchRequest, options?: AxiosRequestConfig) {
        return PosTransactionsApiFp(this.configuration).v1PosTransactionsIdPatch(requestParameters.id, requestParameters.v1PosTransactionsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary pos_transactions#in_progress
     * @param {PosTransactionsApiV1PosTransactionsInProgressGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosTransactionsApi
     */
    public v1PosTransactionsInProgressGet(requestParameters: PosTransactionsApiV1PosTransactionsInProgressGetRequest = {}, options?: AxiosRequestConfig) {
        return PosTransactionsApiFp(this.configuration).v1PosTransactionsInProgressGet(requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary pos_transactions#create
     * @param {PosTransactionsApiV1PosTransactionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosTransactionsApi
     */
    public v1PosTransactionsPost(requestParameters: PosTransactionsApiV1PosTransactionsPostRequest = {}, options?: AxiosRequestConfig) {
        return PosTransactionsApiFp(this.configuration).v1PosTransactionsPost(requestParameters.v1PosTransactionsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary pos_transactions#stats
     * @param {PosTransactionsApiV1PosTransactionsStatsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosTransactionsApi
     */
    public v1PosTransactionsStatsGet(requestParameters: PosTransactionsApiV1PosTransactionsStatsGetRequest = {}, options?: AxiosRequestConfig) {
        return PosTransactionsApiFp(this.configuration).v1PosTransactionsStatsGet(requestParameters.from, requestParameters.until, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductCategoriesApi - axios parameter creator
 * @export
 */
export const ProductCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary product_categories#index
         * @param {number} [page] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductCategoriesGet: async (page?: number, content?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/product-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary product_categories#batch_update
         * @param {string} id 
         * @param {V1ProductCategoriesIdBatchUpdatePatchRequest} [v1ProductCategoriesIdBatchUpdatePatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductCategoriesIdBatchUpdatePatch: async (id: string, v1ProductCategoriesIdBatchUpdatePatchRequest?: V1ProductCategoriesIdBatchUpdatePatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ProductCategoriesIdBatchUpdatePatch', 'id', id)
            const localVarPath = `/v1/product-categories/{id}/batch-update`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1ProductCategoriesIdBatchUpdatePatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary product_categories#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductCategoriesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ProductCategoriesIdDelete', 'id', id)
            const localVarPath = `/v1/product-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary product_categories#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductCategoriesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ProductCategoriesIdGet', 'id', id)
            const localVarPath = `/v1/product-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary product_categories#update
         * @param {string} id 
         * @param {V1ProductCategoriesGetRequest} [v1ProductCategoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductCategoriesIdPatch: async (id: string, v1ProductCategoriesGetRequest?: V1ProductCategoriesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ProductCategoriesIdPatch', 'id', id)
            const localVarPath = `/v1/product-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1ProductCategoriesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary product_categories#products
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductCategoriesIdProductsGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ProductCategoriesIdProductsGet', 'id', id)
            const localVarPath = `/v1/product-categories/{id}/products`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary product_categories#create
         * @param {V1ProductCategoriesGetRequest} [v1ProductCategoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductCategoriesPost: async (v1ProductCategoriesGetRequest?: V1ProductCategoriesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/product-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1ProductCategoriesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductCategoriesApi - functional programming interface
 * @export
 */
export const ProductCategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductCategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary product_categories#index
         * @param {number} [page] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductCategoriesGet(page?: number, content?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ProductCategoriesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ProductCategoriesGet(page, content, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary product_categories#batch_update
         * @param {string} id 
         * @param {V1ProductCategoriesIdBatchUpdatePatchRequest} [v1ProductCategoriesIdBatchUpdatePatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductCategoriesIdBatchUpdatePatch(id: string, v1ProductCategoriesIdBatchUpdatePatchRequest?: V1ProductCategoriesIdBatchUpdatePatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ProductCategoriesIdBatchUpdatePatch(id, v1ProductCategoriesIdBatchUpdatePatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary product_categories#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductCategoriesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ProductCategoriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary product_categories#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductCategoriesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ProductCategoriesGet201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ProductCategoriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary product_categories#update
         * @param {string} id 
         * @param {V1ProductCategoriesGetRequest} [v1ProductCategoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductCategoriesIdPatch(id: string, v1ProductCategoriesGetRequest?: V1ProductCategoriesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ProductCategoriesGet201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ProductCategoriesIdPatch(id, v1ProductCategoriesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary product_categories#products
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductCategoriesIdProductsGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ProductCategoriesIdProductsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ProductCategoriesIdProductsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary product_categories#create
         * @param {V1ProductCategoriesGetRequest} [v1ProductCategoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductCategoriesPost(v1ProductCategoriesGetRequest?: V1ProductCategoriesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ProductCategoriesGet201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ProductCategoriesPost(v1ProductCategoriesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductCategoriesApi - factory interface
 * @export
 */
export const ProductCategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductCategoriesApiFp(configuration)
    return {
        /**
         * 
         * @summary product_categories#index
         * @param {number} [page] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductCategoriesGet(page?: number, content?: string, options?: any): AxiosPromise<V1ProductCategoriesGet200Response> {
            return localVarFp.v1ProductCategoriesGet(page, content, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary product_categories#batch_update
         * @param {string} id 
         * @param {V1ProductCategoriesIdBatchUpdatePatchRequest} [v1ProductCategoriesIdBatchUpdatePatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductCategoriesIdBatchUpdatePatch(id: string, v1ProductCategoriesIdBatchUpdatePatchRequest?: V1ProductCategoriesIdBatchUpdatePatchRequest, options?: any): AxiosPromise<void> {
            return localVarFp.v1ProductCategoriesIdBatchUpdatePatch(id, v1ProductCategoriesIdBatchUpdatePatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary product_categories#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductCategoriesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1ProductCategoriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary product_categories#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductCategoriesIdGet(id: string, options?: any): AxiosPromise<V1ProductCategoriesGet201Response> {
            return localVarFp.v1ProductCategoriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary product_categories#update
         * @param {string} id 
         * @param {V1ProductCategoriesGetRequest} [v1ProductCategoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductCategoriesIdPatch(id: string, v1ProductCategoriesGetRequest?: V1ProductCategoriesGetRequest, options?: any): AxiosPromise<V1ProductCategoriesGet201Response> {
            return localVarFp.v1ProductCategoriesIdPatch(id, v1ProductCategoriesGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary product_categories#products
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductCategoriesIdProductsGet(id: string, options?: any): AxiosPromise<V1ProductCategoriesIdProductsGet200Response> {
            return localVarFp.v1ProductCategoriesIdProductsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary product_categories#create
         * @param {V1ProductCategoriesGetRequest} [v1ProductCategoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductCategoriesPost(v1ProductCategoriesGetRequest?: V1ProductCategoriesGetRequest, options?: any): AxiosPromise<V1ProductCategoriesGet201Response> {
            return localVarFp.v1ProductCategoriesPost(v1ProductCategoriesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1ProductCategoriesGet operation in ProductCategoriesApi.
 * @export
 * @interface ProductCategoriesApiV1ProductCategoriesGetRequest
 */
export interface ProductCategoriesApiV1ProductCategoriesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductCategoriesApiV1ProductCategoriesGet
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof ProductCategoriesApiV1ProductCategoriesGet
     */
    readonly content?: string
}

/**
 * Request parameters for v1ProductCategoriesIdBatchUpdatePatch operation in ProductCategoriesApi.
 * @export
 * @interface ProductCategoriesApiV1ProductCategoriesIdBatchUpdatePatchRequest
 */
export interface ProductCategoriesApiV1ProductCategoriesIdBatchUpdatePatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductCategoriesApiV1ProductCategoriesIdBatchUpdatePatch
     */
    readonly id: string

    /**
     * 
     * @type {V1ProductCategoriesIdBatchUpdatePatchRequest}
     * @memberof ProductCategoriesApiV1ProductCategoriesIdBatchUpdatePatch
     */
    readonly v1ProductCategoriesIdBatchUpdatePatchRequest?: V1ProductCategoriesIdBatchUpdatePatchRequest
}

/**
 * Request parameters for v1ProductCategoriesIdDelete operation in ProductCategoriesApi.
 * @export
 * @interface ProductCategoriesApiV1ProductCategoriesIdDeleteRequest
 */
export interface ProductCategoriesApiV1ProductCategoriesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductCategoriesApiV1ProductCategoriesIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for v1ProductCategoriesIdGet operation in ProductCategoriesApi.
 * @export
 * @interface ProductCategoriesApiV1ProductCategoriesIdGetRequest
 */
export interface ProductCategoriesApiV1ProductCategoriesIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductCategoriesApiV1ProductCategoriesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for v1ProductCategoriesIdPatch operation in ProductCategoriesApi.
 * @export
 * @interface ProductCategoriesApiV1ProductCategoriesIdPatchRequest
 */
export interface ProductCategoriesApiV1ProductCategoriesIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductCategoriesApiV1ProductCategoriesIdPatch
     */
    readonly id: string

    /**
     * 
     * @type {V1ProductCategoriesGetRequest}
     * @memberof ProductCategoriesApiV1ProductCategoriesIdPatch
     */
    readonly v1ProductCategoriesGetRequest?: V1ProductCategoriesGetRequest
}

/**
 * Request parameters for v1ProductCategoriesIdProductsGet operation in ProductCategoriesApi.
 * @export
 * @interface ProductCategoriesApiV1ProductCategoriesIdProductsGetRequest
 */
export interface ProductCategoriesApiV1ProductCategoriesIdProductsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductCategoriesApiV1ProductCategoriesIdProductsGet
     */
    readonly id: string
}

/**
 * Request parameters for v1ProductCategoriesPost operation in ProductCategoriesApi.
 * @export
 * @interface ProductCategoriesApiV1ProductCategoriesPostRequest
 */
export interface ProductCategoriesApiV1ProductCategoriesPostRequest {
    /**
     * 
     * @type {V1ProductCategoriesGetRequest}
     * @memberof ProductCategoriesApiV1ProductCategoriesPost
     */
    readonly v1ProductCategoriesGetRequest?: V1ProductCategoriesGetRequest
}

/**
 * ProductCategoriesApi - object-oriented interface
 * @export
 * @class ProductCategoriesApi
 * @extends {BaseAPI}
 */
export class ProductCategoriesApi extends BaseAPI {
    /**
     * 
     * @summary product_categories#index
     * @param {ProductCategoriesApiV1ProductCategoriesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoriesApi
     */
    public v1ProductCategoriesGet(requestParameters: ProductCategoriesApiV1ProductCategoriesGetRequest = {}, options?: AxiosRequestConfig) {
        return ProductCategoriesApiFp(this.configuration).v1ProductCategoriesGet(requestParameters.page, requestParameters.content, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary product_categories#batch_update
     * @param {ProductCategoriesApiV1ProductCategoriesIdBatchUpdatePatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoriesApi
     */
    public v1ProductCategoriesIdBatchUpdatePatch(requestParameters: ProductCategoriesApiV1ProductCategoriesIdBatchUpdatePatchRequest, options?: AxiosRequestConfig) {
        return ProductCategoriesApiFp(this.configuration).v1ProductCategoriesIdBatchUpdatePatch(requestParameters.id, requestParameters.v1ProductCategoriesIdBatchUpdatePatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary product_categories#destroy
     * @param {ProductCategoriesApiV1ProductCategoriesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoriesApi
     */
    public v1ProductCategoriesIdDelete(requestParameters: ProductCategoriesApiV1ProductCategoriesIdDeleteRequest, options?: AxiosRequestConfig) {
        return ProductCategoriesApiFp(this.configuration).v1ProductCategoriesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary product_categories#show
     * @param {ProductCategoriesApiV1ProductCategoriesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoriesApi
     */
    public v1ProductCategoriesIdGet(requestParameters: ProductCategoriesApiV1ProductCategoriesIdGetRequest, options?: AxiosRequestConfig) {
        return ProductCategoriesApiFp(this.configuration).v1ProductCategoriesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary product_categories#update
     * @param {ProductCategoriesApiV1ProductCategoriesIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoriesApi
     */
    public v1ProductCategoriesIdPatch(requestParameters: ProductCategoriesApiV1ProductCategoriesIdPatchRequest, options?: AxiosRequestConfig) {
        return ProductCategoriesApiFp(this.configuration).v1ProductCategoriesIdPatch(requestParameters.id, requestParameters.v1ProductCategoriesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary product_categories#products
     * @param {ProductCategoriesApiV1ProductCategoriesIdProductsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoriesApi
     */
    public v1ProductCategoriesIdProductsGet(requestParameters: ProductCategoriesApiV1ProductCategoriesIdProductsGetRequest, options?: AxiosRequestConfig) {
        return ProductCategoriesApiFp(this.configuration).v1ProductCategoriesIdProductsGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary product_categories#create
     * @param {ProductCategoriesApiV1ProductCategoriesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoriesApi
     */
    public v1ProductCategoriesPost(requestParameters: ProductCategoriesApiV1ProductCategoriesPostRequest = {}, options?: AxiosRequestConfig) {
        return ProductCategoriesApiFp(this.configuration).v1ProductCategoriesPost(requestParameters.v1ProductCategoriesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductModifiersApi - axios parameter creator
 * @export
 */
export const ProductModifiersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary product_modifiers#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductModifiersIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ProductModifiersIdDelete', 'id', id)
            const localVarPath = `/v1/product_modifiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary product_modifiers#update
         * @param {string} id 
         * @param {V1ProductModifiersPostRequest} [v1ProductModifiersPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductModifiersIdPatch: async (id: string, v1ProductModifiersPostRequest?: V1ProductModifiersPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ProductModifiersIdPatch', 'id', id)
            const localVarPath = `/v1/product_modifiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1ProductModifiersPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary product_modifiers#create
         * @param {V1ProductModifiersPostRequest} [v1ProductModifiersPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductModifiersPost: async (v1ProductModifiersPostRequest?: V1ProductModifiersPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/product_modifiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1ProductModifiersPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductModifiersApi - functional programming interface
 * @export
 */
export const ProductModifiersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductModifiersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary product_modifiers#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductModifiersIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ProductModifiersIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary product_modifiers#update
         * @param {string} id 
         * @param {V1ProductModifiersPostRequest} [v1ProductModifiersPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductModifiersIdPatch(id: string, v1ProductModifiersPostRequest?: V1ProductModifiersPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ProductModifiersPost201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ProductModifiersIdPatch(id, v1ProductModifiersPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary product_modifiers#create
         * @param {V1ProductModifiersPostRequest} [v1ProductModifiersPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductModifiersPost(v1ProductModifiersPostRequest?: V1ProductModifiersPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ProductModifiersPost201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ProductModifiersPost(v1ProductModifiersPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductModifiersApi - factory interface
 * @export
 */
export const ProductModifiersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductModifiersApiFp(configuration)
    return {
        /**
         * 
         * @summary product_modifiers#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductModifiersIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1ProductModifiersIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary product_modifiers#update
         * @param {string} id 
         * @param {V1ProductModifiersPostRequest} [v1ProductModifiersPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductModifiersIdPatch(id: string, v1ProductModifiersPostRequest?: V1ProductModifiersPostRequest, options?: any): AxiosPromise<V1ProductModifiersPost201Response> {
            return localVarFp.v1ProductModifiersIdPatch(id, v1ProductModifiersPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary product_modifiers#create
         * @param {V1ProductModifiersPostRequest} [v1ProductModifiersPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductModifiersPost(v1ProductModifiersPostRequest?: V1ProductModifiersPostRequest, options?: any): AxiosPromise<V1ProductModifiersPost201Response> {
            return localVarFp.v1ProductModifiersPost(v1ProductModifiersPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1ProductModifiersIdDelete operation in ProductModifiersApi.
 * @export
 * @interface ProductModifiersApiV1ProductModifiersIdDeleteRequest
 */
export interface ProductModifiersApiV1ProductModifiersIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductModifiersApiV1ProductModifiersIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for v1ProductModifiersIdPatch operation in ProductModifiersApi.
 * @export
 * @interface ProductModifiersApiV1ProductModifiersIdPatchRequest
 */
export interface ProductModifiersApiV1ProductModifiersIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductModifiersApiV1ProductModifiersIdPatch
     */
    readonly id: string

    /**
     * 
     * @type {V1ProductModifiersPostRequest}
     * @memberof ProductModifiersApiV1ProductModifiersIdPatch
     */
    readonly v1ProductModifiersPostRequest?: V1ProductModifiersPostRequest
}

/**
 * Request parameters for v1ProductModifiersPost operation in ProductModifiersApi.
 * @export
 * @interface ProductModifiersApiV1ProductModifiersPostRequest
 */
export interface ProductModifiersApiV1ProductModifiersPostRequest {
    /**
     * 
     * @type {V1ProductModifiersPostRequest}
     * @memberof ProductModifiersApiV1ProductModifiersPost
     */
    readonly v1ProductModifiersPostRequest?: V1ProductModifiersPostRequest
}

/**
 * ProductModifiersApi - object-oriented interface
 * @export
 * @class ProductModifiersApi
 * @extends {BaseAPI}
 */
export class ProductModifiersApi extends BaseAPI {
    /**
     * 
     * @summary product_modifiers#destroy
     * @param {ProductModifiersApiV1ProductModifiersIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductModifiersApi
     */
    public v1ProductModifiersIdDelete(requestParameters: ProductModifiersApiV1ProductModifiersIdDeleteRequest, options?: AxiosRequestConfig) {
        return ProductModifiersApiFp(this.configuration).v1ProductModifiersIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary product_modifiers#update
     * @param {ProductModifiersApiV1ProductModifiersIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductModifiersApi
     */
    public v1ProductModifiersIdPatch(requestParameters: ProductModifiersApiV1ProductModifiersIdPatchRequest, options?: AxiosRequestConfig) {
        return ProductModifiersApiFp(this.configuration).v1ProductModifiersIdPatch(requestParameters.id, requestParameters.v1ProductModifiersPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary product_modifiers#create
     * @param {ProductModifiersApiV1ProductModifiersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductModifiersApi
     */
    public v1ProductModifiersPost(requestParameters: ProductModifiersApiV1ProductModifiersPostRequest = {}, options?: AxiosRequestConfig) {
        return ProductModifiersApiFp(this.configuration).v1ProductModifiersPost(requestParameters.v1ProductModifiersPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary products#index
         * @param {number} [page] 
         * @param {string} [by] The field you want to search. ie: name barcode
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [productCategoryIds] Search by product_category_ids, comma separated. ie: 1,2,3
         * @param {string} [ids] Search by product ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductsGet: async (page?: number, by?: string, content?: string, productCategoryIds?: string, ids?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (by !== undefined) {
                localVarQueryParameter['by'] = by;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }

            if (productCategoryIds !== undefined) {
                localVarQueryParameter['product_category_ids'] = productCategoryIds;
            }

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary products#destroy
         * @param {string} id 
         * @param {'damage' | 'no_sale'} [deleteReason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductsIdDelete: async (id: string, deleteReason?: 'damage' | 'no_sale', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ProductsIdDelete', 'id', id)
            const localVarPath = `/v1/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (deleteReason !== undefined) {
                localVarQueryParameter['delete_reason'] = deleteReason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary products#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ProductsIdGet', 'id', id)
            const localVarPath = `/v1/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary products#images_order
         * @param {string} id 
         * @param {V1ProductsIdImagesOrderPostRequest} [v1ProductsIdImagesOrderPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductsIdImagesOrderPost: async (id: string, v1ProductsIdImagesOrderPostRequest?: V1ProductsIdImagesOrderPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ProductsIdImagesOrderPost', 'id', id)
            const localVarPath = `/v1/products/{id}/images-order`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1ProductsIdImagesOrderPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary products#modifiers
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductsIdModifiersGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ProductsIdModifiersGet', 'id', id)
            const localVarPath = `/v1/products/{id}/modifiers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary products#update
         * @param {string} id 
         * @param {V1ProductsIdDeleteRequest} [v1ProductsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductsIdPatch: async (id: string, v1ProductsIdDeleteRequest?: V1ProductsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ProductsIdPatch', 'id', id)
            const localVarPath = `/v1/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1ProductsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary products#create
         * @param {V1ProductsMultiplePostRequest} [v1ProductsMultiplePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductsMultiplePost: async (v1ProductsMultiplePostRequest?: V1ProductsMultiplePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/products/multiple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1ProductsMultiplePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary products#pos
         * @param {number} [page] 
         * @param {string} [by] The field you want to search. ie: name barcode
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [productCategoryIds] Search by product_category_ids, comma separated. ie: 1,2,3
         * @param {string} [ids] Search by product ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductsPosGet: async (page?: number, by?: string, content?: string, productCategoryIds?: string, ids?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/products/pos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (by !== undefined) {
                localVarQueryParameter['by'] = by;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }

            if (productCategoryIds !== undefined) {
                localVarQueryParameter['product_category_ids'] = productCategoryIds;
            }

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary products#create
         * @param {V1ProductsGetRequest} [v1ProductsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductsPost: async (v1ProductsGetRequest?: V1ProductsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1ProductsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary products_images#destroy
         * @param {string} productId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductsProductIdImagesIdDelete: async (productId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('v1ProductsProductIdImagesIdDelete', 'productId', productId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ProductsProductIdImagesIdDelete', 'id', id)
            const localVarPath = `/v1/products/{product_id}/images/{id}`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary products#uncategorized
         * @param {number} [page] 
         * @param {string} [content] Send the string you want to search in a products name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductsUncategorizedGet: async (page?: number, content?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/products/uncategorized`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary products#bulk_destroy_2
         * @param {boolean} [allProducts] 
         * @param {string} [productIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ProductsBulkDeleteAppDelete: async (allProducts?: boolean, productIds?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/products/bulk-delete-app`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (allProducts !== undefined) {
                localVarQueryParameter['all_products'] = allProducts;
            }

            if (productIds !== undefined) {
                localVarQueryParameter['product_ids'] = productIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary products#bulk_destroy
         * @param {boolean} [allProducts] 
         * @param {string} [productIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ProductsBulkDeleteDelete: async (allProducts?: boolean, productIds?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/products/bulk-delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (allProducts !== undefined) {
                localVarQueryParameter['all_products'] = allProducts;
            }

            if (productIds !== undefined) {
                localVarQueryParameter['product_ids'] = productIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary products#index
         * @param {number} [page] 
         * @param {string} [by] The field you want to search. ie: name barcode
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [productCategoryIds] Search by product_category_ids, comma separated. ie: 1,2,3
         * @param {'all' | 'low_stock' | 'stock_0'} [filterBy] Filter product by this options
         * @param {string} [ids] Search by product ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ProductsGet: async (page?: number, by?: string, content?: string, productCategoryIds?: string, filterBy?: 'all' | 'low_stock' | 'stock_0', ids?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (by !== undefined) {
                localVarQueryParameter['by'] = by;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }

            if (productCategoryIds !== undefined) {
                localVarQueryParameter['product_category_ids'] = productCategoryIds;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filter_by'] = filterBy;
            }

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary products#pos
         * @param {number} [page] 
         * @param {string} [by] The field you want to search. ie: name barcode
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [productCategoryIds] Search by product_category_ids, comma separated. ie: 1,2,3
         * @param {'all' | 'low_stock' | 'stock_0'} [filterBy] Filter product by this options
         * @param {string} [ids] Search by product ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ProductsPosGet: async (page?: number, by?: string, content?: string, productCategoryIds?: string, filterBy?: 'all' | 'low_stock' | 'stock_0', ids?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/products/pos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (by !== undefined) {
                localVarQueryParameter['by'] = by;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }

            if (productCategoryIds !== undefined) {
                localVarQueryParameter['product_category_ids'] = productCategoryIds;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filter_by'] = filterBy;
            }

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary products#index
         * @param {number} [page] 
         * @param {string} [by] The field you want to search. ie: name barcode
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [productCategoryIds] Search by product_category_ids, comma separated. ie: 1,2,3
         * @param {string} [ids] Search by product ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductsGet(page?: number, by?: string, content?: string, productCategoryIds?: string, ids?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ProductsPosGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ProductsGet(page, by, content, productCategoryIds, ids, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary products#destroy
         * @param {string} id 
         * @param {'damage' | 'no_sale'} [deleteReason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductsIdDelete(id: string, deleteReason?: 'damage' | 'no_sale', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ProductsIdDelete(id, deleteReason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary products#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ProductsIdDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ProductsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary products#images_order
         * @param {string} id 
         * @param {V1ProductsIdImagesOrderPostRequest} [v1ProductsIdImagesOrderPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductsIdImagesOrderPost(id: string, v1ProductsIdImagesOrderPostRequest?: V1ProductsIdImagesOrderPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1OffersGet200ResponseOffersInnerProductsInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ProductsIdImagesOrderPost(id, v1ProductsIdImagesOrderPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary products#modifiers
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductsIdModifiersGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ProductsIdModifiersGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ProductsIdModifiersGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary products#update
         * @param {string} id 
         * @param {V1ProductsIdDeleteRequest} [v1ProductsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductsIdPatch(id: string, v1ProductsIdDeleteRequest?: V1ProductsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1OffersGet200ResponseOffersInnerProductsInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ProductsIdPatch(id, v1ProductsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary products#create
         * @param {V1ProductsMultiplePostRequest} [v1ProductsMultiplePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductsMultiplePost(v1ProductsMultiplePostRequest?: V1ProductsMultiplePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ProductsMultiplePost(v1ProductsMultiplePostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary products#pos
         * @param {number} [page] 
         * @param {string} [by] The field you want to search. ie: name barcode
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [productCategoryIds] Search by product_category_ids, comma separated. ie: 1,2,3
         * @param {string} [ids] Search by product ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductsPosGet(page?: number, by?: string, content?: string, productCategoryIds?: string, ids?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ProductsPosGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ProductsPosGet(page, by, content, productCategoryIds, ids, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary products#create
         * @param {V1ProductsGetRequest} [v1ProductsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductsPost(v1ProductsGetRequest?: V1ProductsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1OffersGet200ResponseOffersInnerProductsInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ProductsPost(v1ProductsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary products_images#destroy
         * @param {string} productId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductsProductIdImagesIdDelete(productId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ProductsProductIdImagesIdDelete(productId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary products#uncategorized
         * @param {number} [page] 
         * @param {string} [content] Send the string you want to search in a products name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProductsUncategorizedGet(page?: number, content?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1OffersProductsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ProductsUncategorizedGet(page, content, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary products#bulk_destroy_2
         * @param {boolean} [allProducts] 
         * @param {string} [productIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ProductsBulkDeleteAppDelete(allProducts?: boolean, productIds?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ProductsBulkDeleteAppDelete(allProducts, productIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary products#bulk_destroy
         * @param {boolean} [allProducts] 
         * @param {string} [productIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ProductsBulkDeleteDelete(allProducts?: boolean, productIds?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ProductsBulkDeleteDelete(allProducts, productIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary products#index
         * @param {number} [page] 
         * @param {string} [by] The field you want to search. ie: name barcode
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [productCategoryIds] Search by product_category_ids, comma separated. ie: 1,2,3
         * @param {'all' | 'low_stock' | 'stock_0'} [filterBy] Filter product by this options
         * @param {string} [ids] Search by product ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ProductsGet(page?: number, by?: string, content?: string, productCategoryIds?: string, filterBy?: 'all' | 'low_stock' | 'stock_0', ids?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2ProductsPosGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ProductsGet(page, by, content, productCategoryIds, filterBy, ids, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary products#pos
         * @param {number} [page] 
         * @param {string} [by] The field you want to search. ie: name barcode
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [productCategoryIds] Search by product_category_ids, comma separated. ie: 1,2,3
         * @param {'all' | 'low_stock' | 'stock_0'} [filterBy] Filter product by this options
         * @param {string} [ids] Search by product ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ProductsPosGet(page?: number, by?: string, content?: string, productCategoryIds?: string, filterBy?: 'all' | 'low_stock' | 'stock_0', ids?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2ProductsPosGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ProductsPosGet(page, by, content, productCategoryIds, filterBy, ids, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * 
         * @summary products#index
         * @param {number} [page] 
         * @param {string} [by] The field you want to search. ie: name barcode
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [productCategoryIds] Search by product_category_ids, comma separated. ie: 1,2,3
         * @param {string} [ids] Search by product ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductsGet(page?: number, by?: string, content?: string, productCategoryIds?: string, ids?: string, order?: string, options?: any): AxiosPromise<V1ProductsPosGet200Response> {
            return localVarFp.v1ProductsGet(page, by, content, productCategoryIds, ids, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary products#destroy
         * @param {string} id 
         * @param {'damage' | 'no_sale'} [deleteReason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductsIdDelete(id: string, deleteReason?: 'damage' | 'no_sale', options?: any): AxiosPromise<void> {
            return localVarFp.v1ProductsIdDelete(id, deleteReason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary products#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductsIdGet(id: string, options?: any): AxiosPromise<V1ProductsIdDelete200Response> {
            return localVarFp.v1ProductsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary products#images_order
         * @param {string} id 
         * @param {V1ProductsIdImagesOrderPostRequest} [v1ProductsIdImagesOrderPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductsIdImagesOrderPost(id: string, v1ProductsIdImagesOrderPostRequest?: V1ProductsIdImagesOrderPostRequest, options?: any): AxiosPromise<V1OffersGet200ResponseOffersInnerProductsInner> {
            return localVarFp.v1ProductsIdImagesOrderPost(id, v1ProductsIdImagesOrderPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary products#modifiers
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductsIdModifiersGet(id: string, options?: any): AxiosPromise<V1ProductsIdModifiersGet200Response> {
            return localVarFp.v1ProductsIdModifiersGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary products#update
         * @param {string} id 
         * @param {V1ProductsIdDeleteRequest} [v1ProductsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductsIdPatch(id: string, v1ProductsIdDeleteRequest?: V1ProductsIdDeleteRequest, options?: any): AxiosPromise<V1OffersGet200ResponseOffersInnerProductsInner> {
            return localVarFp.v1ProductsIdPatch(id, v1ProductsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary products#create
         * @param {V1ProductsMultiplePostRequest} [v1ProductsMultiplePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductsMultiplePost(v1ProductsMultiplePostRequest?: V1ProductsMultiplePostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.v1ProductsMultiplePost(v1ProductsMultiplePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary products#pos
         * @param {number} [page] 
         * @param {string} [by] The field you want to search. ie: name barcode
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [productCategoryIds] Search by product_category_ids, comma separated. ie: 1,2,3
         * @param {string} [ids] Search by product ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductsPosGet(page?: number, by?: string, content?: string, productCategoryIds?: string, ids?: string, order?: string, options?: any): AxiosPromise<V1ProductsPosGet200Response> {
            return localVarFp.v1ProductsPosGet(page, by, content, productCategoryIds, ids, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary products#create
         * @param {V1ProductsGetRequest} [v1ProductsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductsPost(v1ProductsGetRequest?: V1ProductsGetRequest, options?: any): AxiosPromise<V1OffersGet200ResponseOffersInnerProductsInner> {
            return localVarFp.v1ProductsPost(v1ProductsGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary products_images#destroy
         * @param {string} productId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductsProductIdImagesIdDelete(productId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1ProductsProductIdImagesIdDelete(productId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary products#uncategorized
         * @param {number} [page] 
         * @param {string} [content] Send the string you want to search in a products name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProductsUncategorizedGet(page?: number, content?: string, options?: any): AxiosPromise<V1OffersProductsGet200Response> {
            return localVarFp.v1ProductsUncategorizedGet(page, content, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary products#bulk_destroy_2
         * @param {boolean} [allProducts] 
         * @param {string} [productIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ProductsBulkDeleteAppDelete(allProducts?: boolean, productIds?: string, options?: any): AxiosPromise<void> {
            return localVarFp.v2ProductsBulkDeleteAppDelete(allProducts, productIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary products#bulk_destroy
         * @param {boolean} [allProducts] 
         * @param {string} [productIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ProductsBulkDeleteDelete(allProducts?: boolean, productIds?: string, options?: any): AxiosPromise<void> {
            return localVarFp.v2ProductsBulkDeleteDelete(allProducts, productIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary products#index
         * @param {number} [page] 
         * @param {string} [by] The field you want to search. ie: name barcode
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [productCategoryIds] Search by product_category_ids, comma separated. ie: 1,2,3
         * @param {'all' | 'low_stock' | 'stock_0'} [filterBy] Filter product by this options
         * @param {string} [ids] Search by product ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ProductsGet(page?: number, by?: string, content?: string, productCategoryIds?: string, filterBy?: 'all' | 'low_stock' | 'stock_0', ids?: string, order?: string, options?: any): AxiosPromise<V2ProductsPosGet200Response> {
            return localVarFp.v2ProductsGet(page, by, content, productCategoryIds, filterBy, ids, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary products#pos
         * @param {number} [page] 
         * @param {string} [by] The field you want to search. ie: name barcode
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [productCategoryIds] Search by product_category_ids, comma separated. ie: 1,2,3
         * @param {'all' | 'low_stock' | 'stock_0'} [filterBy] Filter product by this options
         * @param {string} [ids] Search by product ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ProductsPosGet(page?: number, by?: string, content?: string, productCategoryIds?: string, filterBy?: 'all' | 'low_stock' | 'stock_0', ids?: string, order?: string, options?: any): AxiosPromise<V2ProductsPosGet200Response> {
            return localVarFp.v2ProductsPosGet(page, by, content, productCategoryIds, filterBy, ids, order, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1ProductsGet operation in ProductsApi.
 * @export
 * @interface ProductsApiV1ProductsGetRequest
 */
export interface ProductsApiV1ProductsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductsApiV1ProductsGet
     */
    readonly page?: number

    /**
     * The field you want to search. ie: name barcode
     * @type {string}
     * @memberof ProductsApiV1ProductsGet
     */
    readonly by?: string

    /**
     * The content you want to search. ie: query prop1,prop2,prop3
     * @type {string}
     * @memberof ProductsApiV1ProductsGet
     */
    readonly content?: string

    /**
     * Search by product_category_ids, comma separated. ie: 1,2,3
     * @type {string}
     * @memberof ProductsApiV1ProductsGet
     */
    readonly productCategoryIds?: string

    /**
     * Search by product ids, comma separated. ie: 1,2,3
     * @type {string}
     * @memberof ProductsApiV1ProductsGet
     */
    readonly ids?: string

    /**
     * Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
     * @type {string}
     * @memberof ProductsApiV1ProductsGet
     */
    readonly order?: string
}

/**
 * Request parameters for v1ProductsIdDelete operation in ProductsApi.
 * @export
 * @interface ProductsApiV1ProductsIdDeleteRequest
 */
export interface ProductsApiV1ProductsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductsApiV1ProductsIdDelete
     */
    readonly id: string

    /**
     * 
     * @type {'damage' | 'no_sale'}
     * @memberof ProductsApiV1ProductsIdDelete
     */
    readonly deleteReason?: 'damage' | 'no_sale'
}

/**
 * Request parameters for v1ProductsIdGet operation in ProductsApi.
 * @export
 * @interface ProductsApiV1ProductsIdGetRequest
 */
export interface ProductsApiV1ProductsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductsApiV1ProductsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for v1ProductsIdImagesOrderPost operation in ProductsApi.
 * @export
 * @interface ProductsApiV1ProductsIdImagesOrderPostRequest
 */
export interface ProductsApiV1ProductsIdImagesOrderPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductsApiV1ProductsIdImagesOrderPost
     */
    readonly id: string

    /**
     * 
     * @type {V1ProductsIdImagesOrderPostRequest}
     * @memberof ProductsApiV1ProductsIdImagesOrderPost
     */
    readonly v1ProductsIdImagesOrderPostRequest?: V1ProductsIdImagesOrderPostRequest
}

/**
 * Request parameters for v1ProductsIdModifiersGet operation in ProductsApi.
 * @export
 * @interface ProductsApiV1ProductsIdModifiersGetRequest
 */
export interface ProductsApiV1ProductsIdModifiersGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductsApiV1ProductsIdModifiersGet
     */
    readonly id: string
}

/**
 * Request parameters for v1ProductsIdPatch operation in ProductsApi.
 * @export
 * @interface ProductsApiV1ProductsIdPatchRequest
 */
export interface ProductsApiV1ProductsIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductsApiV1ProductsIdPatch
     */
    readonly id: string

    /**
     * 
     * @type {V1ProductsIdDeleteRequest}
     * @memberof ProductsApiV1ProductsIdPatch
     */
    readonly v1ProductsIdDeleteRequest?: V1ProductsIdDeleteRequest
}

/**
 * Request parameters for v1ProductsMultiplePost operation in ProductsApi.
 * @export
 * @interface ProductsApiV1ProductsMultiplePostRequest
 */
export interface ProductsApiV1ProductsMultiplePostRequest {
    /**
     * 
     * @type {V1ProductsMultiplePostRequest}
     * @memberof ProductsApiV1ProductsMultiplePost
     */
    readonly v1ProductsMultiplePostRequest?: V1ProductsMultiplePostRequest
}

/**
 * Request parameters for v1ProductsPosGet operation in ProductsApi.
 * @export
 * @interface ProductsApiV1ProductsPosGetRequest
 */
export interface ProductsApiV1ProductsPosGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductsApiV1ProductsPosGet
     */
    readonly page?: number

    /**
     * The field you want to search. ie: name barcode
     * @type {string}
     * @memberof ProductsApiV1ProductsPosGet
     */
    readonly by?: string

    /**
     * The content you want to search. ie: query prop1,prop2,prop3
     * @type {string}
     * @memberof ProductsApiV1ProductsPosGet
     */
    readonly content?: string

    /**
     * Search by product_category_ids, comma separated. ie: 1,2,3
     * @type {string}
     * @memberof ProductsApiV1ProductsPosGet
     */
    readonly productCategoryIds?: string

    /**
     * Search by product ids, comma separated. ie: 1,2,3
     * @type {string}
     * @memberof ProductsApiV1ProductsPosGet
     */
    readonly ids?: string

    /**
     * Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
     * @type {string}
     * @memberof ProductsApiV1ProductsPosGet
     */
    readonly order?: string
}

/**
 * Request parameters for v1ProductsPost operation in ProductsApi.
 * @export
 * @interface ProductsApiV1ProductsPostRequest
 */
export interface ProductsApiV1ProductsPostRequest {
    /**
     * 
     * @type {V1ProductsGetRequest}
     * @memberof ProductsApiV1ProductsPost
     */
    readonly v1ProductsGetRequest?: V1ProductsGetRequest
}

/**
 * Request parameters for v1ProductsProductIdImagesIdDelete operation in ProductsApi.
 * @export
 * @interface ProductsApiV1ProductsProductIdImagesIdDeleteRequest
 */
export interface ProductsApiV1ProductsProductIdImagesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductsApiV1ProductsProductIdImagesIdDelete
     */
    readonly productId: string

    /**
     * 
     * @type {string}
     * @memberof ProductsApiV1ProductsProductIdImagesIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for v1ProductsUncategorizedGet operation in ProductsApi.
 * @export
 * @interface ProductsApiV1ProductsUncategorizedGetRequest
 */
export interface ProductsApiV1ProductsUncategorizedGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductsApiV1ProductsUncategorizedGet
     */
    readonly page?: number

    /**
     * Send the string you want to search in a products name
     * @type {string}
     * @memberof ProductsApiV1ProductsUncategorizedGet
     */
    readonly content?: string
}

/**
 * Request parameters for v2ProductsBulkDeleteAppDelete operation in ProductsApi.
 * @export
 * @interface ProductsApiV2ProductsBulkDeleteAppDeleteRequest
 */
export interface ProductsApiV2ProductsBulkDeleteAppDeleteRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ProductsApiV2ProductsBulkDeleteAppDelete
     */
    readonly allProducts?: boolean

    /**
     * 
     * @type {string}
     * @memberof ProductsApiV2ProductsBulkDeleteAppDelete
     */
    readonly productIds?: string
}

/**
 * Request parameters for v2ProductsBulkDeleteDelete operation in ProductsApi.
 * @export
 * @interface ProductsApiV2ProductsBulkDeleteDeleteRequest
 */
export interface ProductsApiV2ProductsBulkDeleteDeleteRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ProductsApiV2ProductsBulkDeleteDelete
     */
    readonly allProducts?: boolean

    /**
     * 
     * @type {string}
     * @memberof ProductsApiV2ProductsBulkDeleteDelete
     */
    readonly productIds?: string
}

/**
 * Request parameters for v2ProductsGet operation in ProductsApi.
 * @export
 * @interface ProductsApiV2ProductsGetRequest
 */
export interface ProductsApiV2ProductsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductsApiV2ProductsGet
     */
    readonly page?: number

    /**
     * The field you want to search. ie: name barcode
     * @type {string}
     * @memberof ProductsApiV2ProductsGet
     */
    readonly by?: string

    /**
     * The content you want to search. ie: query prop1,prop2,prop3
     * @type {string}
     * @memberof ProductsApiV2ProductsGet
     */
    readonly content?: string

    /**
     * Search by product_category_ids, comma separated. ie: 1,2,3
     * @type {string}
     * @memberof ProductsApiV2ProductsGet
     */
    readonly productCategoryIds?: string

    /**
     * Filter product by this options
     * @type {'all' | 'low_stock' | 'stock_0'}
     * @memberof ProductsApiV2ProductsGet
     */
    readonly filterBy?: 'all' | 'low_stock' | 'stock_0'

    /**
     * Search by product ids, comma separated. ie: 1,2,3
     * @type {string}
     * @memberof ProductsApiV2ProductsGet
     */
    readonly ids?: string

    /**
     * Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
     * @type {string}
     * @memberof ProductsApiV2ProductsGet
     */
    readonly order?: string
}

/**
 * Request parameters for v2ProductsPosGet operation in ProductsApi.
 * @export
 * @interface ProductsApiV2ProductsPosGetRequest
 */
export interface ProductsApiV2ProductsPosGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductsApiV2ProductsPosGet
     */
    readonly page?: number

    /**
     * The field you want to search. ie: name barcode
     * @type {string}
     * @memberof ProductsApiV2ProductsPosGet
     */
    readonly by?: string

    /**
     * The content you want to search. ie: query prop1,prop2,prop3
     * @type {string}
     * @memberof ProductsApiV2ProductsPosGet
     */
    readonly content?: string

    /**
     * Search by product_category_ids, comma separated. ie: 1,2,3
     * @type {string}
     * @memberof ProductsApiV2ProductsPosGet
     */
    readonly productCategoryIds?: string

    /**
     * Filter product by this options
     * @type {'all' | 'low_stock' | 'stock_0'}
     * @memberof ProductsApiV2ProductsPosGet
     */
    readonly filterBy?: 'all' | 'low_stock' | 'stock_0'

    /**
     * Search by product ids, comma separated. ie: 1,2,3
     * @type {string}
     * @memberof ProductsApiV2ProductsPosGet
     */
    readonly ids?: string

    /**
     * Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
     * @type {string}
     * @memberof ProductsApiV2ProductsPosGet
     */
    readonly order?: string
}

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * 
     * @summary products#index
     * @param {ProductsApiV1ProductsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public v1ProductsGet(requestParameters: ProductsApiV1ProductsGetRequest = {}, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).v1ProductsGet(requestParameters.page, requestParameters.by, requestParameters.content, requestParameters.productCategoryIds, requestParameters.ids, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary products#destroy
     * @param {ProductsApiV1ProductsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public v1ProductsIdDelete(requestParameters: ProductsApiV1ProductsIdDeleteRequest, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).v1ProductsIdDelete(requestParameters.id, requestParameters.deleteReason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary products#show
     * @param {ProductsApiV1ProductsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public v1ProductsIdGet(requestParameters: ProductsApiV1ProductsIdGetRequest, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).v1ProductsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary products#images_order
     * @param {ProductsApiV1ProductsIdImagesOrderPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public v1ProductsIdImagesOrderPost(requestParameters: ProductsApiV1ProductsIdImagesOrderPostRequest, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).v1ProductsIdImagesOrderPost(requestParameters.id, requestParameters.v1ProductsIdImagesOrderPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary products#modifiers
     * @param {ProductsApiV1ProductsIdModifiersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public v1ProductsIdModifiersGet(requestParameters: ProductsApiV1ProductsIdModifiersGetRequest, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).v1ProductsIdModifiersGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary products#update
     * @param {ProductsApiV1ProductsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public v1ProductsIdPatch(requestParameters: ProductsApiV1ProductsIdPatchRequest, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).v1ProductsIdPatch(requestParameters.id, requestParameters.v1ProductsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary products#create
     * @param {ProductsApiV1ProductsMultiplePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public v1ProductsMultiplePost(requestParameters: ProductsApiV1ProductsMultiplePostRequest = {}, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).v1ProductsMultiplePost(requestParameters.v1ProductsMultiplePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary products#pos
     * @param {ProductsApiV1ProductsPosGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public v1ProductsPosGet(requestParameters: ProductsApiV1ProductsPosGetRequest = {}, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).v1ProductsPosGet(requestParameters.page, requestParameters.by, requestParameters.content, requestParameters.productCategoryIds, requestParameters.ids, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary products#create
     * @param {ProductsApiV1ProductsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public v1ProductsPost(requestParameters: ProductsApiV1ProductsPostRequest = {}, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).v1ProductsPost(requestParameters.v1ProductsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary products_images#destroy
     * @param {ProductsApiV1ProductsProductIdImagesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public v1ProductsProductIdImagesIdDelete(requestParameters: ProductsApiV1ProductsProductIdImagesIdDeleteRequest, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).v1ProductsProductIdImagesIdDelete(requestParameters.productId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary products#uncategorized
     * @param {ProductsApiV1ProductsUncategorizedGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public v1ProductsUncategorizedGet(requestParameters: ProductsApiV1ProductsUncategorizedGetRequest = {}, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).v1ProductsUncategorizedGet(requestParameters.page, requestParameters.content, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary products#bulk_destroy_2
     * @param {ProductsApiV2ProductsBulkDeleteAppDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public v2ProductsBulkDeleteAppDelete(requestParameters: ProductsApiV2ProductsBulkDeleteAppDeleteRequest = {}, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).v2ProductsBulkDeleteAppDelete(requestParameters.allProducts, requestParameters.productIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary products#bulk_destroy
     * @param {ProductsApiV2ProductsBulkDeleteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public v2ProductsBulkDeleteDelete(requestParameters: ProductsApiV2ProductsBulkDeleteDeleteRequest = {}, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).v2ProductsBulkDeleteDelete(requestParameters.allProducts, requestParameters.productIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary products#index
     * @param {ProductsApiV2ProductsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public v2ProductsGet(requestParameters: ProductsApiV2ProductsGetRequest = {}, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).v2ProductsGet(requestParameters.page, requestParameters.by, requestParameters.content, requestParameters.productCategoryIds, requestParameters.filterBy, requestParameters.ids, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary products#pos
     * @param {ProductsApiV2ProductsPosGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public v2ProductsPosGet(requestParameters: ProductsApiV2ProductsPosGetRequest = {}, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).v2ProductsPosGet(requestParameters.page, requestParameters.by, requestParameters.content, requestParameters.productCategoryIds, requestParameters.filterBy, requestParameters.ids, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReceiptsApi - axios parameter creator
 * @export
 */
export const ReceiptsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary receipts#index
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {'sales' | 'expenses' | 'deposit' | 'stock'} [transactionType] 
         * @param {'cash' | 'card' | 'other' | 'bank' | 'on-credit'} [paymentMethod] 
         * @param {number} [userId] 
         * @param {boolean} [recurrent] 
         * @param {number} [page] 
         * @param {string} [ids] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReceiptsGet: async (from?: string, until?: string, transactionType?: 'sales' | 'expenses' | 'deposit' | 'stock', paymentMethod?: 'cash' | 'card' | 'other' | 'bank' | 'on-credit', userId?: number, recurrent?: boolean, page?: number, ids?: string, code?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/receipts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }

            if (transactionType !== undefined) {
                localVarQueryParameter['transaction_type'] = transactionType;
            }

            if (paymentMethod !== undefined) {
                localVarQueryParameter['payment_method'] = paymentMethod;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (recurrent !== undefined) {
                localVarQueryParameter['recurrent'] = recurrent;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary receipts#show
         * @param {string} id 
         * @param {'a4'} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReceiptsIdGet: async (id: string, format?: 'a4', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ReceiptsIdGet', 'id', id)
            const localVarPath = `/v1/receipts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary receipts#quote
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReceiptsQuoteIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ReceiptsQuoteIdGet', 'id', id)
            const localVarPath = `/v1/receipts/quote/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceiptsApi - functional programming interface
 * @export
 */
export const ReceiptsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceiptsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary receipts#index
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {'sales' | 'expenses' | 'deposit' | 'stock'} [transactionType] 
         * @param {'cash' | 'card' | 'other' | 'bank' | 'on-credit'} [paymentMethod] 
         * @param {number} [userId] 
         * @param {boolean} [recurrent] 
         * @param {number} [page] 
         * @param {string} [ids] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReceiptsGet(from?: string, until?: string, transactionType?: 'sales' | 'expenses' | 'deposit' | 'stock', paymentMethod?: 'cash' | 'card' | 'other' | 'bank' | 'on-credit', userId?: number, recurrent?: boolean, page?: number, ids?: string, code?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersPreValidateSubscriptionPost422Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReceiptsGet(from, until, transactionType, paymentMethod, userId, recurrent, page, ids, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary receipts#show
         * @param {string} id 
         * @param {'a4'} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReceiptsIdGet(id: string, format?: 'a4', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReceiptsIdGet(id, format, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary receipts#quote
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReceiptsQuoteIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReceiptsQuoteIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReceiptsApi - factory interface
 * @export
 */
export const ReceiptsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceiptsApiFp(configuration)
    return {
        /**
         * 
         * @summary receipts#index
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {'sales' | 'expenses' | 'deposit' | 'stock'} [transactionType] 
         * @param {'cash' | 'card' | 'other' | 'bank' | 'on-credit'} [paymentMethod] 
         * @param {number} [userId] 
         * @param {boolean} [recurrent] 
         * @param {number} [page] 
         * @param {string} [ids] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReceiptsGet(from?: string, until?: string, transactionType?: 'sales' | 'expenses' | 'deposit' | 'stock', paymentMethod?: 'cash' | 'card' | 'other' | 'bank' | 'on-credit', userId?: number, recurrent?: boolean, page?: number, ids?: string, code?: string, options?: any): AxiosPromise<UsersPreValidateSubscriptionPost422Response> {
            return localVarFp.v1ReceiptsGet(from, until, transactionType, paymentMethod, userId, recurrent, page, ids, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary receipts#show
         * @param {string} id 
         * @param {'a4'} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReceiptsIdGet(id: string, format?: 'a4', options?: any): AxiosPromise<File> {
            return localVarFp.v1ReceiptsIdGet(id, format, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary receipts#quote
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReceiptsQuoteIdGet(id: string, options?: any): AxiosPromise<File> {
            return localVarFp.v1ReceiptsQuoteIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1ReceiptsGet operation in ReceiptsApi.
 * @export
 * @interface ReceiptsApiV1ReceiptsGetRequest
 */
export interface ReceiptsApiV1ReceiptsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ReceiptsApiV1ReceiptsGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof ReceiptsApiV1ReceiptsGet
     */
    readonly until?: string

    /**
     * 
     * @type {'sales' | 'expenses' | 'deposit' | 'stock'}
     * @memberof ReceiptsApiV1ReceiptsGet
     */
    readonly transactionType?: 'sales' | 'expenses' | 'deposit' | 'stock'

    /**
     * 
     * @type {'cash' | 'card' | 'other' | 'bank' | 'on-credit'}
     * @memberof ReceiptsApiV1ReceiptsGet
     */
    readonly paymentMethod?: 'cash' | 'card' | 'other' | 'bank' | 'on-credit'

    /**
     * 
     * @type {number}
     * @memberof ReceiptsApiV1ReceiptsGet
     */
    readonly userId?: number

    /**
     * 
     * @type {boolean}
     * @memberof ReceiptsApiV1ReceiptsGet
     */
    readonly recurrent?: boolean

    /**
     * 
     * @type {number}
     * @memberof ReceiptsApiV1ReceiptsGet
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof ReceiptsApiV1ReceiptsGet
     */
    readonly ids?: string

    /**
     * 
     * @type {string}
     * @memberof ReceiptsApiV1ReceiptsGet
     */
    readonly code?: string
}

/**
 * Request parameters for v1ReceiptsIdGet operation in ReceiptsApi.
 * @export
 * @interface ReceiptsApiV1ReceiptsIdGetRequest
 */
export interface ReceiptsApiV1ReceiptsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ReceiptsApiV1ReceiptsIdGet
     */
    readonly id: string

    /**
     * 
     * @type {'a4'}
     * @memberof ReceiptsApiV1ReceiptsIdGet
     */
    readonly format?: 'a4'
}

/**
 * Request parameters for v1ReceiptsQuoteIdGet operation in ReceiptsApi.
 * @export
 * @interface ReceiptsApiV1ReceiptsQuoteIdGetRequest
 */
export interface ReceiptsApiV1ReceiptsQuoteIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ReceiptsApiV1ReceiptsQuoteIdGet
     */
    readonly id: string
}

/**
 * ReceiptsApi - object-oriented interface
 * @export
 * @class ReceiptsApi
 * @extends {BaseAPI}
 */
export class ReceiptsApi extends BaseAPI {
    /**
     * 
     * @summary receipts#index
     * @param {ReceiptsApiV1ReceiptsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptsApi
     */
    public v1ReceiptsGet(requestParameters: ReceiptsApiV1ReceiptsGetRequest = {}, options?: AxiosRequestConfig) {
        return ReceiptsApiFp(this.configuration).v1ReceiptsGet(requestParameters.from, requestParameters.until, requestParameters.transactionType, requestParameters.paymentMethod, requestParameters.userId, requestParameters.recurrent, requestParameters.page, requestParameters.ids, requestParameters.code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary receipts#show
     * @param {ReceiptsApiV1ReceiptsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptsApi
     */
    public v1ReceiptsIdGet(requestParameters: ReceiptsApiV1ReceiptsIdGetRequest, options?: AxiosRequestConfig) {
        return ReceiptsApiFp(this.configuration).v1ReceiptsIdGet(requestParameters.id, requestParameters.format, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary receipts#quote
     * @param {ReceiptsApiV1ReceiptsQuoteIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptsApi
     */
    public v1ReceiptsQuoteIdGet(requestParameters: ReceiptsApiV1ReceiptsQuoteIdGetRequest, options?: AxiosRequestConfig) {
        return ReceiptsApiFp(this.configuration).v1ReceiptsQuoteIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary reports#products_stats
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReportsProductsStatsGet: async (from?: string, until?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/products-stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary reports#top_users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReportsTopUsersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/top-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary reports#products_stats
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReportsProductsStatsGet(from?: string, until?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersPreValidateSubscriptionPost422Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReportsProductsStatsGet(from, until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary reports#top_users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReportsTopUsersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersPreValidateSubscriptionPost422Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReportsTopUsersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary reports#products_stats
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReportsProductsStatsGet(from?: string, until?: string, options?: any): AxiosPromise<UsersPreValidateSubscriptionPost422Response> {
            return localVarFp.v1ReportsProductsStatsGet(from, until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary reports#top_users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReportsTopUsersGet(options?: any): AxiosPromise<UsersPreValidateSubscriptionPost422Response> {
            return localVarFp.v1ReportsTopUsersGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1ReportsProductsStatsGet operation in ReportsApi.
 * @export
 * @interface ReportsApiV1ReportsProductsStatsGetRequest
 */
export interface ReportsApiV1ReportsProductsStatsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiV1ReportsProductsStatsGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiV1ReportsProductsStatsGet
     */
    readonly until?: string
}

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @summary reports#products_stats
     * @param {ReportsApiV1ReportsProductsStatsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public v1ReportsProductsStatsGet(requestParameters: ReportsApiV1ReportsProductsStatsGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).v1ReportsProductsStatsGet(requestParameters.from, requestParameters.until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary reports#top_users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public v1ReportsTopUsersGet(options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).v1ReportsTopUsersGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary roles#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RolesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary roles#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RolesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1RolesIdGet', 'id', id)
            const localVarPath = `/v1/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary roles#update
         * @param {string} id 
         * @param {V1RolesIdGetRequest} [v1RolesIdGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RolesIdPatch: async (id: string, v1RolesIdGetRequest?: V1RolesIdGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1RolesIdPatch', 'id', id)
            const localVarPath = `/v1/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1RolesIdGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary roles#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1RolesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1RolesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1RolesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary roles#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1RolesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1RolesGet200ResponseRolesInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1RolesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary roles#update
         * @param {string} id 
         * @param {V1RolesIdGetRequest} [v1RolesIdGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1RolesIdPatch(id: string, v1RolesIdGetRequest?: V1RolesIdGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1RolesGet200ResponseRolesInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1RolesIdPatch(id, v1RolesIdGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesApiFp(configuration)
    return {
        /**
         * 
         * @summary roles#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RolesGet(options?: any): AxiosPromise<V1RolesGet200Response> {
            return localVarFp.v1RolesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary roles#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RolesIdGet(id: string, options?: any): AxiosPromise<V1RolesGet200ResponseRolesInner> {
            return localVarFp.v1RolesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary roles#update
         * @param {string} id 
         * @param {V1RolesIdGetRequest} [v1RolesIdGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RolesIdPatch(id: string, v1RolesIdGetRequest?: V1RolesIdGetRequest, options?: any): AxiosPromise<V1RolesGet200ResponseRolesInner> {
            return localVarFp.v1RolesIdPatch(id, v1RolesIdGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1RolesIdGet operation in RolesApi.
 * @export
 * @interface RolesApiV1RolesIdGetRequest
 */
export interface RolesApiV1RolesIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof RolesApiV1RolesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for v1RolesIdPatch operation in RolesApi.
 * @export
 * @interface RolesApiV1RolesIdPatchRequest
 */
export interface RolesApiV1RolesIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof RolesApiV1RolesIdPatch
     */
    readonly id: string

    /**
     * 
     * @type {V1RolesIdGetRequest}
     * @memberof RolesApiV1RolesIdPatch
     */
    readonly v1RolesIdGetRequest?: V1RolesIdGetRequest
}

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
    /**
     * 
     * @summary roles#index
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public v1RolesGet(options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).v1RolesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary roles#show
     * @param {RolesApiV1RolesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public v1RolesIdGet(requestParameters: RolesApiV1RolesIdGetRequest, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).v1RolesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary roles#update
     * @param {RolesApiV1RolesIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public v1RolesIdPatch(requestParameters: RolesApiV1RolesIdPatchRequest, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).v1RolesIdPatch(requestParameters.id, requestParameters.v1RolesIdGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SaleTransactionsApi - axios parameter creator
 * @export
 */
export const SaleTransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary sale_transactions#index
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesTransactionsGet: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/sales-transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sale_transactions#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesTransactionsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1SalesTransactionsIdDelete', 'id', id)
            const localVarPath = `/v1/sales-transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sale_transactions#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesTransactionsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1SalesTransactionsIdGet', 'id', id)
            const localVarPath = `/v1/sales-transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sale_transactions#update
         * @param {string} id 
         * @param {V1SalesTransactionsIdDeleteRequest} [v1SalesTransactionsIdDeleteRequest] contact_id is required if payment_type is \&quot;credit\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesTransactionsIdPatch: async (id: string, v1SalesTransactionsIdDeleteRequest?: V1SalesTransactionsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1SalesTransactionsIdPatch', 'id', id)
            const localVarPath = `/v1/sales-transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1SalesTransactionsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sale_transactions#create
         * @param {V1SalesTransactionsGetRequest} [v1SalesTransactionsGetRequest] contact_id is required if payment_type is \&quot;credit\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesTransactionsPost: async (v1SalesTransactionsGetRequest?: V1SalesTransactionsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/sales-transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1SalesTransactionsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sale_transactions#destroy_quote
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesTransactionsQuoteIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1SalesTransactionsQuoteIdDelete', 'id', id)
            const localVarPath = `/v1/sales-transactions/quote/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sale_transactions#single_quote
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesTransactionsQuoteIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1SalesTransactionsQuoteIdGet', 'id', id)
            const localVarPath = `/v1/sales-transactions/quote/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sale_transactions#update_quote
         * @param {string} id 
         * @param {V1SalesTransactionsQuoteIdDeleteRequest} [v1SalesTransactionsQuoteIdDeleteRequest] contact_id is required
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesTransactionsQuoteIdPatch: async (id: string, v1SalesTransactionsQuoteIdDeleteRequest?: V1SalesTransactionsQuoteIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1SalesTransactionsQuoteIdPatch', 'id', id)
            const localVarPath = `/v1/sales-transactions/quote/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1SalesTransactionsQuoteIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sale_transactions#validate_product_stock
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesTransactionsQuoteIdValidateProductStockGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1SalesTransactionsQuoteIdValidateProductStockGet', 'id', id)
            const localVarPath = `/v1/sales-transactions/quote/{id}/validate-product-stock`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sales_transactions#quote
         * @param {V1SalesTransactionsQuotePostRequest} [v1SalesTransactionsQuotePostRequest] contact_id is required if payment_type is \&quot;credit\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesTransactionsQuotePost: async (v1SalesTransactionsQuotePostRequest?: V1SalesTransactionsQuotePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/sales-transactions/quote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1SalesTransactionsQuotePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sale_transactions#quotes
         * @param {number} [page] 
         * @param {string} [code] Search by quote code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesTransactionsQuotesGet: async (page?: number, code?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/sales-transactions/quotes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SaleTransactionsApi - functional programming interface
 * @export
 */
export const SaleTransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SaleTransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary sale_transactions#index
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SalesTransactionsGet(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1SalesTransactionsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1SalesTransactionsGet(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sale_transactions#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SalesTransactionsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1SalesTransactionsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sale_transactions#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SalesTransactionsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1SalesTransactionsIdDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1SalesTransactionsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sale_transactions#update
         * @param {string} id 
         * @param {V1SalesTransactionsIdDeleteRequest} [v1SalesTransactionsIdDeleteRequest] contact_id is required if payment_type is \&quot;credit\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SalesTransactionsIdPatch(id: string, v1SalesTransactionsIdDeleteRequest?: V1SalesTransactionsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1SalesTransactionsIdDelete200Response1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1SalesTransactionsIdPatch(id, v1SalesTransactionsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sale_transactions#create
         * @param {V1SalesTransactionsGetRequest} [v1SalesTransactionsGetRequest] contact_id is required if payment_type is \&quot;credit\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SalesTransactionsPost(v1SalesTransactionsGetRequest?: V1SalesTransactionsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1SalesTransactionsGet201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1SalesTransactionsPost(v1SalesTransactionsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sale_transactions#destroy_quote
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SalesTransactionsQuoteIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1SalesTransactionsQuoteIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sale_transactions#single_quote
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SalesTransactionsQuoteIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1SalesTransactionsQuoteIdDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1SalesTransactionsQuoteIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sale_transactions#update_quote
         * @param {string} id 
         * @param {V1SalesTransactionsQuoteIdDeleteRequest} [v1SalesTransactionsQuoteIdDeleteRequest] contact_id is required
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SalesTransactionsQuoteIdPatch(id: string, v1SalesTransactionsQuoteIdDeleteRequest?: V1SalesTransactionsQuoteIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1SalesTransactionsQuoteIdDelete200Response1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1SalesTransactionsQuoteIdPatch(id, v1SalesTransactionsQuoteIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sale_transactions#validate_product_stock
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SalesTransactionsQuoteIdValidateProductStockGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1SalesTransactionsQuoteIdValidateProductStockGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1SalesTransactionsQuoteIdValidateProductStockGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sales_transactions#quote
         * @param {V1SalesTransactionsQuotePostRequest} [v1SalesTransactionsQuotePostRequest] contact_id is required if payment_type is \&quot;credit\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SalesTransactionsQuotePost(v1SalesTransactionsQuotePostRequest?: V1SalesTransactionsQuotePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1SalesTransactionsQuotePost201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1SalesTransactionsQuotePost(v1SalesTransactionsQuotePostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sale_transactions#quotes
         * @param {number} [page] 
         * @param {string} [code] Search by quote code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SalesTransactionsQuotesGet(page?: number, code?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1SalesTransactionsQuotesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1SalesTransactionsQuotesGet(page, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SaleTransactionsApi - factory interface
 * @export
 */
export const SaleTransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SaleTransactionsApiFp(configuration)
    return {
        /**
         * 
         * @summary sale_transactions#index
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesTransactionsGet(page?: number, options?: any): AxiosPromise<V1SalesTransactionsGet200Response> {
            return localVarFp.v1SalesTransactionsGet(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sale_transactions#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesTransactionsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1SalesTransactionsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sale_transactions#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesTransactionsIdGet(id: string, options?: any): AxiosPromise<V1SalesTransactionsIdDelete200Response> {
            return localVarFp.v1SalesTransactionsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sale_transactions#update
         * @param {string} id 
         * @param {V1SalesTransactionsIdDeleteRequest} [v1SalesTransactionsIdDeleteRequest] contact_id is required if payment_type is \&quot;credit\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesTransactionsIdPatch(id: string, v1SalesTransactionsIdDeleteRequest?: V1SalesTransactionsIdDeleteRequest, options?: any): AxiosPromise<V1SalesTransactionsIdDelete200Response1> {
            return localVarFp.v1SalesTransactionsIdPatch(id, v1SalesTransactionsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sale_transactions#create
         * @param {V1SalesTransactionsGetRequest} [v1SalesTransactionsGetRequest] contact_id is required if payment_type is \&quot;credit\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesTransactionsPost(v1SalesTransactionsGetRequest?: V1SalesTransactionsGetRequest, options?: any): AxiosPromise<V1SalesTransactionsGet201Response> {
            return localVarFp.v1SalesTransactionsPost(v1SalesTransactionsGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sale_transactions#destroy_quote
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesTransactionsQuoteIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1SalesTransactionsQuoteIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sale_transactions#single_quote
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesTransactionsQuoteIdGet(id: string, options?: any): AxiosPromise<V1SalesTransactionsQuoteIdDelete200Response> {
            return localVarFp.v1SalesTransactionsQuoteIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sale_transactions#update_quote
         * @param {string} id 
         * @param {V1SalesTransactionsQuoteIdDeleteRequest} [v1SalesTransactionsQuoteIdDeleteRequest] contact_id is required
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesTransactionsQuoteIdPatch(id: string, v1SalesTransactionsQuoteIdDeleteRequest?: V1SalesTransactionsQuoteIdDeleteRequest, options?: any): AxiosPromise<V1SalesTransactionsQuoteIdDelete200Response1> {
            return localVarFp.v1SalesTransactionsQuoteIdPatch(id, v1SalesTransactionsQuoteIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sale_transactions#validate_product_stock
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesTransactionsQuoteIdValidateProductStockGet(id: string, options?: any): AxiosPromise<V1SalesTransactionsQuoteIdValidateProductStockGet200Response> {
            return localVarFp.v1SalesTransactionsQuoteIdValidateProductStockGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sales_transactions#quote
         * @param {V1SalesTransactionsQuotePostRequest} [v1SalesTransactionsQuotePostRequest] contact_id is required if payment_type is \&quot;credit\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesTransactionsQuotePost(v1SalesTransactionsQuotePostRequest?: V1SalesTransactionsQuotePostRequest, options?: any): AxiosPromise<V1SalesTransactionsQuotePost201Response> {
            return localVarFp.v1SalesTransactionsQuotePost(v1SalesTransactionsQuotePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sale_transactions#quotes
         * @param {number} [page] 
         * @param {string} [code] Search by quote code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesTransactionsQuotesGet(page?: number, code?: string, options?: any): AxiosPromise<V1SalesTransactionsQuotesGet200Response> {
            return localVarFp.v1SalesTransactionsQuotesGet(page, code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1SalesTransactionsGet operation in SaleTransactionsApi.
 * @export
 * @interface SaleTransactionsApiV1SalesTransactionsGetRequest
 */
export interface SaleTransactionsApiV1SalesTransactionsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof SaleTransactionsApiV1SalesTransactionsGet
     */
    readonly page?: number
}

/**
 * Request parameters for v1SalesTransactionsIdDelete operation in SaleTransactionsApi.
 * @export
 * @interface SaleTransactionsApiV1SalesTransactionsIdDeleteRequest
 */
export interface SaleTransactionsApiV1SalesTransactionsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof SaleTransactionsApiV1SalesTransactionsIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for v1SalesTransactionsIdGet operation in SaleTransactionsApi.
 * @export
 * @interface SaleTransactionsApiV1SalesTransactionsIdGetRequest
 */
export interface SaleTransactionsApiV1SalesTransactionsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof SaleTransactionsApiV1SalesTransactionsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for v1SalesTransactionsIdPatch operation in SaleTransactionsApi.
 * @export
 * @interface SaleTransactionsApiV1SalesTransactionsIdPatchRequest
 */
export interface SaleTransactionsApiV1SalesTransactionsIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof SaleTransactionsApiV1SalesTransactionsIdPatch
     */
    readonly id: string

    /**
     * contact_id is required if payment_type is \&quot;credit\&quot;
     * @type {V1SalesTransactionsIdDeleteRequest}
     * @memberof SaleTransactionsApiV1SalesTransactionsIdPatch
     */
    readonly v1SalesTransactionsIdDeleteRequest?: V1SalesTransactionsIdDeleteRequest
}

/**
 * Request parameters for v1SalesTransactionsPost operation in SaleTransactionsApi.
 * @export
 * @interface SaleTransactionsApiV1SalesTransactionsPostRequest
 */
export interface SaleTransactionsApiV1SalesTransactionsPostRequest {
    /**
     * contact_id is required if payment_type is \&quot;credit\&quot;
     * @type {V1SalesTransactionsGetRequest}
     * @memberof SaleTransactionsApiV1SalesTransactionsPost
     */
    readonly v1SalesTransactionsGetRequest?: V1SalesTransactionsGetRequest
}

/**
 * Request parameters for v1SalesTransactionsQuoteIdDelete operation in SaleTransactionsApi.
 * @export
 * @interface SaleTransactionsApiV1SalesTransactionsQuoteIdDeleteRequest
 */
export interface SaleTransactionsApiV1SalesTransactionsQuoteIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof SaleTransactionsApiV1SalesTransactionsQuoteIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for v1SalesTransactionsQuoteIdGet operation in SaleTransactionsApi.
 * @export
 * @interface SaleTransactionsApiV1SalesTransactionsQuoteIdGetRequest
 */
export interface SaleTransactionsApiV1SalesTransactionsQuoteIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof SaleTransactionsApiV1SalesTransactionsQuoteIdGet
     */
    readonly id: string
}

/**
 * Request parameters for v1SalesTransactionsQuoteIdPatch operation in SaleTransactionsApi.
 * @export
 * @interface SaleTransactionsApiV1SalesTransactionsQuoteIdPatchRequest
 */
export interface SaleTransactionsApiV1SalesTransactionsQuoteIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof SaleTransactionsApiV1SalesTransactionsQuoteIdPatch
     */
    readonly id: string

    /**
     * contact_id is required
     * @type {V1SalesTransactionsQuoteIdDeleteRequest}
     * @memberof SaleTransactionsApiV1SalesTransactionsQuoteIdPatch
     */
    readonly v1SalesTransactionsQuoteIdDeleteRequest?: V1SalesTransactionsQuoteIdDeleteRequest
}

/**
 * Request parameters for v1SalesTransactionsQuoteIdValidateProductStockGet operation in SaleTransactionsApi.
 * @export
 * @interface SaleTransactionsApiV1SalesTransactionsQuoteIdValidateProductStockGetRequest
 */
export interface SaleTransactionsApiV1SalesTransactionsQuoteIdValidateProductStockGetRequest {
    /**
     * 
     * @type {string}
     * @memberof SaleTransactionsApiV1SalesTransactionsQuoteIdValidateProductStockGet
     */
    readonly id: string
}

/**
 * Request parameters for v1SalesTransactionsQuotePost operation in SaleTransactionsApi.
 * @export
 * @interface SaleTransactionsApiV1SalesTransactionsQuotePostRequest
 */
export interface SaleTransactionsApiV1SalesTransactionsQuotePostRequest {
    /**
     * contact_id is required if payment_type is \&quot;credit\&quot;
     * @type {V1SalesTransactionsQuotePostRequest}
     * @memberof SaleTransactionsApiV1SalesTransactionsQuotePost
     */
    readonly v1SalesTransactionsQuotePostRequest?: V1SalesTransactionsQuotePostRequest
}

/**
 * Request parameters for v1SalesTransactionsQuotesGet operation in SaleTransactionsApi.
 * @export
 * @interface SaleTransactionsApiV1SalesTransactionsQuotesGetRequest
 */
export interface SaleTransactionsApiV1SalesTransactionsQuotesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof SaleTransactionsApiV1SalesTransactionsQuotesGet
     */
    readonly page?: number

    /**
     * Search by quote code
     * @type {string}
     * @memberof SaleTransactionsApiV1SalesTransactionsQuotesGet
     */
    readonly code?: string
}

/**
 * SaleTransactionsApi - object-oriented interface
 * @export
 * @class SaleTransactionsApi
 * @extends {BaseAPI}
 */
export class SaleTransactionsApi extends BaseAPI {
    /**
     * 
     * @summary sale_transactions#index
     * @param {SaleTransactionsApiV1SalesTransactionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleTransactionsApi
     */
    public v1SalesTransactionsGet(requestParameters: SaleTransactionsApiV1SalesTransactionsGetRequest = {}, options?: AxiosRequestConfig) {
        return SaleTransactionsApiFp(this.configuration).v1SalesTransactionsGet(requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sale_transactions#destroy
     * @param {SaleTransactionsApiV1SalesTransactionsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleTransactionsApi
     */
    public v1SalesTransactionsIdDelete(requestParameters: SaleTransactionsApiV1SalesTransactionsIdDeleteRequest, options?: AxiosRequestConfig) {
        return SaleTransactionsApiFp(this.configuration).v1SalesTransactionsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sale_transactions#show
     * @param {SaleTransactionsApiV1SalesTransactionsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleTransactionsApi
     */
    public v1SalesTransactionsIdGet(requestParameters: SaleTransactionsApiV1SalesTransactionsIdGetRequest, options?: AxiosRequestConfig) {
        return SaleTransactionsApiFp(this.configuration).v1SalesTransactionsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sale_transactions#update
     * @param {SaleTransactionsApiV1SalesTransactionsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleTransactionsApi
     */
    public v1SalesTransactionsIdPatch(requestParameters: SaleTransactionsApiV1SalesTransactionsIdPatchRequest, options?: AxiosRequestConfig) {
        return SaleTransactionsApiFp(this.configuration).v1SalesTransactionsIdPatch(requestParameters.id, requestParameters.v1SalesTransactionsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sale_transactions#create
     * @param {SaleTransactionsApiV1SalesTransactionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleTransactionsApi
     */
    public v1SalesTransactionsPost(requestParameters: SaleTransactionsApiV1SalesTransactionsPostRequest = {}, options?: AxiosRequestConfig) {
        return SaleTransactionsApiFp(this.configuration).v1SalesTransactionsPost(requestParameters.v1SalesTransactionsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sale_transactions#destroy_quote
     * @param {SaleTransactionsApiV1SalesTransactionsQuoteIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleTransactionsApi
     */
    public v1SalesTransactionsQuoteIdDelete(requestParameters: SaleTransactionsApiV1SalesTransactionsQuoteIdDeleteRequest, options?: AxiosRequestConfig) {
        return SaleTransactionsApiFp(this.configuration).v1SalesTransactionsQuoteIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sale_transactions#single_quote
     * @param {SaleTransactionsApiV1SalesTransactionsQuoteIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleTransactionsApi
     */
    public v1SalesTransactionsQuoteIdGet(requestParameters: SaleTransactionsApiV1SalesTransactionsQuoteIdGetRequest, options?: AxiosRequestConfig) {
        return SaleTransactionsApiFp(this.configuration).v1SalesTransactionsQuoteIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sale_transactions#update_quote
     * @param {SaleTransactionsApiV1SalesTransactionsQuoteIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleTransactionsApi
     */
    public v1SalesTransactionsQuoteIdPatch(requestParameters: SaleTransactionsApiV1SalesTransactionsQuoteIdPatchRequest, options?: AxiosRequestConfig) {
        return SaleTransactionsApiFp(this.configuration).v1SalesTransactionsQuoteIdPatch(requestParameters.id, requestParameters.v1SalesTransactionsQuoteIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sale_transactions#validate_product_stock
     * @param {SaleTransactionsApiV1SalesTransactionsQuoteIdValidateProductStockGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleTransactionsApi
     */
    public v1SalesTransactionsQuoteIdValidateProductStockGet(requestParameters: SaleTransactionsApiV1SalesTransactionsQuoteIdValidateProductStockGetRequest, options?: AxiosRequestConfig) {
        return SaleTransactionsApiFp(this.configuration).v1SalesTransactionsQuoteIdValidateProductStockGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sales_transactions#quote
     * @param {SaleTransactionsApiV1SalesTransactionsQuotePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleTransactionsApi
     */
    public v1SalesTransactionsQuotePost(requestParameters: SaleTransactionsApiV1SalesTransactionsQuotePostRequest = {}, options?: AxiosRequestConfig) {
        return SaleTransactionsApiFp(this.configuration).v1SalesTransactionsQuotePost(requestParameters.v1SalesTransactionsQuotePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sale_transactions#quotes
     * @param {SaleTransactionsApiV1SalesTransactionsQuotesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleTransactionsApi
     */
    public v1SalesTransactionsQuotesGet(requestParameters: SaleTransactionsApiV1SalesTransactionsQuotesGetRequest = {}, options?: AxiosRequestConfig) {
        return SaleTransactionsApiFp(this.configuration).v1SalesTransactionsQuotesGet(requestParameters.page, requestParameters.code, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ServiceCategoriesApi - axios parameter creator
 * @export
 */
export const ServiceCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary service_categories#index
         * @param {number} [page] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceCategoriesGet: async (page?: number, content?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/service-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary service_categories#batch_update
         * @param {string} id 
         * @param {V1ServiceCategoriesIdBatchUpdatePatchRequest} [v1ServiceCategoriesIdBatchUpdatePatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceCategoriesIdBatchUpdatePatch: async (id: string, v1ServiceCategoriesIdBatchUpdatePatchRequest?: V1ServiceCategoriesIdBatchUpdatePatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ServiceCategoriesIdBatchUpdatePatch', 'id', id)
            const localVarPath = `/v1/service-categories/{id}/batch-update`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1ServiceCategoriesIdBatchUpdatePatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary service_categories#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceCategoriesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ServiceCategoriesIdDelete', 'id', id)
            const localVarPath = `/v1/service-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary service_categories#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceCategoriesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ServiceCategoriesIdGet', 'id', id)
            const localVarPath = `/v1/service-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary service_categories#update
         * @param {string} id 
         * @param {V1ServiceCategoriesGetRequest} [v1ServiceCategoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceCategoriesIdPatch: async (id: string, v1ServiceCategoriesGetRequest?: V1ServiceCategoriesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ServiceCategoriesIdPatch', 'id', id)
            const localVarPath = `/v1/service-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1ServiceCategoriesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary service_categories#services
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceCategoriesIdServicesGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ServiceCategoriesIdServicesGet', 'id', id)
            const localVarPath = `/v1/service-categories/{id}/services`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary service_categories#create
         * @param {V1ServiceCategoriesGetRequest} [v1ServiceCategoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceCategoriesPost: async (v1ServiceCategoriesGetRequest?: V1ServiceCategoriesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/service-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1ServiceCategoriesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceCategoriesApi - functional programming interface
 * @export
 */
export const ServiceCategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServiceCategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary service_categories#index
         * @param {number} [page] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ServiceCategoriesGet(page?: number, content?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ServiceCategoriesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ServiceCategoriesGet(page, content, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary service_categories#batch_update
         * @param {string} id 
         * @param {V1ServiceCategoriesIdBatchUpdatePatchRequest} [v1ServiceCategoriesIdBatchUpdatePatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ServiceCategoriesIdBatchUpdatePatch(id: string, v1ServiceCategoriesIdBatchUpdatePatchRequest?: V1ServiceCategoriesIdBatchUpdatePatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ServiceCategoriesIdBatchUpdatePatch(id, v1ServiceCategoriesIdBatchUpdatePatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary service_categories#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ServiceCategoriesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ServiceCategoriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary service_categories#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ServiceCategoriesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ProductCategoriesGet201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ServiceCategoriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary service_categories#update
         * @param {string} id 
         * @param {V1ServiceCategoriesGetRequest} [v1ServiceCategoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ServiceCategoriesIdPatch(id: string, v1ServiceCategoriesGetRequest?: V1ServiceCategoriesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ProductCategoriesGet201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ServiceCategoriesIdPatch(id, v1ServiceCategoriesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary service_categories#services
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ServiceCategoriesIdServicesGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ServiceCategoriesIdServicesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ServiceCategoriesIdServicesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary service_categories#create
         * @param {V1ServiceCategoriesGetRequest} [v1ServiceCategoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ServiceCategoriesPost(v1ServiceCategoriesGetRequest?: V1ServiceCategoriesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ProductCategoriesGet201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ServiceCategoriesPost(v1ServiceCategoriesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ServiceCategoriesApi - factory interface
 * @export
 */
export const ServiceCategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServiceCategoriesApiFp(configuration)
    return {
        /**
         * 
         * @summary service_categories#index
         * @param {number} [page] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceCategoriesGet(page?: number, content?: string, options?: any): AxiosPromise<V1ServiceCategoriesGet200Response> {
            return localVarFp.v1ServiceCategoriesGet(page, content, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary service_categories#batch_update
         * @param {string} id 
         * @param {V1ServiceCategoriesIdBatchUpdatePatchRequest} [v1ServiceCategoriesIdBatchUpdatePatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceCategoriesIdBatchUpdatePatch(id: string, v1ServiceCategoriesIdBatchUpdatePatchRequest?: V1ServiceCategoriesIdBatchUpdatePatchRequest, options?: any): AxiosPromise<void> {
            return localVarFp.v1ServiceCategoriesIdBatchUpdatePatch(id, v1ServiceCategoriesIdBatchUpdatePatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary service_categories#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceCategoriesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1ServiceCategoriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary service_categories#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceCategoriesIdGet(id: string, options?: any): AxiosPromise<V1ProductCategoriesGet201Response> {
            return localVarFp.v1ServiceCategoriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary service_categories#update
         * @param {string} id 
         * @param {V1ServiceCategoriesGetRequest} [v1ServiceCategoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceCategoriesIdPatch(id: string, v1ServiceCategoriesGetRequest?: V1ServiceCategoriesGetRequest, options?: any): AxiosPromise<V1ProductCategoriesGet201Response> {
            return localVarFp.v1ServiceCategoriesIdPatch(id, v1ServiceCategoriesGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary service_categories#services
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceCategoriesIdServicesGet(id: string, options?: any): AxiosPromise<V1ServiceCategoriesIdServicesGet200Response> {
            return localVarFp.v1ServiceCategoriesIdServicesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary service_categories#create
         * @param {V1ServiceCategoriesGetRequest} [v1ServiceCategoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceCategoriesPost(v1ServiceCategoriesGetRequest?: V1ServiceCategoriesGetRequest, options?: any): AxiosPromise<V1ProductCategoriesGet201Response> {
            return localVarFp.v1ServiceCategoriesPost(v1ServiceCategoriesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1ServiceCategoriesGet operation in ServiceCategoriesApi.
 * @export
 * @interface ServiceCategoriesApiV1ServiceCategoriesGetRequest
 */
export interface ServiceCategoriesApiV1ServiceCategoriesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ServiceCategoriesApiV1ServiceCategoriesGet
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof ServiceCategoriesApiV1ServiceCategoriesGet
     */
    readonly content?: string
}

/**
 * Request parameters for v1ServiceCategoriesIdBatchUpdatePatch operation in ServiceCategoriesApi.
 * @export
 * @interface ServiceCategoriesApiV1ServiceCategoriesIdBatchUpdatePatchRequest
 */
export interface ServiceCategoriesApiV1ServiceCategoriesIdBatchUpdatePatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceCategoriesApiV1ServiceCategoriesIdBatchUpdatePatch
     */
    readonly id: string

    /**
     * 
     * @type {V1ServiceCategoriesIdBatchUpdatePatchRequest}
     * @memberof ServiceCategoriesApiV1ServiceCategoriesIdBatchUpdatePatch
     */
    readonly v1ServiceCategoriesIdBatchUpdatePatchRequest?: V1ServiceCategoriesIdBatchUpdatePatchRequest
}

/**
 * Request parameters for v1ServiceCategoriesIdDelete operation in ServiceCategoriesApi.
 * @export
 * @interface ServiceCategoriesApiV1ServiceCategoriesIdDeleteRequest
 */
export interface ServiceCategoriesApiV1ServiceCategoriesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceCategoriesApiV1ServiceCategoriesIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for v1ServiceCategoriesIdGet operation in ServiceCategoriesApi.
 * @export
 * @interface ServiceCategoriesApiV1ServiceCategoriesIdGetRequest
 */
export interface ServiceCategoriesApiV1ServiceCategoriesIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceCategoriesApiV1ServiceCategoriesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for v1ServiceCategoriesIdPatch operation in ServiceCategoriesApi.
 * @export
 * @interface ServiceCategoriesApiV1ServiceCategoriesIdPatchRequest
 */
export interface ServiceCategoriesApiV1ServiceCategoriesIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceCategoriesApiV1ServiceCategoriesIdPatch
     */
    readonly id: string

    /**
     * 
     * @type {V1ServiceCategoriesGetRequest}
     * @memberof ServiceCategoriesApiV1ServiceCategoriesIdPatch
     */
    readonly v1ServiceCategoriesGetRequest?: V1ServiceCategoriesGetRequest
}

/**
 * Request parameters for v1ServiceCategoriesIdServicesGet operation in ServiceCategoriesApi.
 * @export
 * @interface ServiceCategoriesApiV1ServiceCategoriesIdServicesGetRequest
 */
export interface ServiceCategoriesApiV1ServiceCategoriesIdServicesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceCategoriesApiV1ServiceCategoriesIdServicesGet
     */
    readonly id: string
}

/**
 * Request parameters for v1ServiceCategoriesPost operation in ServiceCategoriesApi.
 * @export
 * @interface ServiceCategoriesApiV1ServiceCategoriesPostRequest
 */
export interface ServiceCategoriesApiV1ServiceCategoriesPostRequest {
    /**
     * 
     * @type {V1ServiceCategoriesGetRequest}
     * @memberof ServiceCategoriesApiV1ServiceCategoriesPost
     */
    readonly v1ServiceCategoriesGetRequest?: V1ServiceCategoriesGetRequest
}

/**
 * ServiceCategoriesApi - object-oriented interface
 * @export
 * @class ServiceCategoriesApi
 * @extends {BaseAPI}
 */
export class ServiceCategoriesApi extends BaseAPI {
    /**
     * 
     * @summary service_categories#index
     * @param {ServiceCategoriesApiV1ServiceCategoriesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCategoriesApi
     */
    public v1ServiceCategoriesGet(requestParameters: ServiceCategoriesApiV1ServiceCategoriesGetRequest = {}, options?: AxiosRequestConfig) {
        return ServiceCategoriesApiFp(this.configuration).v1ServiceCategoriesGet(requestParameters.page, requestParameters.content, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary service_categories#batch_update
     * @param {ServiceCategoriesApiV1ServiceCategoriesIdBatchUpdatePatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCategoriesApi
     */
    public v1ServiceCategoriesIdBatchUpdatePatch(requestParameters: ServiceCategoriesApiV1ServiceCategoriesIdBatchUpdatePatchRequest, options?: AxiosRequestConfig) {
        return ServiceCategoriesApiFp(this.configuration).v1ServiceCategoriesIdBatchUpdatePatch(requestParameters.id, requestParameters.v1ServiceCategoriesIdBatchUpdatePatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary service_categories#destroy
     * @param {ServiceCategoriesApiV1ServiceCategoriesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCategoriesApi
     */
    public v1ServiceCategoriesIdDelete(requestParameters: ServiceCategoriesApiV1ServiceCategoriesIdDeleteRequest, options?: AxiosRequestConfig) {
        return ServiceCategoriesApiFp(this.configuration).v1ServiceCategoriesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary service_categories#show
     * @param {ServiceCategoriesApiV1ServiceCategoriesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCategoriesApi
     */
    public v1ServiceCategoriesIdGet(requestParameters: ServiceCategoriesApiV1ServiceCategoriesIdGetRequest, options?: AxiosRequestConfig) {
        return ServiceCategoriesApiFp(this.configuration).v1ServiceCategoriesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary service_categories#update
     * @param {ServiceCategoriesApiV1ServiceCategoriesIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCategoriesApi
     */
    public v1ServiceCategoriesIdPatch(requestParameters: ServiceCategoriesApiV1ServiceCategoriesIdPatchRequest, options?: AxiosRequestConfig) {
        return ServiceCategoriesApiFp(this.configuration).v1ServiceCategoriesIdPatch(requestParameters.id, requestParameters.v1ServiceCategoriesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary service_categories#services
     * @param {ServiceCategoriesApiV1ServiceCategoriesIdServicesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCategoriesApi
     */
    public v1ServiceCategoriesIdServicesGet(requestParameters: ServiceCategoriesApiV1ServiceCategoriesIdServicesGetRequest, options?: AxiosRequestConfig) {
        return ServiceCategoriesApiFp(this.configuration).v1ServiceCategoriesIdServicesGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary service_categories#create
     * @param {ServiceCategoriesApiV1ServiceCategoriesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCategoriesApi
     */
    public v1ServiceCategoriesPost(requestParameters: ServiceCategoriesApiV1ServiceCategoriesPostRequest = {}, options?: AxiosRequestConfig) {
        return ServiceCategoriesApiFp(this.configuration).v1ServiceCategoriesPost(requestParameters.v1ServiceCategoriesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ServicesApi - axios parameter creator
 * @export
 */
export const ServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary services#index
         * @param {number} [page] 
         * @param {string} [by] The field you want to search. ie: name barcode
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [serviceCategoryIds] Search by service_category_ids, comma separated. ie: 1,2,3
         * @param {string} [ids] Search by service ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServicesGet: async (page?: number, by?: string, content?: string, serviceCategoryIds?: string, ids?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (by !== undefined) {
                localVarQueryParameter['by'] = by;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }

            if (serviceCategoryIds !== undefined) {
                localVarQueryParameter['service_category_ids'] = serviceCategoryIds;
            }

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary services#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServicesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ServicesIdDelete', 'id', id)
            const localVarPath = `/v1/services/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary services#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServicesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ServicesIdGet', 'id', id)
            const localVarPath = `/v1/services/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary services#images_order
         * @param {string} id 
         * @param {V1ServicesIdImagesOrderPostRequest} [v1ServicesIdImagesOrderPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServicesIdImagesOrderPost: async (id: string, v1ServicesIdImagesOrderPostRequest?: V1ServicesIdImagesOrderPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ServicesIdImagesOrderPost', 'id', id)
            const localVarPath = `/v1/services/{id}/images-order`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1ServicesIdImagesOrderPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary services#modifiers
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServicesIdModifiersGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ServicesIdModifiersGet', 'id', id)
            const localVarPath = `/v1/services/{id}/modifiers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary services#update
         * @param {string} id 
         * @param {V1ServicesGetRequest} [v1ServicesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServicesIdPatch: async (id: string, v1ServicesGetRequest?: V1ServicesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ServicesIdPatch', 'id', id)
            const localVarPath = `/v1/services/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1ServicesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary services#pos
         * @param {number} [page] 
         * @param {string} [by] The field you want to search. ie: name barcode
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [serviceCategoryIds] Search by service_category_ids, comma separated. ie: 1,2,3
         * @param {string} [ids] Search by service ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServicesPosGet: async (page?: number, by?: string, content?: string, serviceCategoryIds?: string, ids?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/services/pos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (by !== undefined) {
                localVarQueryParameter['by'] = by;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }

            if (serviceCategoryIds !== undefined) {
                localVarQueryParameter['service_category_ids'] = serviceCategoryIds;
            }

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary services#create
         * @param {V1ServicesGetRequest} [v1ServicesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServicesPost: async (v1ServicesGetRequest?: V1ServicesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1ServicesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary services_images#destroy
         * @param {string} serviceId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServicesServiceIdImagesIdDelete: async (serviceId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('v1ServicesServiceIdImagesIdDelete', 'serviceId', serviceId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1ServicesServiceIdImagesIdDelete', 'id', id)
            const localVarPath = `/v1/services/{service_id}/images/{id}`
                .replace(`{${"service_id"}}`, encodeURIComponent(String(serviceId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary services#uncategorized
         * @param {number} [page] 
         * @param {string} [content] Send the string you want to search in a services name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServicesUncategorizedGet: async (page?: number, content?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/services/uncategorized`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServicesApi - functional programming interface
 * @export
 */
export const ServicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary services#index
         * @param {number} [page] 
         * @param {string} [by] The field you want to search. ie: name barcode
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [serviceCategoryIds] Search by service_category_ids, comma separated. ie: 1,2,3
         * @param {string} [ids] Search by service ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ServicesGet(page?: number, by?: string, content?: string, serviceCategoryIds?: string, ids?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ServicesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ServicesGet(page, by, content, serviceCategoryIds, ids, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary services#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ServicesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ServicesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary services#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ServicesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ServiceCategoriesIdServicesGet200ResponseServiceCategoryServicesInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ServicesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary services#images_order
         * @param {string} id 
         * @param {V1ServicesIdImagesOrderPostRequest} [v1ServicesIdImagesOrderPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ServicesIdImagesOrderPost(id: string, v1ServicesIdImagesOrderPostRequest?: V1ServicesIdImagesOrderPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ServiceCategoriesIdServicesGet200ResponseServiceCategoryServicesInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ServicesIdImagesOrderPost(id, v1ServicesIdImagesOrderPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary services#modifiers
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ServicesIdModifiersGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ServicesIdModifiersGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ServicesIdModifiersGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary services#update
         * @param {string} id 
         * @param {V1ServicesGetRequest} [v1ServicesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ServicesIdPatch(id: string, v1ServicesGetRequest?: V1ServicesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ServiceCategoriesIdServicesGet200ResponseServiceCategoryServicesInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ServicesIdPatch(id, v1ServicesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary services#pos
         * @param {number} [page] 
         * @param {string} [by] The field you want to search. ie: name barcode
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [serviceCategoryIds] Search by service_category_ids, comma separated. ie: 1,2,3
         * @param {string} [ids] Search by service ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ServicesPosGet(page?: number, by?: string, content?: string, serviceCategoryIds?: string, ids?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ServicesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ServicesPosGet(page, by, content, serviceCategoryIds, ids, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary services#create
         * @param {V1ServicesGetRequest} [v1ServicesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ServicesPost(v1ServicesGetRequest?: V1ServicesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ServiceCategoriesIdServicesGet200ResponseServiceCategoryServicesInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ServicesPost(v1ServicesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary services_images#destroy
         * @param {string} serviceId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ServicesServiceIdImagesIdDelete(serviceId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ServicesServiceIdImagesIdDelete(serviceId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary services#uncategorized
         * @param {number} [page] 
         * @param {string} [content] Send the string you want to search in a services name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ServicesUncategorizedGet(page?: number, content?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ServicesUncategorizedGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ServicesUncategorizedGet(page, content, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ServicesApi - factory interface
 * @export
 */
export const ServicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServicesApiFp(configuration)
    return {
        /**
         * 
         * @summary services#index
         * @param {number} [page] 
         * @param {string} [by] The field you want to search. ie: name barcode
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [serviceCategoryIds] Search by service_category_ids, comma separated. ie: 1,2,3
         * @param {string} [ids] Search by service ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServicesGet(page?: number, by?: string, content?: string, serviceCategoryIds?: string, ids?: string, order?: string, options?: any): AxiosPromise<V1ServicesGet200Response> {
            return localVarFp.v1ServicesGet(page, by, content, serviceCategoryIds, ids, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary services#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServicesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1ServicesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary services#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServicesIdGet(id: string, options?: any): AxiosPromise<V1ServiceCategoriesIdServicesGet200ResponseServiceCategoryServicesInner> {
            return localVarFp.v1ServicesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary services#images_order
         * @param {string} id 
         * @param {V1ServicesIdImagesOrderPostRequest} [v1ServicesIdImagesOrderPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServicesIdImagesOrderPost(id: string, v1ServicesIdImagesOrderPostRequest?: V1ServicesIdImagesOrderPostRequest, options?: any): AxiosPromise<V1ServiceCategoriesIdServicesGet200ResponseServiceCategoryServicesInner> {
            return localVarFp.v1ServicesIdImagesOrderPost(id, v1ServicesIdImagesOrderPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary services#modifiers
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServicesIdModifiersGet(id: string, options?: any): AxiosPromise<V1ServicesIdModifiersGet200Response> {
            return localVarFp.v1ServicesIdModifiersGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary services#update
         * @param {string} id 
         * @param {V1ServicesGetRequest} [v1ServicesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServicesIdPatch(id: string, v1ServicesGetRequest?: V1ServicesGetRequest, options?: any): AxiosPromise<V1ServiceCategoriesIdServicesGet200ResponseServiceCategoryServicesInner> {
            return localVarFp.v1ServicesIdPatch(id, v1ServicesGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary services#pos
         * @param {number} [page] 
         * @param {string} [by] The field you want to search. ie: name barcode
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [serviceCategoryIds] Search by service_category_ids, comma separated. ie: 1,2,3
         * @param {string} [ids] Search by service ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServicesPosGet(page?: number, by?: string, content?: string, serviceCategoryIds?: string, ids?: string, order?: string, options?: any): AxiosPromise<V1ServicesGet200Response> {
            return localVarFp.v1ServicesPosGet(page, by, content, serviceCategoryIds, ids, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary services#create
         * @param {V1ServicesGetRequest} [v1ServicesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServicesPost(v1ServicesGetRequest?: V1ServicesGetRequest, options?: any): AxiosPromise<V1ServiceCategoriesIdServicesGet200ResponseServiceCategoryServicesInner> {
            return localVarFp.v1ServicesPost(v1ServicesGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary services_images#destroy
         * @param {string} serviceId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServicesServiceIdImagesIdDelete(serviceId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1ServicesServiceIdImagesIdDelete(serviceId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary services#uncategorized
         * @param {number} [page] 
         * @param {string} [content] Send the string you want to search in a services name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServicesUncategorizedGet(page?: number, content?: string, options?: any): AxiosPromise<V1ServicesUncategorizedGet200Response> {
            return localVarFp.v1ServicesUncategorizedGet(page, content, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1ServicesGet operation in ServicesApi.
 * @export
 * @interface ServicesApiV1ServicesGetRequest
 */
export interface ServicesApiV1ServicesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ServicesApiV1ServicesGet
     */
    readonly page?: number

    /**
     * The field you want to search. ie: name barcode
     * @type {string}
     * @memberof ServicesApiV1ServicesGet
     */
    readonly by?: string

    /**
     * The content you want to search. ie: query prop1,prop2,prop3
     * @type {string}
     * @memberof ServicesApiV1ServicesGet
     */
    readonly content?: string

    /**
     * Search by service_category_ids, comma separated. ie: 1,2,3
     * @type {string}
     * @memberof ServicesApiV1ServicesGet
     */
    readonly serviceCategoryIds?: string

    /**
     * Search by service ids, comma separated. ie: 1,2,3
     * @type {string}
     * @memberof ServicesApiV1ServicesGet
     */
    readonly ids?: string

    /**
     * Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
     * @type {string}
     * @memberof ServicesApiV1ServicesGet
     */
    readonly order?: string
}

/**
 * Request parameters for v1ServicesIdDelete operation in ServicesApi.
 * @export
 * @interface ServicesApiV1ServicesIdDeleteRequest
 */
export interface ServicesApiV1ServicesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ServicesApiV1ServicesIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for v1ServicesIdGet operation in ServicesApi.
 * @export
 * @interface ServicesApiV1ServicesIdGetRequest
 */
export interface ServicesApiV1ServicesIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ServicesApiV1ServicesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for v1ServicesIdImagesOrderPost operation in ServicesApi.
 * @export
 * @interface ServicesApiV1ServicesIdImagesOrderPostRequest
 */
export interface ServicesApiV1ServicesIdImagesOrderPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ServicesApiV1ServicesIdImagesOrderPost
     */
    readonly id: string

    /**
     * 
     * @type {V1ServicesIdImagesOrderPostRequest}
     * @memberof ServicesApiV1ServicesIdImagesOrderPost
     */
    readonly v1ServicesIdImagesOrderPostRequest?: V1ServicesIdImagesOrderPostRequest
}

/**
 * Request parameters for v1ServicesIdModifiersGet operation in ServicesApi.
 * @export
 * @interface ServicesApiV1ServicesIdModifiersGetRequest
 */
export interface ServicesApiV1ServicesIdModifiersGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ServicesApiV1ServicesIdModifiersGet
     */
    readonly id: string
}

/**
 * Request parameters for v1ServicesIdPatch operation in ServicesApi.
 * @export
 * @interface ServicesApiV1ServicesIdPatchRequest
 */
export interface ServicesApiV1ServicesIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ServicesApiV1ServicesIdPatch
     */
    readonly id: string

    /**
     * 
     * @type {V1ServicesGetRequest}
     * @memberof ServicesApiV1ServicesIdPatch
     */
    readonly v1ServicesGetRequest?: V1ServicesGetRequest
}

/**
 * Request parameters for v1ServicesPosGet operation in ServicesApi.
 * @export
 * @interface ServicesApiV1ServicesPosGetRequest
 */
export interface ServicesApiV1ServicesPosGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ServicesApiV1ServicesPosGet
     */
    readonly page?: number

    /**
     * The field you want to search. ie: name barcode
     * @type {string}
     * @memberof ServicesApiV1ServicesPosGet
     */
    readonly by?: string

    /**
     * The content you want to search. ie: query prop1,prop2,prop3
     * @type {string}
     * @memberof ServicesApiV1ServicesPosGet
     */
    readonly content?: string

    /**
     * Search by service_category_ids, comma separated. ie: 1,2,3
     * @type {string}
     * @memberof ServicesApiV1ServicesPosGet
     */
    readonly serviceCategoryIds?: string

    /**
     * Search by service ids, comma separated. ie: 1,2,3
     * @type {string}
     * @memberof ServicesApiV1ServicesPosGet
     */
    readonly ids?: string

    /**
     * Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
     * @type {string}
     * @memberof ServicesApiV1ServicesPosGet
     */
    readonly order?: string
}

/**
 * Request parameters for v1ServicesPost operation in ServicesApi.
 * @export
 * @interface ServicesApiV1ServicesPostRequest
 */
export interface ServicesApiV1ServicesPostRequest {
    /**
     * 
     * @type {V1ServicesGetRequest}
     * @memberof ServicesApiV1ServicesPost
     */
    readonly v1ServicesGetRequest?: V1ServicesGetRequest
}

/**
 * Request parameters for v1ServicesServiceIdImagesIdDelete operation in ServicesApi.
 * @export
 * @interface ServicesApiV1ServicesServiceIdImagesIdDeleteRequest
 */
export interface ServicesApiV1ServicesServiceIdImagesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ServicesApiV1ServicesServiceIdImagesIdDelete
     */
    readonly serviceId: string

    /**
     * 
     * @type {string}
     * @memberof ServicesApiV1ServicesServiceIdImagesIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for v1ServicesUncategorizedGet operation in ServicesApi.
 * @export
 * @interface ServicesApiV1ServicesUncategorizedGetRequest
 */
export interface ServicesApiV1ServicesUncategorizedGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ServicesApiV1ServicesUncategorizedGet
     */
    readonly page?: number

    /**
     * Send the string you want to search in a services name
     * @type {string}
     * @memberof ServicesApiV1ServicesUncategorizedGet
     */
    readonly content?: string
}

/**
 * ServicesApi - object-oriented interface
 * @export
 * @class ServicesApi
 * @extends {BaseAPI}
 */
export class ServicesApi extends BaseAPI {
    /**
     * 
     * @summary services#index
     * @param {ServicesApiV1ServicesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public v1ServicesGet(requestParameters: ServicesApiV1ServicesGetRequest = {}, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).v1ServicesGet(requestParameters.page, requestParameters.by, requestParameters.content, requestParameters.serviceCategoryIds, requestParameters.ids, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary services#destroy
     * @param {ServicesApiV1ServicesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public v1ServicesIdDelete(requestParameters: ServicesApiV1ServicesIdDeleteRequest, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).v1ServicesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary services#show
     * @param {ServicesApiV1ServicesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public v1ServicesIdGet(requestParameters: ServicesApiV1ServicesIdGetRequest, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).v1ServicesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary services#images_order
     * @param {ServicesApiV1ServicesIdImagesOrderPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public v1ServicesIdImagesOrderPost(requestParameters: ServicesApiV1ServicesIdImagesOrderPostRequest, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).v1ServicesIdImagesOrderPost(requestParameters.id, requestParameters.v1ServicesIdImagesOrderPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary services#modifiers
     * @param {ServicesApiV1ServicesIdModifiersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public v1ServicesIdModifiersGet(requestParameters: ServicesApiV1ServicesIdModifiersGetRequest, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).v1ServicesIdModifiersGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary services#update
     * @param {ServicesApiV1ServicesIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public v1ServicesIdPatch(requestParameters: ServicesApiV1ServicesIdPatchRequest, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).v1ServicesIdPatch(requestParameters.id, requestParameters.v1ServicesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary services#pos
     * @param {ServicesApiV1ServicesPosGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public v1ServicesPosGet(requestParameters: ServicesApiV1ServicesPosGetRequest = {}, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).v1ServicesPosGet(requestParameters.page, requestParameters.by, requestParameters.content, requestParameters.serviceCategoryIds, requestParameters.ids, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary services#create
     * @param {ServicesApiV1ServicesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public v1ServicesPost(requestParameters: ServicesApiV1ServicesPostRequest = {}, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).v1ServicesPost(requestParameters.v1ServicesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary services_images#destroy
     * @param {ServicesApiV1ServicesServiceIdImagesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public v1ServicesServiceIdImagesIdDelete(requestParameters: ServicesApiV1ServicesServiceIdImagesIdDeleteRequest, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).v1ServicesServiceIdImagesIdDelete(requestParameters.serviceId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary services#uncategorized
     * @param {ServicesApiV1ServicesUncategorizedGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public v1ServicesUncategorizedGet(requestParameters: ServicesApiV1ServicesUncategorizedGetRequest = {}, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).v1ServicesUncategorizedGet(requestParameters.page, requestParameters.content, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShopifyOrganizationsApi - axios parameter creator
 * @export
 */
export const ShopifyOrganizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary organizations#index
         * @param {string} token Authorization token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ShopifyTokenOrganizationsGet: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('v2ShopifyTokenOrganizationsGet', 'token', token)
            const localVarPath = `/v2/shopify/{token}/organizations`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShopifyOrganizationsApi - functional programming interface
 * @export
 */
export const ShopifyOrganizationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShopifyOrganizationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary organizations#index
         * @param {string} token Authorization token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ShopifyTokenOrganizationsGet(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2ShopifyTokenOrganizationsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ShopifyTokenOrganizationsGet(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShopifyOrganizationsApi - factory interface
 * @export
 */
export const ShopifyOrganizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShopifyOrganizationsApiFp(configuration)
    return {
        /**
         * 
         * @summary organizations#index
         * @param {string} token Authorization token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ShopifyTokenOrganizationsGet(token: string, options?: any): AxiosPromise<V2ShopifyTokenOrganizationsGet200Response> {
            return localVarFp.v2ShopifyTokenOrganizationsGet(token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v2ShopifyTokenOrganizationsGet operation in ShopifyOrganizationsApi.
 * @export
 * @interface ShopifyOrganizationsApiV2ShopifyTokenOrganizationsGetRequest
 */
export interface ShopifyOrganizationsApiV2ShopifyTokenOrganizationsGetRequest {
    /**
     * Authorization token
     * @type {string}
     * @memberof ShopifyOrganizationsApiV2ShopifyTokenOrganizationsGet
     */
    readonly token: string
}

/**
 * ShopifyOrganizationsApi - object-oriented interface
 * @export
 * @class ShopifyOrganizationsApi
 * @extends {BaseAPI}
 */
export class ShopifyOrganizationsApi extends BaseAPI {
    /**
     * 
     * @summary organizations#index
     * @param {ShopifyOrganizationsApiV2ShopifyTokenOrganizationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopifyOrganizationsApi
     */
    public v2ShopifyTokenOrganizationsGet(requestParameters: ShopifyOrganizationsApiV2ShopifyTokenOrganizationsGetRequest, options?: AxiosRequestConfig) {
        return ShopifyOrganizationsApiFp(this.configuration).v2ShopifyTokenOrganizationsGet(requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShopifySalesTransactionssApi - axios parameter creator
 * @export
 */
export const ShopifySalesTransactionssApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary sales_transactions#create
         * @param {string} token Authorization token
         * @param {V2ShopifyTokenSalesTransactionsPostRequest} [v2ShopifyTokenSalesTransactionsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ShopifyTokenSalesTransactionsPost: async (token: string, v2ShopifyTokenSalesTransactionsPostRequest?: V2ShopifyTokenSalesTransactionsPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('v2ShopifyTokenSalesTransactionsPost', 'token', token)
            const localVarPath = `/v2/shopify/{token}/sales_transactions`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v2ShopifyTokenSalesTransactionsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShopifySalesTransactionssApi - functional programming interface
 * @export
 */
export const ShopifySalesTransactionssApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShopifySalesTransactionssApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary sales_transactions#create
         * @param {string} token Authorization token
         * @param {V2ShopifyTokenSalesTransactionsPostRequest} [v2ShopifyTokenSalesTransactionsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ShopifyTokenSalesTransactionsPost(token: string, v2ShopifyTokenSalesTransactionsPostRequest?: V2ShopifyTokenSalesTransactionsPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ShopifyTokenSalesTransactionsPost(token, v2ShopifyTokenSalesTransactionsPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShopifySalesTransactionssApi - factory interface
 * @export
 */
export const ShopifySalesTransactionssApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShopifySalesTransactionssApiFp(configuration)
    return {
        /**
         * 
         * @summary sales_transactions#create
         * @param {string} token Authorization token
         * @param {V2ShopifyTokenSalesTransactionsPostRequest} [v2ShopifyTokenSalesTransactionsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ShopifyTokenSalesTransactionsPost(token: string, v2ShopifyTokenSalesTransactionsPostRequest?: V2ShopifyTokenSalesTransactionsPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.v2ShopifyTokenSalesTransactionsPost(token, v2ShopifyTokenSalesTransactionsPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v2ShopifyTokenSalesTransactionsPost operation in ShopifySalesTransactionssApi.
 * @export
 * @interface ShopifySalesTransactionssApiV2ShopifyTokenSalesTransactionsPostRequest
 */
export interface ShopifySalesTransactionssApiV2ShopifyTokenSalesTransactionsPostRequest {
    /**
     * Authorization token
     * @type {string}
     * @memberof ShopifySalesTransactionssApiV2ShopifyTokenSalesTransactionsPost
     */
    readonly token: string

    /**
     * 
     * @type {V2ShopifyTokenSalesTransactionsPostRequest}
     * @memberof ShopifySalesTransactionssApiV2ShopifyTokenSalesTransactionsPost
     */
    readonly v2ShopifyTokenSalesTransactionsPostRequest?: V2ShopifyTokenSalesTransactionsPostRequest
}

/**
 * ShopifySalesTransactionssApi - object-oriented interface
 * @export
 * @class ShopifySalesTransactionssApi
 * @extends {BaseAPI}
 */
export class ShopifySalesTransactionssApi extends BaseAPI {
    /**
     * 
     * @summary sales_transactions#create
     * @param {ShopifySalesTransactionssApiV2ShopifyTokenSalesTransactionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopifySalesTransactionssApi
     */
    public v2ShopifyTokenSalesTransactionsPost(requestParameters: ShopifySalesTransactionssApiV2ShopifyTokenSalesTransactionsPostRequest, options?: AxiosRequestConfig) {
        return ShopifySalesTransactionssApiFp(this.configuration).v2ShopifyTokenSalesTransactionsPost(requestParameters.token, requestParameters.v2ShopifyTokenSalesTransactionsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatsApi - axios parameter creator
 * @export
 */
export const StatsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary stats#index
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StatsGet: async (from?: string, until?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary stock_analytics#index
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StockAnalyticsGet: async (from?: string, until?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/stock-analytics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatsApi - functional programming interface
 * @export
 */
export const StatsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary stats#index
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1StatsGet(from?: string, until?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1StatsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1StatsGet(from, until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary stock_analytics#index
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1StockAnalyticsGet(from?: string, until?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1StockAnalyticsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1StockAnalyticsGet(from, until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatsApi - factory interface
 * @export
 */
export const StatsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatsApiFp(configuration)
    return {
        /**
         * 
         * @summary stats#index
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StatsGet(from?: string, until?: string, options?: any): AxiosPromise<V1StatsGet200Response> {
            return localVarFp.v1StatsGet(from, until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary stock_analytics#index
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StockAnalyticsGet(from?: string, until?: string, options?: any): AxiosPromise<V1StockAnalyticsGet200Response> {
            return localVarFp.v1StockAnalyticsGet(from, until, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1StatsGet operation in StatsApi.
 * @export
 * @interface StatsApiV1StatsGetRequest
 */
export interface StatsApiV1StatsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof StatsApiV1StatsGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof StatsApiV1StatsGet
     */
    readonly until?: string
}

/**
 * Request parameters for v1StockAnalyticsGet operation in StatsApi.
 * @export
 * @interface StatsApiV1StockAnalyticsGetRequest
 */
export interface StatsApiV1StockAnalyticsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof StatsApiV1StockAnalyticsGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof StatsApiV1StockAnalyticsGet
     */
    readonly until?: string
}

/**
 * StatsApi - object-oriented interface
 * @export
 * @class StatsApi
 * @extends {BaseAPI}
 */
export class StatsApi extends BaseAPI {
    /**
     * 
     * @summary stats#index
     * @param {StatsApiV1StatsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public v1StatsGet(requestParameters: StatsApiV1StatsGetRequest = {}, options?: AxiosRequestConfig) {
        return StatsApiFp(this.configuration).v1StatsGet(requestParameters.from, requestParameters.until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary stock_analytics#index
     * @param {StatsApiV1StockAnalyticsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public v1StockAnalyticsGet(requestParameters: StatsApiV1StockAnalyticsGetRequest = {}, options?: AxiosRequestConfig) {
        return StatsApiFp(this.configuration).v1StockAnalyticsGet(requestParameters.from, requestParameters.until, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StripeChargesApi - axios parameter creator
 * @export
 */
export const StripeChargesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary stripe_charges#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StripeChargesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1StripeChargesIdDelete', 'id', id)
            const localVarPath = `/v1/stripe-charges/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary stripe_charges#create
         * @param {V1StripeChargesPostRequest} [v1StripeChargesPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StripeChargesPost: async (v1StripeChargesPostRequest?: V1StripeChargesPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/stripe-charges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1StripeChargesPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StripeChargesApi - functional programming interface
 * @export
 */
export const StripeChargesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StripeChargesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary stripe_charges#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1StripeChargesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1StripeChargesIdDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1StripeChargesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary stripe_charges#create
         * @param {V1StripeChargesPostRequest} [v1StripeChargesPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1StripeChargesPost(v1StripeChargesPostRequest?: V1StripeChargesPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1StripeChargesPost201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1StripeChargesPost(v1StripeChargesPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StripeChargesApi - factory interface
 * @export
 */
export const StripeChargesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StripeChargesApiFp(configuration)
    return {
        /**
         * 
         * @summary stripe_charges#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StripeChargesIdDelete(id: string, options?: any): AxiosPromise<V1StripeChargesIdDelete200Response> {
            return localVarFp.v1StripeChargesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary stripe_charges#create
         * @param {V1StripeChargesPostRequest} [v1StripeChargesPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StripeChargesPost(v1StripeChargesPostRequest?: V1StripeChargesPostRequest, options?: any): AxiosPromise<V1StripeChargesPost201Response> {
            return localVarFp.v1StripeChargesPost(v1StripeChargesPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1StripeChargesIdDelete operation in StripeChargesApi.
 * @export
 * @interface StripeChargesApiV1StripeChargesIdDeleteRequest
 */
export interface StripeChargesApiV1StripeChargesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof StripeChargesApiV1StripeChargesIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for v1StripeChargesPost operation in StripeChargesApi.
 * @export
 * @interface StripeChargesApiV1StripeChargesPostRequest
 */
export interface StripeChargesApiV1StripeChargesPostRequest {
    /**
     * 
     * @type {V1StripeChargesPostRequest}
     * @memberof StripeChargesApiV1StripeChargesPost
     */
    readonly v1StripeChargesPostRequest?: V1StripeChargesPostRequest
}

/**
 * StripeChargesApi - object-oriented interface
 * @export
 * @class StripeChargesApi
 * @extends {BaseAPI}
 */
export class StripeChargesApi extends BaseAPI {
    /**
     * 
     * @summary stripe_charges#destroy
     * @param {StripeChargesApiV1StripeChargesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeChargesApi
     */
    public v1StripeChargesIdDelete(requestParameters: StripeChargesApiV1StripeChargesIdDeleteRequest, options?: AxiosRequestConfig) {
        return StripeChargesApiFp(this.configuration).v1StripeChargesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary stripe_charges#create
     * @param {StripeChargesApiV1StripeChargesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeChargesApi
     */
    public v1StripeChargesPost(requestParameters: StripeChargesApiV1StripeChargesPostRequest = {}, options?: AxiosRequestConfig) {
        return StripeChargesApiFp(this.configuration).v1StripeChargesPost(requestParameters.v1StripeChargesPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TagsApi - axios parameter creator
 * @export
 */
export const TagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary tags#index
         * @param {'color' | 'size' | 'style'} dimension 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TagsGet: async (dimension: 'color' | 'size' | 'style', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dimension' is not null or undefined
            assertParamExists('v1TagsGet', 'dimension', dimension)
            const localVarPath = `/v1/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (dimension !== undefined) {
                localVarQueryParameter['dimension'] = dimension;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagsApi - functional programming interface
 * @export
 */
export const TagsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary tags#index
         * @param {'color' | 'size' | 'style'} dimension 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1TagsGet(dimension: 'color' | 'size' | 'style', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1TagsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1TagsGet(dimension, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TagsApi - factory interface
 * @export
 */
export const TagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagsApiFp(configuration)
    return {
        /**
         * 
         * @summary tags#index
         * @param {'color' | 'size' | 'style'} dimension 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TagsGet(dimension: 'color' | 'size' | 'style', options?: any): AxiosPromise<V1TagsGet200Response> {
            return localVarFp.v1TagsGet(dimension, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1TagsGet operation in TagsApi.
 * @export
 * @interface TagsApiV1TagsGetRequest
 */
export interface TagsApiV1TagsGetRequest {
    /**
     * 
     * @type {'color' | 'size' | 'style'}
     * @memberof TagsApiV1TagsGet
     */
    readonly dimension: 'color' | 'size' | 'style'
}

/**
 * TagsApi - object-oriented interface
 * @export
 * @class TagsApi
 * @extends {BaseAPI}
 */
export class TagsApi extends BaseAPI {
    /**
     * 
     * @summary tags#index
     * @param {TagsApiV1TagsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public v1TagsGet(requestParameters: TagsApiV1TagsGetRequest, options?: AxiosRequestConfig) {
        return TagsApiFp(this.configuration).v1TagsGet(requestParameters.dimension, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TransactionCategoriesApi - axios parameter creator
 * @export
 */
export const TransactionCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary transaction_categories#index
         * @param {number} [page] 
         * @param {string} [content] 
         * @param {string} [transactionTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionCategoriesGet: async (page?: number, content?: string, transactionTypeId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/transaction-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }

            if (transactionTypeId !== undefined) {
                localVarQueryParameter['transaction_type_id'] = transactionTypeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary transaction_categories#add_budget
         * @param {string} id 
         * @param {V1TransactionCategoriesIdAddBudgetPatchRequest} [v1TransactionCategoriesIdAddBudgetPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionCategoriesIdAddBudgetPatch: async (id: string, v1TransactionCategoriesIdAddBudgetPatchRequest?: V1TransactionCategoriesIdAddBudgetPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1TransactionCategoriesIdAddBudgetPatch', 'id', id)
            const localVarPath = `/v1/transaction-categories/{id}/add-budget`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1TransactionCategoriesIdAddBudgetPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary transaction_categories#batch_update
         * @param {string} id 
         * @param {V1TransactionCategoriesIdBatchUpdatePatchRequest} [v1TransactionCategoriesIdBatchUpdatePatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionCategoriesIdBatchUpdatePatch: async (id: string, v1TransactionCategoriesIdBatchUpdatePatchRequest?: V1TransactionCategoriesIdBatchUpdatePatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1TransactionCategoriesIdBatchUpdatePatch', 'id', id)
            const localVarPath = `/v1/transaction-categories/{id}/batch-update`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1TransactionCategoriesIdBatchUpdatePatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary transaction_categories#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionCategoriesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1TransactionCategoriesIdDelete', 'id', id)
            const localVarPath = `/v1/transaction-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary transaction_categories#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionCategoriesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1TransactionCategoriesIdGet', 'id', id)
            const localVarPath = `/v1/transaction-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary transaction_categories#update
         * @param {string} id 
         * @param {V1TransactionCategoriesIdDeleteRequest} [v1TransactionCategoriesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionCategoriesIdPatch: async (id: string, v1TransactionCategoriesIdDeleteRequest?: V1TransactionCategoriesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1TransactionCategoriesIdPatch', 'id', id)
            const localVarPath = `/v1/transaction-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1TransactionCategoriesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary transaction_categories#transactions
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionCategoriesIdTransactionsGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1TransactionCategoriesIdTransactionsGet', 'id', id)
            const localVarPath = `/v1/transaction-categories/{id}/transactions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary transaction_categories#pie_chart
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionCategoriesPieChartGet: async (from?: string, until?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/transaction-categories/pie-chart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary transaction_categories#create
         * @param {V1TransactionCategoriesGetRequest} [v1TransactionCategoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionCategoriesPost: async (v1TransactionCategoriesGetRequest?: V1TransactionCategoriesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/transaction-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1TransactionCategoriesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary transaction_categories#report
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionCategoriesReportGet: async (from?: string, until?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/transaction-categories/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary transaction_categories#top
         * @param {'sale' | 'expense' | 'pos' | 'quote'} [transactionType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionCategoriesTopGet: async (transactionType?: 'sale' | 'expense' | 'pos' | 'quote', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/transaction-categories/top`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (transactionType !== undefined) {
                localVarQueryParameter['transaction_type'] = transactionType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionCategoriesApi - functional programming interface
 * @export
 */
export const TransactionCategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionCategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary transaction_categories#index
         * @param {number} [page] 
         * @param {string} [content] 
         * @param {string} [transactionTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1TransactionCategoriesGet(page?: number, content?: string, transactionTypeId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1TransactionCategoriesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1TransactionCategoriesGet(page, content, transactionTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary transaction_categories#add_budget
         * @param {string} id 
         * @param {V1TransactionCategoriesIdAddBudgetPatchRequest} [v1TransactionCategoriesIdAddBudgetPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1TransactionCategoriesIdAddBudgetPatch(id: string, v1TransactionCategoriesIdAddBudgetPatchRequest?: V1TransactionCategoriesIdAddBudgetPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1TransactionCategoriesIdAddBudgetPatch(id, v1TransactionCategoriesIdAddBudgetPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary transaction_categories#batch_update
         * @param {string} id 
         * @param {V1TransactionCategoriesIdBatchUpdatePatchRequest} [v1TransactionCategoriesIdBatchUpdatePatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1TransactionCategoriesIdBatchUpdatePatch(id: string, v1TransactionCategoriesIdBatchUpdatePatchRequest?: V1TransactionCategoriesIdBatchUpdatePatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1TransactionCategoriesIdBatchUpdatePatch(id, v1TransactionCategoriesIdBatchUpdatePatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary transaction_categories#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1TransactionCategoriesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1TransactionCategoriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary transaction_categories#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1TransactionCategoriesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1TransactionCategoriesIdDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1TransactionCategoriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary transaction_categories#update
         * @param {string} id 
         * @param {V1TransactionCategoriesIdDeleteRequest} [v1TransactionCategoriesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1TransactionCategoriesIdPatch(id: string, v1TransactionCategoriesIdDeleteRequest?: V1TransactionCategoriesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1TransactionCategoriesGet201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1TransactionCategoriesIdPatch(id, v1TransactionCategoriesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary transaction_categories#transactions
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1TransactionCategoriesIdTransactionsGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1TransactionCategoriesIdTransactionsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1TransactionCategoriesIdTransactionsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary transaction_categories#pie_chart
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1TransactionCategoriesPieChartGet(from?: string, until?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1TransactionCategoriesPieChartGet(from, until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary transaction_categories#create
         * @param {V1TransactionCategoriesGetRequest} [v1TransactionCategoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1TransactionCategoriesPost(v1TransactionCategoriesGetRequest?: V1TransactionCategoriesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1TransactionCategoriesGet201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1TransactionCategoriesPost(v1TransactionCategoriesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary transaction_categories#report
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1TransactionCategoriesReportGet(from?: string, until?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersPreValidateSubscriptionPost422Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1TransactionCategoriesReportGet(from, until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary transaction_categories#top
         * @param {'sale' | 'expense' | 'pos' | 'quote'} [transactionType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1TransactionCategoriesTopGet(transactionType?: 'sale' | 'expense' | 'pos' | 'quote', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1TransactionCategoriesTopGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1TransactionCategoriesTopGet(transactionType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransactionCategoriesApi - factory interface
 * @export
 */
export const TransactionCategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionCategoriesApiFp(configuration)
    return {
        /**
         * 
         * @summary transaction_categories#index
         * @param {number} [page] 
         * @param {string} [content] 
         * @param {string} [transactionTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionCategoriesGet(page?: number, content?: string, transactionTypeId?: string, options?: any): AxiosPromise<V1TransactionCategoriesGet200Response> {
            return localVarFp.v1TransactionCategoriesGet(page, content, transactionTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary transaction_categories#add_budget
         * @param {string} id 
         * @param {V1TransactionCategoriesIdAddBudgetPatchRequest} [v1TransactionCategoriesIdAddBudgetPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionCategoriesIdAddBudgetPatch(id: string, v1TransactionCategoriesIdAddBudgetPatchRequest?: V1TransactionCategoriesIdAddBudgetPatchRequest, options?: any): AxiosPromise<void> {
            return localVarFp.v1TransactionCategoriesIdAddBudgetPatch(id, v1TransactionCategoriesIdAddBudgetPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary transaction_categories#batch_update
         * @param {string} id 
         * @param {V1TransactionCategoriesIdBatchUpdatePatchRequest} [v1TransactionCategoriesIdBatchUpdatePatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionCategoriesIdBatchUpdatePatch(id: string, v1TransactionCategoriesIdBatchUpdatePatchRequest?: V1TransactionCategoriesIdBatchUpdatePatchRequest, options?: any): AxiosPromise<void> {
            return localVarFp.v1TransactionCategoriesIdBatchUpdatePatch(id, v1TransactionCategoriesIdBatchUpdatePatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary transaction_categories#destroy
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionCategoriesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1TransactionCategoriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary transaction_categories#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionCategoriesIdGet(id: string, options?: any): AxiosPromise<V1TransactionCategoriesIdDelete200Response> {
            return localVarFp.v1TransactionCategoriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary transaction_categories#update
         * @param {string} id 
         * @param {V1TransactionCategoriesIdDeleteRequest} [v1TransactionCategoriesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionCategoriesIdPatch(id: string, v1TransactionCategoriesIdDeleteRequest?: V1TransactionCategoriesIdDeleteRequest, options?: any): AxiosPromise<V1TransactionCategoriesGet201Response> {
            return localVarFp.v1TransactionCategoriesIdPatch(id, v1TransactionCategoriesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary transaction_categories#transactions
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionCategoriesIdTransactionsGet(id: string, options?: any): AxiosPromise<V1TransactionCategoriesIdTransactionsGet200Response> {
            return localVarFp.v1TransactionCategoriesIdTransactionsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary transaction_categories#pie_chart
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionCategoriesPieChartGet(from?: string, until?: string, options?: any): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.v1TransactionCategoriesPieChartGet(from, until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary transaction_categories#create
         * @param {V1TransactionCategoriesGetRequest} [v1TransactionCategoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionCategoriesPost(v1TransactionCategoriesGetRequest?: V1TransactionCategoriesGetRequest, options?: any): AxiosPromise<V1TransactionCategoriesGet201Response> {
            return localVarFp.v1TransactionCategoriesPost(v1TransactionCategoriesGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary transaction_categories#report
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionCategoriesReportGet(from?: string, until?: string, options?: any): AxiosPromise<UsersPreValidateSubscriptionPost422Response> {
            return localVarFp.v1TransactionCategoriesReportGet(from, until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary transaction_categories#top
         * @param {'sale' | 'expense' | 'pos' | 'quote'} [transactionType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionCategoriesTopGet(transactionType?: 'sale' | 'expense' | 'pos' | 'quote', options?: any): AxiosPromise<V1TransactionCategoriesTopGet200Response> {
            return localVarFp.v1TransactionCategoriesTopGet(transactionType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1TransactionCategoriesGet operation in TransactionCategoriesApi.
 * @export
 * @interface TransactionCategoriesApiV1TransactionCategoriesGetRequest
 */
export interface TransactionCategoriesApiV1TransactionCategoriesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof TransactionCategoriesApiV1TransactionCategoriesGet
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof TransactionCategoriesApiV1TransactionCategoriesGet
     */
    readonly content?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionCategoriesApiV1TransactionCategoriesGet
     */
    readonly transactionTypeId?: string
}

/**
 * Request parameters for v1TransactionCategoriesIdAddBudgetPatch operation in TransactionCategoriesApi.
 * @export
 * @interface TransactionCategoriesApiV1TransactionCategoriesIdAddBudgetPatchRequest
 */
export interface TransactionCategoriesApiV1TransactionCategoriesIdAddBudgetPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionCategoriesApiV1TransactionCategoriesIdAddBudgetPatch
     */
    readonly id: string

    /**
     * 
     * @type {V1TransactionCategoriesIdAddBudgetPatchRequest}
     * @memberof TransactionCategoriesApiV1TransactionCategoriesIdAddBudgetPatch
     */
    readonly v1TransactionCategoriesIdAddBudgetPatchRequest?: V1TransactionCategoriesIdAddBudgetPatchRequest
}

/**
 * Request parameters for v1TransactionCategoriesIdBatchUpdatePatch operation in TransactionCategoriesApi.
 * @export
 * @interface TransactionCategoriesApiV1TransactionCategoriesIdBatchUpdatePatchRequest
 */
export interface TransactionCategoriesApiV1TransactionCategoriesIdBatchUpdatePatchRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionCategoriesApiV1TransactionCategoriesIdBatchUpdatePatch
     */
    readonly id: string

    /**
     * 
     * @type {V1TransactionCategoriesIdBatchUpdatePatchRequest}
     * @memberof TransactionCategoriesApiV1TransactionCategoriesIdBatchUpdatePatch
     */
    readonly v1TransactionCategoriesIdBatchUpdatePatchRequest?: V1TransactionCategoriesIdBatchUpdatePatchRequest
}

/**
 * Request parameters for v1TransactionCategoriesIdDelete operation in TransactionCategoriesApi.
 * @export
 * @interface TransactionCategoriesApiV1TransactionCategoriesIdDeleteRequest
 */
export interface TransactionCategoriesApiV1TransactionCategoriesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionCategoriesApiV1TransactionCategoriesIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for v1TransactionCategoriesIdGet operation in TransactionCategoriesApi.
 * @export
 * @interface TransactionCategoriesApiV1TransactionCategoriesIdGetRequest
 */
export interface TransactionCategoriesApiV1TransactionCategoriesIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionCategoriesApiV1TransactionCategoriesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for v1TransactionCategoriesIdPatch operation in TransactionCategoriesApi.
 * @export
 * @interface TransactionCategoriesApiV1TransactionCategoriesIdPatchRequest
 */
export interface TransactionCategoriesApiV1TransactionCategoriesIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionCategoriesApiV1TransactionCategoriesIdPatch
     */
    readonly id: string

    /**
     * 
     * @type {V1TransactionCategoriesIdDeleteRequest}
     * @memberof TransactionCategoriesApiV1TransactionCategoriesIdPatch
     */
    readonly v1TransactionCategoriesIdDeleteRequest?: V1TransactionCategoriesIdDeleteRequest
}

/**
 * Request parameters for v1TransactionCategoriesIdTransactionsGet operation in TransactionCategoriesApi.
 * @export
 * @interface TransactionCategoriesApiV1TransactionCategoriesIdTransactionsGetRequest
 */
export interface TransactionCategoriesApiV1TransactionCategoriesIdTransactionsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionCategoriesApiV1TransactionCategoriesIdTransactionsGet
     */
    readonly id: string
}

/**
 * Request parameters for v1TransactionCategoriesPieChartGet operation in TransactionCategoriesApi.
 * @export
 * @interface TransactionCategoriesApiV1TransactionCategoriesPieChartGetRequest
 */
export interface TransactionCategoriesApiV1TransactionCategoriesPieChartGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionCategoriesApiV1TransactionCategoriesPieChartGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionCategoriesApiV1TransactionCategoriesPieChartGet
     */
    readonly until?: string
}

/**
 * Request parameters for v1TransactionCategoriesPost operation in TransactionCategoriesApi.
 * @export
 * @interface TransactionCategoriesApiV1TransactionCategoriesPostRequest
 */
export interface TransactionCategoriesApiV1TransactionCategoriesPostRequest {
    /**
     * 
     * @type {V1TransactionCategoriesGetRequest}
     * @memberof TransactionCategoriesApiV1TransactionCategoriesPost
     */
    readonly v1TransactionCategoriesGetRequest?: V1TransactionCategoriesGetRequest
}

/**
 * Request parameters for v1TransactionCategoriesReportGet operation in TransactionCategoriesApi.
 * @export
 * @interface TransactionCategoriesApiV1TransactionCategoriesReportGetRequest
 */
export interface TransactionCategoriesApiV1TransactionCategoriesReportGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionCategoriesApiV1TransactionCategoriesReportGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionCategoriesApiV1TransactionCategoriesReportGet
     */
    readonly until?: string
}

/**
 * Request parameters for v1TransactionCategoriesTopGet operation in TransactionCategoriesApi.
 * @export
 * @interface TransactionCategoriesApiV1TransactionCategoriesTopGetRequest
 */
export interface TransactionCategoriesApiV1TransactionCategoriesTopGetRequest {
    /**
     * 
     * @type {'sale' | 'expense' | 'pos' | 'quote'}
     * @memberof TransactionCategoriesApiV1TransactionCategoriesTopGet
     */
    readonly transactionType?: 'sale' | 'expense' | 'pos' | 'quote'
}

/**
 * TransactionCategoriesApi - object-oriented interface
 * @export
 * @class TransactionCategoriesApi
 * @extends {BaseAPI}
 */
export class TransactionCategoriesApi extends BaseAPI {
    /**
     * 
     * @summary transaction_categories#index
     * @param {TransactionCategoriesApiV1TransactionCategoriesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionCategoriesApi
     */
    public v1TransactionCategoriesGet(requestParameters: TransactionCategoriesApiV1TransactionCategoriesGetRequest = {}, options?: AxiosRequestConfig) {
        return TransactionCategoriesApiFp(this.configuration).v1TransactionCategoriesGet(requestParameters.page, requestParameters.content, requestParameters.transactionTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary transaction_categories#add_budget
     * @param {TransactionCategoriesApiV1TransactionCategoriesIdAddBudgetPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionCategoriesApi
     */
    public v1TransactionCategoriesIdAddBudgetPatch(requestParameters: TransactionCategoriesApiV1TransactionCategoriesIdAddBudgetPatchRequest, options?: AxiosRequestConfig) {
        return TransactionCategoriesApiFp(this.configuration).v1TransactionCategoriesIdAddBudgetPatch(requestParameters.id, requestParameters.v1TransactionCategoriesIdAddBudgetPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary transaction_categories#batch_update
     * @param {TransactionCategoriesApiV1TransactionCategoriesIdBatchUpdatePatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionCategoriesApi
     */
    public v1TransactionCategoriesIdBatchUpdatePatch(requestParameters: TransactionCategoriesApiV1TransactionCategoriesIdBatchUpdatePatchRequest, options?: AxiosRequestConfig) {
        return TransactionCategoriesApiFp(this.configuration).v1TransactionCategoriesIdBatchUpdatePatch(requestParameters.id, requestParameters.v1TransactionCategoriesIdBatchUpdatePatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary transaction_categories#destroy
     * @param {TransactionCategoriesApiV1TransactionCategoriesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionCategoriesApi
     */
    public v1TransactionCategoriesIdDelete(requestParameters: TransactionCategoriesApiV1TransactionCategoriesIdDeleteRequest, options?: AxiosRequestConfig) {
        return TransactionCategoriesApiFp(this.configuration).v1TransactionCategoriesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary transaction_categories#show
     * @param {TransactionCategoriesApiV1TransactionCategoriesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionCategoriesApi
     */
    public v1TransactionCategoriesIdGet(requestParameters: TransactionCategoriesApiV1TransactionCategoriesIdGetRequest, options?: AxiosRequestConfig) {
        return TransactionCategoriesApiFp(this.configuration).v1TransactionCategoriesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary transaction_categories#update
     * @param {TransactionCategoriesApiV1TransactionCategoriesIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionCategoriesApi
     */
    public v1TransactionCategoriesIdPatch(requestParameters: TransactionCategoriesApiV1TransactionCategoriesIdPatchRequest, options?: AxiosRequestConfig) {
        return TransactionCategoriesApiFp(this.configuration).v1TransactionCategoriesIdPatch(requestParameters.id, requestParameters.v1TransactionCategoriesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary transaction_categories#transactions
     * @param {TransactionCategoriesApiV1TransactionCategoriesIdTransactionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionCategoriesApi
     */
    public v1TransactionCategoriesIdTransactionsGet(requestParameters: TransactionCategoriesApiV1TransactionCategoriesIdTransactionsGetRequest, options?: AxiosRequestConfig) {
        return TransactionCategoriesApiFp(this.configuration).v1TransactionCategoriesIdTransactionsGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary transaction_categories#pie_chart
     * @param {TransactionCategoriesApiV1TransactionCategoriesPieChartGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionCategoriesApi
     */
    public v1TransactionCategoriesPieChartGet(requestParameters: TransactionCategoriesApiV1TransactionCategoriesPieChartGetRequest = {}, options?: AxiosRequestConfig) {
        return TransactionCategoriesApiFp(this.configuration).v1TransactionCategoriesPieChartGet(requestParameters.from, requestParameters.until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary transaction_categories#create
     * @param {TransactionCategoriesApiV1TransactionCategoriesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionCategoriesApi
     */
    public v1TransactionCategoriesPost(requestParameters: TransactionCategoriesApiV1TransactionCategoriesPostRequest = {}, options?: AxiosRequestConfig) {
        return TransactionCategoriesApiFp(this.configuration).v1TransactionCategoriesPost(requestParameters.v1TransactionCategoriesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary transaction_categories#report
     * @param {TransactionCategoriesApiV1TransactionCategoriesReportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionCategoriesApi
     */
    public v1TransactionCategoriesReportGet(requestParameters: TransactionCategoriesApiV1TransactionCategoriesReportGetRequest = {}, options?: AxiosRequestConfig) {
        return TransactionCategoriesApiFp(this.configuration).v1TransactionCategoriesReportGet(requestParameters.from, requestParameters.until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary transaction_categories#top
     * @param {TransactionCategoriesApiV1TransactionCategoriesTopGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionCategoriesApi
     */
    public v1TransactionCategoriesTopGet(requestParameters: TransactionCategoriesApiV1TransactionCategoriesTopGetRequest = {}, options?: AxiosRequestConfig) {
        return TransactionCategoriesApiFp(this.configuration).v1TransactionCategoriesTopGet(requestParameters.transactionType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TransactionsApi - axios parameter creator
 * @export
 */
export const TransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary reports_transactions#cash_cut_details
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReportsTransactionsCashCutDetailsGet: async (from?: string, until?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports-transactions/cash-cut-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary reports_transactions#cash_cut
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReportsTransactionsCashCutPdfGet: async (from?: string, until?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports-transactions/cash-cut.pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary transactions#by_day
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {'sale' | 'expense' | 'pos' | 'quote'} [transactionType] 
         * @param {'cash' | 'card' | 'other' | 'bank' | 'on-credit'} [paymentMethod] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionsByDayGet: async (from?: string, until?: string, transactionType?: 'sale' | 'expense' | 'pos' | 'quote', paymentMethod?: 'cash' | 'card' | 'other' | 'bank' | 'on-credit', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/transactions/by-day`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }

            if (transactionType !== undefined) {
                localVarQueryParameter['transaction_type'] = transactionType;
            }

            if (paymentMethod !== undefined) {
                localVarQueryParameter['payment_method'] = paymentMethod;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary transactions#details
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {number} [userId] 
         * @param {'sales' | 'expenses' | 'deposit' | 'stock'} [transactionType] 
         * @param {'cash' | 'card' | 'other' | 'bank' | 'on-credit'} [paymentMethod] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionsDetailsGet: async (from?: string, until?: string, userId?: number, transactionType?: 'sales' | 'expenses' | 'deposit' | 'stock', paymentMethod?: 'cash' | 'card' | 'other' | 'bank' | 'on-credit', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/transactions/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (transactionType !== undefined) {
                localVarQueryParameter['transaction_type'] = transactionType;
            }

            if (paymentMethod !== undefined) {
                localVarQueryParameter['payment_method'] = paymentMethod;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary transactions#index
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionsGet: async (from?: string, until?: string, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary transactions#unified
         * @param {number} [page] 
         * @param {'pos' | 'web'} [type] Filter by transaction type
         * @param {string} [orderNumber] Filter by order number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionsUnifiedGet: async (page?: number, type?: 'pos' | 'web', orderNumber?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/transactions/unified`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (orderNumber !== undefined) {
                localVarQueryParameter['order_number'] = orderNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionsApi - functional programming interface
 * @export
 */
export const TransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary reports_transactions#cash_cut_details
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReportsTransactionsCashCutDetailsGet(from?: string, until?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ReportsTransactionsCashCutDetailsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReportsTransactionsCashCutDetailsGet(from, until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary reports_transactions#cash_cut
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReportsTransactionsCashCutPdfGet(from?: string, until?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReportsTransactionsCashCutPdfGet(from, until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary transactions#by_day
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {'sale' | 'expense' | 'pos' | 'quote'} [transactionType] 
         * @param {'cash' | 'card' | 'other' | 'bank' | 'on-credit'} [paymentMethod] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1TransactionsByDayGet(from?: string, until?: string, transactionType?: 'sale' | 'expense' | 'pos' | 'quote', paymentMethod?: 'cash' | 'card' | 'other' | 'bank' | 'on-credit', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1TransactionsByDayGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1TransactionsByDayGet(from, until, transactionType, paymentMethod, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary transactions#details
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {number} [userId] 
         * @param {'sales' | 'expenses' | 'deposit' | 'stock'} [transactionType] 
         * @param {'cash' | 'card' | 'other' | 'bank' | 'on-credit'} [paymentMethod] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1TransactionsDetailsGet(from?: string, until?: string, userId?: number, transactionType?: 'sales' | 'expenses' | 'deposit' | 'stock', paymentMethod?: 'cash' | 'card' | 'other' | 'bank' | 'on-credit', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1TransactionsDetailsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1TransactionsDetailsGet(from, until, userId, transactionType, paymentMethod, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary transactions#index
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1TransactionsGet(from?: string, until?: string, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1TransactionsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1TransactionsGet(from, until, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary transactions#unified
         * @param {number} [page] 
         * @param {'pos' | 'web'} [type] Filter by transaction type
         * @param {string} [orderNumber] Filter by order number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1TransactionsUnifiedGet(page?: number, type?: 'pos' | 'web', orderNumber?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1PosTransactionsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1TransactionsUnifiedGet(page, type, orderNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransactionsApi - factory interface
 * @export
 */
export const TransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionsApiFp(configuration)
    return {
        /**
         * 
         * @summary reports_transactions#cash_cut_details
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReportsTransactionsCashCutDetailsGet(from?: string, until?: string, options?: any): AxiosPromise<V1ReportsTransactionsCashCutDetailsGet200Response> {
            return localVarFp.v1ReportsTransactionsCashCutDetailsGet(from, until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary reports_transactions#cash_cut
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReportsTransactionsCashCutPdfGet(from?: string, until?: string, options?: any): AxiosPromise<File> {
            return localVarFp.v1ReportsTransactionsCashCutPdfGet(from, until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary transactions#by_day
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {'sale' | 'expense' | 'pos' | 'quote'} [transactionType] 
         * @param {'cash' | 'card' | 'other' | 'bank' | 'on-credit'} [paymentMethod] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionsByDayGet(from?: string, until?: string, transactionType?: 'sale' | 'expense' | 'pos' | 'quote', paymentMethod?: 'cash' | 'card' | 'other' | 'bank' | 'on-credit', options?: any): AxiosPromise<V1TransactionsByDayGet200Response> {
            return localVarFp.v1TransactionsByDayGet(from, until, transactionType, paymentMethod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary transactions#details
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {number} [userId] 
         * @param {'sales' | 'expenses' | 'deposit' | 'stock'} [transactionType] 
         * @param {'cash' | 'card' | 'other' | 'bank' | 'on-credit'} [paymentMethod] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionsDetailsGet(from?: string, until?: string, userId?: number, transactionType?: 'sales' | 'expenses' | 'deposit' | 'stock', paymentMethod?: 'cash' | 'card' | 'other' | 'bank' | 'on-credit', options?: any): AxiosPromise<V1TransactionsDetailsGet200Response> {
            return localVarFp.v1TransactionsDetailsGet(from, until, userId, transactionType, paymentMethod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary transactions#index
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionsGet(from?: string, until?: string, page?: number, options?: any): AxiosPromise<V1TransactionsGet200Response> {
            return localVarFp.v1TransactionsGet(from, until, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary transactions#unified
         * @param {number} [page] 
         * @param {'pos' | 'web'} [type] Filter by transaction type
         * @param {string} [orderNumber] Filter by order number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TransactionsUnifiedGet(page?: number, type?: 'pos' | 'web', orderNumber?: string, options?: any): AxiosPromise<V1PosTransactionsGet200Response> {
            return localVarFp.v1TransactionsUnifiedGet(page, type, orderNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1ReportsTransactionsCashCutDetailsGet operation in TransactionsApi.
 * @export
 * @interface TransactionsApiV1ReportsTransactionsCashCutDetailsGetRequest
 */
export interface TransactionsApiV1ReportsTransactionsCashCutDetailsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionsApiV1ReportsTransactionsCashCutDetailsGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionsApiV1ReportsTransactionsCashCutDetailsGet
     */
    readonly until?: string
}

/**
 * Request parameters for v1ReportsTransactionsCashCutPdfGet operation in TransactionsApi.
 * @export
 * @interface TransactionsApiV1ReportsTransactionsCashCutPdfGetRequest
 */
export interface TransactionsApiV1ReportsTransactionsCashCutPdfGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionsApiV1ReportsTransactionsCashCutPdfGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionsApiV1ReportsTransactionsCashCutPdfGet
     */
    readonly until?: string
}

/**
 * Request parameters for v1TransactionsByDayGet operation in TransactionsApi.
 * @export
 * @interface TransactionsApiV1TransactionsByDayGetRequest
 */
export interface TransactionsApiV1TransactionsByDayGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionsApiV1TransactionsByDayGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionsApiV1TransactionsByDayGet
     */
    readonly until?: string

    /**
     * 
     * @type {'sale' | 'expense' | 'pos' | 'quote'}
     * @memberof TransactionsApiV1TransactionsByDayGet
     */
    readonly transactionType?: 'sale' | 'expense' | 'pos' | 'quote'

    /**
     * 
     * @type {'cash' | 'card' | 'other' | 'bank' | 'on-credit'}
     * @memberof TransactionsApiV1TransactionsByDayGet
     */
    readonly paymentMethod?: 'cash' | 'card' | 'other' | 'bank' | 'on-credit'
}

/**
 * Request parameters for v1TransactionsDetailsGet operation in TransactionsApi.
 * @export
 * @interface TransactionsApiV1TransactionsDetailsGetRequest
 */
export interface TransactionsApiV1TransactionsDetailsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionsApiV1TransactionsDetailsGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionsApiV1TransactionsDetailsGet
     */
    readonly until?: string

    /**
     * 
     * @type {number}
     * @memberof TransactionsApiV1TransactionsDetailsGet
     */
    readonly userId?: number

    /**
     * 
     * @type {'sales' | 'expenses' | 'deposit' | 'stock'}
     * @memberof TransactionsApiV1TransactionsDetailsGet
     */
    readonly transactionType?: 'sales' | 'expenses' | 'deposit' | 'stock'

    /**
     * 
     * @type {'cash' | 'card' | 'other' | 'bank' | 'on-credit'}
     * @memberof TransactionsApiV1TransactionsDetailsGet
     */
    readonly paymentMethod?: 'cash' | 'card' | 'other' | 'bank' | 'on-credit'
}

/**
 * Request parameters for v1TransactionsGet operation in TransactionsApi.
 * @export
 * @interface TransactionsApiV1TransactionsGetRequest
 */
export interface TransactionsApiV1TransactionsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionsApiV1TransactionsGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof TransactionsApiV1TransactionsGet
     */
    readonly until?: string

    /**
     * 
     * @type {number}
     * @memberof TransactionsApiV1TransactionsGet
     */
    readonly page?: number
}

/**
 * Request parameters for v1TransactionsUnifiedGet operation in TransactionsApi.
 * @export
 * @interface TransactionsApiV1TransactionsUnifiedGetRequest
 */
export interface TransactionsApiV1TransactionsUnifiedGetRequest {
    /**
     * 
     * @type {number}
     * @memberof TransactionsApiV1TransactionsUnifiedGet
     */
    readonly page?: number

    /**
     * Filter by transaction type
     * @type {'pos' | 'web'}
     * @memberof TransactionsApiV1TransactionsUnifiedGet
     */
    readonly type?: 'pos' | 'web'

    /**
     * Filter by order number
     * @type {string}
     * @memberof TransactionsApiV1TransactionsUnifiedGet
     */
    readonly orderNumber?: string
}

/**
 * TransactionsApi - object-oriented interface
 * @export
 * @class TransactionsApi
 * @extends {BaseAPI}
 */
export class TransactionsApi extends BaseAPI {
    /**
     * 
     * @summary reports_transactions#cash_cut_details
     * @param {TransactionsApiV1ReportsTransactionsCashCutDetailsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public v1ReportsTransactionsCashCutDetailsGet(requestParameters: TransactionsApiV1ReportsTransactionsCashCutDetailsGetRequest = {}, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).v1ReportsTransactionsCashCutDetailsGet(requestParameters.from, requestParameters.until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary reports_transactions#cash_cut
     * @param {TransactionsApiV1ReportsTransactionsCashCutPdfGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public v1ReportsTransactionsCashCutPdfGet(requestParameters: TransactionsApiV1ReportsTransactionsCashCutPdfGetRequest = {}, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).v1ReportsTransactionsCashCutPdfGet(requestParameters.from, requestParameters.until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary transactions#by_day
     * @param {TransactionsApiV1TransactionsByDayGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public v1TransactionsByDayGet(requestParameters: TransactionsApiV1TransactionsByDayGetRequest = {}, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).v1TransactionsByDayGet(requestParameters.from, requestParameters.until, requestParameters.transactionType, requestParameters.paymentMethod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary transactions#details
     * @param {TransactionsApiV1TransactionsDetailsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public v1TransactionsDetailsGet(requestParameters: TransactionsApiV1TransactionsDetailsGetRequest = {}, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).v1TransactionsDetailsGet(requestParameters.from, requestParameters.until, requestParameters.userId, requestParameters.transactionType, requestParameters.paymentMethod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary transactions#index
     * @param {TransactionsApiV1TransactionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public v1TransactionsGet(requestParameters: TransactionsApiV1TransactionsGetRequest = {}, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).v1TransactionsGet(requestParameters.from, requestParameters.until, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary transactions#unified
     * @param {TransactionsApiV1TransactionsUnifiedGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public v1TransactionsUnifiedGet(requestParameters: TransactionsApiV1TransactionsUnifiedGetRequest = {}, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).v1TransactionsUnifiedGet(requestParameters.page, requestParameters.type, requestParameters.orderNumber, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary user#invite
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserInvitePatch: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user#update_language
         * @param {V1UserLanguagePatchRequest} [v1UserLanguagePatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserLanguagePatch: async (v1UserLanguagePatchRequest?: V1UserLanguagePatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/language`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1UserLanguagePatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user#me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserMeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user#update
         * @param {string} userPhone 
         * @param {number} userCountryId 
         * @param {string} userLanguage 
         * @param {string} organizationName 
         * @param {number} organizationCountryId 
         * @param {string} organizationBusinessType 
         * @param {string} organizationNumberOfEmployees 
         * @param {string} [userFirstName] 
         * @param {string} [userLastName] 
         * @param {string} [userTimeZone] 
         * @param {string} [organizationMoneySymbol] 
         * @param {File} [organizationAvatar] 
         * @param {string} [organizationIndustry] 
         * @param {boolean} [organizationIsDollar] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserPatch: async (userPhone: string, userCountryId: number, userLanguage: string, organizationName: string, organizationCountryId: number, organizationBusinessType: string, organizationNumberOfEmployees: string, userFirstName?: string, userLastName?: string, userTimeZone?: string, organizationMoneySymbol?: string, organizationAvatar?: File, organizationIndustry?: string, organizationIsDollar?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPhone' is not null or undefined
            assertParamExists('v1UserPatch', 'userPhone', userPhone)
            // verify required parameter 'userCountryId' is not null or undefined
            assertParamExists('v1UserPatch', 'userCountryId', userCountryId)
            // verify required parameter 'userLanguage' is not null or undefined
            assertParamExists('v1UserPatch', 'userLanguage', userLanguage)
            // verify required parameter 'organizationName' is not null or undefined
            assertParamExists('v1UserPatch', 'organizationName', organizationName)
            // verify required parameter 'organizationCountryId' is not null or undefined
            assertParamExists('v1UserPatch', 'organizationCountryId', organizationCountryId)
            // verify required parameter 'organizationBusinessType' is not null or undefined
            assertParamExists('v1UserPatch', 'organizationBusinessType', organizationBusinessType)
            // verify required parameter 'organizationNumberOfEmployees' is not null or undefined
            assertParamExists('v1UserPatch', 'organizationNumberOfEmployees', organizationNumberOfEmployees)
            const localVarPath = `/v1/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


            if (userFirstName !== undefined) { 
                localVarFormParams.append('user_first_name', userFirstName as any);
            }
    
            if (userLastName !== undefined) { 
                localVarFormParams.append('user_last_name', userLastName as any);
            }
    
            if (userPhone !== undefined) { 
                localVarFormParams.append('user_phone', userPhone as any);
            }
    
            if (userCountryId !== undefined) { 
                localVarFormParams.append('user_country_id', userCountryId as any);
            }
    
            if (userTimeZone !== undefined) { 
                localVarFormParams.append('user_time_zone', userTimeZone as any);
            }
    
            if (userLanguage !== undefined) { 
                localVarFormParams.append('user_language', userLanguage as any);
            }
    
            if (organizationName !== undefined) { 
                localVarFormParams.append('organization_name', organizationName as any);
            }
    
            if (organizationMoneySymbol !== undefined) { 
                localVarFormParams.append('organization_money_symbol', organizationMoneySymbol as any);
            }
    
            if (organizationAvatar !== undefined) { 
                localVarFormParams.append('organization_avatar', organizationAvatar as any);
            }
    
            if (organizationCountryId !== undefined) { 
                localVarFormParams.append('organization_country_id', organizationCountryId as any);
            }
    
            if (organizationBusinessType !== undefined) { 
                localVarFormParams.append('organization_business_type', organizationBusinessType as any);
            }
    
            if (organizationIndustry !== undefined) { 
                localVarFormParams.append('organization_industry', organizationIndustry as any);
            }
    
            if (organizationIsDollar !== undefined) { 
                localVarFormParams.append('organization_is_dollar', organizationIsDollar as any);
            }
    
            if (organizationNumberOfEmployees !== undefined) { 
                localVarFormParams.append('organization_number_of_employees', organizationNumberOfEmployees as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user#program_destroy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserProgramDestroyPatch: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/program-destroy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user#cancel_subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserSubscriptionDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user#subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserSubscriptionGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user#update_subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserSubscriptionPatch: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary user#invite
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UserInvitePatch(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1UserMeGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UserInvitePatch(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user#update_language
         * @param {V1UserLanguagePatchRequest} [v1UserLanguagePatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UserLanguagePatch(v1UserLanguagePatchRequest?: V1UserLanguagePatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UserLanguagePatch(v1UserLanguagePatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user#me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UserMeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1UserMeGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UserMeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user#update
         * @param {string} userPhone 
         * @param {number} userCountryId 
         * @param {string} userLanguage 
         * @param {string} organizationName 
         * @param {number} organizationCountryId 
         * @param {string} organizationBusinessType 
         * @param {string} organizationNumberOfEmployees 
         * @param {string} [userFirstName] 
         * @param {string} [userLastName] 
         * @param {string} [userTimeZone] 
         * @param {string} [organizationMoneySymbol] 
         * @param {File} [organizationAvatar] 
         * @param {string} [organizationIndustry] 
         * @param {boolean} [organizationIsDollar] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UserPatch(userPhone: string, userCountryId: number, userLanguage: string, organizationName: string, organizationCountryId: number, organizationBusinessType: string, organizationNumberOfEmployees: string, userFirstName?: string, userLastName?: string, userTimeZone?: string, organizationMoneySymbol?: string, organizationAvatar?: File, organizationIndustry?: string, organizationIsDollar?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1UserMeGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UserPatch(userPhone, userCountryId, userLanguage, organizationName, organizationCountryId, organizationBusinessType, organizationNumberOfEmployees, userFirstName, userLastName, userTimeZone, organizationMoneySymbol, organizationAvatar, organizationIndustry, organizationIsDollar, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user#program_destroy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UserProgramDestroyPatch(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UserProgramDestroyPatch(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user#cancel_subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UserSubscriptionDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UserSubscriptionDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user#subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UserSubscriptionGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1UserSubscriptionDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UserSubscriptionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user#update_subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UserSubscriptionPatch(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UserSubscriptionPatch(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary user#invite
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserInvitePatch(options?: any): AxiosPromise<V1UserMeGet200Response> {
            return localVarFp.v1UserInvitePatch(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user#update_language
         * @param {V1UserLanguagePatchRequest} [v1UserLanguagePatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserLanguagePatch(v1UserLanguagePatchRequest?: V1UserLanguagePatchRequest, options?: any): AxiosPromise<void> {
            return localVarFp.v1UserLanguagePatch(v1UserLanguagePatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user#me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserMeGet(options?: any): AxiosPromise<V1UserMeGet200Response> {
            return localVarFp.v1UserMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user#update
         * @param {string} userPhone 
         * @param {number} userCountryId 
         * @param {string} userLanguage 
         * @param {string} organizationName 
         * @param {number} organizationCountryId 
         * @param {string} organizationBusinessType 
         * @param {string} organizationNumberOfEmployees 
         * @param {string} [userFirstName] 
         * @param {string} [userLastName] 
         * @param {string} [userTimeZone] 
         * @param {string} [organizationMoneySymbol] 
         * @param {File} [organizationAvatar] 
         * @param {string} [organizationIndustry] 
         * @param {boolean} [organizationIsDollar] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserPatch(userPhone: string, userCountryId: number, userLanguage: string, organizationName: string, organizationCountryId: number, organizationBusinessType: string, organizationNumberOfEmployees: string, userFirstName?: string, userLastName?: string, userTimeZone?: string, organizationMoneySymbol?: string, organizationAvatar?: File, organizationIndustry?: string, organizationIsDollar?: boolean, options?: any): AxiosPromise<V1UserMeGet200Response> {
            return localVarFp.v1UserPatch(userPhone, userCountryId, userLanguage, organizationName, organizationCountryId, organizationBusinessType, organizationNumberOfEmployees, userFirstName, userLastName, userTimeZone, organizationMoneySymbol, organizationAvatar, organizationIndustry, organizationIsDollar, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user#program_destroy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserProgramDestroyPatch(options?: any): AxiosPromise<void> {
            return localVarFp.v1UserProgramDestroyPatch(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user#cancel_subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserSubscriptionDelete(options?: any): AxiosPromise<void> {
            return localVarFp.v1UserSubscriptionDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user#subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserSubscriptionGet(options?: any): AxiosPromise<V1UserSubscriptionDelete200Response> {
            return localVarFp.v1UserSubscriptionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user#update_subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserSubscriptionPatch(options?: any): AxiosPromise<void> {
            return localVarFp.v1UserSubscriptionPatch(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1UserLanguagePatch operation in UserApi.
 * @export
 * @interface UserApiV1UserLanguagePatchRequest
 */
export interface UserApiV1UserLanguagePatchRequest {
    /**
     * 
     * @type {V1UserLanguagePatchRequest}
     * @memberof UserApiV1UserLanguagePatch
     */
    readonly v1UserLanguagePatchRequest?: V1UserLanguagePatchRequest
}

/**
 * Request parameters for v1UserPatch operation in UserApi.
 * @export
 * @interface UserApiV1UserPatchRequest
 */
export interface UserApiV1UserPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiV1UserPatch
     */
    readonly userPhone: string

    /**
     * 
     * @type {number}
     * @memberof UserApiV1UserPatch
     */
    readonly userCountryId: number

    /**
     * 
     * @type {string}
     * @memberof UserApiV1UserPatch
     */
    readonly userLanguage: string

    /**
     * 
     * @type {string}
     * @memberof UserApiV1UserPatch
     */
    readonly organizationName: string

    /**
     * 
     * @type {number}
     * @memberof UserApiV1UserPatch
     */
    readonly organizationCountryId: number

    /**
     * 
     * @type {string}
     * @memberof UserApiV1UserPatch
     */
    readonly organizationBusinessType: string

    /**
     * 
     * @type {string}
     * @memberof UserApiV1UserPatch
     */
    readonly organizationNumberOfEmployees: string

    /**
     * 
     * @type {string}
     * @memberof UserApiV1UserPatch
     */
    readonly userFirstName?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiV1UserPatch
     */
    readonly userLastName?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiV1UserPatch
     */
    readonly userTimeZone?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiV1UserPatch
     */
    readonly organizationMoneySymbol?: string

    /**
     * 
     * @type {File}
     * @memberof UserApiV1UserPatch
     */
    readonly organizationAvatar?: File

    /**
     * 
     * @type {string}
     * @memberof UserApiV1UserPatch
     */
    readonly organizationIndustry?: string

    /**
     * 
     * @type {boolean}
     * @memberof UserApiV1UserPatch
     */
    readonly organizationIsDollar?: boolean
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary user#invite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public v1UserInvitePatch(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).v1UserInvitePatch(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user#update_language
     * @param {UserApiV1UserLanguagePatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public v1UserLanguagePatch(requestParameters: UserApiV1UserLanguagePatchRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).v1UserLanguagePatch(requestParameters.v1UserLanguagePatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user#me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public v1UserMeGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).v1UserMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user#update
     * @param {UserApiV1UserPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public v1UserPatch(requestParameters: UserApiV1UserPatchRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).v1UserPatch(requestParameters.userPhone, requestParameters.userCountryId, requestParameters.userLanguage, requestParameters.organizationName, requestParameters.organizationCountryId, requestParameters.organizationBusinessType, requestParameters.organizationNumberOfEmployees, requestParameters.userFirstName, requestParameters.userLastName, requestParameters.userTimeZone, requestParameters.organizationMoneySymbol, requestParameters.organizationAvatar, requestParameters.organizationIndustry, requestParameters.organizationIsDollar, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user#program_destroy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public v1UserProgramDestroyPatch(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).v1UserProgramDestroyPatch(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user#cancel_subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public v1UserSubscriptionDelete(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).v1UserSubscriptionDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user#subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public v1UserSubscriptionGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).v1UserSubscriptionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user#update_subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public v1UserSubscriptionPatch(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).v1UserSubscriptionPatch(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserOrganizationsApi - axios parameter creator
 * @export
 */
export const UserOrganizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary user_organizations#invitees
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InviteesGet: async (search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/invitees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user_organizations#join
         * @param {V1JoinPostRequest} [v1JoinPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JoinPost: async (v1JoinPostRequest?: V1JoinPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/join`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1JoinPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user_organizations#index
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RolesRoleIdInvitesGet: async (roleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('v1RolesRoleIdInvitesGet', 'roleId', roleId)
            const localVarPath = `/v1/roles/{role_id}/invites`
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user_organizations#destroy
         * @param {string} roleId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RolesRoleIdInvitesIdDelete: async (roleId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('v1RolesRoleIdInvitesIdDelete', 'roleId', roleId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1RolesRoleIdInvitesIdDelete', 'id', id)
            const localVarPath = `/v1/roles/{role_id}/invites/{id}`
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user_organizations#show
         * @param {string} roleId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RolesRoleIdInvitesIdGet: async (roleId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('v1RolesRoleIdInvitesIdGet', 'roleId', roleId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1RolesRoleIdInvitesIdGet', 'id', id)
            const localVarPath = `/v1/roles/{role_id}/invites/{id}`
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user_organizations#update
         * @param {string} roleId 
         * @param {string} id 
         * @param {V1RolesRoleIdInvitesGetRequest} [v1RolesRoleIdInvitesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RolesRoleIdInvitesIdPatch: async (roleId: string, id: string, v1RolesRoleIdInvitesGetRequest?: V1RolesRoleIdInvitesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('v1RolesRoleIdInvitesIdPatch', 'roleId', roleId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1RolesRoleIdInvitesIdPatch', 'id', id)
            const localVarPath = `/v1/roles/{role_id}/invites/{id}`
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1RolesRoleIdInvitesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user_organizations#multiple
         * @param {string} roleId 
         * @param {V1RolesRoleIdInvitesMultiplePostRequest} [v1RolesRoleIdInvitesMultiplePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RolesRoleIdInvitesMultiplePost: async (roleId: string, v1RolesRoleIdInvitesMultiplePostRequest?: V1RolesRoleIdInvitesMultiplePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('v1RolesRoleIdInvitesMultiplePost', 'roleId', roleId)
            const localVarPath = `/v1/roles/{role_id}/invites/multiple`
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1RolesRoleIdInvitesMultiplePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user_organizations#create
         * @param {string} roleId 
         * @param {V1RolesRoleIdInvitesGetRequest} [v1RolesRoleIdInvitesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RolesRoleIdInvitesPost: async (roleId: string, v1RolesRoleIdInvitesGetRequest?: V1RolesRoleIdInvitesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('v1RolesRoleIdInvitesPost', 'roleId', roleId)
            const localVarPath = `/v1/roles/{role_id}/invites`
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1RolesRoleIdInvitesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserOrganizationsApi - functional programming interface
 * @export
 */
export const UserOrganizationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserOrganizationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary user_organizations#invitees
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InviteesGet(search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1InviteesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1InviteesGet(search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user_organizations#join
         * @param {V1JoinPostRequest} [v1JoinPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1JoinPost(v1JoinPostRequest?: V1JoinPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1JoinPost(v1JoinPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user_organizations#index
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1RolesRoleIdInvitesGet(roleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1RolesRoleIdInvitesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1RolesRoleIdInvitesGet(roleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user_organizations#destroy
         * @param {string} roleId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1RolesRoleIdInvitesIdDelete(roleId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1RolesRoleIdInvitesIdDelete(roleId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user_organizations#show
         * @param {string} roleId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1RolesRoleIdInvitesIdGet(roleId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1RolesRoleIdInvitesIdDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1RolesRoleIdInvitesIdGet(roleId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user_organizations#update
         * @param {string} roleId 
         * @param {string} id 
         * @param {V1RolesRoleIdInvitesGetRequest} [v1RolesRoleIdInvitesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1RolesRoleIdInvitesIdPatch(roleId: string, id: string, v1RolesRoleIdInvitesGetRequest?: V1RolesRoleIdInvitesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1RolesRoleIdInvitesIdDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1RolesRoleIdInvitesIdPatch(roleId, id, v1RolesRoleIdInvitesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user_organizations#multiple
         * @param {string} roleId 
         * @param {V1RolesRoleIdInvitesMultiplePostRequest} [v1RolesRoleIdInvitesMultiplePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1RolesRoleIdInvitesMultiplePost(roleId: string, v1RolesRoleIdInvitesMultiplePostRequest?: V1RolesRoleIdInvitesMultiplePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1RolesRoleIdInvitesMultiplePost(roleId, v1RolesRoleIdInvitesMultiplePostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user_organizations#create
         * @param {string} roleId 
         * @param {V1RolesRoleIdInvitesGetRequest} [v1RolesRoleIdInvitesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1RolesRoleIdInvitesPost(roleId: string, v1RolesRoleIdInvitesGetRequest?: V1RolesRoleIdInvitesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1RolesRoleIdInvitesPost(roleId, v1RolesRoleIdInvitesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserOrganizationsApi - factory interface
 * @export
 */
export const UserOrganizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserOrganizationsApiFp(configuration)
    return {
        /**
         * 
         * @summary user_organizations#invitees
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InviteesGet(search?: string, options?: any): AxiosPromise<V1InviteesGet200Response> {
            return localVarFp.v1InviteesGet(search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user_organizations#join
         * @param {V1JoinPostRequest} [v1JoinPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1JoinPost(v1JoinPostRequest?: V1JoinPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.v1JoinPost(v1JoinPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user_organizations#index
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RolesRoleIdInvitesGet(roleId: string, options?: any): AxiosPromise<V1RolesRoleIdInvitesGet200Response> {
            return localVarFp.v1RolesRoleIdInvitesGet(roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user_organizations#destroy
         * @param {string} roleId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RolesRoleIdInvitesIdDelete(roleId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1RolesRoleIdInvitesIdDelete(roleId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user_organizations#show
         * @param {string} roleId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RolesRoleIdInvitesIdGet(roleId: string, id: string, options?: any): AxiosPromise<V1RolesRoleIdInvitesIdDelete200Response> {
            return localVarFp.v1RolesRoleIdInvitesIdGet(roleId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user_organizations#update
         * @param {string} roleId 
         * @param {string} id 
         * @param {V1RolesRoleIdInvitesGetRequest} [v1RolesRoleIdInvitesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RolesRoleIdInvitesIdPatch(roleId: string, id: string, v1RolesRoleIdInvitesGetRequest?: V1RolesRoleIdInvitesGetRequest, options?: any): AxiosPromise<V1RolesRoleIdInvitesIdDelete200Response> {
            return localVarFp.v1RolesRoleIdInvitesIdPatch(roleId, id, v1RolesRoleIdInvitesGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user_organizations#multiple
         * @param {string} roleId 
         * @param {V1RolesRoleIdInvitesMultiplePostRequest} [v1RolesRoleIdInvitesMultiplePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RolesRoleIdInvitesMultiplePost(roleId: string, v1RolesRoleIdInvitesMultiplePostRequest?: V1RolesRoleIdInvitesMultiplePostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.v1RolesRoleIdInvitesMultiplePost(roleId, v1RolesRoleIdInvitesMultiplePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user_organizations#create
         * @param {string} roleId 
         * @param {V1RolesRoleIdInvitesGetRequest} [v1RolesRoleIdInvitesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RolesRoleIdInvitesPost(roleId: string, v1RolesRoleIdInvitesGetRequest?: V1RolesRoleIdInvitesGetRequest, options?: any): AxiosPromise<void> {
            return localVarFp.v1RolesRoleIdInvitesPost(roleId, v1RolesRoleIdInvitesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1InviteesGet operation in UserOrganizationsApi.
 * @export
 * @interface UserOrganizationsApiV1InviteesGetRequest
 */
export interface UserOrganizationsApiV1InviteesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UserOrganizationsApiV1InviteesGet
     */
    readonly search?: string
}

/**
 * Request parameters for v1JoinPost operation in UserOrganizationsApi.
 * @export
 * @interface UserOrganizationsApiV1JoinPostRequest
 */
export interface UserOrganizationsApiV1JoinPostRequest {
    /**
     * 
     * @type {V1JoinPostRequest}
     * @memberof UserOrganizationsApiV1JoinPost
     */
    readonly v1JoinPostRequest?: V1JoinPostRequest
}

/**
 * Request parameters for v1RolesRoleIdInvitesGet operation in UserOrganizationsApi.
 * @export
 * @interface UserOrganizationsApiV1RolesRoleIdInvitesGetRequest
 */
export interface UserOrganizationsApiV1RolesRoleIdInvitesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UserOrganizationsApiV1RolesRoleIdInvitesGet
     */
    readonly roleId: string
}

/**
 * Request parameters for v1RolesRoleIdInvitesIdDelete operation in UserOrganizationsApi.
 * @export
 * @interface UserOrganizationsApiV1RolesRoleIdInvitesIdDeleteRequest
 */
export interface UserOrganizationsApiV1RolesRoleIdInvitesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof UserOrganizationsApiV1RolesRoleIdInvitesIdDelete
     */
    readonly roleId: string

    /**
     * 
     * @type {string}
     * @memberof UserOrganizationsApiV1RolesRoleIdInvitesIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for v1RolesRoleIdInvitesIdGet operation in UserOrganizationsApi.
 * @export
 * @interface UserOrganizationsApiV1RolesRoleIdInvitesIdGetRequest
 */
export interface UserOrganizationsApiV1RolesRoleIdInvitesIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UserOrganizationsApiV1RolesRoleIdInvitesIdGet
     */
    readonly roleId: string

    /**
     * 
     * @type {string}
     * @memberof UserOrganizationsApiV1RolesRoleIdInvitesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for v1RolesRoleIdInvitesIdPatch operation in UserOrganizationsApi.
 * @export
 * @interface UserOrganizationsApiV1RolesRoleIdInvitesIdPatchRequest
 */
export interface UserOrganizationsApiV1RolesRoleIdInvitesIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof UserOrganizationsApiV1RolesRoleIdInvitesIdPatch
     */
    readonly roleId: string

    /**
     * 
     * @type {string}
     * @memberof UserOrganizationsApiV1RolesRoleIdInvitesIdPatch
     */
    readonly id: string

    /**
     * 
     * @type {V1RolesRoleIdInvitesGetRequest}
     * @memberof UserOrganizationsApiV1RolesRoleIdInvitesIdPatch
     */
    readonly v1RolesRoleIdInvitesGetRequest?: V1RolesRoleIdInvitesGetRequest
}

/**
 * Request parameters for v1RolesRoleIdInvitesMultiplePost operation in UserOrganizationsApi.
 * @export
 * @interface UserOrganizationsApiV1RolesRoleIdInvitesMultiplePostRequest
 */
export interface UserOrganizationsApiV1RolesRoleIdInvitesMultiplePostRequest {
    /**
     * 
     * @type {string}
     * @memberof UserOrganizationsApiV1RolesRoleIdInvitesMultiplePost
     */
    readonly roleId: string

    /**
     * 
     * @type {V1RolesRoleIdInvitesMultiplePostRequest}
     * @memberof UserOrganizationsApiV1RolesRoleIdInvitesMultiplePost
     */
    readonly v1RolesRoleIdInvitesMultiplePostRequest?: V1RolesRoleIdInvitesMultiplePostRequest
}

/**
 * Request parameters for v1RolesRoleIdInvitesPost operation in UserOrganizationsApi.
 * @export
 * @interface UserOrganizationsApiV1RolesRoleIdInvitesPostRequest
 */
export interface UserOrganizationsApiV1RolesRoleIdInvitesPostRequest {
    /**
     * 
     * @type {string}
     * @memberof UserOrganizationsApiV1RolesRoleIdInvitesPost
     */
    readonly roleId: string

    /**
     * 
     * @type {V1RolesRoleIdInvitesGetRequest}
     * @memberof UserOrganizationsApiV1RolesRoleIdInvitesPost
     */
    readonly v1RolesRoleIdInvitesGetRequest?: V1RolesRoleIdInvitesGetRequest
}

/**
 * UserOrganizationsApi - object-oriented interface
 * @export
 * @class UserOrganizationsApi
 * @extends {BaseAPI}
 */
export class UserOrganizationsApi extends BaseAPI {
    /**
     * 
     * @summary user_organizations#invitees
     * @param {UserOrganizationsApiV1InviteesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserOrganizationsApi
     */
    public v1InviteesGet(requestParameters: UserOrganizationsApiV1InviteesGetRequest = {}, options?: AxiosRequestConfig) {
        return UserOrganizationsApiFp(this.configuration).v1InviteesGet(requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user_organizations#join
     * @param {UserOrganizationsApiV1JoinPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserOrganizationsApi
     */
    public v1JoinPost(requestParameters: UserOrganizationsApiV1JoinPostRequest = {}, options?: AxiosRequestConfig) {
        return UserOrganizationsApiFp(this.configuration).v1JoinPost(requestParameters.v1JoinPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user_organizations#index
     * @param {UserOrganizationsApiV1RolesRoleIdInvitesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserOrganizationsApi
     */
    public v1RolesRoleIdInvitesGet(requestParameters: UserOrganizationsApiV1RolesRoleIdInvitesGetRequest, options?: AxiosRequestConfig) {
        return UserOrganizationsApiFp(this.configuration).v1RolesRoleIdInvitesGet(requestParameters.roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user_organizations#destroy
     * @param {UserOrganizationsApiV1RolesRoleIdInvitesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserOrganizationsApi
     */
    public v1RolesRoleIdInvitesIdDelete(requestParameters: UserOrganizationsApiV1RolesRoleIdInvitesIdDeleteRequest, options?: AxiosRequestConfig) {
        return UserOrganizationsApiFp(this.configuration).v1RolesRoleIdInvitesIdDelete(requestParameters.roleId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user_organizations#show
     * @param {UserOrganizationsApiV1RolesRoleIdInvitesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserOrganizationsApi
     */
    public v1RolesRoleIdInvitesIdGet(requestParameters: UserOrganizationsApiV1RolesRoleIdInvitesIdGetRequest, options?: AxiosRequestConfig) {
        return UserOrganizationsApiFp(this.configuration).v1RolesRoleIdInvitesIdGet(requestParameters.roleId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user_organizations#update
     * @param {UserOrganizationsApiV1RolesRoleIdInvitesIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserOrganizationsApi
     */
    public v1RolesRoleIdInvitesIdPatch(requestParameters: UserOrganizationsApiV1RolesRoleIdInvitesIdPatchRequest, options?: AxiosRequestConfig) {
        return UserOrganizationsApiFp(this.configuration).v1RolesRoleIdInvitesIdPatch(requestParameters.roleId, requestParameters.id, requestParameters.v1RolesRoleIdInvitesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user_organizations#multiple
     * @param {UserOrganizationsApiV1RolesRoleIdInvitesMultiplePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserOrganizationsApi
     */
    public v1RolesRoleIdInvitesMultiplePost(requestParameters: UserOrganizationsApiV1RolesRoleIdInvitesMultiplePostRequest, options?: AxiosRequestConfig) {
        return UserOrganizationsApiFp(this.configuration).v1RolesRoleIdInvitesMultiplePost(requestParameters.roleId, requestParameters.v1RolesRoleIdInvitesMultiplePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user_organizations#create
     * @param {UserOrganizationsApiV1RolesRoleIdInvitesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserOrganizationsApi
     */
    public v1RolesRoleIdInvitesPost(requestParameters: UserOrganizationsApiV1RolesRoleIdInvitesPostRequest, options?: AxiosRequestConfig) {
        return UserOrganizationsApiFp(this.configuration).v1RolesRoleIdInvitesPost(requestParameters.roleId, requestParameters.v1RolesRoleIdInvitesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary login#android
         * @param {UsersAndroidPostRequest} [usersAndroidPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAndroidPost: async (usersAndroidPostRequest?: UsersAndroidPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/android`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersAndroidPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary confirmations#show
         * @param {string} confirmationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersConfirmationGet: async (confirmationToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'confirmationToken' is not null or undefined
            assertParamExists('usersConfirmationGet', 'confirmationToken', confirmationToken)
            const localVarPath = `/users/confirmation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (confirmationToken !== undefined) {
                localVarQueryParameter['confirmation_token'] = confirmationToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary login#ios
         * @param {UsersIosPostRequest} [usersIosPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIosPost: async (usersIosPostRequest?: UsersIosPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/ios`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersIosPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sessions#create
         * @param {UsersLoginPostRequest} [usersLoginPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersLoginPost: async (usersLoginPostRequest?: UsersLoginPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersLoginPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sessions#destroy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersLogoutDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary passwords#create
         * @param {UsersPasswordPostRequest1} [usersPasswordPostRequest1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPasswordPost: async (usersPasswordPostRequest1?: UsersPasswordPostRequest1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersPasswordPostRequest1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary passwords#update
         * @param {UsersPasswordPostRequest} [usersPasswordPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPasswordPut: async (usersPasswordPostRequest?: UsersPasswordPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersPasswordPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary registrations#create
         * @param {UsersPostRequest} [usersPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPost: async (usersPostRequest?: UsersPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes all organizations for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserRemoveOrganizationsDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/remove-organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update user password without token
         * @param {V1UserUpdatePasswordPutRequest} [v1UserUpdatePasswordPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserUpdatePasswordPut: async (v1UserUpdatePasswordPutRequest?: V1UserUpdatePasswordPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/update-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1UserUpdatePasswordPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a single user
         * @param {V1UserUpdateSinglePatchRequest} [v1UserUpdateSinglePatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserUpdateSinglePatch: async (v1UserUpdateSinglePatchRequest?: V1UserUpdateSinglePatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/update-single`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1UserUpdateSinglePatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary login#android
         * @param {UsersAndroidPostRequest} [usersAndroidPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAndroidPost(usersAndroidPostRequest?: UsersAndroidPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersAndroidPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAndroidPost(usersAndroidPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary confirmations#show
         * @param {string} confirmationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersConfirmationGet(confirmationToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersConfirmationGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersConfirmationGet(confirmationToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary login#ios
         * @param {UsersIosPostRequest} [usersIosPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersIosPost(usersIosPostRequest?: UsersIosPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersAndroidPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIosPost(usersIosPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sessions#create
         * @param {UsersLoginPostRequest} [usersLoginPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersLoginPost(usersLoginPostRequest?: UsersLoginPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersAndroidPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersLoginPost(usersLoginPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sessions#destroy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersLogoutDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersLogoutDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary passwords#create
         * @param {UsersPasswordPostRequest1} [usersPasswordPostRequest1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersPasswordPost(usersPasswordPostRequest1?: UsersPasswordPostRequest1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersPasswordPost(usersPasswordPostRequest1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary passwords#update
         * @param {UsersPasswordPostRequest} [usersPasswordPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersPasswordPut(usersPasswordPostRequest?: UsersPasswordPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersPasswordPut(usersPasswordPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary registrations#create
         * @param {UsersPostRequest} [usersPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersPost(usersPostRequest?: UsersPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersPost201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersPost(usersPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes all organizations for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UserRemoveOrganizationsDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UserRemoveOrganizationsDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update user password without token
         * @param {V1UserUpdatePasswordPutRequest} [v1UserUpdatePasswordPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UserUpdatePasswordPut(v1UserUpdatePasswordPutRequest?: V1UserUpdatePasswordPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UserUpdatePasswordPut(v1UserUpdatePasswordPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a single user
         * @param {V1UserUpdateSinglePatchRequest} [v1UserUpdateSinglePatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UserUpdateSinglePatch(v1UserUpdateSinglePatchRequest?: V1UserUpdateSinglePatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UserUpdateSinglePatch(v1UserUpdateSinglePatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary login#android
         * @param {UsersAndroidPostRequest} [usersAndroidPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAndroidPost(usersAndroidPostRequest?: UsersAndroidPostRequest, options?: any): AxiosPromise<UsersAndroidPost200Response> {
            return localVarFp.usersAndroidPost(usersAndroidPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary confirmations#show
         * @param {string} confirmationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersConfirmationGet(confirmationToken: string, options?: any): AxiosPromise<UsersConfirmationGet200Response> {
            return localVarFp.usersConfirmationGet(confirmationToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary login#ios
         * @param {UsersIosPostRequest} [usersIosPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIosPost(usersIosPostRequest?: UsersIosPostRequest, options?: any): AxiosPromise<UsersAndroidPost200Response> {
            return localVarFp.usersIosPost(usersIosPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sessions#create
         * @param {UsersLoginPostRequest} [usersLoginPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersLoginPost(usersLoginPostRequest?: UsersLoginPostRequest, options?: any): AxiosPromise<UsersAndroidPost200Response> {
            return localVarFp.usersLoginPost(usersLoginPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sessions#destroy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersLogoutDelete(options?: any): AxiosPromise<void> {
            return localVarFp.usersLogoutDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary passwords#create
         * @param {UsersPasswordPostRequest1} [usersPasswordPostRequest1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPasswordPost(usersPasswordPostRequest1?: UsersPasswordPostRequest1, options?: any): AxiosPromise<void> {
            return localVarFp.usersPasswordPost(usersPasswordPostRequest1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary passwords#update
         * @param {UsersPasswordPostRequest} [usersPasswordPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPasswordPut(usersPasswordPostRequest?: UsersPasswordPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.usersPasswordPut(usersPasswordPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary registrations#create
         * @param {UsersPostRequest} [usersPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPost(usersPostRequest?: UsersPostRequest, options?: any): AxiosPromise<UsersPost201Response> {
            return localVarFp.usersPost(usersPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes all organizations for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserRemoveOrganizationsDelete(options?: any): AxiosPromise<void> {
            return localVarFp.v1UserRemoveOrganizationsDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update user password without token
         * @param {V1UserUpdatePasswordPutRequest} [v1UserUpdatePasswordPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserUpdatePasswordPut(v1UserUpdatePasswordPutRequest?: V1UserUpdatePasswordPutRequest, options?: any): AxiosPromise<void> {
            return localVarFp.v1UserUpdatePasswordPut(v1UserUpdatePasswordPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a single user
         * @param {V1UserUpdateSinglePatchRequest} [v1UserUpdateSinglePatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserUpdateSinglePatch(v1UserUpdateSinglePatchRequest?: V1UserUpdateSinglePatchRequest, options?: any): AxiosPromise<void> {
            return localVarFp.v1UserUpdateSinglePatch(v1UserUpdateSinglePatchRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for usersAndroidPost operation in UsersApi.
 * @export
 * @interface UsersApiUsersAndroidPostRequest
 */
export interface UsersApiUsersAndroidPostRequest {
    /**
     * 
     * @type {UsersAndroidPostRequest}
     * @memberof UsersApiUsersAndroidPost
     */
    readonly usersAndroidPostRequest?: UsersAndroidPostRequest
}

/**
 * Request parameters for usersConfirmationGet operation in UsersApi.
 * @export
 * @interface UsersApiUsersConfirmationGetRequest
 */
export interface UsersApiUsersConfirmationGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersConfirmationGet
     */
    readonly confirmationToken: string
}

/**
 * Request parameters for usersIosPost operation in UsersApi.
 * @export
 * @interface UsersApiUsersIosPostRequest
 */
export interface UsersApiUsersIosPostRequest {
    /**
     * 
     * @type {UsersIosPostRequest}
     * @memberof UsersApiUsersIosPost
     */
    readonly usersIosPostRequest?: UsersIosPostRequest
}

/**
 * Request parameters for usersLoginPost operation in UsersApi.
 * @export
 * @interface UsersApiUsersLoginPostRequest
 */
export interface UsersApiUsersLoginPostRequest {
    /**
     * 
     * @type {UsersLoginPostRequest}
     * @memberof UsersApiUsersLoginPost
     */
    readonly usersLoginPostRequest?: UsersLoginPostRequest
}

/**
 * Request parameters for usersPasswordPost operation in UsersApi.
 * @export
 * @interface UsersApiUsersPasswordPostRequest
 */
export interface UsersApiUsersPasswordPostRequest {
    /**
     * 
     * @type {UsersPasswordPostRequest1}
     * @memberof UsersApiUsersPasswordPost
     */
    readonly usersPasswordPostRequest1?: UsersPasswordPostRequest1
}

/**
 * Request parameters for usersPasswordPut operation in UsersApi.
 * @export
 * @interface UsersApiUsersPasswordPutRequest
 */
export interface UsersApiUsersPasswordPutRequest {
    /**
     * 
     * @type {UsersPasswordPostRequest}
     * @memberof UsersApiUsersPasswordPut
     */
    readonly usersPasswordPostRequest?: UsersPasswordPostRequest
}

/**
 * Request parameters for usersPost operation in UsersApi.
 * @export
 * @interface UsersApiUsersPostRequest
 */
export interface UsersApiUsersPostRequest {
    /**
     * 
     * @type {UsersPostRequest}
     * @memberof UsersApiUsersPost
     */
    readonly usersPostRequest?: UsersPostRequest
}

/**
 * Request parameters for v1UserUpdatePasswordPut operation in UsersApi.
 * @export
 * @interface UsersApiV1UserUpdatePasswordPutRequest
 */
export interface UsersApiV1UserUpdatePasswordPutRequest {
    /**
     * 
     * @type {V1UserUpdatePasswordPutRequest}
     * @memberof UsersApiV1UserUpdatePasswordPut
     */
    readonly v1UserUpdatePasswordPutRequest?: V1UserUpdatePasswordPutRequest
}

/**
 * Request parameters for v1UserUpdateSinglePatch operation in UsersApi.
 * @export
 * @interface UsersApiV1UserUpdateSinglePatchRequest
 */
export interface UsersApiV1UserUpdateSinglePatchRequest {
    /**
     * 
     * @type {V1UserUpdateSinglePatchRequest}
     * @memberof UsersApiV1UserUpdateSinglePatch
     */
    readonly v1UserUpdateSinglePatchRequest?: V1UserUpdateSinglePatchRequest
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary login#android
     * @param {UsersApiUsersAndroidPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersAndroidPost(requestParameters: UsersApiUsersAndroidPostRequest = {}, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersAndroidPost(requestParameters.usersAndroidPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary confirmations#show
     * @param {UsersApiUsersConfirmationGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersConfirmationGet(requestParameters: UsersApiUsersConfirmationGetRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersConfirmationGet(requestParameters.confirmationToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary login#ios
     * @param {UsersApiUsersIosPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersIosPost(requestParameters: UsersApiUsersIosPostRequest = {}, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersIosPost(requestParameters.usersIosPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sessions#create
     * @param {UsersApiUsersLoginPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersLoginPost(requestParameters: UsersApiUsersLoginPostRequest = {}, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersLoginPost(requestParameters.usersLoginPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sessions#destroy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersLogoutDelete(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersLogoutDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary passwords#create
     * @param {UsersApiUsersPasswordPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersPasswordPost(requestParameters: UsersApiUsersPasswordPostRequest = {}, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersPasswordPost(requestParameters.usersPasswordPostRequest1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary passwords#update
     * @param {UsersApiUsersPasswordPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersPasswordPut(requestParameters: UsersApiUsersPasswordPutRequest = {}, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersPasswordPut(requestParameters.usersPasswordPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary registrations#create
     * @param {UsersApiUsersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersPost(requestParameters: UsersApiUsersPostRequest = {}, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersPost(requestParameters.usersPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes all organizations for the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UserRemoveOrganizationsDelete(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).v1UserRemoveOrganizationsDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update user password without token
     * @param {UsersApiV1UserUpdatePasswordPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UserUpdatePasswordPut(requestParameters: UsersApiV1UserUpdatePasswordPutRequest = {}, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).v1UserUpdatePasswordPut(requestParameters.v1UserUpdatePasswordPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a single user
     * @param {UsersApiV1UserUpdateSinglePatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UserUpdateSinglePatch(requestParameters: UsersApiV1UserUpdateSinglePatchRequest = {}, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).v1UserUpdateSinglePatch(requestParameters.v1UserUpdateSinglePatchRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VersionApi - axios parameter creator
 * @export
 */
export const VersionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary version#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VersionGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VersionApi - functional programming interface
 * @export
 */
export const VersionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VersionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary version#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VersionGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1VersionGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1VersionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VersionApi - factory interface
 * @export
 */
export const VersionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VersionApiFp(configuration)
    return {
        /**
         * 
         * @summary version#index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VersionGet(options?: any): AxiosPromise<V1VersionGet200Response> {
            return localVarFp.v1VersionGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VersionApi - object-oriented interface
 * @export
 * @class VersionApi
 * @extends {BaseAPI}
 */
export class VersionApi extends BaseAPI {
    /**
     * 
     * @summary version#index
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApi
     */
    public v1VersionGet(options?: AxiosRequestConfig) {
        return VersionApiFp(this.configuration).v1VersionGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VoiceCommandsApi - axios parameter creator
 * @export
 */
export const VoiceCommandsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary voice_commands#execute
         * @param {V1VoiceCommandsExecutePostRequest} [v1VoiceCommandsExecutePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VoiceCommandsExecutePost: async (v1VoiceCommandsExecutePostRequest?: V1VoiceCommandsExecutePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/voice_commands/execute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1VoiceCommandsExecutePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary voice_commands#transcribe
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VoiceCommandsTranscribeIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1VoiceCommandsTranscribeIdGet', 'id', id)
            const localVarPath = `/v1/voice_commands/transcribe/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VoiceCommandsApi - functional programming interface
 * @export
 */
export const VoiceCommandsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VoiceCommandsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary voice_commands#execute
         * @param {V1VoiceCommandsExecutePostRequest} [v1VoiceCommandsExecutePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VoiceCommandsExecutePost(v1VoiceCommandsExecutePostRequest?: V1VoiceCommandsExecutePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1VoiceCommandsExecutePost201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1VoiceCommandsExecutePost(v1VoiceCommandsExecutePostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary voice_commands#transcribe
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1VoiceCommandsTranscribeIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1VoiceCommandsTranscribeIdGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1VoiceCommandsTranscribeIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VoiceCommandsApi - factory interface
 * @export
 */
export const VoiceCommandsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VoiceCommandsApiFp(configuration)
    return {
        /**
         * 
         * @summary voice_commands#execute
         * @param {V1VoiceCommandsExecutePostRequest} [v1VoiceCommandsExecutePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VoiceCommandsExecutePost(v1VoiceCommandsExecutePostRequest?: V1VoiceCommandsExecutePostRequest, options?: any): AxiosPromise<V1VoiceCommandsExecutePost201Response> {
            return localVarFp.v1VoiceCommandsExecutePost(v1VoiceCommandsExecutePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary voice_commands#transcribe
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VoiceCommandsTranscribeIdGet(id: string, options?: any): AxiosPromise<V1VoiceCommandsTranscribeIdGet200Response> {
            return localVarFp.v1VoiceCommandsTranscribeIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1VoiceCommandsExecutePost operation in VoiceCommandsApi.
 * @export
 * @interface VoiceCommandsApiV1VoiceCommandsExecutePostRequest
 */
export interface VoiceCommandsApiV1VoiceCommandsExecutePostRequest {
    /**
     * 
     * @type {V1VoiceCommandsExecutePostRequest}
     * @memberof VoiceCommandsApiV1VoiceCommandsExecutePost
     */
    readonly v1VoiceCommandsExecutePostRequest?: V1VoiceCommandsExecutePostRequest
}

/**
 * Request parameters for v1VoiceCommandsTranscribeIdGet operation in VoiceCommandsApi.
 * @export
 * @interface VoiceCommandsApiV1VoiceCommandsTranscribeIdGetRequest
 */
export interface VoiceCommandsApiV1VoiceCommandsTranscribeIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof VoiceCommandsApiV1VoiceCommandsTranscribeIdGet
     */
    readonly id: string
}

/**
 * VoiceCommandsApi - object-oriented interface
 * @export
 * @class VoiceCommandsApi
 * @extends {BaseAPI}
 */
export class VoiceCommandsApi extends BaseAPI {
    /**
     * 
     * @summary voice_commands#execute
     * @param {VoiceCommandsApiV1VoiceCommandsExecutePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceCommandsApi
     */
    public v1VoiceCommandsExecutePost(requestParameters: VoiceCommandsApiV1VoiceCommandsExecutePostRequest = {}, options?: AxiosRequestConfig) {
        return VoiceCommandsApiFp(this.configuration).v1VoiceCommandsExecutePost(requestParameters.v1VoiceCommandsExecutePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary voice_commands#transcribe
     * @param {VoiceCommandsApiV1VoiceCommandsTranscribeIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceCommandsApi
     */
    public v1VoiceCommandsTranscribeIdGet(requestParameters: VoiceCommandsApiV1VoiceCommandsTranscribeIdGetRequest, options?: AxiosRequestConfig) {
        return VoiceCommandsApiFp(this.configuration).v1VoiceCommandsTranscribeIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebCouponsApi - axios parameter creator
 * @export
 */
export const WebCouponsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary coupons#validate
         * @param {string} slug 
         * @param {string} [couponCode] 
         * @param {string} [productIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebOrganizationsSlugCouponsValidateGet: async (slug: string, couponCode?: string, productIds?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('v1WebOrganizationsSlugCouponsValidateGet', 'slug', slug)
            const localVarPath = `/v1/web/organizations/{slug}/coupons/validate`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (couponCode !== undefined) {
                localVarQueryParameter['coupon_code'] = couponCode;
            }

            if (productIds !== undefined) {
                localVarQueryParameter['product_ids'] = productIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebCouponsApi - functional programming interface
 * @export
 */
export const WebCouponsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebCouponsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary coupons#validate
         * @param {string} slug 
         * @param {string} [couponCode] 
         * @param {string} [productIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1WebOrganizationsSlugCouponsValidateGet(slug: string, couponCode?: string, productIds?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1WebOrganizationsSlugCouponsValidateGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1WebOrganizationsSlugCouponsValidateGet(slug, couponCode, productIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebCouponsApi - factory interface
 * @export
 */
export const WebCouponsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebCouponsApiFp(configuration)
    return {
        /**
         * 
         * @summary coupons#validate
         * @param {string} slug 
         * @param {string} [couponCode] 
         * @param {string} [productIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebOrganizationsSlugCouponsValidateGet(slug: string, couponCode?: string, productIds?: string, options?: any): AxiosPromise<V1WebOrganizationsSlugCouponsValidateGet200Response> {
            return localVarFp.v1WebOrganizationsSlugCouponsValidateGet(slug, couponCode, productIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1WebOrganizationsSlugCouponsValidateGet operation in WebCouponsApi.
 * @export
 * @interface WebCouponsApiV1WebOrganizationsSlugCouponsValidateGetRequest
 */
export interface WebCouponsApiV1WebOrganizationsSlugCouponsValidateGetRequest {
    /**
     * 
     * @type {string}
     * @memberof WebCouponsApiV1WebOrganizationsSlugCouponsValidateGet
     */
    readonly slug: string

    /**
     * 
     * @type {string}
     * @memberof WebCouponsApiV1WebOrganizationsSlugCouponsValidateGet
     */
    readonly couponCode?: string

    /**
     * 
     * @type {string}
     * @memberof WebCouponsApiV1WebOrganizationsSlugCouponsValidateGet
     */
    readonly productIds?: string
}

/**
 * WebCouponsApi - object-oriented interface
 * @export
 * @class WebCouponsApi
 * @extends {BaseAPI}
 */
export class WebCouponsApi extends BaseAPI {
    /**
     * 
     * @summary coupons#validate
     * @param {WebCouponsApiV1WebOrganizationsSlugCouponsValidateGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebCouponsApi
     */
    public v1WebOrganizationsSlugCouponsValidateGet(requestParameters: WebCouponsApiV1WebOrganizationsSlugCouponsValidateGetRequest, options?: AxiosRequestConfig) {
        return WebCouponsApiFp(this.configuration).v1WebOrganizationsSlugCouponsValidateGet(requestParameters.slug, requestParameters.couponCode, requestParameters.productIds, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebOrganizationsApi - axios parameter creator
 * @export
 */
export const WebOrganizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary organization#show
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebOrganizationsSlugGet: async (slug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('v1WebOrganizationsSlugGet', 'slug', slug)
            const localVarPath = `/v1/web/organizations/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebOrganizationsApi - functional programming interface
 * @export
 */
export const WebOrganizationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebOrganizationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary organization#show
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1WebOrganizationsSlugGet(slug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1WebOrganizationsSlugGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1WebOrganizationsSlugGet(slug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebOrganizationsApi - factory interface
 * @export
 */
export const WebOrganizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebOrganizationsApiFp(configuration)
    return {
        /**
         * 
         * @summary organization#show
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebOrganizationsSlugGet(slug: string, options?: any): AxiosPromise<V1WebOrganizationsSlugGet200Response> {
            return localVarFp.v1WebOrganizationsSlugGet(slug, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1WebOrganizationsSlugGet operation in WebOrganizationsApi.
 * @export
 * @interface WebOrganizationsApiV1WebOrganizationsSlugGetRequest
 */
export interface WebOrganizationsApiV1WebOrganizationsSlugGetRequest {
    /**
     * 
     * @type {string}
     * @memberof WebOrganizationsApiV1WebOrganizationsSlugGet
     */
    readonly slug: string
}

/**
 * WebOrganizationsApi - object-oriented interface
 * @export
 * @class WebOrganizationsApi
 * @extends {BaseAPI}
 */
export class WebOrganizationsApi extends BaseAPI {
    /**
     * 
     * @summary organization#show
     * @param {WebOrganizationsApiV1WebOrganizationsSlugGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebOrganizationsApi
     */
    public v1WebOrganizationsSlugGet(requestParameters: WebOrganizationsApiV1WebOrganizationsSlugGetRequest, options?: AxiosRequestConfig) {
        return WebOrganizationsApiFp(this.configuration).v1WebOrganizationsSlugGet(requestParameters.slug, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebProductCategoriesApi - axios parameter creator
 * @export
 */
export const WebProductCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary product_categories#index
         * @param {string} slug 
         * @param {number} [page] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebOrganizationsSlugProductCategoriesGet: async (slug: string, page?: number, content?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('v1WebOrganizationsSlugProductCategoriesGet', 'slug', slug)
            const localVarPath = `/v1/web/organizations/{slug}/product-categories`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebProductCategoriesApi - functional programming interface
 * @export
 */
export const WebProductCategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebProductCategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary product_categories#index
         * @param {string} slug 
         * @param {number} [page] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1WebOrganizationsSlugProductCategoriesGet(slug: string, page?: number, content?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1WebOrganizationsSlugProductCategoriesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1WebOrganizationsSlugProductCategoriesGet(slug, page, content, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebProductCategoriesApi - factory interface
 * @export
 */
export const WebProductCategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebProductCategoriesApiFp(configuration)
    return {
        /**
         * 
         * @summary product_categories#index
         * @param {string} slug 
         * @param {number} [page] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebOrganizationsSlugProductCategoriesGet(slug: string, page?: number, content?: string, options?: any): AxiosPromise<V1WebOrganizationsSlugProductCategoriesGet200Response> {
            return localVarFp.v1WebOrganizationsSlugProductCategoriesGet(slug, page, content, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1WebOrganizationsSlugProductCategoriesGet operation in WebProductCategoriesApi.
 * @export
 * @interface WebProductCategoriesApiV1WebOrganizationsSlugProductCategoriesGetRequest
 */
export interface WebProductCategoriesApiV1WebOrganizationsSlugProductCategoriesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof WebProductCategoriesApiV1WebOrganizationsSlugProductCategoriesGet
     */
    readonly slug: string

    /**
     * 
     * @type {number}
     * @memberof WebProductCategoriesApiV1WebOrganizationsSlugProductCategoriesGet
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof WebProductCategoriesApiV1WebOrganizationsSlugProductCategoriesGet
     */
    readonly content?: string
}

/**
 * WebProductCategoriesApi - object-oriented interface
 * @export
 * @class WebProductCategoriesApi
 * @extends {BaseAPI}
 */
export class WebProductCategoriesApi extends BaseAPI {
    /**
     * 
     * @summary product_categories#index
     * @param {WebProductCategoriesApiV1WebOrganizationsSlugProductCategoriesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebProductCategoriesApi
     */
    public v1WebOrganizationsSlugProductCategoriesGet(requestParameters: WebProductCategoriesApiV1WebOrganizationsSlugProductCategoriesGetRequest, options?: AxiosRequestConfig) {
        return WebProductCategoriesApiFp(this.configuration).v1WebOrganizationsSlugProductCategoriesGet(requestParameters.slug, requestParameters.page, requestParameters.content, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebProductsApi - axios parameter creator
 * @export
 */
export const WebProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary products#index
         * @param {string} slug 
         * @param {number} [page] 
         * @param {string} [by] The field you want to search. ie: name barcode
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [categoryIds] Search by category_ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebOrganizationsSlugProductsGet: async (slug: string, page?: number, by?: string, content?: string, categoryIds?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('v1WebOrganizationsSlugProductsGet', 'slug', slug)
            const localVarPath = `/v1/web/organizations/{slug}/products`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (by !== undefined) {
                localVarQueryParameter['by'] = by;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }

            if (categoryIds !== undefined) {
                localVarQueryParameter['category_ids'] = categoryIds;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary products#index
         * @param {string} slug 
         * @param {number} [page] 
         * @param {string} [by] The field you want to search. ie: name barcode
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [categoryIds] Search by category_ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2WebOrganizationsSlugProductsGet: async (slug: string, page?: number, by?: string, content?: string, categoryIds?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('v2WebOrganizationsSlugProductsGet', 'slug', slug)
            const localVarPath = `/v2/web/organizations/{slug}/products`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (by !== undefined) {
                localVarQueryParameter['by'] = by;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }

            if (categoryIds !== undefined) {
                localVarQueryParameter['category_ids'] = categoryIds;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary products#show
         * @param {string} slug 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2WebOrganizationsSlugProductsIdGet: async (slug: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('v2WebOrganizationsSlugProductsIdGet', 'slug', slug)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2WebOrganizationsSlugProductsIdGet', 'id', id)
            const localVarPath = `/v2/web/organizations/{slug}/products/{id}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary products#modifiers
         * @param {string} slug 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2WebOrganizationsSlugProductsIdModifiersGet: async (slug: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('v2WebOrganizationsSlugProductsIdModifiersGet', 'slug', slug)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2WebOrganizationsSlugProductsIdModifiersGet', 'id', id)
            const localVarPath = `/v2/web/organizations/{slug}/products/{id}/modifiers`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebProductsApi - functional programming interface
 * @export
 */
export const WebProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary products#index
         * @param {string} slug 
         * @param {number} [page] 
         * @param {string} [by] The field you want to search. ie: name barcode
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [categoryIds] Search by category_ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1WebOrganizationsSlugProductsGet(slug: string, page?: number, by?: string, content?: string, categoryIds?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1WebOrganizationsSlugProductsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1WebOrganizationsSlugProductsGet(slug, page, by, content, categoryIds, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary products#index
         * @param {string} slug 
         * @param {number} [page] 
         * @param {string} [by] The field you want to search. ie: name barcode
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [categoryIds] Search by category_ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2WebOrganizationsSlugProductsGet(slug: string, page?: number, by?: string, content?: string, categoryIds?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2OffersProductsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2WebOrganizationsSlugProductsGet(slug, page, by, content, categoryIds, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary products#show
         * @param {string} slug 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2WebOrganizationsSlugProductsIdGet(slug: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ProductsIdDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2WebOrganizationsSlugProductsIdGet(slug, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary products#modifiers
         * @param {string} slug 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2WebOrganizationsSlugProductsIdModifiersGet(slug: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ProductsIdModifiersGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2WebOrganizationsSlugProductsIdModifiersGet(slug, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebProductsApi - factory interface
 * @export
 */
export const WebProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebProductsApiFp(configuration)
    return {
        /**
         * 
         * @summary products#index
         * @param {string} slug 
         * @param {number} [page] 
         * @param {string} [by] The field you want to search. ie: name barcode
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [categoryIds] Search by category_ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebOrganizationsSlugProductsGet(slug: string, page?: number, by?: string, content?: string, categoryIds?: string, order?: string, options?: any): AxiosPromise<V1WebOrganizationsSlugProductsGet200Response> {
            return localVarFp.v1WebOrganizationsSlugProductsGet(slug, page, by, content, categoryIds, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary products#index
         * @param {string} slug 
         * @param {number} [page] 
         * @param {string} [by] The field you want to search. ie: name barcode
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [categoryIds] Search by category_ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2WebOrganizationsSlugProductsGet(slug: string, page?: number, by?: string, content?: string, categoryIds?: string, order?: string, options?: any): AxiosPromise<V2OffersProductsGet200Response> {
            return localVarFp.v2WebOrganizationsSlugProductsGet(slug, page, by, content, categoryIds, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary products#show
         * @param {string} slug 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2WebOrganizationsSlugProductsIdGet(slug: string, id: string, options?: any): AxiosPromise<V1ProductsIdDelete200Response> {
            return localVarFp.v2WebOrganizationsSlugProductsIdGet(slug, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary products#modifiers
         * @param {string} slug 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2WebOrganizationsSlugProductsIdModifiersGet(slug: string, id: string, options?: any): AxiosPromise<V1ProductsIdModifiersGet200Response> {
            return localVarFp.v2WebOrganizationsSlugProductsIdModifiersGet(slug, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1WebOrganizationsSlugProductsGet operation in WebProductsApi.
 * @export
 * @interface WebProductsApiV1WebOrganizationsSlugProductsGetRequest
 */
export interface WebProductsApiV1WebOrganizationsSlugProductsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof WebProductsApiV1WebOrganizationsSlugProductsGet
     */
    readonly slug: string

    /**
     * 
     * @type {number}
     * @memberof WebProductsApiV1WebOrganizationsSlugProductsGet
     */
    readonly page?: number

    /**
     * The field you want to search. ie: name barcode
     * @type {string}
     * @memberof WebProductsApiV1WebOrganizationsSlugProductsGet
     */
    readonly by?: string

    /**
     * The content you want to search. ie: query prop1,prop2,prop3
     * @type {string}
     * @memberof WebProductsApiV1WebOrganizationsSlugProductsGet
     */
    readonly content?: string

    /**
     * Search by category_ids, comma separated. ie: 1,2,3
     * @type {string}
     * @memberof WebProductsApiV1WebOrganizationsSlugProductsGet
     */
    readonly categoryIds?: string

    /**
     * Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
     * @type {string}
     * @memberof WebProductsApiV1WebOrganizationsSlugProductsGet
     */
    readonly order?: string
}

/**
 * Request parameters for v2WebOrganizationsSlugProductsGet operation in WebProductsApi.
 * @export
 * @interface WebProductsApiV2WebOrganizationsSlugProductsGetRequest
 */
export interface WebProductsApiV2WebOrganizationsSlugProductsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof WebProductsApiV2WebOrganizationsSlugProductsGet
     */
    readonly slug: string

    /**
     * 
     * @type {number}
     * @memberof WebProductsApiV2WebOrganizationsSlugProductsGet
     */
    readonly page?: number

    /**
     * The field you want to search. ie: name barcode
     * @type {string}
     * @memberof WebProductsApiV2WebOrganizationsSlugProductsGet
     */
    readonly by?: string

    /**
     * The content you want to search. ie: query prop1,prop2,prop3
     * @type {string}
     * @memberof WebProductsApiV2WebOrganizationsSlugProductsGet
     */
    readonly content?: string

    /**
     * Search by category_ids, comma separated. ie: 1,2,3
     * @type {string}
     * @memberof WebProductsApiV2WebOrganizationsSlugProductsGet
     */
    readonly categoryIds?: string

    /**
     * Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
     * @type {string}
     * @memberof WebProductsApiV2WebOrganizationsSlugProductsGet
     */
    readonly order?: string
}

/**
 * Request parameters for v2WebOrganizationsSlugProductsIdGet operation in WebProductsApi.
 * @export
 * @interface WebProductsApiV2WebOrganizationsSlugProductsIdGetRequest
 */
export interface WebProductsApiV2WebOrganizationsSlugProductsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof WebProductsApiV2WebOrganizationsSlugProductsIdGet
     */
    readonly slug: string

    /**
     * 
     * @type {string}
     * @memberof WebProductsApiV2WebOrganizationsSlugProductsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for v2WebOrganizationsSlugProductsIdModifiersGet operation in WebProductsApi.
 * @export
 * @interface WebProductsApiV2WebOrganizationsSlugProductsIdModifiersGetRequest
 */
export interface WebProductsApiV2WebOrganizationsSlugProductsIdModifiersGetRequest {
    /**
     * 
     * @type {string}
     * @memberof WebProductsApiV2WebOrganizationsSlugProductsIdModifiersGet
     */
    readonly slug: string

    /**
     * 
     * @type {string}
     * @memberof WebProductsApiV2WebOrganizationsSlugProductsIdModifiersGet
     */
    readonly id: string
}

/**
 * WebProductsApi - object-oriented interface
 * @export
 * @class WebProductsApi
 * @extends {BaseAPI}
 */
export class WebProductsApi extends BaseAPI {
    /**
     * 
     * @summary products#index
     * @param {WebProductsApiV1WebOrganizationsSlugProductsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebProductsApi
     */
    public v1WebOrganizationsSlugProductsGet(requestParameters: WebProductsApiV1WebOrganizationsSlugProductsGetRequest, options?: AxiosRequestConfig) {
        return WebProductsApiFp(this.configuration).v1WebOrganizationsSlugProductsGet(requestParameters.slug, requestParameters.page, requestParameters.by, requestParameters.content, requestParameters.categoryIds, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary products#index
     * @param {WebProductsApiV2WebOrganizationsSlugProductsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebProductsApi
     */
    public v2WebOrganizationsSlugProductsGet(requestParameters: WebProductsApiV2WebOrganizationsSlugProductsGetRequest, options?: AxiosRequestConfig) {
        return WebProductsApiFp(this.configuration).v2WebOrganizationsSlugProductsGet(requestParameters.slug, requestParameters.page, requestParameters.by, requestParameters.content, requestParameters.categoryIds, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary products#show
     * @param {WebProductsApiV2WebOrganizationsSlugProductsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebProductsApi
     */
    public v2WebOrganizationsSlugProductsIdGet(requestParameters: WebProductsApiV2WebOrganizationsSlugProductsIdGetRequest, options?: AxiosRequestConfig) {
        return WebProductsApiFp(this.configuration).v2WebOrganizationsSlugProductsIdGet(requestParameters.slug, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary products#modifiers
     * @param {WebProductsApiV2WebOrganizationsSlugProductsIdModifiersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebProductsApi
     */
    public v2WebOrganizationsSlugProductsIdModifiersGet(requestParameters: WebProductsApiV2WebOrganizationsSlugProductsIdModifiersGetRequest, options?: AxiosRequestConfig) {
        return WebProductsApiFp(this.configuration).v2WebOrganizationsSlugProductsIdModifiersGet(requestParameters.slug, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebRatingsApi - axios parameter creator
 * @export
 */
export const WebRatingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ratings#create
         * @param {string} code 
         * @param {V1WebSalesTransactionsCodeRatingsPostRequest} [v1WebSalesTransactionsCodeRatingsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebSalesTransactionsCodeRatingsPost: async (code: string, v1WebSalesTransactionsCodeRatingsPostRequest?: V1WebSalesTransactionsCodeRatingsPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('v1WebSalesTransactionsCodeRatingsPost', 'code', code)
            const localVarPath = `/v1/web/sales-transactions/{code}/ratings`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1WebSalesTransactionsCodeRatingsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebRatingsApi - functional programming interface
 * @export
 */
export const WebRatingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebRatingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ratings#create
         * @param {string} code 
         * @param {V1WebSalesTransactionsCodeRatingsPostRequest} [v1WebSalesTransactionsCodeRatingsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1WebSalesTransactionsCodeRatingsPost(code: string, v1WebSalesTransactionsCodeRatingsPostRequest?: V1WebSalesTransactionsCodeRatingsPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1WebSalesTransactionsCodeRatingsPost(code, v1WebSalesTransactionsCodeRatingsPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebRatingsApi - factory interface
 * @export
 */
export const WebRatingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebRatingsApiFp(configuration)
    return {
        /**
         * 
         * @summary ratings#create
         * @param {string} code 
         * @param {V1WebSalesTransactionsCodeRatingsPostRequest} [v1WebSalesTransactionsCodeRatingsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebSalesTransactionsCodeRatingsPost(code: string, v1WebSalesTransactionsCodeRatingsPostRequest?: V1WebSalesTransactionsCodeRatingsPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.v1WebSalesTransactionsCodeRatingsPost(code, v1WebSalesTransactionsCodeRatingsPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1WebSalesTransactionsCodeRatingsPost operation in WebRatingsApi.
 * @export
 * @interface WebRatingsApiV1WebSalesTransactionsCodeRatingsPostRequest
 */
export interface WebRatingsApiV1WebSalesTransactionsCodeRatingsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof WebRatingsApiV1WebSalesTransactionsCodeRatingsPost
     */
    readonly code: string

    /**
     * 
     * @type {V1WebSalesTransactionsCodeRatingsPostRequest}
     * @memberof WebRatingsApiV1WebSalesTransactionsCodeRatingsPost
     */
    readonly v1WebSalesTransactionsCodeRatingsPostRequest?: V1WebSalesTransactionsCodeRatingsPostRequest
}

/**
 * WebRatingsApi - object-oriented interface
 * @export
 * @class WebRatingsApi
 * @extends {BaseAPI}
 */
export class WebRatingsApi extends BaseAPI {
    /**
     * 
     * @summary ratings#create
     * @param {WebRatingsApiV1WebSalesTransactionsCodeRatingsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebRatingsApi
     */
    public v1WebSalesTransactionsCodeRatingsPost(requestParameters: WebRatingsApiV1WebSalesTransactionsCodeRatingsPostRequest, options?: AxiosRequestConfig) {
        return WebRatingsApiFp(this.configuration).v1WebSalesTransactionsCodeRatingsPost(requestParameters.code, requestParameters.v1WebSalesTransactionsCodeRatingsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebSalesTransactionsApi - axios parameter creator
 * @export
 */
export const WebSalesTransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary sales_transactions#create
         * @param {string} slug 
         * @param {V1WebOrganizationsSlugSalesTransactionsPostRequest} [v1WebOrganizationsSlugSalesTransactionsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebOrganizationsSlugSalesTransactionsPost: async (slug: string, v1WebOrganizationsSlugSalesTransactionsPostRequest?: V1WebOrganizationsSlugSalesTransactionsPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('v1WebOrganizationsSlugSalesTransactionsPost', 'slug', slug)
            const localVarPath = `/v1/web/organizations/{slug}/sales-transactions`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1WebOrganizationsSlugSalesTransactionsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebSalesTransactionsApi - functional programming interface
 * @export
 */
export const WebSalesTransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebSalesTransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary sales_transactions#create
         * @param {string} slug 
         * @param {V1WebOrganizationsSlugSalesTransactionsPostRequest} [v1WebOrganizationsSlugSalesTransactionsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1WebOrganizationsSlugSalesTransactionsPost(slug: string, v1WebOrganizationsSlugSalesTransactionsPostRequest?: V1WebOrganizationsSlugSalesTransactionsPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1WebOrganizationsSlugSalesTransactionsPost201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1WebOrganizationsSlugSalesTransactionsPost(slug, v1WebOrganizationsSlugSalesTransactionsPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebSalesTransactionsApi - factory interface
 * @export
 */
export const WebSalesTransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebSalesTransactionsApiFp(configuration)
    return {
        /**
         * 
         * @summary sales_transactions#create
         * @param {string} slug 
         * @param {V1WebOrganizationsSlugSalesTransactionsPostRequest} [v1WebOrganizationsSlugSalesTransactionsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebOrganizationsSlugSalesTransactionsPost(slug: string, v1WebOrganizationsSlugSalesTransactionsPostRequest?: V1WebOrganizationsSlugSalesTransactionsPostRequest, options?: any): AxiosPromise<V1WebOrganizationsSlugSalesTransactionsPost201Response> {
            return localVarFp.v1WebOrganizationsSlugSalesTransactionsPost(slug, v1WebOrganizationsSlugSalesTransactionsPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1WebOrganizationsSlugSalesTransactionsPost operation in WebSalesTransactionsApi.
 * @export
 * @interface WebSalesTransactionsApiV1WebOrganizationsSlugSalesTransactionsPostRequest
 */
export interface WebSalesTransactionsApiV1WebOrganizationsSlugSalesTransactionsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof WebSalesTransactionsApiV1WebOrganizationsSlugSalesTransactionsPost
     */
    readonly slug: string

    /**
     * 
     * @type {V1WebOrganizationsSlugSalesTransactionsPostRequest}
     * @memberof WebSalesTransactionsApiV1WebOrganizationsSlugSalesTransactionsPost
     */
    readonly v1WebOrganizationsSlugSalesTransactionsPostRequest?: V1WebOrganizationsSlugSalesTransactionsPostRequest
}

/**
 * WebSalesTransactionsApi - object-oriented interface
 * @export
 * @class WebSalesTransactionsApi
 * @extends {BaseAPI}
 */
export class WebSalesTransactionsApi extends BaseAPI {
    /**
     * 
     * @summary sales_transactions#create
     * @param {WebSalesTransactionsApiV1WebOrganizationsSlugSalesTransactionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebSalesTransactionsApi
     */
    public v1WebOrganizationsSlugSalesTransactionsPost(requestParameters: WebSalesTransactionsApiV1WebOrganizationsSlugSalesTransactionsPostRequest, options?: AxiosRequestConfig) {
        return WebSalesTransactionsApiFp(this.configuration).v1WebOrganizationsSlugSalesTransactionsPost(requestParameters.slug, requestParameters.v1WebOrganizationsSlugSalesTransactionsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebServiceCategoriesApi - axios parameter creator
 * @export
 */
export const WebServiceCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary service_categories#index
         * @param {string} slug 
         * @param {number} [page] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebOrganizationsSlugServiceCategoriesGet: async (slug: string, page?: number, content?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('v1WebOrganizationsSlugServiceCategoriesGet', 'slug', slug)
            const localVarPath = `/v1/web/organizations/{slug}/service-categories`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebServiceCategoriesApi - functional programming interface
 * @export
 */
export const WebServiceCategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebServiceCategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary service_categories#index
         * @param {string} slug 
         * @param {number} [page] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1WebOrganizationsSlugServiceCategoriesGet(slug: string, page?: number, content?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1WebOrganizationsSlugServiceCategoriesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1WebOrganizationsSlugServiceCategoriesGet(slug, page, content, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebServiceCategoriesApi - factory interface
 * @export
 */
export const WebServiceCategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebServiceCategoriesApiFp(configuration)
    return {
        /**
         * 
         * @summary service_categories#index
         * @param {string} slug 
         * @param {number} [page] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebOrganizationsSlugServiceCategoriesGet(slug: string, page?: number, content?: string, options?: any): AxiosPromise<V1WebOrganizationsSlugServiceCategoriesGet200Response> {
            return localVarFp.v1WebOrganizationsSlugServiceCategoriesGet(slug, page, content, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1WebOrganizationsSlugServiceCategoriesGet operation in WebServiceCategoriesApi.
 * @export
 * @interface WebServiceCategoriesApiV1WebOrganizationsSlugServiceCategoriesGetRequest
 */
export interface WebServiceCategoriesApiV1WebOrganizationsSlugServiceCategoriesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof WebServiceCategoriesApiV1WebOrganizationsSlugServiceCategoriesGet
     */
    readonly slug: string

    /**
     * 
     * @type {number}
     * @memberof WebServiceCategoriesApiV1WebOrganizationsSlugServiceCategoriesGet
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof WebServiceCategoriesApiV1WebOrganizationsSlugServiceCategoriesGet
     */
    readonly content?: string
}

/**
 * WebServiceCategoriesApi - object-oriented interface
 * @export
 * @class WebServiceCategoriesApi
 * @extends {BaseAPI}
 */
export class WebServiceCategoriesApi extends BaseAPI {
    /**
     * 
     * @summary service_categories#index
     * @param {WebServiceCategoriesApiV1WebOrganizationsSlugServiceCategoriesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServiceCategoriesApi
     */
    public v1WebOrganizationsSlugServiceCategoriesGet(requestParameters: WebServiceCategoriesApiV1WebOrganizationsSlugServiceCategoriesGetRequest, options?: AxiosRequestConfig) {
        return WebServiceCategoriesApiFp(this.configuration).v1WebOrganizationsSlugServiceCategoriesGet(requestParameters.slug, requestParameters.page, requestParameters.content, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebServicesApi - axios parameter creator
 * @export
 */
export const WebServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary services#index
         * @param {string} slug 
         * @param {number} [page] 
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [categoryIds] Search by category_ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebOrganizationsSlugServicesGet: async (slug: string, page?: number, content?: string, categoryIds?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('v1WebOrganizationsSlugServicesGet', 'slug', slug)
            const localVarPath = `/v1/web/organizations/{slug}/services`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }

            if (categoryIds !== undefined) {
                localVarQueryParameter['category_ids'] = categoryIds;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary services#modifiers
         * @param {string} slug 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebOrganizationsSlugServicesIdModifiersGet: async (slug: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('v1WebOrganizationsSlugServicesIdModifiersGet', 'slug', slug)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1WebOrganizationsSlugServicesIdModifiersGet', 'id', id)
            const localVarPath = `/v1/web/organizations/{slug}/services/{id}/modifiers`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebServicesApi - functional programming interface
 * @export
 */
export const WebServicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebServicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary services#index
         * @param {string} slug 
         * @param {number} [page] 
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [categoryIds] Search by category_ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1WebOrganizationsSlugServicesGet(slug: string, page?: number, content?: string, categoryIds?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1WebOrganizationsSlugServicesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1WebOrganizationsSlugServicesGet(slug, page, content, categoryIds, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary services#modifiers
         * @param {string} slug 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1WebOrganizationsSlugServicesIdModifiersGet(slug: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ServicesIdModifiersGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1WebOrganizationsSlugServicesIdModifiersGet(slug, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebServicesApi - factory interface
 * @export
 */
export const WebServicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebServicesApiFp(configuration)
    return {
        /**
         * 
         * @summary services#index
         * @param {string} slug 
         * @param {number} [page] 
         * @param {string} [content] The content you want to search. ie: query prop1,prop2,prop3
         * @param {string} [categoryIds] Search by category_ids, comma separated. ie: 1,2,3
         * @param {string} [order] Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebOrganizationsSlugServicesGet(slug: string, page?: number, content?: string, categoryIds?: string, order?: string, options?: any): AxiosPromise<V1WebOrganizationsSlugServicesGet200Response> {
            return localVarFp.v1WebOrganizationsSlugServicesGet(slug, page, content, categoryIds, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary services#modifiers
         * @param {string} slug 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebOrganizationsSlugServicesIdModifiersGet(slug: string, id: string, options?: any): AxiosPromise<V1ServicesIdModifiersGet200Response> {
            return localVarFp.v1WebOrganizationsSlugServicesIdModifiersGet(slug, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1WebOrganizationsSlugServicesGet operation in WebServicesApi.
 * @export
 * @interface WebServicesApiV1WebOrganizationsSlugServicesGetRequest
 */
export interface WebServicesApiV1WebOrganizationsSlugServicesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof WebServicesApiV1WebOrganizationsSlugServicesGet
     */
    readonly slug: string

    /**
     * 
     * @type {number}
     * @memberof WebServicesApiV1WebOrganizationsSlugServicesGet
     */
    readonly page?: number

    /**
     * The content you want to search. ie: query prop1,prop2,prop3
     * @type {string}
     * @memberof WebServicesApiV1WebOrganizationsSlugServicesGet
     */
    readonly content?: string

    /**
     * Search by category_ids, comma separated. ie: 1,2,3
     * @type {string}
     * @memberof WebServicesApiV1WebOrganizationsSlugServicesGet
     */
    readonly categoryIds?: string

    /**
     * Order by the field you want. ie: name,+ name,- created_at,+ created_at,- price_cents,+ price_cents,-
     * @type {string}
     * @memberof WebServicesApiV1WebOrganizationsSlugServicesGet
     */
    readonly order?: string
}

/**
 * Request parameters for v1WebOrganizationsSlugServicesIdModifiersGet operation in WebServicesApi.
 * @export
 * @interface WebServicesApiV1WebOrganizationsSlugServicesIdModifiersGetRequest
 */
export interface WebServicesApiV1WebOrganizationsSlugServicesIdModifiersGetRequest {
    /**
     * 
     * @type {string}
     * @memberof WebServicesApiV1WebOrganizationsSlugServicesIdModifiersGet
     */
    readonly slug: string

    /**
     * 
     * @type {string}
     * @memberof WebServicesApiV1WebOrganizationsSlugServicesIdModifiersGet
     */
    readonly id: string
}

/**
 * WebServicesApi - object-oriented interface
 * @export
 * @class WebServicesApi
 * @extends {BaseAPI}
 */
export class WebServicesApi extends BaseAPI {
    /**
     * 
     * @summary services#index
     * @param {WebServicesApiV1WebOrganizationsSlugServicesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServicesApi
     */
    public v1WebOrganizationsSlugServicesGet(requestParameters: WebServicesApiV1WebOrganizationsSlugServicesGetRequest, options?: AxiosRequestConfig) {
        return WebServicesApiFp(this.configuration).v1WebOrganizationsSlugServicesGet(requestParameters.slug, requestParameters.page, requestParameters.content, requestParameters.categoryIds, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary services#modifiers
     * @param {WebServicesApiV1WebOrganizationsSlugServicesIdModifiersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebServicesApi
     */
    public v1WebOrganizationsSlugServicesIdModifiersGet(requestParameters: WebServicesApiV1WebOrganizationsSlugServicesIdModifiersGetRequest, options?: AxiosRequestConfig) {
        return WebServicesApiFp(this.configuration).v1WebOrganizationsSlugServicesIdModifiersGet(requestParameters.slug, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebTransactionsApi - axios parameter creator
 * @export
 */
export const WebTransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary web_transactions#count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebTransactionsCountGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/web-transactions/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary web_transactions#index
         * @param {'in-approval' | 'in-progress' | 'finished' | 'cancelled'} processStatus 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebTransactionsGet: async (processStatus: 'in-approval' | 'in-progress' | 'finished' | 'cancelled', page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processStatus' is not null or undefined
            assertParamExists('v1WebTransactionsGet', 'processStatus', processStatus)
            const localVarPath = `/v1/web-transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (processStatus !== undefined) {
                localVarQueryParameter['process_status'] = processStatus;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary web_transactions#approve
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebTransactionsIdApprovePatch: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1WebTransactionsIdApprovePatch', 'id', id)
            const localVarPath = `/v1/web-transactions/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary web_transactions#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebTransactionsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1WebTransactionsIdGet', 'id', id)
            const localVarPath = `/v1/web-transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary web_transactions#reject
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebTransactionsIdRejectPatch: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1WebTransactionsIdRejectPatch', 'id', id)
            const localVarPath = `/v1/web-transactions/{id}/reject`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebTransactionsApi - functional programming interface
 * @export
 */
export const WebTransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebTransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary web_transactions#count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1WebTransactionsCountGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1WebTransactionsCountGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1WebTransactionsCountGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary web_transactions#index
         * @param {'in-approval' | 'in-progress' | 'finished' | 'cancelled'} processStatus 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1WebTransactionsGet(processStatus: 'in-approval' | 'in-progress' | 'finished' | 'cancelled', page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1WebTransactionsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1WebTransactionsGet(processStatus, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary web_transactions#approve
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1WebTransactionsIdApprovePatch(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1WebTransactionsIdApprovePatch(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary web_transactions#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1WebTransactionsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1WebTransactionsIdGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1WebTransactionsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary web_transactions#reject
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1WebTransactionsIdRejectPatch(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1WebTransactionsIdRejectPatch(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebTransactionsApi - factory interface
 * @export
 */
export const WebTransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebTransactionsApiFp(configuration)
    return {
        /**
         * 
         * @summary web_transactions#count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebTransactionsCountGet(options?: any): AxiosPromise<V1WebTransactionsCountGet200Response> {
            return localVarFp.v1WebTransactionsCountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary web_transactions#index
         * @param {'in-approval' | 'in-progress' | 'finished' | 'cancelled'} processStatus 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebTransactionsGet(processStatus: 'in-approval' | 'in-progress' | 'finished' | 'cancelled', page?: number, options?: any): AxiosPromise<V1WebTransactionsGet200Response> {
            return localVarFp.v1WebTransactionsGet(processStatus, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary web_transactions#approve
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebTransactionsIdApprovePatch(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1WebTransactionsIdApprovePatch(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary web_transactions#show
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebTransactionsIdGet(id: string, options?: any): AxiosPromise<V1WebTransactionsIdGet200Response> {
            return localVarFp.v1WebTransactionsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary web_transactions#reject
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WebTransactionsIdRejectPatch(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1WebTransactionsIdRejectPatch(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v1WebTransactionsGet operation in WebTransactionsApi.
 * @export
 * @interface WebTransactionsApiV1WebTransactionsGetRequest
 */
export interface WebTransactionsApiV1WebTransactionsGetRequest {
    /**
     * 
     * @type {'in-approval' | 'in-progress' | 'finished' | 'cancelled'}
     * @memberof WebTransactionsApiV1WebTransactionsGet
     */
    readonly processStatus: 'in-approval' | 'in-progress' | 'finished' | 'cancelled'

    /**
     * 
     * @type {number}
     * @memberof WebTransactionsApiV1WebTransactionsGet
     */
    readonly page?: number
}

/**
 * Request parameters for v1WebTransactionsIdApprovePatch operation in WebTransactionsApi.
 * @export
 * @interface WebTransactionsApiV1WebTransactionsIdApprovePatchRequest
 */
export interface WebTransactionsApiV1WebTransactionsIdApprovePatchRequest {
    /**
     * 
     * @type {string}
     * @memberof WebTransactionsApiV1WebTransactionsIdApprovePatch
     */
    readonly id: string
}

/**
 * Request parameters for v1WebTransactionsIdGet operation in WebTransactionsApi.
 * @export
 * @interface WebTransactionsApiV1WebTransactionsIdGetRequest
 */
export interface WebTransactionsApiV1WebTransactionsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof WebTransactionsApiV1WebTransactionsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for v1WebTransactionsIdRejectPatch operation in WebTransactionsApi.
 * @export
 * @interface WebTransactionsApiV1WebTransactionsIdRejectPatchRequest
 */
export interface WebTransactionsApiV1WebTransactionsIdRejectPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof WebTransactionsApiV1WebTransactionsIdRejectPatch
     */
    readonly id: string
}

/**
 * WebTransactionsApi - object-oriented interface
 * @export
 * @class WebTransactionsApi
 * @extends {BaseAPI}
 */
export class WebTransactionsApi extends BaseAPI {
    /**
     * 
     * @summary web_transactions#count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebTransactionsApi
     */
    public v1WebTransactionsCountGet(options?: AxiosRequestConfig) {
        return WebTransactionsApiFp(this.configuration).v1WebTransactionsCountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary web_transactions#index
     * @param {WebTransactionsApiV1WebTransactionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebTransactionsApi
     */
    public v1WebTransactionsGet(requestParameters: WebTransactionsApiV1WebTransactionsGetRequest, options?: AxiosRequestConfig) {
        return WebTransactionsApiFp(this.configuration).v1WebTransactionsGet(requestParameters.processStatus, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary web_transactions#approve
     * @param {WebTransactionsApiV1WebTransactionsIdApprovePatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebTransactionsApi
     */
    public v1WebTransactionsIdApprovePatch(requestParameters: WebTransactionsApiV1WebTransactionsIdApprovePatchRequest, options?: AxiosRequestConfig) {
        return WebTransactionsApiFp(this.configuration).v1WebTransactionsIdApprovePatch(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary web_transactions#show
     * @param {WebTransactionsApiV1WebTransactionsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebTransactionsApi
     */
    public v1WebTransactionsIdGet(requestParameters: WebTransactionsApiV1WebTransactionsIdGetRequest, options?: AxiosRequestConfig) {
        return WebTransactionsApiFp(this.configuration).v1WebTransactionsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary web_transactions#reject
     * @param {WebTransactionsApiV1WebTransactionsIdRejectPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebTransactionsApi
     */
    public v1WebTransactionsIdRejectPatch(requestParameters: WebTransactionsApiV1WebTransactionsIdRejectPatchRequest, options?: AxiosRequestConfig) {
        return WebTransactionsApiFp(this.configuration).v1WebTransactionsIdRejectPatch(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


